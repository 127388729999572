import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {DEFAULT_CRUD_STATE, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import ExtClientRoleDialog from "./ext-client-role-dialog";
import SimpleTable from "../../components/simple-table";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";
import {LockClosedIcon} from "@heroicons/react/solid";
import {getSecondResource} from "../../data/actions/secondResource";
import {ClipboardCopyIcon, PhoneOutgoingIcon} from "@heroicons/react/outline";

class ExtClientRolesPage extends Component {

    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search);
        const RoleStatusID = params.get('stateFilter');


        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "RoleName",

            query: "",
            archived: false,

            fields: this.getFields(),
            search: {
                RoleStatusID: new Field("RoleStatusID", RoleStatusID ?? '', [], false, 'select'),
            }
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));

        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {},
            resource: Resources.ExtClientDashboard
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    }

    handleSearchChange = (name, value) => {
        this.setState({
            search: FieldsManager.updateField(this.state.search, name, value),
            paginationPage: 1,
            offset: 0
        }, () => this.fetchData());
    };

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.ExtClientRoles;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            RoleStatusID: this.state.search.RoleStatusID.value
        }
    }

    getFields = (item = null, excludeField = null) => {
        const fieldTemplates = {
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select'),
            SpecialityID: new Field("SpecialityID", '', [], false, 'select'),
            ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
            ContactID: new Field("ContactID", item ? (item.FirstName + " " + item.LastName) : "", [], false, 'select-search'),
            RoleStatusID: new Field("RoleStatusID", '', ['empty'], false, 'select'),
            PostDate: new Field("PostDate", '', [], false, 'date'),
            FillDate: new Field("FillDate", '', [], false, 'date'),
            FilledCandidateID: new Field("FilledCandidateID", item ? (item.FilledCandidate) : "", [], false, 'select-search'),
            AgreedSalary: new Field("AgreedSalary", '', ['float_or_empty'], false, 'text')
        };

        delete fieldTemplates[excludeField];

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const cards = [
            {
                name: 'Open roles',
                RoleStatusID: 1,
                icon: ClipboardCopyIcon,
                amount: getProp(this.props, "secondResource.data.openCount", 0)
            },
            {
                name: 'Interviewing roles',
                RoleStatusID: 2,
                icon: PhoneOutgoingIcon,
                amount: getProp(this.props, "secondResource.data.interviewCount", 0)
            },
            {
                name: 'Filled roles',
                RoleStatusID: 3,
                icon: LockClosedIcon,
                amount: getProp(this.props, "secondResource.data.closedCount", 0)
            },
        ];

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.roles")}
                    >
                    </PageHeader>

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">

                        {/* Overview cards */}
                        <div className="sm:mb-6 lg:mb-8">
                            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                {/* Card */}
                                {cards.map((card) => (
                                    <div key={card.name} className="bg-inverse overflow-hidden shadow rounded-lg">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <card.icon className="h-6 w-6 text-secondary-400"
                                                               aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="text-sm font-medium text-secondary-500 truncate">{card.name}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-secondary-900">{card.amount}</div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                className="w-full"
                                classNameContainer={"md:mr-6 mb-3 w-full md:w-auto"}
                            />
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleToggleView}
                        />
                    </div>

                    <ExtClientRoleDialog
                        {...this.props}
                        id={getProp(this.state.selectedItem, this.getPrimaryKey(), 0)}
                        visible={!!this.state.viewModalOpen}
                        onClose={this.handleToggleView}
                    />

                </main>

            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ExtClientRolesPage);