import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import {classNames} from "../../util/util";
import ReportsTab from "./reportsTab";
import PageHeader from "../../components/layout-dashboard/page-header";
import NavResponsive from "../../components/nav-responsive";

class ReportsPage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Client report', resource: Resources.ReportsClient, current: true},
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {

    };

    /** Data Events
     ================================================================= */


    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <LayoutDashboard {...this.props}>
                <PageHeader
                    title={translate("page.title.reports")}
                />

                <main className="pb-16 relative custom-min-h-page">
                    <div className="pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="">
                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>

                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                    onChange={e => this.handleTabChange(e.target.value)}
                                >
                                    {this.state.tabs.map((tab) => (
                                        <option
                                            key={tab.name}
                                            value={tab.resource}
                                        >
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="border-b-2 border-primary-500 mt-2"></div>
                            </div>

                            <div className="hidden sm:block">
                                <div className="border-b border-secondary-200">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* Content */}
                        <div className="">
                            {this.state.selectedTab === Resources.ReportsClient && (
                                <ReportsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ReportsPage);