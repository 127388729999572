import React, {Component, Fragment} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {classNames, getProp, toFrontDate} from "../../util/util";
import DescriptionTab from "./../client-single-page/roles/descriptionTab";
import {getDialogResourceDetails} from "../../data/actions/dialogResource";
import {XIcon} from "@heroicons/react/outline";
import {Field} from "../../data/services/fields";
import DialogResourceListTab from "../../components/resource-dialog-list-tab";
import CandidatesTab from "./../client-single-page/roles/candidatesTab";
import {BriefcaseIcon, CalendarIcon, LocationMarkerIcon} from "@heroicons/react/solid";
import DialogDocumentsTab from "../common/dialogDocumentsTab";
import CandidateInterviewTab from "./../client-single-page/roles/candidateInterviewTab";
import Loader from "../../components/loader";
import CandidateMeetingTab from "./../client-single-page/roles/candidateMeetingTab";

export default class ExtClientRoleDialog extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Dates', resource: Resources.ExtRoleDates, current: true},

            {name: 'Description', resource: Resources.ExtRoleDescription, current: false},
            {name: 'Candidates', resource: Resources.ExtRoleCandidates, current: false},

            {name: 'Media', resource: Resources.ExtRoleMedia, current: false},
            {name: 'Documents', resource: Resources.ExtRoleDocuments, current: false},
            {name: 'Interview', resource: Resources.ExtRoleCandidateInterview, current: false},
            {name: 'Meeting', resource: Resources.ExtRoleCandidateMeeting, current: false},

            {name: 'Feedback', resource: Resources.ExtRoleFeedback, current: false}
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                selectedTab: null
            }, () => {
                this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.ExtRoleDates)
            });
        }

        if ((prevProps.dialogResource.detailsData === null) && (!!this.props.dialogResource.detailsData)) {
            LocalStorage.getAndSave('rolesHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.dialogResource.detailsData.RoleID,
                    name: this.props.dialogResource.detailsData.RoleName
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.ExtRole
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleCopyToClipboard = (link) => {
        this.setState({urlCopied: true}, () => {
            let textField = window.document.getElementById('cpBuffer')
            textField.innerText = link;
            textField.className = "";
            textField.select();
            // textField.setSelectionRange(0, 99999);
            window.document.execCommand('copy');
            textField.className = "invisible";
        });

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getShareLink = () => {
        const role = getProp(this.props, "dialogResource.detailsData", {});

        return window.location.origin + '/role/' + role.SeoUrl;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose, dialogResource} = this.props;

        const role = getProp(this.props, "dialogResource.detailsData", {});

        const isLoading = getProp(this.props, "dialogResource.isLoadingDetails", false);

        return (
            <Transition show={visible} as={Fragment}>
                <Dialog
                    as="div"
                    id="modal"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    static
                    open={visible}
                    onClose={onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="min-h-90vh theme-dark-popup inline-block w-full my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() => onClose(false)}
                                    >
                                        <span className="sr-only">{translate("text.close")}</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                {/* Page title */}
                                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                    <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                        {role.RoleName}
                                    </h1>
                                    {(!dialogResource.isLoading || role.RoleName) && (
                                        <div
                                            className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <LocationMarkerIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {role.ClientLocation}
                                            </div>
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <BriefcaseIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {role.Title}
                                            </div>
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <CalendarIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {toFrontDate(role.PostDate)}
                                            </div>
                                        </div>
                                    )}

                                    {isLoading && (
                                        <div className={"inset-center"}>
                                            <Loader/>
                                        </div>
                                    )}
                                </div>

                                <div className="p-4">
                                    {/* Tabs */}
                                    <div className="sm:hidden">
                                        <label htmlFor="tabs" className="sr-only">
                                            {translate("text.select_tab")}
                                        </label>

                                        <select
                                            id="tabs"
                                            name="tabs"
                                            className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                            defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                            onChange={e => this.handleTabChange(e.target.value)}
                                        >
                                            {this.state.tabs.map((tab) => (
                                                <option
                                                    key={tab.name}
                                                    value={tab.resource}
                                                >
                                                    {tab.name}
                                                </option>
                                            ))}
                                        </select>

                                        <div className="border-b-2 border-primary-500 mt-6"></div>
                                    </div>

                                    <div className="hidden sm:block">
                                        <div className="border-b border-secondary-200">
                                            <nav className="mt-2 -mb-px flex space-x-8 overflow-y-auto"
                                                 aria-label="Tabs">
                                                {this.state.tabs.map((tab) => (
                                                    <button
                                                        key={tab.name}
                                                        onClick={() => this.handleTabChange(tab.resource)}
                                                        className={classNames(
                                                            tab.current
                                                                ? 'border-primary-500 text-primary-600'
                                                                : 'border-transparent text-secondary-500 hover:text-secondary-700 hover:border-secondary-200',
                                                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                        )}
                                                    >
                                                        {tab.name}
                                                        {tab.count ? (
                                                            <span
                                                                className={classNames(
                                                                    tab.current ? 'bg-primary-100 text-primary-600' : 'bg-secondary-100 text-secondary-900',
                                                                    'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                                )}
                                                            >
                                                          {tab.count}
                                                        </span>
                                                        ) : null}
                                                    </button>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>

                                    {/* Content */}
                                    <div className="mt-10">

                                        {this.state.selectedTab === Resources.ExtRoleDates && (
                                            <DialogResourceListTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}

                                                dialogTitle={"Expense"}

                                                sortBy={"RoleDatesID"}
                                                primaryKey={"RoleDatesID"}
                                                resourceName={Resources.ExtRoleDates}
                                                fields={{
                                                    Milestone: new Field("Milestone", '', ['empty'], false),
                                                    Date: new Field("Date", '', ['empty'], false, 'datetime'),
                                                    Description: new Field("Description", '', ['empty'], false, 'textarea')
                                                }}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleDescription && (
                                            <DescriptionTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}
                                                resourceName={this.state.selectedTab}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleCandidates && (
                                            <CandidatesTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}
                                                role={role}
                                                resourceName={this.state.selectedTab}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleMedia && (
                                            <DialogDocumentsTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}

                                                resourceName={Resources.ExtRoleMedia}
                                                primaryKey={"RoleMediaID"}
                                                mediaTypesText={translate("text.video_formats")}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleDocuments && (
                                            <DialogDocumentsTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}

                                                resourceName={this.state.selectedTab}
                                                primaryKey={"RoleDocumentID"}

                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleCandidateInterview && (
                                            <CandidateInterviewTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                history={this.props.history}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}
                                                resourceName={this.state.selectedTab}
                                                role={role}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleCandidateMeeting && (
                                            <CandidateMeetingTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                history={this.props.history}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}
                                                role={role}
                                                dialogTitle={"Meeting"}

                                                sortBy={"MeetingID"}
                                                primaryKey={"MeetingID"}
                                                resourceName={Resources.ExtRoleCandidateMeeting}
                                                fields={{
                                                    CandidateID: new Field("CandidateID", "", ['empty'], false, 'select-search'),
                                                    Date: new Field("Date", '', ['empty'], false, 'datetime'),
                                                    Description: new Field("Description", '', ['empty'], false, 'textarea')
                                                }}
                                                metadata={{
                                                    CandidateID: {
                                                        api: 'api/' + Resources.Candidates,
                                                        query: {},
                                                        searchMap: (item) => ({
                                                            value: item.CandidateID,
                                                            label: item.FirstName + ' ' + item.LastName
                                                        })
                                                    },
                                                }}
                                            />
                                        )}

                                        {this.state.selectedTab === Resources.ExtRoleFeedback && (
                                            <DialogResourceListTab
                                                id={this.getId()}
                                                dispatch={this.props.dispatch}
                                                translate={translate}
                                                dialogResource={this.props.dialogResource}

                                                dialogTitle={"Feedback"}

                                                sortBy={"OrderNum"}
                                                primaryKey={"RoleFeedbackID"}
                                                resourceName={Resources.ExtRoleFeedback}
                                                fields={{
                                                    OrderNum: new Field("OrderNum", '', ['integer'], false, 'text'),
                                                    Title: new Field("Title", '', ['empty'], false, 'text'),
                                                    Description: new Field("Description", '', [''], false, 'textarea'),
                                                    FeedbackQuestionTypeID: new Field("FeedbackQuestionTypeID", '', ['empty'], false, 'select')
                                                }}
                                            />
                                        )}
                                    </div>

                                </div>

                                <textarea id={"cpBuffer"} className={"invisible"}/>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        )
    }
}