import React, {Component} from "react";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fieldsToHtml, fillFieldsFromData, getProp, isNotificationVisible} from "../../util/util";
import {Field} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";
import Slideover from "../../components/slideover";
import RoleCard from "../../components/role-card";
import LayoutDashboard from "../../components/layout-dashboard";
import FieldTextarea from "../../components/field-textarea";
import {getSecondResource} from "../../data/actions/secondResource";
import {ClipboardCheckIcon, ClipboardCopyIcon} from "@heroicons/react/outline";
import Loader from "../../components/loader";
import {getDialogResource} from "../../data/actions/dialogResource";
import ExtCandidateRoleSurvey from "./ext-candidate-role-survey";
import DialogDefault from "../../components/dialog-default";

class RolesExtPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "",
            sortBy: "",

            query: "",
            fields: this.getFields(),
            questionsFields: {},

            CandidateProfileID: null,

            selectedItem: null,
            viewModalOpen: false,
            applyModalOpen: false,
            applyEditModalOpen: false,
            applySurveyOpen: false,
            recruiterModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.ExtDashboard
        }));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading && !!this.props.resource?.data?.questions) {
            this.setState({
                questionsFields: Object.values(getProp(this.props, "resource.data.questions", {})).reduce((outerMemo, outerIt) => {
                    const newKeys = outerIt.reduce((memo, it) => {
                        memo[it.RoleQuestionsID] = it.Answer;
                        return memo;
                    }, {})

                    return Object.assign({}, outerMemo, newKeys);
                }, {})
            }, () => {

            });
        }

        if(!prevProps.resource.create?.id && !!this.props.resource.create?.id) {
            this.fetchSurveyData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    fetchSurveyData = () => {
        const role = this.state.selectedItem;
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: Object.assign({
                RoleID: role.RoleID,
            }, this.getQuery()),
            resource: Resources.ExtCandidateRolesSurvey
        }));
    }

    applyToRole = () => {
        const role = this.state.selectedItem;
        const questionsFields = this.state.questionsFields;
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                CandidateProfileID: this.state.CandidateProfileID,
                RoleID: role.RoleID,
                Answers: Object.keys(questionsFields).map((key) => {
                    return {
                        RoleQuestionsID: key,
                        Description: questionsFields[key]
                    }
                })
            },
            notificationMessage: "You have applied to the role " + role.RoleName,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));

        this.setState({
            applySurveyOpen: true,
            applyEditModalOpen: false,
            applyModalOpen: false
        })
    }

    editRoleApplication = () => {
        const role = this.state.selectedItem;
        const questionsFields = this.state.questionsFields;
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                RoleID: role.RoleID,
                Answers: Object.keys(questionsFields).map((key) => {
                    return {
                        RoleQuestionsID: key,
                        Description: questionsFields[key]
                    }
                })
            },
            notificationMessage: "You have updated your role application",
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));

        this.handleToggleViewModal({});
    }

    removeApplication = () => {
        const role = this.state.selectedItem;
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            piggyQuery: this.getQuery(),
            query: {
                RoleID: role.RoleID
            },
            notificationMessage: "You have removed your application for the role " + role.RoleName,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
        this.handleToggleViewModal({});
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleToggleViewModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen,
            applyModalOpen: false,
            applyEditModalOpen: false,
            applySurveyOpen: false
        })
    }

    handleQuestionAnswerChange = (question, answer) => {
        let questionsFields = this.state.questionsFields;
        questionsFields[question] = answer;
        this.setState({
            questionsFields: questionsFields
        })
    }

    handleApplyToRole = () => {
        this.setState({
            applyModalOpen: true
        });
    }

    handleEditRoleApplication = () => {
        this.setState({
            applyEditModalOpen: true
        });
    }

    handleEditRoleSurvey = () => {
        this.setState({
            applySurveyOpen: true,
            applyEditModalOpen: false,
            applyModalOpen: false
        }, () => {
            this.fetchSurveyData();
        });
    }

    toggleRecruiterModal = (it = null) => {
        this.setState({
            recruiterModalOpen: !this.state.recruiterModalOpen,
            selectedRecruiter: it
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.ExtRoles;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            token: this.props.match.params && this.props.match.params.token
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select'),
            SpecialityID: new Field("SpecialityID", '', [], false, 'select'),
            LocationName: new Field("LocationName", '', [], false, 'text'),
            RecruiterID: new Field("RecruiterID", '', [], false, 'select', {onCellClick: (it) => this.toggleRecruiterModal(it)}),
            Contacted: new Field("Contacted", '', [], false, 'checkbox'),
            Applied: new Field("Applied", '', [], false, 'checkbox')
            //Applied, Responsibilities, ClientLocationID, LocationName, AdvDescription
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const questions = getProp(this.props, "resource.data.questions", {});

        const questionsToRender = this.state.selectedItem ? getProp(questions, this.state.selectedItem.RoleID + "", []).map((it) => {
            return (
                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:py-5">
                    <label htmlFor={it.RoleQuestionsID}
                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                        {it.Description}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <FieldTextarea
                            className="form-control h-28"
                            onChange={(name, value) => {
                                this.handleQuestionAnswerChange(it.RoleQuestionsID, value)
                            }}
                            name={it.RoleQuestionsID}
                            addClass={"form-control"}
                            value={this.state.questionsFields[it.RoleQuestionsID] ?? ""}
                        />
                    </div>
                </div>
            )
        }) : [];

        const cards = [
            {
                name: 'Open roles',
                href: '/roles-board',
                icon: ClipboardCopyIcon,
                amount: getProp(this.props, "secondResource.data.openCount", 0)
            },
            {
                name: 'Applied roles',
                href: '/roles-board',
                icon: ClipboardCheckIcon,
                amount: getProp(this.props, "secondResource.data.appliedCount", 0)
            }
        ];

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    {!this.props.resource.isLoading && (
                        <PageHeader
                            title={translate("page.title.roles")}
                        />
                    )}


                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">

                        {/* Overview cards */}
                        <div className="sm:mb-6 lg:mb-8">
                            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                {/* Card */}
                                {cards.map((card) => (
                                    <div key={card.name} className="bg-inverse overflow-hidden shadow rounded-lg">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <card.icon className="h-6 w-6 text-secondary-400"
                                                               aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className="text-sm font-medium text-secondary-500 truncate">{card.name}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-secondary-900">{card.amount}</div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleToggleViewModal}
                        />{/* Switched view and edit on purpose */}
                    </div>
                </main>

                <Slideover
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                    customWidth="max-w-2xl"
                    disableCloseOnOutsideClick={isNotificationVisible(this.props.ui)}
                    // Show profile version selector if available, more than 1 profile exists
                >
                    {!this.state.applyModalOpen && !this.state.applyEditModalOpen && !this.state.applySurveyOpen && (
                        <RoleCard
                            token={this.props.match.params && this.props.match.params.token}
                            role={this.state.selectedItem}
                            dispatch={this.props.dispatch}
                            onApplyToRole={this.handleApplyToRole}
                            onEditRoleApplication={this.handleEditRoleApplication}
                            onEditRoleSurvey={this.handleEditRoleSurvey}
                            onRemoveRole={this.removeApplication}
                        />
                    )}

                    {this.state.applyModalOpen && (
                        <>
                            <p className="mb-4">Select version of your profile that you want to use for the application.</p>

                            {fieldsToHtml([new Field("CandidateProfileID", this.state.CandidateProfileID, ['empty'], false, 'select-search')], translate, (name, value) => {
                                this.setState({
                                    CandidateProfileID: value
                                })
                            }, {
                                CandidateProfileID: {
                                    api: 'api/' + Resources.ExtRoleVersionCreate,
                                    query: {},
                                    searchMap: (item) => ({
                                        value: item.CandidateProfileID,
                                        label: item.ProfileName,
                                    })
                                }
                            })}

                            <p className={"mt-2"}>Would you mind answering few questions before applying to the role?</p>

                            <div>
                                {questionsToRender}
                            </div>

                            <button
                                type="button"
                                onClick={this.applyToRole}
                                className="btn btn-primary mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                            >
                                Confirm application
                            </button>
                        </>
                    )}

                    {this.state.applyEditModalOpen && (
                        <>
                            <p>Here are your answers so far, update them if needed.</p>

                            <div>
                                {questionsToRender}
                            </div>

                            <button
                                type="button"
                                onClick={this.editRoleApplication}
                                className="btn btn-primary mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                            >
                                Confirm answers update
                            </button>
                        </>
                    )}

                    {this.state.applySurveyOpen && (
                        <React.Fragment>
                            {!isLoading && (
                                <ExtCandidateRoleSurvey
                                    role={this.state.selectedItem}
                                    onClose={this.handleToggleViewModal}
                                    getQuery={this.getQuery}
                                    {...this.props}
                                />
                            )}

                            {!!isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </Slideover>

                <DialogDefault
                    title={"Recruiter Card"}
                    visible={this.state.recruiterModalOpen}
                    widthClass={"max-w-xl"}
                    onClose={() => this.toggleRecruiterModal()} // prevents data loss during closing animation
                    translate={translate}
                >
                    <h2 className="text-lg mb-3 mt-4">{"Contact info for " + this.state.selectedRecruiter?.Recruiter}</h2>

                    {fieldsToHtml(Object.values(Object.assign({}, {
                        Recruiter: new Field("RecruiterName", this.state.selectedRecruiter?.Recruiter, [], false, 'readonly'),
                        RecruiterEmail: new Field("RecruiterEmail", this.state.selectedRecruiter?.RecruiterEmail, [], false, 'readonly'),
                        RecruiterPhone: new Field("RecruiterPhone", this.state.selectedRecruiter?.RecruiterPhone, [], false, 'readonly')
                    })), translate)}

                    <div className="mb-3"/>
                </DialogDefault>

            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(RolesExtPage);