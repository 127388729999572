import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp} from "../../util/util";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";
import {getSecondResource} from "../../data/actions/secondResource";

export default class SmsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedNumber: null,
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
        this.fetchNumbersData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    fetchNumbersData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.CompanyPhoneNumberList
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit && !!this.state.Number) {
            this.setState({canSubmit: false}, () => {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: {
                        Number: this.state.selectedNumber
                    },
                    resource: this.getResourceName(),
                    piggyResource: this.getResourceName()
                }));
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleNumberSelected = (number) => {
        this.setState({
            canSubmit: true,
            selectedNumber: number
        })
    }

    handleCancel = () => {
        this.setState({
            selectedNumber: null,
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.CompanyPhoneNumber;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const data = getProp(this.props, "resource.data", {});

        const numberList = getProp(this.props, "secondResource.data.list", []).map((it) => {
            return (
                <div
                    className={" hover:cursor-pointer col-span-1 hover:bg-primary-600 hover:text-white " + (this.state.selectedNumber == it.phone ? ("text-white bg-primary-600") : "text-primary-500")}
                    onClick={() => this.handleNumberSelected(it.phone)}
                >
                    <span>{it.phone}</span>
                </div>
            )
        })
        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                {!isLoading && (
                    <div className="pt-5">
                        <div>
                            {!!data.Number && (
                                <div className={"mb-40 text-xl"}>
                                    <p className="">
                                        Currently assigned Company phone number: <span
                                        className={"font-bold"}>{data.Number}</span>.
                                    </p>
                                    <p>
                                        This number is used for communication with Candidates via SMS messaging.
                                    </p>
                                </div>
                            )}
                            {!data.Number && (
                                <div className={"mb-5 text-xl"}>
                                    <p>
                                        Company phone number is not selected.
                                    </p>
                                    <p>
                                        When a phone number is selected, Candidate SMS messaging will be enabled.
                                    </p>
                                </div>
                            )}

                            {!data.Number && (
                                <div className="mb-32 text-xl">
                                    {this.state.selectedNumber && (
                                        <div className={"mt-2 mb-2 font-bold"}>
                                            Selected number: <span>{this.state.selectedNumber}</span>
                                        </div>
                                    )}
                                    <h3 className={"mt-2 mb-2"}>List of available phone numbers:</h3>
                                    <div className={"grid grid-cols-2 gap-4 mt-4 text-center md:grid-cols-5"}>
                                        {numberList}
                                    </div>

                                    <div className="flex justify-end mt-10">
                                        <Button type="outline"
                                                className="btn btn-outline focus:ring-offset-inverse"
                                                disabled={!this.state.canSubmit}
                                                onClick={this.handleCancel}
                                        >
                                            {translate("btn.cancel")}
                                        </Button>

                                        <Button
                                            type="submit"
                                            className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                            disabled={!this.state.canSubmit}
                                            onClick={this.state.canSubmit && this.onSubmit}
                                        >
                                            {translate("btn.save")}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                )}
            </div>
        )
    }
}
