import React, {Component, Fragment} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {getProp, toFrontDate} from "../../../util/util";
import CompensationTab from "./compensationTab";
import DescriptionTab from "./descriptionTab";
import {getDialogResourceDetails} from "../../../data/actions/dialogResource";
import {BookmarkIcon, XIcon} from "@heroicons/react/outline";
import {Field, FieldsManager} from "../../../data/services/fields";
import DialogResourceListTab from "../../../components/resource-dialog-list-tab";
import CandidatesTab from "./candidatesTab";
import PricingTab from "./pricingTab";
import {BriefcaseIcon, CalendarIcon, LocationMarkerIcon} from "@heroicons/react/solid";
import DialogDocumentsTab from "../../common/dialogDocumentsTab";
import CandidateInterviewTab from "./candidateInterviewTab";
import Loader from "../../../components/loader";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from "react-share";
import CandidateMeetingTab from "./candidateMeetingTab";
import CommissionTab from "./commistionTab";
import NavResponsive from "../../../components/nav-responsive";
import TabSurvey from "../../../components/tab-survey";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";
import DialogCustom from "../../../components/dialog-custom";
import ModalSaveResource from "../../../components/modal/modal-save-resource";

export default class RoleModal extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Description', resource: Resources.RoleDescription, current: true},
            {name: 'Compensation', resource: Resources.RoleCompensation, current: false},
            {name: 'Experience', resource: Resources.RoleExperience, current: false},
            {name: 'Skills', resource: Resources.RoleSkills, current: false},
            {name: 'Survey', resource: Resources.RoleSurvey, current: false},
            {name: 'Questions', resource: Resources.RoleQuestions, current: false},
            {name: 'Pricing', resource: Resources.RolePricing, current: false},
            {name: 'Commission', resource: Resources.RoleCommission, current: false},
            {name: 'Expense', resource: Resources.RoleExpense, current: false},
            {name: 'Dates', resource: Resources.RoleDates, current: false},
            {name: 'Candidates', resource: Resources.RoleCandidates, current: false},
            {name: 'Media', resource: Resources.RoleMedia, current: false},
            {name: 'Documents', resource: Resources.RoleDocuments, current: false},
            {name: 'Interview', resource: Resources.RoleCandidateInterview, current: false},
            {name: 'Meeting', resource: Resources.RoleCandidateMeeting, current: false},
            {name: 'Feedback', resource: Resources.RoleFeedback, current: false}
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                selectedTab: null
            }, () => {
                this.fetchData();
                this.handleTabChange(this.props.selectedTab ?? Resources.RoleCompensation)
            });
        }

        if ((prevProps.dialogResource.detailsData === null) && (!!this.props.dialogResource.detailsData)) {
            LocalStorage.getAndSave('rolesHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.dialogResource.detailsData.RoleID,
                    name: this.props.dialogResource.detailsData.RoleName
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId()
            },
            resource: Resources.Role
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleCopyToClipboard = (link) => {
        this.setState({urlCopied: true}, () => {
            let textField = window.document.getElementById('cpBuffer')
            textField.innerText = link;
            textField.className = "";
            textField.select();
            // textField.setSelectionRange(0, 99999);
            window.document.execCommand('copy');
            textField.className = "invisible";
        });

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getShareLink = () => {
        const role = getProp(this.props, "dialogResource.detailsData", {});

        return window.location.origin + '/role/' + role.SeoUrl;
    }

    onSelectItem = (item) => {
        this.setState({
            selectedItem: item
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose, dialogResource, topPosition} = this.props;

        const role = getProp(this.props, "dialogResource.detailsData", {});

        const selectedSkills = getProp(this.props, "dialogResource.data.selectedItems", []).map(item => item.SkillID);

        const isLoading = getProp(this.props, "dialogResource.isLoadingDetails", false);

        return (
            <DialogCustom
                topPosition={topPosition}
                show={!!visible}
                widthClass="w-full"
                className={"my-8 min-h-90vh w-[calc(100vw-4rem)]"}
                hideFooter={true}
                translate={translate}
                onClose={onClose}
            >
                <React.Fragment>
                    <div className="bg-white px-4 py-5 border-b-2 border-primary-600 sm:px-6">
                        {!!isLoading && (
                            <Loader className="inset-center" />
                        )}

                        <div className="md:flex md:space-x-8">
                            {!isLoading && (
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                    <span className="font-normal block sm:inline">Role / {role.RoleName}</span>
                                </h1>
                            )}

                            {(!dialogResource.isLoading || role.RoleName) && (
                                <div
                                    className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <LocationMarkerIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                            aria-hidden="true"/>
                                        {role.ClientLocation}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <BriefcaseIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                            aria-hidden="true"/>
                                        {role.Title}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <CalendarIcon
                                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                            aria-hidden="true"/>
                                        {toFrontDate(role.PostDate)}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Social share icons */}
                        {role.RoleStatusID !== 3 && (
                            <div className={"mt-2 sm:flex items-center"}>
                                <div className={"mr-2 pt-1 align-top inline-block"}>
                                    Share on
                                </div>
                                <div className={"mr-2 inline-flex"}>
                                    <LinkedinShareButton
                                        title={role.RoleName}
                                        summary={""}
                                        source={this.getShareLink()}
                                        url={this.getShareLink()}
                                    >
                                        <LinkedinIcon size={32} round={true}/>
                                    </LinkedinShareButton>
                                </div>

                                <div className={"mr-2 inline-flex"}>
                                    <TwitterShareButton
                                        title={role.RoleName}
                                        summary={""}
                                        via={this.getShareLink()}
                                        url={this.getShareLink()}
                                    >
                                        <TwitterIcon size={32} round={true}/>
                                    </TwitterShareButton>
                                </div>

                                <div className={"mr-2 inline-flex"}>
                                    <FacebookShareButton
                                        quote={""}
                                        url={this.getShareLink()}
                                    >
                                        <FacebookIcon size={32} round={true}/>
                                    </FacebookShareButton>
                                </div>

                                <div className={"sm:mr-2 block sm:inline-block"}>
                                    <span
                                        className="relative z-0 inline-flex flex-wrap lg:shadow-sm rounded-md md:mr-6">
                                        <div
                                            className="mb-1 sm:mb-0 relative w-full md:w-auto inline-flex items-center px-4 py-2 rounded-md rounded-r-none border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            <BookmarkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                            {this.getShareLink()}
                                        </div>

                                        <button
                                            type="button"
                                            onClick={() => this.handleCopyToClipboard(this.getShareLink())}
                                            className="lg:inline-flex relative items-center px-3 py-2 rounded-md rounded-l-none lg:-ml-px border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            {translate("text.copy_url_to_clipboard")}
                                        </button>

                                        {this.state.urlCopied && (
                                            <span
                                                className="ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                                {translate("text.url_copied_successfully")}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        )}

                        {role.RoleStatusID === 3 && (
                            <div className={"mt-2 sm:flex items-center"}>
                                <div className={"mr-2 pt-1 align-top inline-block"}>
                                    Role Status: <span className="font-bold">FILLED</span> (sharing is disabled)
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="p-4">

                        {/* Tabs */}
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                {translate("text.select_tab")}
                            </label>

                            <select
                                id="tabs"
                                name="tabs"
                                className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                onChange={e => this.handleTabChange(e.target.value)}
                            >
                                {this.state.tabs.map((tab) => (
                                    <option
                                        key={tab.name}
                                        value={tab.resource}
                                    >
                                        {tab.name}
                                    </option>
                                ))}
                            </select>

                            <div className="border-b-2 border-primary-500 mt-6"></div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="border-b border-secondary-200">
                                <NavResponsive
                                    tabs={this.state.tabs}
                                    onTabChange={this.handleTabChange}
                                />
                            </div>
                        </div>

                        {/* Content */}
                        <div className="mt-10">
                            {this.state.selectedTab === Resources.RoleCompensation && (
                                <CompensationTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleDates && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Dates"}

                                    sortBy={"RoleDatesID"}
                                    primaryKey={"RoleDatesID"}
                                    resourceName={Resources.RoleDates}
                                    fields={{
                                        Milestone: new Field("Milestone", '', ['empty'], false),
                                        Date: new Field("Date", '', ['empty'], false, 'datetime'),
                                        Description: new Field("Description", '', ['empty'], false, 'textarea')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleDescription && (
                                <DescriptionTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleExperience && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Experience"}

                                    sortBy={"RoleTitleID"}
                                    primaryKey={"RoleExperienceID"}
                                    resourceName={Resources.RoleExperience}
                                    fields={{
                                        RoleTitleID: new Field("RoleTitleID", '', [], false, 'select-search-create'),
                                        SpecialityID: new Field("SpecialityID", '', [], false, 'select-search-create'),
                                        YearsExperience: new Field("YearsExperience", '', ['float'], false),
                                        IsRequired: new Field("IsRequired", '', [], false, 'checkbox'),
                                        Description: new Field("Description", '', [], false, 'textarea'),
                                        RankingPoints: new Field("RankingPoints", 5, ['integer'], false, 'text'),
                                    }}
                                    metadata={{
                                        SpecialityID: {
                                            api: 'api/' + Resources.CreatableSpeciality,
                                            query: {id: this.getId()},
                                            searchMap: (item) => ({
                                                value: item.SpecialityID,
                                                label: item.Speciality
                                            })
                                        },
                                        RoleTitleID: {
                                            api: 'api/' + Resources.CreatableRoleTitle,
                                            query: {id: this.getId()},
                                            searchMap: (item) => ({
                                                value: item.RoleTitleID,
                                                label: item.RoleTitle
                                            })
                                        },
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleSkills && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Skill"}

                                    sortBy={"SkillTypeID"}
                                    primaryKey={"RoleSkillID"}
                                    resourceName={Resources.RoleSkills}
                                    fields={{
                                        SkillTypeID: new Field("SkillTypeID", '', ['empty'], false, 'select'),
                                        SkillID: new Field("SkillID", '', ['empty'], false, 'select'),
                                        YearsExperience: new Field("YearsExperience", '', ['float'], false),
                                        IsRequired: new Field("IsRequired", '', [], false, 'checkbox'),
                                        Description: new Field("Description", '', [], false, 'textarea'),
                                        RankingPoints: new Field("RankingPoints", 2, ['integer'], false, 'text'),
                                    }}
                                    handleInputChange={(fields, name, value) => {
                                        if (name === "SkillTypeID") {
                                            fields["SkillID"].value = null;
                                        }
                                        return FieldsManager.updateField(fields, name, value);
                                    }}
                                    onSelectItem={this.onSelectItem}
                                    metadata={{
                                        SkillID: (item, fields) => {
                                            const valToFind = fields.find(i => i.name === "SkillTypeID")?.value;

                                            return LocalStorage.get('lookup', {}).Skill.filter((item) => (!(selectedSkills.includes(item.SkillID) && this.state.selectedItem?.SkillID != item.SkillID)) && (item.SkillTypeID == valToFind)).reduce((memo, item) => {
                                                memo[item.SkillID] = item.Skill;
                                                return memo;
                                            }, {})
                                        }
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleCandidates && (
                                <CandidatesTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                    role={role}
                                />
                            )}

                            {this.state.selectedTab === Resources.RolePricing && (
                                <PricingTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleMedia && (
                                <DialogDocumentsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    resourceName={Resources.RoleMedia}
                                    primaryKey={"RoleMediaID"}
                                    mediaTypesText={translate("text.video_formats")}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleDocuments && (
                                <DialogDocumentsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    resourceName={Resources.RoleDocuments}
                                    resourceSendName={Resources.RoleDocumentsSend}
                                    primaryKey={"RoleDocumentID"}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleQuestions && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Question"}

                                    sortBy={"RoleQuestionsID"}
                                    primaryKey={"RoleQuestionsID"}
                                    resourceName={Resources.RoleQuestions}
                                    fields={{
                                        Description: new Field("Description", '', ['empty'], false, 'textarea')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleSurvey && (
                                <TabSurvey
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    primaryKey={"RoleSurveyID"}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Survey Question"}

                                    resourceName={Resources.RoleSurvey}
                                    {...this.props}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleCandidateInterview && (
                                <CandidateInterviewTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    history={this.props.history}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                    role={role}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleCandidateMeeting && (
                                <CandidateMeetingTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    history={this.props.history}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    secondResource={this.props.secondResource}
                                    role={role}
                                    dialogTitle={"Meeting"}

                                    sortBy={"MeetingID"}
                                    primaryKey={"MeetingID"}
                                    resourceName={Resources.RoleCandidateMeeting}
                                    fields={{
                                        CandidateID: new Field("CandidateID", "", ['empty'], false, 'select-search'),
                                        Date: new Field("Date", '', ['empty'], false, 'datetime'),
                                        Description: new Field("Description", '', ['empty'], false, 'textarea')
                                    }}
                                    metadata={{
                                        CandidateID: {
                                            api: 'api/' + Resources.Candidates,
                                            query: {},
                                            searchMap: (item) => ({
                                                value: item.CandidateID,
                                                label: item.FirstName + ' ' + item.LastName
                                            })
                                        },
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleCommission && (
                                <CommissionTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleExpense && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Expense"}

                                    sortBy={"RoleExpenseID"}
                                    primaryKey={"RoleExpenseID"}
                                    resourceName={Resources.RoleExpense}
                                    fields={{
                                        Date: new Field("Date", '', ['empty'], false, 'date'),
                                        Vendor: new Field("Vendor", '', ['empty'], false, 'text'),
                                        Amount: new Field("Amount", '', ['float'], false, 'text'),
                                        ExpenseCategoryID: new Field("ExpenseCategoryID", '', ['empty'], false, 'select'),
                                        Comment: new Field("Comment", '', [''], false, 'textarea')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.RoleFeedback && (
                                <DialogResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    dialogResource={this.props.dialogResource}

                                    dialogTitle={"Feedback"}

                                    sortBy={"OrderNum"}
                                    primaryKey={"RoleFeedbackID"}
                                    resourceName={Resources.RoleFeedback}
                                    fields={{
                                        OrderNum: new Field("OrderNum", '', ['integer'], false, 'text'),
                                        Title: new Field("Title", '', ['empty'], false, 'text'),
                                        Description: new Field("Description", '', [''], false, 'textarea'),
                                        FeedbackQuestionTypeID: new Field("FeedbackQuestionTypeID", '', ['empty'], false, 'select')
                                    }}
                                />
                            )}
                        </div>

                    </div>

                    <textarea id={"cpBuffer"} className={"invisible"}/>

                </React.Fragment>
            </DialogCustom>
        )
    }
}