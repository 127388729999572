export default class Resources {

    static User = "user";
    static Users = "users";
    static UserDevices = "user/logged-devices";

    static RecruiterProfile = "recruiter-profile";
    static RecruiterProfileList = "recruiter-profile/all";
    static RecruiterProfileRoles = "recruiter-profile/roles";

    static Company = "company";

    static CompanyEmail = "company/email-server";
    static CompanyImage = "company/image";
    static CompanyPhoneNumber = "company/phone-number";
    static CompanyPhoneNumberList = "company/phone-number/list";

    static Resource = "resource";

    static Dashboard = "dashboard";
    static DashboardCount = "dashboard/count";
    static RolesCount = "roles/count";

    static Candidate = "candidate";
    static Meeting = 'meeting';
    static CandidatePackage = "candidate/package";
    static CandidateMessage = "candidate/message";
    static CandidateInviteConnect = "candidate/invite-connect";
    static CandidateMessageSMS = "candidate/message/sms";

    static Tasks = "tasks";

    static Candidates = "candidates";
    static CandidatesSearch = "candidates/search";
    static CandidatesDuplicates = "candidates/duplicates";
    static CandidatesColor = "candidates/color";

    static CandidateImage = "candidate/image";
    static CandidateImport = "candidate/import";
    static CandidateInfo = "candidate/info";
    static CandidateResume = "candidate/resume";
    static CandidateSkills = "candidate/skills";
    static CandidateCredentials = "candidate/credentials";
    static CandidateReferences = "candidate/references";
    static CandidateSummary = "candidate/motivation";
    static CandidateDesiredRole = "candidate/desired-role";
    static CandidateMedia = "candidate/media";
    static CandidateDocuments = "candidate/documents";
    static CandidateNewRoles = "candidate/new-roles";
    static CandidateSurvey = "candidate/survey";
    static CandidateNotes = "candidate/notes";
    static CandidateUser = "candidate/user";
    static CandidateVisitors = "candidate/visitors";
    static CandidateUserEmail = "candidate/user/email";
    static CandidateCommission = "candidate/commission";
    static CandidateExtRecruiterPerm = "ext-candidate/recruiter-perm";

    static CandidateCitiesList = "candidates/cities";

    static Client = "client";
    static Clients = "clients";
    static ClientsColor = "clients/color";

    static ClientsContacts = "clients/contacts";

    static ClientOverview = "client/overview";
    static ClientContacts = "client/contacts";
    static ClientLocations = "client/locations";
    static ClientRoles = "client/roles";
    static ClientInvoices = "client/invoices";
    static ClientDocuments = "client/documents";
    static ClientImage = "client/image";

    static Roles = "roles";
    static RoleShared = "role/shared";
    static RolesShared = "roles/shared";
    static Role = "role";

    static RoleCompensation = "role/compesation";
    static RoleDates = "role/dates";
    static RoleDescription = "role/description";

    static RoleExperience = "role/experience";
    static RoleSkills = "role/skills";
    static RoleDocuments = "role/documents";
    static RoleDocumentsSend = "role/documents/send";
    static RoleMedia = "role/media";
    static RolePricing = "role/pricing";
    static RolePricingItem = "role/pricing/item";
    static RolePricingItemInvoice = "role/pricing/item/invoice";
    static RoleCandidates = "role/candidates";
    static RoleCandidatesAssign = "role/candidates/assign";
    static RoleCandidatesNotify = "role/candidates/notify";
    static RoleQuestions = "role/questions";
    static RoleCommission = "role/commission";
    static RoleExpense = "role/expense";
    static RoleSurvey = "role/survey";
    static RoleFeedback = "role/feedback";
    static RoleCandidateFeedbackReport = "role/candidate/feedback/report";
    static RoleVersionCreate = "candidate/roles/version";
    static ExtRoleVersionCreate = "ext-candidate/roles/version";
    static RoleVersionSwitch = "candidate/roles/version/switch";
    static RolesColor = "roles/color"

    static RoleCandidatesStatusReport = "role/candidate/status/report";

    static RoleQuestionsInterview = "role/questions/interview";
    static RoleSurveyInterview = "role/survey/interview";

    static RoleCandidateInterview = "role/candidate/interview";
    static RoleCandidateMeeting = "role/candidate/meeting";

    static ReportsClient = "reports/client";

    static ShortSurveyQuestions = "survey/question";

    static EmailTemplates = "email/templates";
    static EmailTemplatesParse = "email/templates/parse";

    static FinancialReport = "financial/report";
    static FinancialReportClients = "financial/report/clients";

    static Payment = "payment";

    static ContactUs = 'public/contact-us';

    // Feedback for candidate
    static RoleCandidateFeedbackExternal = "role/candidate/feedback/external";

    // Messaging
    static Messages = "messages";
    static Contacts = "contacts";

    // Personal notes
    static MeetingNotes = "meeting-notes";

    // Meetings
    static Room = "room";

    // Candidate external
    static ExtDashboard = "ext-candidate/dashboard";
    static ExtCandidate = "ext-candidate";
    static ExtCandidateResume = "ext-candidate/resume";
    static ExtCandidateSkills = "ext-candidate/skills";
    static ExtCandidateCredentials = "ext-candidate/credentials";
    static ExtCandidateReferences = "ext-candidate/references";
    static ExtCandidateMotivation = "ext-candidate/motivation";
    static ExtCandidateDesiredRole = "ext-candidate/desired-role";
    static ExtCandidateMedia = "ext-candidate/media";
    static ExtCandidateDocuments = "ext-candidate/documents";
    static ExtCandidateImage = "ext-candidate/image";
    static ExtCandidateInvites = "ext-candidate/recruiter-invites";
    static ExtRoles = "ext-candidate/roles";

    // Client external
    static ExtClientDashboard = "ext-client/dashboard";
    static ExtClientRoles = "ext-client/roles";
    static ExtClientContacts = "ext-client/client-contacts";

    static ExtRoleDates = "ext-client/role/dates";
    static ExtRoleDescription = "ext-client/role/description";

    static ExtRole = "ext-client/role";
    static ExtRoleDocuments = "ext-client/role/documents";
    static ExtRoleMedia = "ext-client/role/media";
    static ExtRoleCandidates = "ext-client/role/candidates";
    static ExtRoleCandidateInterview = "ext-client/role/candidate/interview";
    static ExtRoleCandidateMeeting = "ext-client/role/candidate/meeting";
    static ExtRoleFeedback = "ext-client/role/feedback";

    static CreatableSpeciality = "creatable-lookup/specialty"
    static CreatableRoleTitle = "creatable-lookup/role-title";

    static CandidateRoleInterviewInvitation = "role/candidate/interview/invitation-email"
    static CandidateRoleInterviewFeedbackInvitation = "role/candidate/interview/feedback-email"
    static ExtCandidateRolesSurvey = "ext-candidate/roles/survey"
}
