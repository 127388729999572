import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    classNames,
    DEFAULT_CRUD_STATE,
    fieldsToHtml, fillFieldsFromData,
    getLookup,
    getProp,
    getUserLabel,
    mergeDeep
} from "../../util/util";
import {OfficeBuildingIcon} from '@heroicons/react/solid'
import {Field, FieldsManager} from "../../data/services/fields";
import Env from "../../util/env";
import Loader from "../../components/loader";
import {CollectionIcon, PhoneIcon, UserGroupIcon,} from "@heroicons/react/outline";
import OfficeBuildingOutlineIcon from "@heroicons/react/outline/OfficeBuildingIcon";
import ResourceListTab from "../../components/resource-list-tab";
import {getSecondResource} from "../../data/actions/secondResource";
import RoleModal from "../client-single-page/roles";
import DialogDefault from "../../components/dialog-default";
import {updateResource} from "../../data/actions/resource";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import PageHeader from "../../components/layout-dashboard/page-header";

class DashboardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            selectedTab: "To Do",
            selectedItem: undefined,
            viewModalOpen: false,
            recruiterModalOpen: false,
            editModalTaskOpen: false,
            searchFields: {
                TaskStatusID:  new Field("TaskStatusID", '1', ['empty'], false, 'text')
            },
        };

        this.pagePath = this.props.location.pathname.substring(1);
        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath));
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: Resources.DashboardCount
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value);
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    toggleRecruiterModal = (it = null) => {
        this.setState({
            recruiterModalOpen: !this.state.recruiterModalOpen,
            selectedRecruiter: it
        })
    }

    handleContactClick = (it = null) => {
        this.props.history.push("/contact/" + it.CandidateID);
    }

    handleCardClick = (card) => {
        this.setState({
            selectedTab: card.name
        }, () => this.saveFilters())
    }

    handleToggleEditTaskModal = (item = null) => {
        if (item && !!item.Type) {
            item.TaskDate = item.Date;
            item.TaskTitle = item.Title;
            item.TaskID = item.ID;
        }

        this.setState({
            selectedItem: item,
            editModalTaskOpen: !this.state.editModalTaskOpen
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        let filters = ['selectedTab']

        LocalStorage.persistState(this.pagePath, {selectedTab: this.state.selectedTab}, filters);
    };

    getTaskFieldsEdit = (item = null,) => {
        const fieldTemplates = {
            TaskTitle: new Field("TaskTitle", '', ['empty'], true, 'text'),
            TaskTypeID: new Field("TaskTypeID", '', ['empty'], true, 'select'),
            TaskStatusID: new Field("TaskStatusID", '', ['empty'], false, 'select'),
            RoleID: new Field("RoleID", '', [''], true, 'select-search'),
            CandidateID: new Field("CandidateID", '', [], true, 'select-search'),
            TaskDate: new Field("TaskDate", '', [], true, 'datetime'),

            Description: new Field("Description", '', [], true, 'textarea')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: "ASC",
            sortBy: "TaskDate",
            query: this.state.query,
            archived: 0,
            searchFields: JSON.stringify(this.getValidSearchFields()),
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const userMenuBtnLabel = getUserLabel();

        const user = LocalStorage.get('user');

        const cards = [
            {
                name: "To Do",
                RoleStatusID: "",
                icon: CollectionIcon,
                active: false,
                isClickable: true,
                visible: true,
                amount: getProp(this.props, "secondResource.data.taskCount", "Loading...")
            },
            {
                name: 'Active roles',
                RoleStatusID: 3,
                icon: UserGroupIcon,
                active: false,
                isClickable: true,
                visible: true,
                amount: getProp(this.props, "secondResource.data.openCount", "Loading...")
            },
            {
                name: 'Contacts',
                RoleStatusID: 2,
                icon: PhoneIcon,
                active: false,
                isClickable: true,
                visible: true,
                amount: getProp(this.props, "secondResource.data.candidatesCount", "Loading...")
            },
            {
                name: 'Clients',
                RoleStatusID: 1,
                icon: OfficeBuildingOutlineIcon,
                active: false,
                isClickable: true,
                visible: true,
                amount: getProp(this.props, "secondResource.data.clientsCount", "Loading...")
            }
        ];

        const metadataTask = {
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName + " / " + item.ClientName,
                    ClientID: item.ClientID
                })
            },
            CandidateID: {
                api: 'api/' + Resources.Candidates,
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName
                })
            }
        }

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <main className="flex-1 relative pb-8 z-0">
                        <PageHeader
                            title={translate("page.title.dashboard")}
                        />

                        {/* Overview cards */}
                        <div className="px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8 lg:pt-4 grid mt-2 space-y-4">
                            <div className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                {/* Card */}
                                {cards.filter(card => card.visible).map((card) => (
                                    <div
                                        key={card.name}
                                        className={classNames("bg-inverse overflow-hidden shadow rounded-lg hover:bg-gray-100 cursor-pointer border-2", this.state.selectedTab === card.name ? "border-primary" : "border-transparent")}
                                        onClick={() => card.isClickable ? this.handleCardClick(card) : null}
                                    >
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <card.icon
                                                        className={classNames("h-6 w-6", this.state.selectedTab === card.name ? "text-primary" : "text-secondary-400")}
                                                        aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className={classNames("text-sm truncate", this.state.selectedTab === card.name ? "text-primary-600 font-bold" : "text-secondary-500 font-medium")}>{card.name}</dt>
                                                        <dd>
                                                            <div
                                                                className={classNames("text-lg font-medium", this.state.selectedTab === card.name ? "text-primary-900" : "text-secondary-900")}>{card.amount}</div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {this.state.selectedTab === "To Do" && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    searchFields={{TaskStatusID:  new Field("TaskStatusID", '1', ['empty'], false, 'text')}}
                                    sortBy={"TaskDate"}
                                    sort="Desc"
                                    primaryKey={"TaskTitle"}
                                    resourceName={Resources.Tasks}
                                    fields={{
                                        TaskTitle: new Field("TaskTitle", '', ['empty'], false, 'text', {onCellClick: (it) => this.handleToggleEditTaskModal(it)}),
                                        TaskTypeID: new Field("TaskTypeID", '', ['empty'], false, 'select'),
                                        TaskStatusID: new Field("TaskStatusID", '', ['empty'], false, 'select'),
                                        RoleID: new Field("RoleID", '', [''], false, 'select-search'),
                                        CandidateID: new Field("CandidateID", '', [], false, 'select-search'),
                                        TaskDate: new Field("TaskDate", '', [], false, 'datetime'),
                                    }}
                                />
                            )}

                            {this.state.selectedTab === "Active roles" && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    additionalFilters={{RoleStatusID: 1}}
                                    sort="Desc"
                                    sortBy={"PostDate"}
                                    primaryKey={"PostDate"}
                                    resourceName={Resources.Roles}
                                    fields={{
                                        RoleName: new Field("RoleName", '', ['empty'], false, 'text', {onCellClick: (it) => this.handleToggleView(it)}),
                                        ClientName: new Field("ClientID", '', ['empty'], false, 'text'),
                                        RecruiterID: new Field("RecruiterID", '', [], false, 'select', {onCellClick: (it) => this.toggleRecruiterModal(it)}),
                                        RoleTitleID: new Field("RoleTitleID", '', [], false, 'select-search', {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
                                        ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
                                        ContactID: new Field("ContactID", "", [], false, 'select-search'),
                                        PostDate: new Field("PostDate", '', [], false, 'date')
                                    }}
                                />
                            )}

                            {this.state.selectedTab === "Contacts" && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    sortBy={"ContactName"}
                                    primaryKey={"ContactName"}
                                    resourceName={Resources.Candidates}
                                    fields={{
                                        ContactName: new Field("ContactName", '', ['empty'], false, "text", {onCellClick: (it) => this.handleContactClick(it)}),
                                        Title: new Field("Title", '', ['empty'], false, "text"),
                                        Credentials: new Field("Credentials", '', ['empty'], false, "text"),
                                        Email: new Field("Email", '', ['empty'], false, 'text'),
                                        Phone: new Field("Phone", '', [''], false, "text"),
                                        Source: new Field("Source", '', [], false, 'checkbox'),
                                        City: new Field("City", '', ['empty'], false, "text"),
                                        State: new Field("State", '', ['empty'], false, "text")
                                    }}
                                />
                            )}

                            {this.state.selectedTab === "Clients" && (
                                <ResourceListTab
                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    sortBy={"ClientName"}
                                    primaryKey={"ClientName"}
                                    resourceName={Resources.Clients}
                                    fields={{
                                        ClientName: new Field("ClientName", "", ['empty'], false, 'text'),
                                        OrganizationTypeID: new Field("OrganizationTypeID", "", ['empty'], false, 'select-search'),
                                        State: new Field("State", "", [], false, 'select-search', {}, {isMulti: true}),
                                        AreaCode: new Field("AreaCode", '', ['empty'], false, 'text'),
                                        PhoneNumber: new Field("PhoneNumber", '', ['empty'], false, 'text'),
                                        PhoneExtension: new Field("PhoneExtension", '', [], false, 'text')
                                    }}
                                />
                            )}
                        </div>

                        <div className="p-4 sm:p-6 lg:p-8">
                            {!!this.props.resource.isLoading && (
                                <div className="h-80 absolute text-center w-full">
                                    <div className={"inset-center"}>
                                        <Loader/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </LayoutDashboard>

                {this.state.viewModalOpen && (
                    <RoleModal
                        {...this.props}
                        id={getProp(this.state.selectedItem, "RoleID", 0)}
                        visible={!!this.state.viewModalOpen}
                        onClose={this.handleToggleView}
                    />
                )}

                <DialogDefault
                    title={"Recruiter Card"}
                    visible={this.state.recruiterModalOpen}
                    widthClass={"max-w-xl"}
                    onClose={() => this.toggleRecruiterModal()}
                    translate={translate}
                >
                    <h2 className="text-lg mb-3 mt-4">{"Contact info for " + this.state.selectedRecruiter?.Recruiter}</h2>

                    {fieldsToHtml(Object.values(Object.assign({}, {
                        Recruiter: new Field("RecruiterName", this.state.selectedRecruiter?.Recruiter, [], false, 'readonly'),
                        RecruiterEmail: new Field("RecruiterEmail", this.state.selectedRecruiter?.RecruiterEmail, [], false, 'readonly'),
                        RecruiterPhone: new Field("RecruiterPhone", this.state.selectedRecruiter?.RecruiterPhone, [], false, 'readonly')
                    })), translate)}

                    <div className="mb-3"/>
                </DialogDefault>

                <ModalSaveResource
                    bodyKey={this.state.remountFields}
                    handleInputChange={this.handleInputChange}
                    title={"Edit To Do"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalTaskOpen}
                    onClose={this.handleToggleEditTaskModal}
                    fields={this.getTaskFieldsEdit(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign({TaskID: this.state.selectedItem.TaskID}, params),
                                resource: Resources.Tasks,
                                piggyResource: Resources.Tasks,
                                secondPiggyResource: Resources.DashboardCount
                            }));
                            this.handleToggleEditTaskModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataTask}
                />

            </React.Fragment>
        );
    }
}

export default connect(state => state)(DashboardView);
