import React from "react";

export default function Privacy() {

    return (
        <div className="mt-10">
            <h1 className="mb-3 text-2xl font-bold">Privacy Policy</h1>

            <p className="mb-3">Last Updated: May 5, 2021</p>

            <p className="mb-3">
                This is the GoLeir Privacy Policy, which applies to the GoLeir website and
                GoLeir social media sites. It explains what information we collect when you
                use our site, how we collect information, how we use information and how you
                can manage that information. This Privacy Policy governs our data collection,
                processing and usage practices. We pledge to strictly follow the Privacy Policy
                below, as well as all applicable United States laws and regulations, including
                privacy laws.
            </p>

            <h2 className="mb-2 font-bold text-lg">CHANGES IN OUR PRIVACY POLICY</h2>
            <p className="mb-3">
                GoLeir reserves the right, in its sole discretion, to amend this Privacy Policy.
                The most current version of our Privacy Policy, available on our site, will
                supersede all previous versions. These policies are updated whenever laws
                and regulations applicable to GoLeir change. You should check our site
                regularly for the latest version and discontinue use of the site if you do not
                agree with any of the terms.
            </p>

            <h2 className="mb-2 font-bold text-lg">INFORMATION WE COLLECT</h2>
            <p className="mb-3">
                There are different types of information that we collect on the GoLeir website:
                Personal information: Through our contact forms, we collect personal
                information that you provide to us. Contact information: business name, first
                name, last name, phone number, state, size of company and email address.
                Activity and technology information: We may collect information about
                members’ usage of our site, including what pages and areas you use on our
                site, your IP address, which web browser you use, and dates and times of
                your visits.
            </p>

            <h2 className="mb-2 font-bold text-lg">HOW WE COLLECT INFORMATION</h2>
            <p className="mb-3">
                Some of the information we collect is given to us directly by users through our
                contact forms and GoLeir social sites. We will also keep any information you
                provide to us when you contact us directly by email or phone. In addition,
                information is automatically collected through technology on our Web site,
                including through the use of tracking technologies like cookies, anonymous
                identifiers, metadata, Web beacons and software tools.
            </p>

            <h3>Cookies</h3>
            <p className="mb-3">
                A cookie is a small text file that is stored on a user’s computer for record-
                keeping purposes. We use cookies on this site. We do not link the information
                we store in cookies to any personally identifiable information you submit while
                on our site. We use both session ID cookies and persistent cookies. A session
                ID cookie expires when you close your browser. A persistent cookie remains
                on your hard drive for an extended period of time. Our third-party tracking
                technologies use persistent cookies to anonymously collect usage statistics.
                You can remove persistent cookies by following directions provided in your
                Internet browser’s Help file. If you reject cookies, you may still use our site,
                but your ability to use some areas of our site may be limited.
            </p>

            <h3>Log Files</h3>
            <p className="mb-3">
                As is true of most sites, we gather certain information automatically and store
                it in log files. This information includes Internet protocol (IP) addresses,
                browser type, requested page, operating system and date/time stamp. We
                use this information, which does not identify individual users, to analyze
                trends, to administer the site and to resolve errors. We link this automatically-
                collected data to Personally Identifiable Information only for the purposes of
                investigating fraudulent use of our site.
            </p>

            <h3>Advertising</h3>
            <p className="mb-3">
                We partner with a third party ad network to manage our advertising on other
                sites. Our ad network partner uses cookies and web beacons to collect non-
                personally identifiable information about your activities on this and other
                websites to provide you targeted advertising based upon your interests. It
                connects the activity of www.GoLeir.com with the Adwords advertising
                network and the Doubleclick Cookie. You can opt out of the cookie tracking
                here by visiting Google Ads Settings.
            </p>

            <h2 className="mb-2 font-bold text-lg">USE OF INFORMATION</h2>
            <p className="mb-3">
                Information collected from users will be used to: Provide customized content.
                Provide you with GoLeir services that you request and that we think will be of
                value to you. Improve our products and services. Send you offers and
                promotions from us, only if you opt-in to our marketing program.
            </p>

            <h2 className="mb-2 font-bold text-lg">ADDITIONAL USE OF INFORMATION</h2>
            <p className="mb-3">
                In addition to the above, we use the information we collect to provide,
                maintain, protect and improve our website and services, to develop new ones,
                and to protect GoLeir and our users. We will share personal information with
                companies, organizations or individuals outside of GoLeir if we have a good-
                faith belief that access, use, preservation or disclosure of the information is
                reasonably necessary to: Meet any applicable law, regulation, legal process or
                enforceable governmental request; Enforce applicable Terms of Service,
                including investigation of potential violations; Investigate a violation of our
                Terms of Use, illegal activity, threats to any person’s safety or as otherwise
                required by law; Detect, prevent or otherwise address fraud, security or
                technical issues; We will share your personal information with third parties
                only in the ways that are described in this privacy policy. We do not sell your
                personal data to third parties. Customer Testimonials and Comments We may
                post customer testimonials and comments on our website, which may contain
                Personal Information. We obtain each customer’s consent prior to posting the
                customer’s name and testimonial.
            </p>

            <h2 className="mb-2 font-bold text-lg">HOW WE PROTECT YOUR INFORMATION</h2>
            <p className="mb-3">
                At GoLeir, we realize your personal information is extremely sensitive. We
                keep this information secure through reasonable and appropriate
                administrative, technical and physical safeguards. We follow generally
                accepted standards to protect the personal information submitted to us, both
                during transmission and once we receive it. No method of transmission over
                the Internet, or method of electronic storage, is 100% secure, however.
                Therefore, we cannot guarantee its absolute security.
            </p>

            <h2 className="mb-2 font-bold text-lg">ACCESS AND CHOICE</h2>

            <p className="mb-3">
                If your personal information changes, or if you no longer desire our service,
                you may contact us at support@GoLeir.com. We will respond to your request
                within 30 days. If you wish to subscribe to our email marketing program, we
                will use your name and email address to send marketing emails to you. Out of
                respect for your privacy, you may choose to stop receiving our marketing
                emails by following the unsubscribe instructions included in these emails.
            </p>

            <h2 className="mb-2 font-bold text-lg">LINKS TO THIRD-PARTY SITES</h2>
            <p className="mb-3">
                Our site includes links to other Web sites whose privacy practices may differ
                from those of GoLeir. If you submit personal information to any of those sites,
                your information is governed by their privacy policies. We encourage you to
                carefully read the privacy policy of any Web site you visit. Our site may
                include social media features, such as the Facebook Like button. These
                features may collect your IP address and which page you are visiting on our
                site, and may set a cookie to enable the feature to function properly. Social
                media features and widgets are either hosted by a third party or hosted
                directly on our site. Your interactions with these features are governed by the
                privacy policy of the company providing it.
            </p>

            <h2 className="mb-2 font-bold text-lg">DATA RETENTION</h2>
            <p className="mb-3">
                User information: If you request that we delete your contact information from
                our system, we will do so within 30 days of notice.
            </p>

            <h2 className="mb-2 font-bold text-lg">CONTACTING US</h2>
            <p className="mb-3">
                If you have any questions or concerns regarding your personal information or
                this Privacy Policy, you can contact us by email at, support@GoLeir.com.
            </p>
        </div>
    )
}