import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import SimpleTable from "../../../components/simple-table";
import {fillFieldsFromData, getProp, numberWithCommas} from "../../../util/util";
import {Field} from "../../../data/services/fields";
import ModalSaveResource from "../../../components/modal/modal-save-resource";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import Button from "../../../components/button";
import {downloadFile} from "../../../data/actions/resource";
import {ArchiveIcon, CreditCardIcon, PencilIcon} from "@heroicons/react/outline";

export default class PricingTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "IsInvoiced",

            query: "",
            archived: false,

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,

            createInvoiceModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleDownloadInvoice = (item) => {
        this.props.dispatch(downloadFile({
            user: LocalStorage.get("user"),
            query: {
                name: "Invoice.pdf",
                RolePricingItemID: item.RolePricingItemID
            },
            resource: Resources.RolePricingItemInvoice
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleCreateInvoiceModal = (item = null) => {
        this.setState({
            selectedItem: item,
            createInvoiceModalOpen: !this.state.createInvoiceModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "RolePricingID";
    }

    getResourceName = () => {
        return Resources.RolePricing;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }


    getItemFields = (item = null) => {
        const fieldTemplates = {
            PaymentTypeID: new Field("PaymentTypeID", '', ['empty'], false, 'select'),
            PaymentAmount: new Field("PaymentAmount", '', ['float'], false),
            DueDate: new Field("DueDate", '', [], false, 'date'),
            InvoiceDate: new Field("InvoiceDate", '', [], false, 'date'),
            Comments: new Field("Comments", '', [], false, 'textarea'),
            IsEstimate: new Field("IsEstimate", '', [], false, 'checkbox'),
            IsInvoiced: new Field("IsInvoiced", '', [], true, 'checkbox', {
                hideDialog: true
            }),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);
        const AgreedSalary = getProp(this.props, "dialogResource.data.role.AgreedSalary", 0);


        const toBeInvoiced = data.reduce((memo, it) => {
            return memo + ((it.IsInvoiced) ? 0 : 1);
        }, 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const total = data.reduce((memo, it) => {
            return memo + ((it.PaymentTypeID === 1) ? it['PaymentAmount'] : (AgreedSalary * (it['PaymentAmount'] / 100.0)));
        }, 0);

        const totalPayment = data.reduce((memo, it) => {
            return memo + (!it.IsInvoiced ? 0 : ((it.PaymentTypeID === 1) ? it['PaymentAmount'] : (AgreedSalary * (it['PaymentAmount'] / 100.0))));
        }, 0);

        return (
            <div className="">

                <div className="space-y-8 divide-y divide-secondary-200">
                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 font-bold text-lg">
                        <span>{translate("text.total")}</span> <span>{numberWithCommas(total)}</span>
                        (<span>{translate("text.total_invoiced")}</span> <span>{numberWithCommas(totalPayment)}</span>)
                        <p className="m-3 text-sm text-secondary-500">* Payed values include all entries that are
                            invoiced.</p>
                    </div>
                </div>

                <div className="mt-2 flex flex-wrap">
                    <div className="flex-shrink-0 ml-auto mb-3">
                        <Button
                            type="button"
                            onClick={this.handleToggleCreateInvoiceModal}
                            className={(toBeInvoiced ? "cursor-pointer" : "") + " btn btn-primary focus:ring-offset-inverse mr-2"}
                            disabled={!toBeInvoiced}
                        >
                            <CreditCardIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create_invoice_bundle")}
                        </Button>

                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.add")}
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.getItemFields()}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onCustomAction={(it) => {
                        return (
                            <>
                                {!it.IsInvoiced && (
                                    <>
                                        <Button
                                            type="button"
                                            onClick={() =>  this.handleToggleEditModel(it)}
                                            className={"cursor-pointer ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"}
                                        >
                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                       aria-hidden="true"/>

                                            {translate("Edit")}
                                        </Button>

                                        <Button
                                            type="button"
                                            onClick={() =>  this.handleToggleConfirmModal(it)}
                                            className={"cursor-pointer ml-2 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"}
                                        >
                                            <ArchiveIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                        aria-hidden="true"/>

                                            {translate("Delete")}
                                        </Button>

                                    </>
                                )}
                            </>
                        )
                    }}

                />

                <ModalSaveResource
                    title={"Edit Price"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getItemFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params.RolePricingItemID = this.state.selectedItem.RolePricingItemID;
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.RolePricingItem,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={"Create Price Entry"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getItemFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.RolePricingItem,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                RolePricingItemID: this.state.selectedItem.RolePricingItemID
                            }, this.getQuery()),
                            resource: Resources.RolePricingItem,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <ModalConfirm
                    title={"Create invoice"}
                    text={"Are you sure you want to create invoice?"}
                    onClose={this.handleToggleCreateInvoiceModal}
                    onConfirm={() => {
                        this.props.dispatch(createDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                RoleID: this.getId()
                            }, this.getQuery()),
                            params: {
                                RoleID: this.getId()
                            },
                            resource: Resources.RolePricingItemInvoice,
                            piggyResource: this.getResourceName(),
                            notificationMessage: "Invoice created"
                        }));
                        this.handleToggleCreateInvoiceModal(false)
                    }}
                    visible={this.state.createInvoiceModalOpen}
                />

            </div>
        )
    }
}
