import React, {Component} from 'react'
import {
    AtSymbolIcon,
    BriefcaseIcon,
    CurrencyDollarIcon,
    FolderRemoveIcon,
    LocationMarkerIcon,
    MailIcon,
    PencilIcon,
    PhoneIcon,
    TruckIcon,
    UserAddIcon,
    UserGroupIcon,
    UserRemoveIcon
} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getLookupWithFilter, getProp} from "../../util/util";
import CredentialTab from "./credentialTab";
import MotivationTab from "./motivationTab";
import DesiredRoleTab from "./desiredRolesTab";
import {createSecondResource, deleteSecondResource, getSecondResource} from "../../data/actions/secondResource";
import NewRolesTab from "./newRolesTab";
import SurveyTab from "./surveyTab";
import ResumeTab from "./resumeTab";
import SkillsTab from "./skillsTab";
import {Field} from "../../data/services/fields";
import {createResource} from "../../data/actions/resource";
import NotesTab from "./notesTab";
import ReferenceTab from "./referenceTab";
import Env from "../../util/env";
import DocumentsTab from "../common/documentsTab";
import {SocialIcon} from 'react-social-icons';
import VisitorsTab from "./visitorsTab";
import ResourceListTab from "../../components/resource-list-tab";
import ModalMessage from "../../components/modal/modal-message-contact";
import MessagesTab from "./messagesTab";
import MessagesSmsTab from "./messagesSmsTab";
import NavResponsive from "../../components/nav-responsive";
import ModalInviteCandidate from "../../components/modal/modal-invite-candidate";
import Loader from "../../components/loader";
import ModalCreateVersion from "../../components/modal/modal-create-version";
import ModalConfirm from "../../components/modal/modal-confirm";
import PageHeader from "../../components/layout-dashboard/page-header";
import ListBox from "../../components/listbox";

class CandidateSinglePage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Experience', resource: Resources.CandidateResume, current: true},
            {name: 'Skills', resource: Resources.CandidateSkills, current: false},
            {name: 'Credentials', resource: Resources.CandidateCredentials, current: false},
            {name: 'Summary', resource: Resources.CandidateSummary, current: false},
            //{name: 'Desired Role', resource: Resources.CandidateDesiredRole, current: false},
            {name: 'References', resource: Resources.CandidateReferences, current: false},
            {name: 'Media', resource: Resources.CandidateMedia, current: false},
            {name: 'Documents', resource: Resources.CandidateDocuments, current: false},
            {name: 'New Roles', resource: Resources.CandidateNewRoles, current: false},
            //{name: 'Survey', resource: Resources.CandidateSurvey, current: false},
            {name: 'Notes', resource: Resources.CandidateNotes, current: false},
            {name: 'Email', resource: Resources.CandidateMessage, current: false},
            {name: 'SMS', resource: Resources.CandidateMessageSMS, current: false},
            {name: 'Visitors', resource: Resources.CandidateVisitors, current: false},
            {name: 'Commission', resource: Resources.CandidateCommission, current: false}
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,

            selectedVersionID: 0,

            editModalOpen: false,
            msgModalOpen: false,
            inviteModalOpen: false,
            versionModalOpen: false,
            versionDeleteModalOpen: false,
            versionSwitchModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        const query = new URLSearchParams(this.props.location.search);
        const tab = query.get('tab');
        if (tab) {
            this.handleTabChange(Resources.CandidateMessageSMS);
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.secondResource.data === null) && (!!this.props.secondResource.data)) {
            LocalStorage.getAndSave('contactsHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.match.params.id,
                    name: this.props.secondResource.data.FirstName + " " + this.props.secondResource.data.LastName
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

        if (this.getId() !== prevProps.match.params.id) {
            this.fetchData();
            this.setState({
                selectedTab: null
            }, () => {
                this.handleTabChange(Resources.CandidateResume);
            });
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Candidate
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleMsgModal = () => {
        this.setState({
            msgModalOpen: !this.state.msgModalOpen
        })
    }

    handleToggleInviteModal = () => {
        this.setState({
            inviteModalOpen: !this.state.inviteModalOpen
        })
    }

    handleToggleVersionModal = () => {
        this.setState({
            versionModalOpen: !this.state.versionModalOpen
        })
    }

    handleToggleDeleteVersionModal = () => {
        this.setState({
            versionDeleteModalOpen: !this.state.versionDeleteModalOpen
        })
    }

    handleToggleVersionSwitchModal = () => {
        this.setState({
            versionSwitchModalOpen: !this.state.versionSwitchModalOpen
        })
    }

    handleVersionSwitch = (value) => {
        const tab = this.state.selectedTab;
        this.setState({
            selectedTab: null,
            selectedVersionID: value
        }, () => {
            this.handleTabChange(tab);
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getFields = () => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),

            Email: new Field("Email", '', ['empty'], false, 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),

            Credentials: new Field("Credentials", '', [], false, 'text'),
            TitleID: new Field("TitleID", '', [], false, 'select'),

            Headline: new Field("Headline", '', [], false, 'textarea'),
            Address1: new Field("Address1", '', [], false, 'text'),
            Address2: new Field("Address2", '', [], false, 'text'),
            City: new Field("City", '', [], false, 'text'),
            StateID: new Field("StateID", '', [], false, 'select'),
            PostalCode: new Field("PostalCode", '', [], false, 'text'),

            Relocate: new Field("Relocate", '', [], false, 'checkbox'),

            LinkedinProfileURL: new Field("LinkedinProfileURL", '', [], false, 'text'),

            ImagePath: new Field("ImagePath", '', [], false, 'image')
        };

        const item = getProp(this.props, "secondResource.data", {});

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, secondResource} = this.props;

        const candidate = getProp(this.props, "secondResource.data", {});

        let documentDocType = getLookupWithFilter('MediaType', 'MediaTypeID', 'MediaType',
            item => item.IsVideoType == 0,
            {});

        const IsConnected = getProp(this.props, "secondResource.data.Connected", false);

        const InviteSend = getProp(this.props, "secondResource.data.InviteSend", false);

        const CanEdit = getProp(this.props, "secondResource.data.canEdit", false);

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.contact")}
                >
                    <div className="mt-5 flex xl:mt-0 xl:ml-4">
                        {!!IsConnected && (
                            <button
                                onClick={this.handleToggleMsgModal}
                                type="button"
                                className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <MailIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                                Send Message
                            </button>
                        )}

                        {!IsConnected && (
                            <>
                                {!InviteSend && (
                                    <p className="mb-1 text-sm text-secondary-500 mr-2 pt-1">* Candidate was not invited
                                        to join system, send invite now?</p>
                                )}
                                <button
                                    onClick={this.handleToggleInviteModal}
                                    type="button"
                                    className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                    <MailIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                                    {!InviteSend ? "Send Invite" : "Resend Invite"}
                                </button>
                            </>
                        )}
                    </div>
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    {!!secondResource.isLoading && (
                        <div className="p-4 sm:p-6 text-center">
                            <Loader/>
                        </div>
                    )}

                    <div
                        className={"mx-auto p-4 sm:p-6 lg:p-8 xl:items-center xl:justify-between" + (!!secondResource.isLoading ? " hidden" : " xl:flex")}>
                        {!secondResource.isLoading && !candidate.ImagePath && (
                            <div
                                className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                <div
                                    className="text-inverse font-black">{(candidate.ContactName)?.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                            </div>
                        )}

                        {!secondResource.isLoading && candidate.ImagePath && (
                            <React.Fragment>
                                <div
                                    className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none overflow-hidden">
                                    <img
                                        className="object-cover h-16 w-16"
                                        src={Env.getApiUrl('api/' + Resources.CandidateImage, {
                                            id: this.getId(),
                                            token: LocalStorage.get('user').access_token
                                        }) + "&date=" + new Date().getTime()}
                                        alt=""
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        {(!secondResource.isLoading || candidate.ContactName) && (
                            <div className="flex-1 min-w-0">
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                    {candidate.ContactName}
                                </h1>

                                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
                                    {!!candidate.Title && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Title"}>
                                            <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Title}
                                        </div>
                                    )}

                                    {!!candidate.Phone && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Phone"}>
                                            <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.AreaCode} {candidate.Phone} {candidate.PhoneExtension}
                                        </div>
                                    )}

                                    {!!candidate.Email && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Email"}>
                                            <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Email}
                                        </div>
                                    )}

                                    {(!!candidate.Address1 || !!candidate.Address2) && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Location"}>
                                            <LocationMarkerIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Address1 ? (candidate.Address1 + ",") : ""} {candidate.Address2 ? (candidate.Address2 + ",") : ""} {candidate.City}, {candidate.State}{candidate.PostalCode ? (", " + candidate.PostalCode) : ""}
                                        </div>
                                    )}

                                    {!!candidate.Headline && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Headline"}>
                                            <CurrencyDollarIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Headline}
                                        </div>
                                    )}

                                    {!!candidate.LinkedinProfileURL && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Headline"}>
                                            <SocialIcon className="social-icon-w-30" url={candidate.LinkedinProfileURL}
                                                        target={"_blank"}/>
                                        </div>
                                    )}

                                    <div className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                         title={"Relocate"}>
                                        <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                        {candidate.Relocate ? "Yes" : "No"}
                                    </div>

                                    {!!CanEdit && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-primary-500 cursor-help mr-8"
                                            title={"Edit Candidate Permissions"}>
                                            <PencilIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            You are allowed to edit candidate data
                                        </div>
                                    )}

                                    {!CanEdit && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-orange-800 cursor-help mr-8"
                                            title={"Edit Candidate Permissions"}>
                                            <FolderRemoveIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            Candidate hasn't given you access to edit their profile
                                        </div>
                                    )}

                                    <div
                                        className="mt-2 flex flex-1 items-center text-sm text-primary-800 mr-2"
                                    >
                                        <span className={"flex mr-2"}>Profile Version</span>

                                        <ListBox
                                            name="ProfileVersion"
                                            options={getProp(candidate, "availableVersions", []).map(it => {
                                                let item = {}
                                                item.label = it.ProfileName;
                                                item.value = it.CandidateProfileID;
                                                return item;
                                            })}
                                            onChange={(option) => {
                                                this.handleVersionSwitch(option)
                                            }}
                                            value={this.state.selectedVersionID}
                                        />

                                        {!!CanEdit && (
                                            <>
                                                <div
                                                    onClick={this.handleToggleVersionModal}
                                                    className="ml-2 mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                                    title={"Add Profile"}>
                                                    <UserAddIcon
                                                        className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                                </div>

                                                {(this.state.selectedVersionID != 0) && (<>
                                                    <div
                                                        onClick={this.handleToggleDeleteVersionModal}
                                                        className="mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                                        title={"Remove Profile"}>
                                                        <UserRemoveIcon
                                                            className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                                    </div>

                                                    <div
                                                        onClick={this.handleToggleVersionSwitchModal}
                                                        className="mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                                        title={"Switch base profile"}>
                                                        <UserGroupIcon
                                                            className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                                    </div>
                                                </>)}
                                            </>
                                        )}

                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </header>

                <main className="pt-8 pb-16 sm:px-6 lg:px-8 px-4 relative bg-inverse custom-min-h-tab">
                    <div className="">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("text." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    {translate("text.select_a_tab")}
                                </label>

                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                    onChange={e => this.handleTabChange(e.target.value)}
                                >
                                    {this.state.tabs.map((tab) => (
                                        <option
                                            key={tab.name}
                                            value={tab.resource}
                                        >
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="border-b-2 border-primary-500 mt-6">
                                </div>
                            </div>

                            <div className="hidden sm:block">
                                <div className="border-b border-secondary-200">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* Content */}
                        <div className="mt-6 ">
                            {this.state.selectedTab === Resources.CandidateResume && (
                                <ResumeTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateSkills && (
                                <SkillsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateCredentials && (
                                <CredentialTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateSummary && (
                                <MotivationTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    disableEdit={!CanEdit}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateReferences && (
                                <ReferenceTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    title={"Reference"}
                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateDesiredRole && (
                                <DesiredRoleTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    canSubmit={CanEdit}

                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateMedia && (
                                <DocumentsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    resourceName={Resources.CandidateMedia}
                                    primaryKey={"CandidateMediaID"}
                                    title={"Media"}
                                    mediaTypesText={translate("text.video_formats")}

                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateDocuments && (
                                <DocumentsTab
                                    id={this.getId()}
                                    documentTypes={documentDocType}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    documentType={true}

                                    fields={{
                                        IsPublic: new Field("IsPublic", '', [], false, 'checkbox')
                                    }}
                                    resourceName={Resources.CandidateDocuments}
                                    primaryKey={"CandidateDocumentID"}
                                    title={"Document"}

                                    disableCreate={!CanEdit}
                                    disableEdit={!CanEdit}
                                    disableDelete={!CanEdit}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateNewRoles && (
                                <NewRolesTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    dialogResource={this.props.dialogResource}
                                    resource={this.props.resource}
                                    ui={this.props.ui}
                                    candidate={candidate}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateSurvey && (
                                <SurveyTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateNotes && (
                                <NotesTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    title={"Notes"}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateVisitors && (
                                <VisitorsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateCommission && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    dialogTitle={"Commission"}

                                    sortBy={"CandidateCommissionID"}
                                    primaryKey={"CandidateCommissionID"}
                                    resourceName={Resources.CandidateCommission}
                                    fields={{
                                        Name: new Field("Name", '', ['empty'], false, 'text'),
                                        PaymentAmount: new Field("PaymentAmount", '', ['float'], false, 'text'),
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateMessage && (
                                <MessagesTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    email={candidate?.Email}
                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}

                                    sortBy={"CandidateMessageSentID"}
                                    primaryKey={"CandidateMessageSentID"}
                                    resourceName={Resources.CandidateMessage}
                                    fields={{
                                        ContactID: new Field("ContactID", '', ['empty'], true, 'select-search'),
                                        Date: new Field("Date", '', ['empty'], true, 'date'),
                                        Subject: new Field("Subject", '', ['empty'], true, 'text'),
                                        Message: new Field("Message", '', ['empty'], true, 'textarea'),
                                        IsReceived: new Field("IsReceived", '', [], true, 'checkbox'),
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateMessageSMS && (
                                <MessagesSmsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    disableCreate={true}
                                    disableEdit={true}
                                    disableDelete={true}

                                    resourceName={Resources.CandidateMessageSMS}
                                />
                            )}
                        </div>
                    </div>

                    <ModalMessage
                        title={"Send Message"}
                        visible={this.state.msgModalOpen}
                        onClose={this.handleToggleMsgModal}
                        onSubmit={(params) => {
                            if (params) {
                                params.CandidateID = this.getId();
                                params.id = this.getId();
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    params: params,
                                    resource: Resources.CandidateMessage
                                }));
                                this.handleToggleMsgModal()
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalInviteCandidate
                        title={"Send Invite"}
                        visible={this.state.inviteModalOpen}
                        onClose={this.handleToggleInviteModal}
                        onSubmit={(params) => {
                            if (params) {
                                params.CandidateID = this.getId();
                                params.id = this.getId();
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    params: params,
                                    resource: Resources.CandidateInviteConnect,
                                    notificationMessage: "Email invitation is sent to the candidate"
                                }));
                                this.handleToggleInviteModal()
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalCreateVersion
                        title={"Create Profile Version"}
                        visible={this.state.versionModalOpen}
                        onClose={this.handleToggleVersionModal}
                        onSubmit={(params) => {
                            if (params) {
                                params.CandidateID = this.getId();
                                params.id = this.getId();
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get("user"),
                                    params: params,
                                    resource: Resources.RoleVersionCreate,
                                    notificationMessage: "New profile version is created",
                                    query: this.getQuery(),
                                    piggyResource: Resources.Candidate
                                }));
                                this.handleToggleVersionModal()
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalConfirm
                        title={"Switch Base Profile"}
                        text={"Are you sure you want to make current profile base?"}
                        visible={this.state.versionSwitchModalOpen}
                        onClose={this.handleToggleVersionSwitchModal}
                        btnColor={'blue'}
                        iconColor={'blue'}
                        onConfirm={() => {
                            let params = {};
                            params.CandidateID = this.getId();
                            params.id = this.getId();
                            params.CandidateProfileID = this.state.selectedVersionID;

                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get("user"),
                                params: params,
                                resource: Resources.RoleVersionSwitch,
                                notificationMessage: "Profile is set a as base one.",
                                query: this.getQuery(),
                                piggyResource: Resources.Candidate
                            }));

                            this.handleToggleVersionSwitchModal(false);
                            this.handleVersionSwitch(0);
                        }}
                    />

                    <ModalConfirm
                        title={"Delete Profile Version"}
                        text={"Are you sure you want to delete current profile?"}
                        visible={this.state.versionDeleteModalOpen}
                        onClose={this.handleToggleDeleteVersionModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    CandidateProfileID: this.state.selectedVersionID,
                                    id: this.getId()
                                }, this.getQuery()),
                                resource: Resources.RoleVersionCreate,
                                notificationMessage: "Profile version is deleted",
                                piggyResource: Resources.Candidate
                            }));

                            this.handleToggleDeleteVersionModal(false);
                            this.handleVersionSwitch(0);
                        }}
                    />

                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(CandidateSinglePage);