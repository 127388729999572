import React from "react";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import {classNames} from "../../util/util";
import PencilAltIcon from "@heroicons/react/outline/PencilAltIcon";

export default function InfoParagraph({type, message, children, onClick, className, style}) {

    const getTypeClasses = () => {
        switch (type) {
            case "danger":
                return "border-red-600"
            case "warning":
                return "border-yellow-600"
            case "note":
                return "border-secondary"
            default:
                return "border-link"
        }
    }

    const getTypeIconClasses = () => {
        switch (type) {
            case "danger":
                return "bg-red-600 text-white"
            case "warning":
                return "bg-yellow-600 text-white"
            case "note":
                return "bg-transparent text-default-soft"
            default:
                return "bg-link text-white"
        }
    }

    const getTypeIcon = () => {
        switch (type) {
            case "danger":
                return <ExclamationIcon className="h-6 w-6"/>
            case "warning":
                return <InformationCircleIcon className="h-6 w-6"/>
            case "note":
                return <PencilAltIcon className="h-6 w-6"/>
            default:
                return <InformationCircleIcon className="h-6 w-6"/>
        }
    }

    return (
        <div
            onClick={() => !!onClick ? onClick() : null}
            className={"flex" + (!!onClick ? " cursor-pointer" : "")}
            style={style}
        >

            <div className={classNames(
                "bg-body  text-default border flex items-center flex-grow",
                className ? " " + className : "",
                getTypeClasses()
            )}
            >
                <div
                    className={classNames("px-1 p-2 h-full flex items-center justify-center", getTypeIconClasses())}>
                    {getTypeIcon()}
                </div>

                <div className="p-2 text-current w-full">
                    {message}

                    {children}
                </div>
            </div>
        </div>
    )
}
