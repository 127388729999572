import React from 'react';
import {Menu, Transition} from "@headlessui/react";
import {ArrowsExpandIcon, ChevronUpIcon} from "@heroicons/react/outline";
import {AnnotationIcon, DocumentTextIcon, PencilAltIcon, VideoCameraIcon} from "@heroicons/react/solid";

export default function MobileDropdown(props) {
    const {candidatePresenter, sidebarContent, toggleViewRightBar, translate} = props;
    return (
        <Menu>
            <Menu.Button className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2 focus:outline-none">
                <ChevronUpIcon className="w-5 h-5"/>
            </Menu.Button>

            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Menu.Items className="z-10 absolute right-2 bottom-14 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                        {({ active }) => (
                            <span
                                className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                                href="/account-settings"
                            >
                                <ArrowsExpandIcon className="w-4 h-4 mr-2"/>

                                {!!candidatePresenter ? translate("text.go_primary_window") : translate("text.go_small_window")}
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item onClick={() => toggleViewRightBar(sidebarContent === 'chat' ? false : "chat")}>
                        {({ active }) => (
                            <span
                                className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                                href="/account-settings"
                            >
                                <AnnotationIcon className="w-4 h-4 mr-2"/>

                                {sidebarContent === 'chat' ? translate("text.hide_chat") : translate("text.show_chat")}
                            </span>
                        )}
                    </Menu.Item>

                    <Menu.Item onClick={() => toggleViewRightBar(sidebarContent === 'notes' ? false : "notes")}>
                        {({ active }) => (
                            <span
                                className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                                href="/account-settings"
                            >
                                <PencilAltIcon className="w-4 h-4 mr-2"/>

                                {sidebarContent === 'notes' ? translate("text.hide_notes") : translate("text.show_notes")}
                            </span>
                        )}
                    </Menu.Item>

                    <Menu.Item onClick={() => toggleViewRightBar(sidebarContent === 'recordings' ? false : "recordings")}>
                        {({ active }) => (
                            <span
                                className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                                href="/account-settings"
                            >
                                <VideoCameraIcon className="w-4 h-4 mr-2"/>

                                {sidebarContent === 'recordings' ? "Hide Recordings" : "View Recordings"}
                            </span>
                        )}
                    </Menu.Item>

                    <Menu.Item onClick={() => toggleViewRightBar(sidebarContent === 'documents' ? false : "documents")}>
                        {({ active }) => (
                            <span
                                className={`cursor-pointer block py-2 px-4 flex items-center text-gray-700 ${active && 'bg-primary-600 text-white'}`}
                                href="/account-settings"
                            >
                                <DocumentTextIcon className="w-4 h-4 mr-2"/>

                                {sidebarContent === 'documents' ? translate("text.hide_documents") : translate("text.show_documents")}
                            </span>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}