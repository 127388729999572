import React, {Component, useEffect, useState} from "react";
import Layout from "../../components/layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import {Field, FieldsManager} from "../../data/services/fields";
import {register} from "../../data/actions/user";
import Button from "../../components/button/index";
import {fieldsToHtml, scrollErrorIntoView} from "../../util/util";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";
import HomeIcon from "@heroicons/react/solid/HomeIcon";
import Loader from "../../components/loader";
import {CardElement, Elements, ElementsConsumer, PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import "./payment.css";
import {CheckIcon} from "@heroicons/react/outline";
import {CodeIcon} from "@heroicons/react/solid";
import FieldText from "../../components/field-text";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";

const features = [
    'Candidate CRM – Send email and track from within the system',
    'Communication Management – via email and SMS messaging',
    'Interview Management – Built-in calendar management and integrated video conferencing',
    'Chrome Extensions – Quickly download new candidates from websites',
    'Social connections – Post jobs to leading social networks',
    'Job Portal – Custom job portal to market roles'
];

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#007a64',
            color: '#007a64',
            fontWeight: 500,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#00a88b',
            },
            '::placeholder': {
                color: '#00a88b',
            },
            borderColor: '#00a88b',
            padding: 10
        },
        invalid: {
            iconColor: 'red',
            color: 'red',
        },
    },
};

const ErrorMessage = ({children}) => (
    <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-149 text-red-700">
        <svg className="text-red-500 w-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"/>
        </svg>
        {children}
    </div>
);

const CardField = ({onChange}) => (
    <div>
        <CardElement options={CARD_OPTIONS} onChange={onChange} className="card-element"/>
    </div>
);

const DEFAULT_STATE = {
    error: null,
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    quantity: 1,

    couponApplied: false,
    Coupon: null,
    couponModifier: 1
};

const CheckoutForm = ({stripe, quantity, price, onPaymentSuccess}) => {
    const [paymentRequest, setPaymentRequest] = useState(null);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: quantity +  'Seats Subscription / Per Month',
                    amount: price,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestPayerPhone: true
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                console.log("canMakePayment", result);
                if (result) {
                    setPaymentRequest(pr);
                    pr.on('paymentmethod', (ev) => {
                        //this event is triggered at the completion of the saved payment
                        //interface
                        //we don't care about the actual payment request anymore
                        //now that we have a paymentMethod, we can check out as normal
                        //the checkout function I am not including here, but is very similar
                        //to the 'create a subscription' stripe guide
                        //however it is important to note that I am passing in the event as an
                        //argument.  Once checkout receives a response from my server as to
                        //wether the transaction was successful or not, use
                        //ev.complete('success') and ev.complete('fail') accordingly to close
                        //the modal or display an error message (browser specific behavior)
                        onPaymentSuccess(ev.paymentMethod.id, ev)
                    });
                }
            }).catch((e) => console.log("canMakePayment", e));
        }
    }, [stripe]);

    console.log(paymentRequest);
    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{paymentRequest}} />
    }

    // Use a traditional checkout form.
    return <div></div>;
}

class InjectedStripe extends Component {

    constructor(props) {
        super(props);
        this.stripePromise = loadStripe('pk_test_51Jx7ohCX1EqdpeEcetLKlxiCG4SNgX414re1abRVLYd94wxkNdMgR6tQllC6yfPvYjMKXKi7xw0KimPBFCE6iXMX00KlLU912G');
    }

    render() {
        return (
            <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                    {({stripe, elements}) => (
                        <RegisterView stripe={stripe} elements={elements} {...this.props} />
                    )}
                </ElementsConsumer>
            </Elements>
        )
    }
}

export default connect(state => state)(InjectedStripe);

class RegisterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                FirstName: new Field("FirstName", '', ['empty'], false, "text", {
                    formGroupClass: "sm:col-span-3",
                }),
                LastName: new Field("LastName", '', ['empty'], false, "text", {
                    formGroupClass: "sm:col-span-3",
                }),
                Email: new Field('Email', '', ['empty'], false, "email", {
                    formGroupClass: "sm:col-span-4",
                }),
                CompanyName: new Field("CompanyName", '', [], false, "text", {
                    formGroupClass: "sm:col-span-6",
                }),

                AddressName: new Field('AddressName', "", ['empty'], false, 'text', {
                    formGroupClass: "sm:col-span-6",
                }),
                AddressName2: new Field('AddressName2', "", [], false, 'text', {
                    formGroupClass: "sm:col-span-6",
                }),
                CityName: new Field('CityName', "", ['empty'], false, "text", {
                    formGroupClass: "sm:col-span-3",
                }),
                PostalCode: new Field('PostalCode', "", ['empty'], false, "text", {
                    formGroupClass: "sm:col-span-3",
                }),
            },
            ...DEFAULT_STATE
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleTierSelect = (tierName) => {
        let tiers = this.state.tiers;

        tiers = Object.values(tiers).reduce((memo, it) => {
            it.selected = it.name === tierName;
            memo[it.name] = it;
            return memo;
        }, {});

        this.setState({
            tiers: tiers,
            selectedTier: tierName
        });
    };

    applyCoupon = () => {
        axios.get(
            Env.getApiUrl("/api/coupon/check", {Coupon: this.state.Coupon}),
            {

            }
        )
            .then((response) => {
                const result = processResponse(response);
                if (result && result.status === 0) {
                    this.setState({
                        couponApplied: true,
                        couponModifier: 0.5,
                        couponError: false
                    })
                }
            })
            .catch(() => {
                this.setState({
                    couponApplied: false,
                    couponModifier: 1,
                    couponError: true
                })
            });
    }

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event).then(r => r);
        }
    };

    handleSubmit = async (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, async () => {
            const {stripe, elements} = this.props;
            const {error, cardComplete} = this.state;

            const email = this.state.fields.Email.value;
            const name = this.state.fields.CompanyName.value;

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const card = elements.getElement(CardElement);

            if (card == null) {
                return;
            }

            if (error) {
                card.focus();
                return;
            }

            if (cardComplete) {
                this.setState({processing: true});
            }

            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card,
                billing_details: {
                    email,
                    name,
                },
            });

            console.log(payload);

            this.setState({processing: false});

            if (payload.error) {
                this.setState({error: payload.error});
            } else {
                this.submitPayment(payload.paymentMethod)
            }
        });
    };

    submitPayment = (id) => {
        if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
            this.props.dispatch(register({
                params: Object.assign({
                        Quantity: this.state.quantity,
                        paymentMethodId: id,
                        Coupon: this.state.couponApplied ? this.state.Coupon : ""
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields),
                    {
                        registerCompany: true
                    }
                )
            }));
        } else {
            scrollErrorIntoView(this.state.fields);
        }
    }

    render() {
        const {translate} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {});

        const price = this.state.quantity * 149 * this.state.couponModifier;

        return (
            <Layout {...this.props}>
                <div className="sm:pt-24 sm:px-14 pb-7 layout-min-h">
                    <div className="bg-inverse shadow rounded-lg p-4 sm:p-8 max-w-4xl mx-auto">
                        <div className="p-8 text-center">
                            <img className="inline-block w-40" src="/images/logos/logo.png" alt="GoLeir"/>
                        </div>

                        {(this.props.user.isLoading || this.state.processing) && (
                            <div className={"inset-center"}>
                                <Loader/>
                            </div>
                        )}

                        {this.props.user.error && (
                            <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-149 text-red-700">
                                <svg className="text-red-500 w-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                          clipRule="evenodd"/>
                                </svg>

                                {translate(this.props.user.errorMessage)} {this.props.user.data?.error_message ? translate(this.props.user.data?.error_message) : ""}
                            </div>
                        )}

                        {!this.props.user.isLoading && !this.props.user.register && (
                            <form onKeyPress={this.submitForm}>
                                <div className="">
                                    <div className="bg-white my-4 shadow p-8 rounded-lg">
                                        <div className="bg-white">
                                            <div className="max-w-7xl mx-auto px-4">
                                                <div className="pb-16 xl:flex xl:items-center xl:justify-between">
                                                    <div>
                                                        <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
                                                            <span className="text-gray-900">Everything you need for </span>
                                                            <span className="text-primary-600">$149 a month per user</span>
                                                        </h1>
                                                        <p className="mt-5 text-xl text-gray-500">
                                                            Includes everything we offer.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
                                                    <div>
                                                        <h2 className="text-base font-semibold text-primary-600 tracking-wide uppercase">Everything you need</h2>
                                                        <p className="mt-2 text-3xl font-extrabold text-gray-900">All-in-one platform</p>
                                                        <p className="mt-4 text-lg text-gray-500">
                                                            GoLeir is a cloud-based end-to-end hiring solution for recruiters, staffing agencies, and corporate HRs to
                                                            manage candidates, clients, and roles, streamlining the hiring process.
                                                        </p>
                                                    </div>
                                                    <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
                                                        <ul role="list" className="divide-y divide-gray-200">
                                                            {features.slice(0, 3).map((feature, featureIdx) =>
                                                                featureIdx === 0 ? (
                                                                    <li key={feature} className="py-4 flex md:py-0 md:pb-4">
                                                                        <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                                                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                                                    </li>
                                                                ) : (
                                                                    <li key={feature} className="py-4 flex">
                                                                        <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                                                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                        <ul role="list" className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                                                            {features.slice(3).map((feature, featureIdx) =>
                                                                featureIdx === 0 ? (
                                                                    <li key={feature} className="py-4 flex md:border-t-0 md:py-0 md:pb-4">
                                                                        <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                                                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                                                    </li>
                                                                ) : (
                                                                    <li key={feature} className="py-4 flex">
                                                                        <CheckIcon className="flex-shrink-0 h-6 w-6 text-green-500" aria-hidden="true" />
                                                                        <span className="ml-3 text-base text-gray-500">{feature}</span>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white my-4 shadow p-8 rounded-lg">
                                        <div className=" ">
                                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-left">Information about your company</h2>
                                            <p className="mt-1 text-sm text-gray-500">
                                                Add some text here maybe
                                            </p>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            {fieldsHtml}
                                        </div>
                                    </div>

                                    <div className="bg-white my-4 shadow p-8 rounded-lg">
                                        <div className="sm:flex sm:flex-col sm:align-center">
                                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-left">Payment Information</h2>
                                            <p className="mt-1 text-sm text-gray-500">
                                                $149 per seat. You can add or remove users at any time.
                                            </p>
                                        </div>

                                        <p className="mt-1 text-sm text-gray-500">
                                            Choose number of seats
                                        </p>
                                        <div className="custom-number-input h-10 w-full flex flex-row">
                                            <div
                                                className="flex flex-row h-10 w-9/12 rounded-lg relative bg-transparent mt-1">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            quantity: (this.state.quantity == 1) ? 1 : this.state.quantity - 1
                                                        })
                                                    }}
                                                    className="bg-primary hover:bg-primary-700 text-inverse border border-transparent font-btn h-full w-20 rounded-l cursor-pointer outline-none">
                                                    <span className="m-auto text-2xl font-thin">−</span>
                                                </button>
                                                <input type="number"
                                                        className="outline-none focus:outline-none text-center w-full font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                                        name="custom-input-number" value={this.state.quantity}/>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            quantity: this.state.quantity + 1
                                                        })
                                                    }}
                                                    className="bg-primary hover:bg-primary-700 text-inverse border border-transparent font-btn h-full w-20 rounded-r cursor-pointer">
                                                    <span className="m-auto text-2xl font-thin">+</span>
                                                </button>
                                            </div>
                                            <div className={"ml-2 mt-2"}>
                                                <span>Total</span> <span>${price}</span>
                                            </div>
                                        </div>

                                        <div className={"w-full mt-4"}>
                                            <p className="mt-1 text-sm text-gray-500">
                                                Enter Credit Card information
                                            </p>
                                            <CardField
                                                onChange={(event) => {
                                                    this.setState({
                                                        error: event.error,
                                                        cardComplete: event.complete,
                                                    });
                                                }}
                                            />

                                            {!!this.props.stripe && (
                                                <div className={"mt-2 text-sm text-gray-500"}>
                                                    <div>Or Use</div>
                                                    <CheckoutForm {...this.props} price={price} quantity={this.state.quantity} onPaymentSuccess={(id, ev) => {
                                                        this.submitPayment(id);
                                                    }}/>
                                                </div>
                                            )}

                                            {/**

                                            <p className="mt-2 text-sm text-gray-500">
                                                * Optionally apply coupon code
                                            </p>
                                            {this.state.couponApplied && (
                                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                                                    Applied Coupon: {this.state.Coupon}
                                                </span>
                                            )}



                                            {!this.state.couponApplied && (
                                                <div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <CodeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </div>
                                                            <FieldText
                                                                className={"focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"}
                                                                onChange={(name, value) => {
                                                                    this.setState({
                                                                        Coupon: value
                                                                    })
                                                                }}
                                                                value={this.state.Coupon}
                                                            />
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                this.applyCoupon()
                                                            }}
                                                            type="button"
                                                            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
                                                        >
                                                            <span>Apply Coupon</span>
                                                        </button>
                                                    </div>

                                                    {this.state.couponError && (
                                                        <ErrorMessage>Invalid Coupon</ErrorMessage>
                                                    )}
                                                </div>
                                            )}

                                             **/}
                                        </div>

                                        {this.state.error && <ErrorMessage>{this.state.error.message}</ErrorMessage>}
                                    </div>

                                </div>

                                <div className="mt-6">
                                    <Button
                                        className="w-full justify-center cursor-pointer"
                                        type="primary"
                                        onClick={this.handleSubmit}>
                                        {translate("btn.register")}
                                    </Button>
                                </div>
                            </form>
                        )}

                        {this.props.user.register && (
                            <React.Fragment>
                                <div className="rounded-md bg-green-50 p-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-green-800">
                                                Order completed
                                            </h3>
                                            <div className="mt-2 text-sm text-green-700">
                                                <p>
                                                    {translate("text.register_company_success")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <Link className="btn btn-primary" to={`/login`}>
                                        <HomeIcon className="w-5 h-5 mr-1"/>

                                        {translate("btn.back_to_login")}
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="text-secondary-600 mt-6 text-center">
                        Sign in with another account? <Link
                        className="no-underline border-b border-primary text-primary-700 hover:opacity-70"
                        to={"/login"}>Login</Link>.
                    </div>
                </div>
            </Layout>
        );
    }
}

