import React from "react";
import {classNames} from "../../../util/util"

export default function CalendarMonthView(props) {
    const {days, weekFormat, onEventClick, onDayClick, onMoreClick, maxVisibleEvents} = props;

    return (
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div
                className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                {weekFormat.map(day => (
                    <div className="bg-white py-2">
                        {day.abbr}<span className="sr-only sm:not-sr-only">{day.abbrExt}</span>
                    </div>
                ))}
            </div>

            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                    {days.map((day) => (
                        <div
                            key={day.date}
                            className={classNames(
                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                'min-h-[14rem] relative py-2 px-1'
                            )}
                        >
                            <time
                                dateTime={day.date}
                                className={classNames(
                                    "relative z-10 px-1",
                                    day.isSelected && day.isToday && 'flex h-6 w-6 items-center justify-center rounded-full bg-primary-600 font-semibold text-white',
                                    day.isSelected && !day.isToday && 'flex h-6 w-6 items-center justify-center rounded-full bg-gray-900 font-semibold text-white',
                                    !day.isSelected && day.isToday
                                        ? 'text-primary-600 font-bold'
                                        : undefined
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            {day.events.length > 0 && (
                                <ol className="mt-1">
                                    {day.events.slice(0, day.events.length > maxVisibleEvents ?  maxVisibleEvents - 1 : maxVisibleEvents).map((event) => (
                                        <li key={event.id} onClick={() => onEventClick(event)}
                                            className={classNames("cursor-pointer relative z-10 p-1 rounded-md group", event.color["hoverBg100"])}
                                        >
                                            <div className="">
                                                <p className={classNames(event.color["groupHoverText700"], "flex-auto truncate font-medium text-gray-900 leading-tight flex items-center")}>
                                                    {!!event.icon && (
                                                        <event.icon className={classNames(event.color["text500"], "flex-shrink-0 w-5 h-5 mr-1")} />
                                                    )}

                                                    {!event.icon && (
                                                        <div className="w-5 h-5 mr-1 flex-shrink-0 flex items-center justify-center">
                                                            <div className={classNames(
                                                                event.color["bg400"],
                                                                "w-2 h-2 rounded-full"
                                                            )}
                                                        />
                                                        </div>
                                                    )}

                                                    <time
                                                        dateTime={event.datetime}
                                                        className={classNames(event.color["groupHoverText700"], "mr-1 block flex-none text-gray-700 leading-tight")}
                                                    >
                                                        {event.displayTime.replaceAll(":00", "")}
                                                    </time>

                                                    <span className="font-semibold text-gray-800">{event.name}</span>
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                    {day.events.length > maxVisibleEvents &&
                                    <li className="p-1 rounded-md text-gray-500 relative cursor-pointer z-10 hover:text-primary hover:bg-primary-50 z-10"
                                        onClick={() => onMoreClick(day)}>+ {day.events.length - (maxVisibleEvents - 1)} more</li>}
                                </ol>
                            )}

                            {/*Hover bg*/}
                            <div className="hover:bg-gray-200 cursor-pointer absolute inset-0"
                                 onClick={() => onDayClick(day.date)}/>
                        </div>
                    ))}
                </div>

                <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                    {days.map((day) => (
                        <button
                            key={day.date}
                            onClick={() => onMoreClick(day)}
                            type="button"
                            className={classNames(
                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                day.isSelected && 'text-white',
                                !day.isSelected && day.isToday && 'text-primary-600',
                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                            )}
                        >

                            <time
                                dateTime={day.date}
                                className={classNames(
                                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                    day.isSelected && day.isToday && 'bg-primary-600',
                                    day.isSelected && !day.isToday && 'bg-gray-900',
                                    'ml-auto'
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                            <p className="sr-only">{day.events.length} events</p>
                            {day.events.length > 0 && (
                                <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                    {day.events.map((event) => (
                                        <div key={event.id}
                                             className={"mx-0.5 mb-1 h-1.5 w-1.5 rounded-full " + event.color['bg400']}/>
                                    ))}
                                </div>
                            )}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}