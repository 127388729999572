import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {DownloadIcon} from "@heroicons/react/solid";
import Dropzone from "react-dropzone";
import CSV from "comma-separated-values";
import {Switch} from "@headlessui/react";
import {uploadDocument} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import FieldSelectSearch from "../../components/field-select-search";
import FieldText from "../../components/field-text";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ImportPage extends Component {

    constructor(props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const selectedSubTab = query.get('selectedSubTab');

        this.state = {
            subNavigation: [
                {
                    name: 'CSV',
                    description: '',
                    href: '#',
                    icon: DownloadIcon
                },
                {
                    name: 'Contacts',
                    description: '',
                    href: '#',
                    icon: DownloadIcon
                },
                {
                    name: 'Connections',
                    description: '',
                    href: '#',
                    icon: DownloadIcon
                }
            ],
            selectedTab: selectedSubTab ?? 'Contacts',

            onDuplicateFoundValue: false,
            originalCSV: [],
            previewCSV: [],
            selectedFile: null,

            map: {
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8
            },
            filters: {},
            exFilters: {},
            columnCount: 0,
            parseFail: false
        };

        this.columnValues = {
            1: 'FirstName',
            2: 'LastName',
            3: 'Emails',
            4: 'Headline',
            5: 'Address1',
            6: 'Address2',
            7: 'City',
            8: 'Phone'
        };

        this.tdFieldsClass = "border-secondary-200 border p-3 border-b-0 bg-inverse";
        this.tdClass = "border-secondary-200 border px-3 py-1 border-b-0 bg-inverse";
    }

    /** Lifecycle
     ================================================================= */
    onSubmit = () => {
        this.props.dispatch(uploadDocument({
            user: LocalStorage.get("user"),
            params: {
                onDuplicateFoundValue: this.state.onDuplicateFoundValue ? 1 : 0,
                Type: this.state.selectedTab,
                map: this.state.map
            },
            resource: Resources.CandidateImport,
            files: this.state.selectedFile
        }));
    };

    handleCancel = () => {
        this.setState({
            onDuplicateFoundValue: false,
            previewCSV: [],
            selectedFile: null
        });
    };

    createCSVPreview = () => {
        // Create a reader object
        const reader = new window.FileReader();
        const textFile = this.state.selectedFile[0];

        reader.readAsText(textFile);
        reader.addEventListener('load', (e) => {

            let parsedCSV = new CSV(e.target.result).parse();

            if (!!parsedCSV[1]) {
                parsedCSV = parsedCSV.slice(1);
                this.setState({
                    parseFail: false,
                    originalCSV: parsedCSV,
                    columnCount: parsedCSV[0].length,
                    previewCSV: parsedCSV.map((cell, i) => this.renderRow(cell, i))
                })
            } else {
                this.setState({
                    parseFail: true
                })
            }
        });
    };

    renderRow = (cell, i) => {
        return (
            <tr>
                {(this.state.selectedTab === ('CSV')) && (
                    <>
                        <td className={"sticky left-0 " + this.tdClass}>{i + 1}</td>
                        {cell.map((it) => {
                            return (
                                <td className={ this.tdClass}>{it}</td>
                            )
                        })}
                    </>
                )}

                {(this.state.selectedTab === ('Contacts')) && (
                    <>
                        <td>{i + 1}</td>
                        {cell.map((it) => {
                            return (
                                <td>{it}</td>
                            )
                        })}
                    </>
                )}

                {(this.state.selectedTab === ('Connections')) && (
                    <>
                        <td>
                            {cell[1]}
                        </td>
                        <td>
                            {cell[2]}
                        </td>
                        <td>
                            {cell[3]}
                        </td>
                        <td>
                            {cell[4]}
                        </td>
                        <td>
                            {cell[6]}
                        </td>
                    </>
                )}

            </tr>
        )
    };

    getDemoFilePath = () => {
        return (this.state.selectedTab === ('Connections')) ? "csv/Connections.csv" : "csv/Contacts.csv";
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const thClass = "px-4 py-2 bg-primary text-left text-xs font-medium uppercase tracking-wider border border-primary-600";


        const columnCountArray = [...Array(this.state.columnCount + 1).keys()].slice(1);
        const supHeaders = columnCountArray.map((it) => {
            return (
                <th className={this.tdFieldsClass}>
                    <FieldText
                        className="form-control w-56 inline-block"
                        value={this.state.filters[it] ?? null}
                        onChange={(name, value) => {
                            let filters = this.state.filters;
                            filters[it] = value;

                            this.setState({
                                filters: filters,
                                previewCSV: this.state.originalCSV.filter((itm) => {
                                    return !!itm[it - 1].includes(value)
                                }).map((cell, i) => this.renderRow(cell, i))
                            })
                        }}
                    />
                </th>
            )
        });
        const exSupHeaders = columnCountArray.map((it) => {
            return (
                <th className={this.tdFieldsClass}>
                    <FieldText
                        className="form-control w-56 inline-block"
                        value={this.state.exFilters[it] ?? null}
                        onChange={(name, value) => {
                            let filters = this.state.exFilters;
                            filters[it] = value;

                            this.setState({
                                exFilters: filters,
                                previewCSV: this.state.originalCSV.filter((itm) => {
                                    return !itm[it - 1].includes(value)
                                }).map((cell, i) => this.renderRow(cell, i))
                            })
                        }}
                    />
                </th>
            )
        });
        const headers = columnCountArray.map((it) => {
            // Get row count and add class dropdown-40 (no rows), 48 (1 row), 60(2 rows) or 80(3 rows) or without 5+ rows
            return (
                <th className={thClass}>
                    <FieldSelectSearch
                        className="w-56 inline-block dropdown-60"
                        values={this.columnValues}
                        all={true}
                        value={this.state.map[it] ?? null}
                        onChange={(name, value) => {
                            let map = this.state.map;
                            map[it] = value;
                            this.setState({
                                map: map
                            })
                        }}
                    />
                </th>
            )
        });

        return (
            <LayoutDashboard footerBorder={true} {...this.props}>
                <main className="flex-1 flex flex-col md:flex-row">
                    {/* Secondary sidebar */}
                    <nav
                        aria-label="Sections"
                        className="flex-shrink-0 md:w-56 w-full bg-white border-r border-primary-gray-200 xl:flex xl:flex-col page-content-height"
                    >
                        <div className="flex-shrink-0 h-16 px-6 border-b border-primary-gray-200 flex items-center">
                            <p className="text-lg font-medium text-primary-gray-900">Import</p>
                        </div>
                        <div className="flex-1 min-h-0 overflow-y-auto">
                            {this.state.subNavigation.map((item, i) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        (this.state.selectedTab === item.name) ? 'bg-primary-50 bg-opacity-50' : 'hover:bg-primary-50 hover:bg-opacity-50',
                                        'flex p-6 border-b border-gray-200'
                                    )}
                                    aria-current={(this.state.selectedTab === item.name) ? 'page' : undefined}
                                    onClick={() => {
                                        this.setState({
                                            selectedTab: item.name,
                                            onDuplicateFoundValue: false,
                                            previewCSV: [],
                                            selectedFile: null
                                        })
                                    }}
                                >
                                    <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400"
                                               aria-hidden="true"/>
                                    <div className="ml-3 text-sm">
                                        <p className="font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-gray-500">{item.description}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </nav>

                    <div className="md:flex-1 max-h-screen md:overflow-y-auto">
                        <div className="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                            {!!this.state.parseFail && (
                                <p className={"mb-4 text-red-500"}>Failed to parse given CSV, please check that file is
                                    properly formatted, it must be UTF-8 encoded, and comma delimited.</p>
                            )}
                            {this.state.selectedTab === "CSV" && (
                                <p className={"mb-4"}>This import is for general CSV that can be created in any system.</p>
                            )}

                            {this.state.selectedTab === "Contacts" && (
                                <p className={"mb-4"}>This import is for the LinkedIn Contacts export. Use the "Get a
                                    copy of your data" tool from LinkedIn to create your contact list.</p>
                            )}

                            {this.state.selectedTab === "Connections" && (
                                <p className={"mb-4"}>This import is for the LinkedIn Connections export. Use the "Get a
                                    copy of your data" tool from LinkedIn to create your connections list.</p>
                            )}
                            <p className="m-3 text-sm text-secondary-500">* First row/header should be present in CSV
                                but it will be ignored during import</p>

                            {!this.state.selectedFile && (
                                <>
                                    <a href={this.getDemoFilePath()}
                                       className={"mt-2 mb-2 inline-block"}>{translate("text.example_file")}</a>

                                    <p>Select CSV file to import records:</p>

                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            this.setState({
                                                selectedFile: acceptedFiles.map(file => Object.assign(file, {
                                                    preview: URL.createObjectURL(file)
                                                }))
                                            }, this.createCSVPreview)
                                        }}
                                        multiple={false}
                                        accept={'.csv'}
                                        onDragEnter={() => {

                                        }}
                                        onDragLeave={() => {

                                        }}
                                        onDropAccepted={() => {

                                        }}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div className="mt-1 sm:mt-0" {...getRootProps()}>
                                                <div
                                                    className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <label
                                                                htmlFor="file-upload"
                                                                className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                            >
                                                                <span>Upload a file</span>
                                                                <input {...getInputProps()} />
                                                            </label>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">{"CSV file up to 10MB"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </>
                            )}

                            {this.state.selectedFile && (
                                <>
                                    <p>Confirm that given list of records will be imported:</p>
                                    <p className="m-3 text-sm text-secondary-500">* You can arrange what columns will be
                                        imported, and optionally filter out rows that do not match given criteria.</p>

                                    <Switch.Group as="div" className={"flex items-center mt-2 mb-2"}>
                                        <Switch
                                            checked={!!this.state.onDuplicateFoundValue}
                                            onChange={(value) => this.setState({
                                                onDuplicateFoundValue: value
                                            })}
                                            className={classNames(
                                                !!this.state.onDuplicateFoundValue ? 'bg-primary-600' : 'bg-gray-200',
                                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                                            )}
                                        >
                                            <span className="sr-only">{translate("field.show_archived")}</span>
                                            <span
                                                aria-hidden="true"
                                                className={classNames(
                                                    !!this.state.onDuplicateFoundValue ? 'translate-x-5' : 'translate-x-0',
                                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                )}
                                            />
                                        </Switch>
                                        <Switch.Label as="span" className="ml-3">
                                            <span className="text-sm font-medium text-gray-900 mr-2">Create new on duplicate found (same First Name, and Last Name).</span>
                                        </Switch.Label>
                                    </Switch.Group>

                                    <h3 className={"font-bold mb-1"}>Preview:</h3>

                                    <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-lg relative">
                                        <table className={"min-w-full divide-y divide-secondary-200 border-separate border-spacing-0 min-h-30vh"}>
                                            <thead>

                                            {(this.state.selectedTab === ('CSV')) && (
                                                <>
                                                    <tr>
                                                        <th className={"sticky left-0 text-white z-20 " + (thClass)}><span className="font-bold">#</span></th>
                                                        {headers}
                                                    </tr>
                                                    <tr>
                                                        <th className={"sticky left-0 " + this.tdClass}>Includes:</th>
                                                        {supHeaders}
                                                    </tr>
                                                    <tr>
                                                        <th className={"sticky left-0 " + this.tdClass}>Excludes:</th>
                                                        {exSupHeaders}
                                                    </tr>
                                                </>
                                            )}

                                            {(this.state.selectedTab === ('Contacts')) && (
                                                <>
                                                    <tr>
                                                        <th className={thClass}></th>
                                                        {supHeaders}
                                                    </tr>
                                                    <tr>
                                                        <th className={thClass}>#</th>
                                                        {headers}
                                                    </tr>
                                                </>
                                            )}

                                            {(this.state.selectedTab === 'Connections') && (
                                                <>
                                                    <tr>
                                                        <th className={thClass}>First Name</th>
                                                        <th className={thClass}>Last Name</th>
                                                        <th className={thClass}>Email</th>
                                                        <th className={thClass}>Address</th>
                                                        <th className={thClass}>Title</th>
                                                    </tr>
                                                </>
                                            )}
                                            </thead>
                                            <tbody className="bg-inverse divide-y divide-secondary-200">
                                            {this.state.previewCSV}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <button type="button"
                                                    className="btn btn-outline focus:ring-offset-inverse"
                                                    onClick={this.handleCancel}
                                            >
                                                {translate("btn.cancel")}
                                            </button>

                                            <button
                                                type="submit"
                                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                                onClick={this.onSubmit}
                                            >
                                                {translate("btn.confirm")}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ImportPage);