import {Menu, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import XIcon from "@heroicons/react/solid/XIcon";

export default function BulkSelectMenu(props) {
    const {onSelectAllOnPage, data, bulkSelectedItems} = props;
    return (
        <div className="absolute w-5 h-5 top-3 text-right">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button
                        className="absolute -left-5 -top-3 inline-flex justify-center w-full text-sm font-medium text-white bg-white rounded-sm w-4 h-4 opacity-0">
                    </Menu.Button>
                </div>

                    <Menu.Items
                        className="absolute -left-5 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        onClick={() => onSelectAllOnPage(data, 0)}
                                        className={`${
                                            active ? 'bg-primary-500 text-white' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                    >
                                        <CheckIcon
                                            className="w-5 h-5 mr-2"
                                            aria-hidden="true"
                                        />
                                        Select all visible
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({active}) => (
                                    <button
                                        onClick={() => onSelectAllOnPage(data, bulkSelectedItems.length)}
                                        className={`${
                                            active ? 'bg-primary-500 text-white' : 'text-gray-900'
                                        } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                    >
                                        <XIcon
                                            className="w-5 h-5 mr-2"
                                            aria-hidden="true"
                                        />

                                        Deselect all visible
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
            </Menu>
        </div>
    )
}