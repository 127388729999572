import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../util/util";
import FieldSearch from "../../components/field-search";
import {PlusIcon, UserIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import PageHeader from "../../components/layout-dashboard/page-header";
import icons from "../../util/icons";
import DialogDefault from "../../components/dialog-default";
import {Link} from "react-router-dom";
import ModalUserConfirm from "./modal-user-delete";

class UsersPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "first_name",

            query: "",
            archived: false,

            fields: this.getFields(),
            selectedItemFields: this.getSelectedItemFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleCreateContactModal = () => {
        this.setState({
            createContactModelOpen: !this.state.createContactModelOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ContactID";
    }

    getResourceName = () => {
        return Resources.Users;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, "text"),
            LastName: new Field("LastName", '', ['empty'], false, "text"),
            Email: new Field("Email", '', ['empty'], false, "text"),
            UserRoleID: new Field("UserRoleID", '', ['empty'], false, "select")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSelectedItemFields = () => {
        return {
            login_time: new Field("login_time", '', [], false, 'datetime'),
            os: new Field("os", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnOSIcon(item.os)} <span className='capitalize'>{item.os}</span></span>
                    </td>
                )
            }),
            Browser: new Field("Browser", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnBrowserIcon(item.Browser)} <span className='capitalize'>{item.Browser}</span></span>
                    </td>
                )
            }),
            user_agent: new Field("user_agent", '', ['empty'], false, "text"),
            location: new Field("location", '', ['empty'], false, "text"),
            ip: new Field("ip", '', ['empty'], false, "text")
        };
    };

    returnOSIcon = (os) => {
        switch (os){
            case "linux":
                return icons.linux
            case "mac":
                return icons.mac
            case "windows":
                return icons.windows
            case "iPad":
                return icons.iPad
            case "iPhone":
                return icons.iPhone
            case "Android":
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ["Chrome", "Safari", "Firefox", "Opera", "Explorer", "Edge"].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const qty = getProp(this.props, "resource.data.subscription.Quantity", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            UserRoleID: {
                1: "Super Admin",
                4: "Recruiter"
            }
        };

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.users")}
                    />

                    <div className="bg-primary-600">
                        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                            <div className="flex items-center justify-between flex-wrap">
                                <div className="w-0 flex-1 flex items-center">
                                    <span className="flex p-2 rounded-lg bg-primary-800">
                                      <UserIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                    <p className="ml-3 font-medium text-white truncate">
                                        <span className="md:hidden">We announced a new product!</span>
                                        <span className="hidden md:inline">You are using {count}/{qty} user slots.</span>
                                    </p>
                                </div>
                                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                    <Link
                                        to={"/payment"}
                                        className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-600 bg-white hover:bg-primary-50"
                                    >
                                        Upgrade
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />

                            <div className="ml-auto mb-3">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    disabled={count >= qty}
                                    className={"btn " + ((count >= qty) ? "btn-disabled" : "btn-primary")}
                                >
                                    <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.add")}
                                </button>
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleViewModal}
                            onEdit={this.handleToggleEditModel}
                            onDelete={this.handleToggleConfirmModal}
                        />
                    </div>

                    <DialogDefault
                        title={translate("text.LastTenLogins")}
                        widthClass="max-w-4xl"
                        visible={this.state.viewModalOpen}
                        blurBackdrop={true}
                        onClose={this.handleViewModal}
                        translate={translate}
                    >
                        <SimpleTable
                            data={Array.isArray(this.state.selectedItem?.Logins) ? this.state.selectedItem?.Logins : []}

                            fields={this.state.selectedItemFields}
                            translate={this.props.translate}
                            isLoading={isLoading}
                            limit={this.state.selectedItem?.Logins?.length ?? 0}
                            count={this.state.selectedItem?.Logins?.length ?? 0}
                            offset={0}
                            paginationPage={1}
                        />
                    </DialogDefault>

                    <ModalSaveResource
                        title={"Edit User"}
                        widthClass="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params.ContactID = this.state.selectedItem['ContactID'];
                                params.UserID = this.state.selectedItem['UserID'];
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={"Add User"}
                        widthClass="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalUserConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={(UpdateSubscription) => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                    UpdateSubscription: UpdateSubscription
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(UsersPage);
