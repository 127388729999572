import React, {useEffect, useState, Fragment} from 'react';
import moment from "moment";
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon,} from '@heroicons/react/solid'
import {Menu, Transition} from '@headlessui/react'

import CalendarMonthView from "./calendar-month-view";
import CalendarWeekView from "./calendar-week-view";
import CalendarDayView from "./calendar-day-view";
import CalendarYearView from "./calendar-year-view";
import {cloneDeep, getProp} from "../../util/util";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const viewMenu = [
    {id: "day", name: "Day view",},
    {id: "week", name: "Week view"},
    {id: "month", name: "Month view"},
    // {id: "year", name: "Year view"},
]

const weekFormat = [
    {id: 0, name: "Sunday", abbr: "S", abbrExt: "un"},
    {id: 1, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 2, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 3, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 4, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 5, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 6, name: "Saturday", abbr: "S", abbrExt: "at"},
];

const isoWeekFormat = [ // may be used in future
    {id: 0, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 1, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 2, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 3, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 4, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 5, name: "Saturday", abbr: "S", abbrExt: "at"},
    {id: 6, name: "Sunday", abbr: "S", abbrExt: "un"},
];

const getEventDurationClasses = (duration) => {
    switch (duration) {
        case 15:
            return "px-2";
        case 30:
            return "px-2 py-1";
        default:
            return "p-2";
    }
}

const getWeekFormat = () => {
    return weekFormat;
}

const getMonth = () => {
    return moment().month() + 1;  // +1 since months are zero indexed
}

export default function CalendarNew({events, onFetchData, activeDay, onEventClick, onDayClick, onMoreClick, onSquashedEventsClick, hasCreatePerm, infoIcon, setActiveDay, actions, calendarHeight = "", customViewMenuItems = []}) {

    const numOfDaysDisplayed = 42;
    const today = moment().format("YYYY-MM-DD");

    const [activeView, setActiveView] = useState("month");

    const [activeWeek, setActiveWeek] = useState(moment().week())
    const [activeMonth, setActiveMonth] = useState(getMonth())
    const [activeYear, setActiveYear] = useState(moment().year());

    const firstDay = moment(activeMonth + " " + activeYear, "M YYYY").startOf('month');
    const activeMonthDisplay = moment(activeMonth + " " + activeYear, "M YYYY").format("MMMM YYYY");

    const buffer = !!firstDay.day() ? firstDay.day() : 7; // Draw one whole buffer row is there are no buffer days

    let calendarDay = firstDay.subtract("days", buffer);
    const calendarDays = Array.from(Array(numOfDaysDisplayed).keys());

    const days = calendarDays.map((it, i) => {
        const thisDay = calendarDay.clone().add("days", i).format('YYYY-MM-DD');

        return (
            {
                date: thisDay,
                day: moment(thisDay, 'YYYY-MM-DD').format("DD"),
                isCurrentMonth: moment(activeMonth + " " + activeYear, "M YYYY").isSame(thisDay, 'month'),
                isCurrentWeek: moment(moment().week(activeWeek)).isSame(thisDay, 'week'),
                isSelected: thisDay === activeDay,
                isToday: thisDay === today,
                events: getProp(events, thisDay, [])
            }
        );
    })

    let selectedDay = days.find(day => day.date === activeDay);

    if (!selectedDay) {
        days.find(day => day.date === activeDay)
    }

    const setNextMonth = () => {
        if (activeMonth === 12) {
            setActiveMonth(1);
            setActiveYear(Number(activeYear) + 1);
        } else {
            setActiveMonth(Number(activeMonth) + 1);
        }
    }

    const setPrevMonth = () => {

        if (activeMonth === 1) {
            setActiveMonth(12);
            setActiveYear(Number(activeYear) - 1);
        } else {
            setActiveMonth(Number(activeMonth) - 1);
        }
    }

    const setNextWeek = () => {
        const nextWeek = Number(activeWeek) + 1;
        const nextWeekMonth = moment(nextWeek, 'w').format('M');

        if (activeMonth !== Number(nextWeekMonth)) {
            setActiveMonth(Number(activeMonth) + 1);
        } else {
            setActiveWeek(nextWeek);
        }
    }

    const setPrevWeek = () => {
        const prevWeek = activeWeek - 1;
        const prevWeekMonth = moment(activeWeek, 'w').format('M');

        if (activeMonth !== Number(prevWeekMonth)) {
            setActiveMonth(Number(activeMonth) - 1);
        } else {
            setActiveWeek(prevWeek);
        }
    }

    const setNextDay = () => {
        const nextDay = moment(activeDay, 'YYYY-MM-DD').add(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const nextDayMonth = nextDay.format('M');

        if (currentDayMonth !== nextDayMonth) {
            setActiveMonth(Number(currentDayMonth) + 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            setActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (nextDay.week() !== activeWeek) {
            setActiveWeek(nextDay.week());
        }

        setActiveDay(nextDay.format('YYYY-MM-DD'));
    }

    const setPrevDay = () => {
        const prevDay = moment(activeDay, 'YYYY-MM-DD').subtract(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const prevDayMonth = prevDay.format('M');

        if (activeMonth !== Number(prevDayMonth)) {
            setActiveMonth(Number(activeMonth) - 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            setActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (prevDay.week() !== activeWeek) {
            setActiveWeek(prevDay.week());
        }

        setActiveDay(prevDay.format('YYYY-MM-DD'));
    }

    const resetActiveDate = () => {
        setActiveYear(moment().year());
        setActiveMonth(getMonth());
        setActiveWeek(moment().week());
        setActiveDay(today);
    }

    const handleNextClick = () => {
        if ("month" === activeView) {
            setNextMonth();
        }

        if ("week" === activeView) {
            setNextWeek();
        }

        if ("day" === activeView) {
            setNextDay();
        }
    }

    const handlePrevClick = () => {
        if ("month" === activeView) {
            setPrevMonth()
        }

        if ("week" === activeView) {
            setPrevWeek();
        }

        if ("day" === activeView) {
            setPrevDay();
        }
    }

    const handleViewChangeClick = (id) => {
        setActiveView(id);
        resetActiveDate();
    }

    const renderEventButton = (event) => {
        const eventDurationMinutes = event?.Duration;

        return (
            <button
                onClick={() => event.squashed ? onSquashedEventsClick(event) : onEventClick(event)}
                className={classNames("group absolute text-left w-full max-w-[calc(100%-.5rem)] inset-1 flex flex-col overflow-y-auto rounded-lg text-xs leading-5", event.squashedBg ?? event.color["bg50"], event.color["hoverBg100"], getEventDurationClasses(eventDurationMinutes))}
            >
                {!event.squashed && (
                    <React.Fragment>
                        <p className={classNames("order-1 font-semibold", event.color["text700"])}>{event.name}</p>
                        <p className={classNames("flex", event.color["text500"], event.color["groupHoverText700"])}>
                            <span
                                className={classNames("flex-auto truncate", eventDurationMinutes !== 15 ? "hidden" : "")}>{event.name}</span>
                            <span>{event.displayTime}</span>
                        </p>

                        {/*{event?.metadata?.Notes && (*/}
                        {/*    <p className={classNames("order-2 mt-3", event.color["text500"])}>{event.metadata.Notes}</p>*/}
                        {/*)}*/}
                    </React.Fragment>
                )}

                {event.squashed && (
                    <React.Fragment>
                        <p className={classNames("flex text-gray-700 group-hover:text-gray-900")}>
                            <span
                                className={classNames("flex-auto truncate", eventDurationMinutes !== 15 ? "hidden" : "")}>{event.eventSquashedItems.length} items</span>
                            <span>{event.displayTime}</span>
                        </p>
                        <p className="text-gray-800 font-bold mb-2">{event.eventSquashedItems.length} <span
                            className="hidden xl:inline">Overlapping</span> items</p>

                        {event.eventSquashedItems.map(squashedEvent => {
                            return (
                                <span className="mb-2">
                                    <p className={classNames(squashedEvent.color["groupHoverText700"], "flex-auto truncate font-medium text-gray-900 leading-tight flex items-center")}>
                                        <div className={classNames(
                                            squashedEvent.color["bg400"],
                                            "w-2 h-2 shrink-0 rounded-full mr-1"
                                        )}
                                        />

                                        {squashedEvent.name}
                                    </p>
                                    <time
                                        dateTime={squashedEvent.datetime}
                                        className={classNames(squashedEvent.color["groupHoverText700"], "ml-3 block flex-none text-gray-500 leading-tight")}
                                    >
                                        {squashedEvent.durationDisplay.replaceAll(":00", "")}
                                    </time>
                                </span>
                            )
                        })}
                    </React.Fragment>
                )}
            </button>
        )
    }

    const sortDayEvents = (day) => {
        let dayClone = cloneDeep(day);
        let eventSquashedItems = [];
        let squashedTimeStart = null;
        let squashedDuration = 0;

        dayClone.events = dayClone.events
            .sort((a, b) => {
                return moment(a.time, 'HH:mm').unix() - moment(b.time, 'HH:mm').unix()
            })

        dayClone.events
            .sort((a, b) => {
                return moment(a.time, 'HH:mm').unix() - moment(b.time, 'HH:mm').unix()
            }) // sort by date
            .map((event, eventIndex) => {
                const previousEvent = !!dayClone.events[eventIndex - 1] && dayClone.events[eventIndex - 1];
                const nextEvent = !!dayClone.events[eventIndex + 1] && dayClone.events[eventIndex + 1];

                const isOverlappingWithPrevious = !!previousEvent && moment(event.time, "HH:mm").isBefore(moment(previousEvent.timeEnd, "HH:mm"));
                const isOverlappingWithNext = !!nextEvent && moment(event.timeEnd, "HH:mm").isAfter(moment(nextEvent.time, "HH:mm"));

                // First squashed event
                if (!isOverlappingWithPrevious && isOverlappingWithNext) {
                    squashedTimeStart = event.time;
                    squashedDuration = event.Duration;
                }

                if (isOverlappingWithPrevious) {
                    event.squashedTimeStart = squashedTimeStart;
                    event.squashedDuration = Math.max(squashedDuration, event.Duration);
                }

                if (isOverlappingWithNext) {
                    event.hidden = true;
                }

                if (isOverlappingWithPrevious || isOverlappingWithNext) {
                    eventSquashedItems.push({
                        color: event.color,
                        time: event.time,
                        name: event.name,
                        durationDisplay: event.durationDisplay,
                        date: event.date,
                        metadata: event?.metadata
                    });
                }

                // Set values to last/visible event
                if (isOverlappingWithPrevious && !isOverlappingWithNext) {
                    squashedDuration = 0;
                    event.eventSquashedItems = eventSquashedItems;
                    event.squashedBg = "bg-white border shadow"
                    eventSquashedItems = [];
                    event.squashed = true;
                }

                return event;
            });

        return dayClone;
    }

    const getCurrentWeek = (days) => {
        return days.filter((it, i) => it.isCurrentWeek).map(day => {
            return sortDayEvents(day);
        });
    }

    useEffect(() => {
        onFetchData(activeYear, activeMonth);
    }, [activeYear, activeMonth])

    return (
        <React.Fragment>
            <div className="sm:mb-6 lg:mb-8 rounded-lg border border-gray-200 overflow-hidden">
                <header
                    className="relative flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none z-20 bg-secondary-50">
                    <h1 className="flex text-lg font-semibold text-gray-900">
                        <span>{activeMonthDisplay}</span>

                        {infoIcon}
                    </h1>

                    <div className="flex items-center">
                        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                            <button
                                onClick={handlePrevClick}
                                type="button"
                                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            <button
                                onClick={resetActiveDate}
                                type="button"
                                className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            >
                                Today
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                            <button
                                onClick={handleNextClick}
                                type="button"
                                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <Menu as="div" className="relative">
                                {({ open }) => (
                                    <>
                                        <Menu.Button
                                            type="button"
                                            className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                                        >
                                            <span><span className="capitalize">{activeView}</span> view</span>
                                            <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        </Menu.Button>

                                        <Transition
                                            as={Fragment}
                                            show={open}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="focus:outline-none absolute text-left right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                                <div className="py-1">
                                                    {viewMenu.map(it => (
                                                        <Menu.Item key={it.id}>
                                                            {({active}) => (
                                                                <button
                                                                    onClick={() => handleViewChangeClick(it.id)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'text-left w-full block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    {it.name}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    ))}

                                                    {customViewMenuItems.map(it => (
                                                        <Menu.Item key={it.id}>
                                                            {({active}) => (
                                                                <button
                                                                    onClick={() => it.onMenuItemClick(it.id)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'text-left w-full block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    {it.name}
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>

                            <div className="ml-6 h-6 w-px bg-gray-300 md:mr-6"/>

                            <div className="space-x-4">
                                {actions?.filter(button => button.visible).map(button => (
                                    <button
                                        onClick={() => button.onClick(activeDay)}
                                        type="button"
                                        className={button.className}
                                    >
                                        {!!button.icon && <button.icon className="h-5 w-5 mr-1"/>}
                                        {button.label}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <Menu as="div" className="relative ml-6 md:hidden">
                            {({ open }) => (
                                <>
                                    <Menu.Button
                                        className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                                        <span className="sr-only">Open menu</span>
                                        <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                                    </Menu.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                            {hasCreatePerm && (
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <button
                                                                onClick={() => onDayClick(activeDay)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'w-full text-left block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                Create
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            )}
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <button
                                                            onClick={resetActiveDate}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'w-full text-left block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            Go to today
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                            <div className="py-1">
                                                {viewMenu.map(it => (
                                                    <Menu.Item key={it.id}>
                                                        {({active}) => (
                                                            <button
                                                                onClick={() => handleViewChangeClick(it.id)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'text-left w-full block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                {it.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}

                                                {customViewMenuItems.map(it => (
                                                    <Menu.Item key={it.id}>
                                                        {({active}) => (
                                                            <button
                                                                onClick={() => it.onMenuItemClick(it.id)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                    'text-left w-full block px-4 py-2 text-sm'
                                                                )}
                                                            >
                                                                {it.name}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </>
                            )}
                        </Menu>
                    </div>
                </header>

                {activeView === 'day' && (
                    <CalendarDayView
                        days={days}
                        weekFormat={getWeekFormat()}
                        selectedDay={selectedDay}
                        activeMonthDisplay={activeMonthDisplay}
                        onSetActiveDay={setActiveDay}
                        setNextMonth={setNextMonth}
                        setPrevMonth={setPrevMonth}
                        renderEventButton={renderEventButton}
                        sortDayEvents={sortDayEvents}
                        onDayClick={onDayClick}
                    />
                )}

                {activeView === 'week' && (
                    <CalendarWeekView
                        days={days}
                        onDayClick={onDayClick}
                        weekFormat={getWeekFormat()}
                        calendarHeight={calendarHeight}
                        selectedDay={selectedDay}
                        activeMonth={activeMonth}
                        setActiveDay={setActiveDay}
                        activeMonthDisplay={activeMonthDisplay}
                        renderEventButton={renderEventButton}
                        getCurrentWeek={getCurrentWeek}
                        sortDayEvents={sortDayEvents}
                    />
                )}

                {activeView === 'month' && (
                    <CalendarMonthView
                        days={days}
                        weekFormat={getWeekFormat()}
                        onEventClick={onEventClick}
                        onDayClick={onDayClick}
                        onMoreClick={onMoreClick}
                        maxVisibleEvents={6}
                    />
                )}

                {activeView === 'year' && (
                    <CalendarYearView/>
                )}
            </div>
        </React.Fragment>
    )
}
