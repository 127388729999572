import React, {Component} from 'react'
import {createDialogResource, deleteDialogResource, updateDialogResource} from "../../data/actions/dialogResource";
import LocalStorage from "../../util/localStorage";
import SurveyBuilderSlideOver from "./survey-builder-slide-over";
import {Field} from "../../data/services/fields";
import {fillFieldsFromData, getProp} from "../../util/util";
import {PlusIcon} from "@heroicons/react/solid";
import QuestionMatrix from "./question-types/question-matrix";
import RatingScales from "./question-types/rating-scales";
import LikertScales from "./question-types/likert-scales";
import Resources from "../../data/services/resources";
import Loader from "../loader";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import FieldText from "../field-text";
import FieldTextarea from "../field-textarea";
import ModalConfirm from "../modal/modal-confirm";
import QuestionDropdown from "./question-types/dropdown";
import {LockClosedIcon} from "@heroicons/react/outline";

export default class SurveyBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null,
            selectedItemIndex: -1,
            questions: [],
            questionItems: {},
            isSurveyEditVisible: false,
            confirmModalOpen: false
        };

        this.ratingScalesTop = 10;
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!prevProps.isLoading && !this.props.isLoading) {
            let questionItems = getProp(this.props.data, "questions", {}).reduce((memo, it, i) => {
                const orderNumber = i + 1;

                if (it.Items.length) {
                    memo[orderNumber] = it.Items.map(it => it.RoleSurveyItemName);
                }

                return memo
            }, {});

            this.setState({
                questions: getProp(this.props.data, "questions", []),
                questionItems: questionItems
            });
        }
    }

    /** Data Events
     ================================================================= */


    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        });

        if (!!this.props.onSelectItem) this.props.onSelectItem(item);
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    toggleSurveyForm = (it = null, i = -1) => {
        this.setState({
            isSurveyEditVisible: !this.state.isSurveyEditVisible,
            selectedItem: it,
            selectedItemIndex: i
        })
    }

    getQuestionFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ShortSurveyTypeID: new Field("ShortSurveyTypeID", 4, ['empty'], false, 'select'),
            ShortSurveyQuestion: new Field("ShortSurveyQuestion", null, ['empty'], false, 'text'),
            Instructions: new Field("Instructions", '', [''], false, 'textarea'),
            DesiredAnswer: new Field("DesiredAnswer", null, ['empty'], false, 'select'),
            RankingPoints: new Field("RankingPoints", 3, ['integer'], false, 'text'),
            OrderNum: new Field("OrderNum", 0, ['integer_positive'], false, 'text'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getQuery = () => {
        return {
            id: this.state.selectedItem.RoleSurveyID
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, isLoading} = this.props;

        const questionMatrixID = 3;
        const ratingScalesID = 1;
        const likertScalesID = 2;
        const dropDownID = 4;
        const rankingQuestionsID = 5;
        const textQuestionID = 6;
        const numberQuestionID = 7;

        const surveyAnswers = getProp(this.props.data, "answers", null);

        const survey = getProp(this.state, "questions", []).map((item, i) => {
            let dropdownValues = {};
            let dropdownDesiredValues = null;
            if (item.ShortSurveyTypeID == dropDownID) {
                dropdownValues = item.Items.reduce((memo, it, i) => {
                    memo[it.RoleSurveyItemID] = it.RoleSurveyItemName;
                    return memo
                }, {});


                dropdownDesiredValues = item?.DesiredAnswer ? item.DesiredAnswer.split(",").map((it, i) => {
                    return {label: item?.Items[it - 1].RoleSurveyItemName, value: item?.Items[it - 1].RoleSurveyItemID}
                }) : [];

            }
            return (
                <div key={item.ShortSurveyQuestion} className="flex flex-col justify-center p-4 border-t ">
                    <div className={"flex justify-between items-center px-4 pb-4 mb-4 border-b border-dashed"}>
                        <div>
                            <p className="m-0">
                                <span>{i + 1}. </span>

                                <span className="text-lg text-secondary-800">{item.ShortSurveyQuestion}</span>

                                {!surveyAnswers && item.RankingPoints && (
                                    <span className="text-gray-400 font-bold"> - {item.RankingPoints} points </span>
                                )}
                            </p>
                        </div>

                        {!surveyAnswers && (
                            <div>
                                <button
                                    className="btn btn-outline mr-3"
                                    onClick={() => this.toggleSurveyForm(item, i)}
                                >
                                    {translate("btn.edit")}
                                </button>

                                <button
                                    className="mr-2 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"
                                    onClick={() => this.handleToggleConfirmModal(item)}
                                >
                                    {translate("btn.delete")}
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="container mx-4">
                        {item.ShortSurveyTypeID == questionMatrixID && (
                            <QuestionMatrix
                                index={i}
                                matrixQuestions={this.state.questionItems[i + 1]}
                                translate={translate}
                                preview={true}
                            />
                        )}

                        {item.ShortSurveyTypeID == ratingScalesID && (
                            <RatingScales
                                desiredAnswer={item.DesiredAnswer}
                                topRating={this.ratingScalesTop}
                                preview={true}
                            />
                        )}

                        {item.ShortSurveyTypeID == likertScalesID && (
                            <LikertScales
                                index={i}
                                desiredAnswer={item.DesiredAnswer}
                                preview={true}
                            />
                        )}

                        {item.ShortSurveyTypeID == dropDownID && (
                            <QuestionDropdown
                                preview={true}
                                desiredQuestions={dropdownDesiredValues}
                                values={dropdownValues}
                            />
                        )}

                        {item.ShortSurveyTypeID == numberQuestionID && (
                            <div className="flex items-center">
                                <span className="mr-2 text-primary-600">>=</span>

                                <FieldText
                                    className="form-control w-48"
                                    name={"number_" + item.name + "_" + i + 1}
                                    value={item.DesiredAnswer}
                                    disabled={true}
                                    placeholder={this.props.translate("field.placeholder.type_question")}
                                />
                            </div>
                        )}

                        {item.ShortSurveyTypeID == textQuestionID && (
                            <React.Fragment>
                                <FieldTextarea
                                    className="form-control"
                                    name={"number_" + item.name + "_" + i + 1}
                                    value={item.DesiredAnswer}
                                    disabled={true}
                                    placeholder={this.props.translate("field.placeholder.type_text_question")}
                                />
                            </React.Fragment>
                        )}

                        {item.ShortSurveyTypeID == rankingQuestionsID && (
                            item.Items.map((it, i) => {
                                return (
                                    <div className="flex mb-5">
                                        <div className="px-2 mt-1 w-12 flex items-center">
                                            <div className="mr-2 cursor-move select-none mr-3">
                                                <div className="w-3 grid grid-cols-2 gap-1">
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                    <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">{i + 1}. {it.RoleSurveyItemName}</div>
                                    </div>
                                )
                            })
                        )}

                        {!!item.Instructions && (
                            <div className="rounded-md bg-secondary-50 p-4 mt-6">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon
                                            className="h-5 w-5 text-secondary-400"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-secondary-700">{item.Instructions}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <div className="mt-6 flex flex-wrap">
                    {!!surveyAnswers && !isLoading && (
                        <React.Fragment>
                            <div className="rounded-md bg-yellow-50 py-4 pl-4 pr-12">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <LockClosedIcon className="h-6 w-6 text-yellow-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-base font-semibold text-yellow-800">Survey editing is
                                            locked</h3>
                                        <div className="mt-2 text-base text-yellow-700">
                                            <p>
                                                Some candidates have submitted their answers to this survey.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>


                    )}

                    {!surveyAnswers && !isLoading && (
                        <div className="flex-shrink-0 ml-auto mb-3">
                            <button
                                type="button"
                                onClick={() => this.toggleSurveyForm()}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        </div>
                    )}
                </div>

                <div className="mt-4">
                    <section className="mb-8">
                        <div className="bg-white shadow sm:rounded-md">
                            <div className="px-4 py-1 sm:px-6 bg-secondary-100 flex justify-between items-center">
                                <h2 className="text-lg leading-6 font-medium text-gray-900">
                                    Survey questions
                                </h2>
                            </div>

                            {!survey.length && !isLoading && (
                                <div className="px-4 py-2">
                                    <p className="text-secondary-600">No questions. Click on create button to add
                                        some.</p>
                                </div>
                            )}

                            {!!survey.length && !isLoading && (
                                <div className="">
                                    {survey}
                                </div>
                            )}

                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}
                        </div>
                    </section>

                    <SurveyBuilderSlideOver
                        panelTitle={!!this.state.selectedItem ? "Update question" : "Add question"}
                        show={this.state.isSurveyEditVisible}
                        onClose={this.toggleSurveyForm}
                        canSubmit={true}
                        translate={translate}
                        fields={this.getQuestionFields(this.state.selectedItem)}
                        questionItems={this.state.questionItems[this.state.selectedItemIndex + 1]}
                        questionNum={this.state.questions.length}
                        topRating={this.ratingScalesTop}
                        onSubmit={(fields, params, additionalItems = {}) => {

                            if (params) {
                                let options = [];
                                if (!!additionalItems?.Items) {
                                    options = additionalItems.Items.map((question, i) => {
                                        let q = {}
                                        q.RoleSurveyItemName = question;
                                        q.ItemOrderNum = i + 1;

                                        return q;
                                    });

                                    params.Items = options;

                                    if (params.PreferredQuestion) params.PreferredQuestion = additionalItems.PreferredQuestion + 1;
                                }

                                params.id = this.props.id;


                                // Update or create
                                if (!!this.state.selectedItem) {
                                    params.RoleSurveyID = this.state.selectedItem.RoleSurveyID;
                                    this.props.dispatch(updateDialogResource({
                                        user: LocalStorage.get("user"),
                                        params: params,
                                        query: {id: this.props.id},
                                        resource: Resources.RoleSurvey,
                                        piggyResource: Resources.RoleSurvey
                                    }))
                                } else {
                                    this.props.dispatch(createDialogResource({
                                        user: LocalStorage.get("user"),
                                        params: params,
                                        query: {id: this.props.id},
                                        resource: Resources.RoleSurvey,
                                        piggyResource: Resources.RoleSurvey
                                    }))
                                }

                                this.toggleSurveyForm();
                            }
                        }}
                    >

                    </SurveyBuilderSlideOver>

                    <ModalConfirm
                        title={"Confirm delete"}

                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteDialogResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                piggyQuery: {id: this.props.id},
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </div>
            </React.Fragment>
        )
    }
}
