import React, {Component} from "react";
import {connect} from "react-redux";
import {createResource, getResource, resetResourceMessage} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import PageHeader from "../../components/layout-dashboard/page-header";
import PublicLayout from "../../components/layout";
import Loader from "../../components/loader";
import FieldTextarea from "../../components/field-textarea";
import FieldRadio from "../../components/field-radio";

class FeedbackPublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "RoleName",

            query: "",
            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,

            questions: []
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.props.dispatch(resetResourceMessage());
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                questions: getProp(this.props, "resource.data.questionItems", []),
                fields: this.getFields(getProp(this.props, "resource.data", {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    handleSubmit = () => {
        const data = getProp(this.props, "resource.data", []);

        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: Object.assign({
                RoleCandidateClientFeedbackID: data.RoleCandidateClientFeedbackID,
                ClientContactID: data.ClientContactID,
                Questions: this.state.questions
            }, FieldsManager.getFieldKeyValues(this.state.fields)),
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    /** Helpers
     ================================================================= */

    getResourceName = () => {
        return Resources.RoleCandidateFeedbackExternal;
    }

    getQuery = () => {
        return {
            token: this.props.match.params && this.props.match.params.token
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            FeedbackRecommendID: new Field("FeedbackRecommendID", '', [], false, 'select'),
            Comments: new Field("Comments", '', [], false, 'textarea'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleRankingChange = (value, i) => {
        let questions = this.state.questions;
        questions[i].Answer = value;
        this.setState({
            questions: questions
        });
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data", []);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        /*
        CandidateID: 16
CandidateName: "Jane Doe"
ClientContactID: 24
Comments: "Comments to the recommend"
CreateUpdateDate: "2021-06-15 13:30:35"
FeedbackRecommendID: 1
RoleCandidateClientFeedbackID: 8
RoleCandidateClientFeedbackStatusID: 2
RoleCandidateInterviewID: 8
RoleID: 7
RoleName: "Role CFO12"
Token: "5434543543543"
UpdatedByContactID: 1
         */

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            FeedbackRecommendID: {
                1: "Recommend Hire",
                2: "Don't Recommend",
                3: "Recommend with Comments"
            }
        });

        let questionsFreetext = [];
        const questionsRanking = this.state.questions.map((it, i) => {
            const question = (
                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5">
                    <label htmlFor={"text-" + it.RoleCandidateClientFeedbackItemID}
                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                        {it.Title}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {it.FeedbackQuestionType == "Ranking" && (
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="sm:grid gap-4 grid-cols-5 sm:text-center">
                                    <button
                                        className={"sm:hidden inline-flex items-center px-2 py-0.5 rounded text-xs font-medium m-2 focus:outline-none focus:ring-1 ring-primary-600" + (this.state.questions[i].Answer == 1 ? " bg-purple-100 text-purple-800" : " bg-gray-100 text-gray-800")}
                                        onClick={() => this.handleRankingChange(1, i)}
                                    >
                                        Unsatisfactory
                                    </button>

                                    <button
                                        className={"sm:hidden inline-flex items-center px-2 py-0.5 rounded text-xs font-medium m-2 focus:outline-none focus:ring-1 ring-primary-600" + (this.state.questions[i].Answer == 2 ? " bg-green-100 text-green-800" : " bg-gray-100 text-gray-800")}
                                        onClick={() => this.handleRankingChange(2, i)}
                                    >
                                        Satisfactory
                                    </button>

                                    <button
                                        className={"sm:hidden inline-flex items-center px-2 py-0.5 rounded text-xs font-medium m-2 focus:outline-none focus:ring-1 ring-primary-600" + (this.state.questions[i].Answer == 3 ? " bg-orange-100 text-orange-800" : " bg-gray-100 text-gray-800")}
                                        onClick={() => this.handleRankingChange(3, i)}
                                    >
                                        Average
                                    </button>

                                    <button
                                        className={"sm:hidden inline-flex items-center px-2 py-0.5 rounded text-xs font-medium m-2 focus:outline-none focus:ring-1 ring-primary-600" + (this.state.questions[i].Answer == 4 ? " bg-red-100 text-red-800" : " bg-gray-100 text-gray-800")}
                                        onClick={() => this.handleRankingChange(4, i)}
                                    >
                                        Above Average
                                    </button>

                                    <button
                                        className={"sm:hidden inline-flex items-center px-2 py-0.5 rounded text-xs font-medium m-2 focus:outline-none focus:ring-1 ring-primary-600" + (this.state.questions[i].Answer == 5 ? " bg-blue-100 text-blue-800" : " bg-gray-100 text-gray-800")}
                                        onClick={() => this.handleRankingChange(5, i)}
                                    >
                                        Exceptional
                                    </button>

                                    <div className="text-center hidden sm:block">
                                        <FieldRadio name={"question-" + i} value={this.state.questions[i].Answer == 1}
                                                    onChange={() => this.handleRankingChange(1, i)}/>
                                    </div>

                                    <div className="text-center hidden sm:block">
                                        <FieldRadio name={"question-" + i} value={this.state.questions[i].Answer == 2}
                                                    onChange={() => this.handleRankingChange(2, i)}/>
                                    </div>

                                    <div className="text-center hidden sm:block">
                                        <FieldRadio name={"question-" + i} value={this.state.questions[i].Answer == 3}
                                                    onChange={() => this.handleRankingChange(3, i)}/>
                                    </div>

                                    <div className="text-center hidden sm:block">
                                        <FieldRadio name={"question-" + i} value={this.state.questions[i].Answer == 4}
                                                    onChange={() => this.handleRankingChange(4, i)}/>
                                    </div>

                                    <div className="text-center hidden sm:block">
                                        <FieldRadio name={"question-" + i} value={this.state.questions[i].Answer == 5}
                                                    onChange={() => this.handleRankingChange(5, i)}/>
                                    </div>
                                </div>
                            </div>
                        )}

                        {it.FeedbackQuestionType !== "Ranking" && (
                            <FieldTextarea
                                id={it.RoleCandidateClientFeedbackItemID}
                                name={it.RoleCandidateClientFeedbackItemID}
                                className={"form-control h-28"}
                                onChange={(name, value) => {
                                    let questions = this.state.questions;
                                    questions[i].Answer = value;
                                    this.setState({
                                        questions: questions
                                    })
                                }}
                                value={it.Answer}
                            />
                        )}
                    </div>
                </div>
            );

            if (it.FeedbackQuestionType !== "Ranking") {
                questionsFreetext.push(question)
            } else {
                return question;
            }
        });

        return (
            <PublicLayout {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <PageHeader
                        title={translate("page.title.feedback")}
                    />

                    {isLoading && (
                        <div className="h-80">
                            <div className={"inset-center"}>
                                <Loader/>
                            </div>
                        </div>
                    )}

                    {/* Page content */}
                    {!isLoading && !this.props.resource.create && (
                        <div className="p-4 sm:p-6 lg:p-8">
                            <div className={"mb-2 font-bold"}>Hey {data.ClientContactName}, can you send us feedback for
                                candidate {data.CandidateName} that applied for the role "{data.RoleName}":
                            </div>

                            <div className="space-y-8 divide-y divide-secondary-200">
                                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                    {fieldsHtml[0]}

                                    {questionsRanking.length > 0 && (
                                        <div
                                            className="hidden sm:block sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5">
                                            <div className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2"/>

                                            <div className="mt-1 sm:mt-0 sm:col-span-2 text-xs md:text-base">
                                                <div className="sm:grid gap-4 grid-cols-5 text-center">
                                                    <span className="flex items-center justify-center">Unsatisfactory</span>
                                                    <span className="flex items-center justify-center">Satisfactory</span>
                                                    <span className="flex items-center justify-center">Average</span>
                                                    <span className="flex items-center justify-center">Above Average</span>
                                                    <span className="flex items-center justify-center">Exceptional</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {questionsRanking}

                                    {questionsFreetext}

                                    {fieldsHtml[1]}
                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-self-start">
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={this.handleSubmit}
                                        >
                                            Send Feedback
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isLoading && !!this.props.resource.create && (
                        <div className={"text-xl font-bold text-center m-auto mt-2 mb-2"}>
                            {translate("page.text.thank_send_feedback")}

                            <div className={"text-sm text-gray-400 mt-2"}>* Refreshing this page will bring form back if
                                you want to update your answers
                            </div>
                        </div>
                    )}
                </main>
            </PublicLayout>
        );
    }
}

export default connect(state => state)(FeedbackPublicPage);