import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SimpleTable from "../../components/simple-table";
import {DEFAULT_CRUD_STATE, fieldsSearchToHtml, fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {PlusIcon, UserIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import Slideover from "../../components/slideover";

export default class ContactsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "ClientContact",

            columnFilters: {},

            fields: this.getFields('', ['CandidateID', 'ContactID']),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
            userModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    createUser = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ClientContactID: this.state.selectedItem.ClientContactID
            },
            resource: "client/contacts/user",
            notificationMessage: "Access granted for: " + this.state.selectedItem.Email,
            piggyResource: this.getResourceName()
        }))
    };

    removeUser = () => {
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            piggyQuery: this.getQuery(),
            query: {
                ClientContactID: this.state.selectedItem.ClientContactID
            },
            resource: "client/contacts/user",
            notificationMessage: "Access removed for: " + this.state.selectedItem.Email,
            piggyResource: this.getResourceName()
        }))
    };

    resendEmail = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ClientContactID: this.state.selectedItem.ClientContactID
            },
            resource: "client/contacts/user",
            notificationMessage: "Access email re sent for: " + this.state.selectedItem.Email,
            piggyResource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    };

    handleToggleCreateContactModal = () => {
        this.setState({
            createContactModelOpen: !this.state.createContactModelOpen
        })
    };

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    };

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    };

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    };

    handleCreateUser = (item = null) => {
        this.setState({
            selectedItem: item,
            userModalOpen: !this.state.userModalOpen
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "ClientContactID";
    };

    getResourceName = () => {
        return Resources.ClientContacts;
    };

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    };

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    };

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ClientContact: new Field("ClientContact", '', ['empty'], false, "text"),
            TitleID: new Field("TitleID", '', ['empty'], false, "select-search", {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            Email: new Field("Email", '', ['empty'], false, "text"),

            ContactID: new Field("ContactID", '', ['empty'], false, 'select-search'),
            ContactNote: new Field("ContactNote", '', [''], false, "textarea"),
            IsBillingContact: new Field("IsBillingContact", '', [], false, 'checkbox')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getFieldsContact = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            TitleID: new Field("TitleID", '', ['empty'], false, 'select-search'),

            Address1: new Field("Address1", '', [], false, 'text'),
            Address2: new Field("Address2", '', [], false, 'text'),
            City: new Field("City", '', [], false, 'text'),
            StateID: new Field("StateID", '', [], false, 'select'),
            PostalCode: new Field("PostalCode", '', [], false, 'text'),
            Email: new Field("Email", '', ['empty'], false, 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            ContactNote: new Field("ContactNote", '', [''], false, "textarea"),
            IsBillingContact: new Field("IsBillingContact", '', [], false, 'checkbox')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            TitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            }
        };

        return (
            <div className="">
                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mr-6 mb-3"}
                    />

                    <div className="ml-auto mb-3">
                        <button
                            type="button"
                            onClick={this.handleToggleCreateContactModal}
                            className="btn btn-primary focus:ring-offset-inverse ml-6"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    onUpdateColumnFilter={this.handleUpdateColumnFilter}
                    columnFilters={this.state.columnFilters}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onEdit={this.handleToggleEditModel}
                    onDelete={this.handleToggleConfirmModal}
                    onCustomAction={(it) => {
                        return (
                            <button
                                onClick={() => this.handleCreateUser(it)}
                                type="button"
                                className="ml-2 sm:mt-0 mt-1 sm:mb-0 mb-1 sm:ml-0 mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md
                                text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <UserIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                System Access
                            </button>
                        )
                    }}
                />

                <ModalSaveResource
                    title={"Edit Contact"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFieldsContact(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={"Create Contact"}
                    widthClass="max-w-lg"
                    visible={this.state.createContactModelOpen}
                    onClose={this.handleToggleCreateContactModal}
                    fields={this.getFieldsContact()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateContactModal(false);
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <Slideover
                    visible={this.state.userModalOpen}
                    onClose={this.handleCreateUser}
                >
                    {!this.state?.selectedItem?.ContactID && (
                        <p className={"mb-2"}>
                            You are about to give access to client contact {this.state?.selectedItem?.Email}, are you sure?
                        </p>
                    )}
                    {!!this.state?.selectedItem?.ContactID && (
                        <p className={"mb-2"}>
                            You are about to remove access to client contact {this.state?.selectedItem?.Email}, are you sure?
                        </p>
                    )}
                    {!this.state?.selectedItem?.ContactID && (
                        <button
                            type="button"
                            className="mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => {
                                this.createUser();
                                this.handleCreateUser(false);
                            }}
                        >
                            {translate("btn.confirm_add")}
                        </button>
                    )}
                    {!!this.state?.selectedItem?.ContactID && (
                        <button
                            type="button"
                            className="mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => {
                                this.removeUser();
                                this.handleCreateUser(false)
                            }}
                        >
                            {translate("btn.confirm_remove")}
                        </button>
                    )}

                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => this.handleCreateUser(false)}
                    >
                        {translate("btn.cancel")}
                    </button>

                    {!!this.state?.selectedItem?.ContactID && (
                        <div className={"mt-2"}>
                            <p className={"mb-2 text-center w-full"}>Or</p>
                            <button
                                type="button"
                                className="mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    this.resendEmail();
                                    this.handleCreateUser(false);
                                }}
                            >
                                {translate("btn.resend_invitation")}
                            </button>
                        </div>
                    )}
                </Slideover>
            </div>
        )
    }
}
