import React, {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import {CheckCircleIcon} from '@heroicons/react/outline'
import {XIcon} from '@heroicons/react/solid'
import ExclamationIcon from "@heroicons/react/solid/ExclamationIcon";
import {classNames} from "../../util/util";
import * as RactDom from "react-dom";

export default function Notification({show, onClose, title, message, type, translate, color}) {
    let icon, messageColor;

    switch (type) {
        case "error":
            icon = <ExclamationIcon className={"h-6 w-6 text-" + (color ?? "red") + "-400"} aria-hidden="true"/>
            messageColor = "text-" + (color ?? "red") + "-500";
            message = translate("error_message." + message)
            break;
        default:
            icon = <CheckCircleIcon className={"h-6 w-6 text-" + (color ?? "green") + "-400"} aria-hidden="true"/>
            messageColor = "text-" + (color ?? "gray") + "-500"
    }
    return RactDom.createPortal(
        <>
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="fixed top-5 right-5 z-50 max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                        <div className="flex items-start">

                            <div className="flex-shrink-0">
                                {icon}
                            </div>

                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">{title}</p>
                                <p className={classNames("mt-1 text-sm", messageColor)}>{message}</p>
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        onClose()
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </>,
        document.getElementById('notifications-root')
    )
}