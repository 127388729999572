import React, {Component} from "react";
import IdleTimer from "react-idle-timer";
import {idleTimer} from "../../data/actions/ui";

export default class IdleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inactiveModal: false
        }
    }

    render() {
        const minutes = 30;
        const minute = 1000 * 60;
        const timeout = minute * minutes;
        const nonPrivateRoutes = ["/", "/login", "/roles/:token", "/recruiters", "/role/:url", "/feedback/:token", "/privacy-policy", "/login", "/register", "/register-candidate/:token", "/register-candidate", "/reset-password", "/reset-password-confirm/:email/:token/:is_new", "/reset-password-confirm/:email/:token"]

        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onIdle={(e) => {
                        if (!nonPrivateRoutes.includes(this.props.history.location.pathname)) {
                            this.props.dispatch(idleTimer());
                        }
                    }}
                    timeout={timeout}
                    crossTab={true}
                />
            </React.Fragment>
        );
    }
}