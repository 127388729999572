import React, {Component} from 'react';

import Loading from '../loading';

export default class extends Component {
    componentDidMount() {
        const jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', this.props.filePath, true);
        jsonFile.send();
        jsonFile.responseType = 'arraybuffer';
        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
                // mammoth.convertToHtml(
                //     {arrayBuffer: jsonFile.response},
                //     {includeDefaultStyleMap: true},
                // )
                //     .then((result) => {
                //         const docEl = document.createElement('div');
                //         docEl.className = 'document-container';
                //         docEl.innerHTML = result.value;
                //         document.getElementById('docx').innerHTML = docEl.outerHTML;
                //     })
                //     .catch((a) => {
                //         console.log('something went wrong', a);
                //     })
                //     .done();
            }
        };
    }

    render() {
        return (
            <div id="docx">
                <Loading/>
            </div>);
    }
}
