import React, {Component, Fragment} from 'react'
import {downloadFile} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import SimpleTable from "../../components/simple-table";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {PaperClipIcon, PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import ModalUploadDocument from "../../components/modal/modal-upload-document";
import {Field} from "../../data/services/fields";
import ModalViewDocument from "../../components/modal/modal-view-document";
import Env from "../../util/env";
import {
    createResource,
    deleteResource,
    getResource,
    uploadDocument
} from "../../data/actions/resource";
import ModalVideoPlayer from "../../components/modal/modal-video-player";
import {Dialog, Transition} from "@headlessui/react";
import FieldTextarea from "../../components/field-textarea";
import FieldText from "../../components/field-text";

export default class DocumentsTab extends Component {

    constructor(props) {
        super(props);

        const fields = this.getFields();
        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: Object.values(fields)[0].name,

            columnFilters: {},
            query: "",
            archived: false,

            fields: fields,

            selectedItem: null,
            viewModalOpen: false,
            viewVideoModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            sendModalOpen: false,
            sendEmailMessage: "",
            sendEmail: ""
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    sendEmail = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                Email: this.state.sendEmail,
                Note: this.state.sendEmailMessage,
                DocumentID: this.state.selectedItem[this.getPrimaryKey()]
            },
            notificationMessage: "Document sent to " + this.state.sendEmail,
            resource: this.props.resourceSendName,
        }));
        this.handleToggleSendModal();
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleViewModal = (item = null) => {
        if (item && (item.OriginalFileName.includes("mp4") || item.OriginalFileName.includes("webm"))) {
            this.handleViewVideoModal(item);
        } else {
            this.setState({
                selectedItem: item,
                viewModalOpen: !this.state.viewModalOpen
            });
        }
    }

    handleViewVideoModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewVideoModalOpen: !this.state.viewVideoModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleSendModal = (item = null) => {
        this.setState({
            selectedItem: item,
            sendModalOpen: !this.state.sendModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey;
    }

    getResourceName = () => {
        return this.props.resourceName;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null) => {
        let fieldTemplates;
        fieldTemplates = {
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'date', {
                hideDialog: true
            }),
            OriginalFileName: new Field("OriginalFileName", '', [], false, 'text', {
                hideDialog: true
            }),
            Description: new Field("Description", '', [], false, 'textarea', {
                hideDialog: true
            })
        };

        if (this.props.documentTypes) {
            fieldTemplates.DocumentTypeID = new Field("DocumentType", '', [], false, 'select-search', {
                hideDialog: true
            })
        }

        if (this.props.fields) {
            fieldTemplates = Object.assign(fieldTemplates, this.props.fields);
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const bookmarks = [];

        const columnFiltersLength = Object.keys(this.state.columnFilters).length;

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <div className="mr-6 w-full sm:w-auto mb-3">
                        {!!columnFiltersLength && (
                            <button
                                onClick={() => (!!this.state.query)
                                    ? this.setState({
                                        columnFilters: {},
                                        query: ""
                                    }, () => this.fetchData())
                                    : null
                                }
                                className={"btn " + (!!this.state.query ? "btn-primary" : " btn-disabled")}
                            >
                                Clear all filters
                            </button>
                        )}
                    </div>

                    <div className="flex-shrink-0 ml-auto mb-3">
                        {checkPerm(this.getResourceName(), CREATE_PERM) && (!this.props.disableCreate) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={this.handleViewModal}
                    onDelete={this.handleToggleConfirmModal}
                    onCustomAction={!!this.props.resourceSendName ? (it) => {
                        return (<button
                            onClick={() => this.handleToggleSendModal(it)}
                            type="button"
                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                            Send
                        </button>);
                    } : null}
                />

                <ModalVideoPlayer
                    title={"View video"}
                    className="max-w-2xl"
                    visible={this.state.viewVideoModalOpen}
                    onClose={this.handleViewVideoModal}
                    translate={this.props.translate}
                    filePath={Env.getApiUrl('api/' + this.getResourceName(), {
                        [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                        id: this.getId(),
                        token: LocalStorage.get('user').access_token,
                        video: 1
                    })}
                    bookmarks={bookmarks}
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get('user'),
                            query: {
                                [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                                id: this.getId(),
                                token: LocalStorage.get('user').access_token,
                                name: this.state.selectedItem.OriginalFileName,
                                stream: 1
                            },
                            resource: this.getResourceName()
                        }))
                    }}
                />

                <ModalViewDocument
                    title={"View document"}
                    className="max-w-2xl"
                    visible={this.state.viewModalOpen}
                    onClose={this.handleViewModal}
                    fields={this.getFields()}
                    translate={this.props.translate}

                    fileType={getProp(this.state, "selectedItem.OriginalFileName")?getProp(this.state, "selectedItem.OriginalFileName", "").split(".").pop():""}
                    filePath={Env.getApiUrl('api/' + this.getResourceName(), {
                        [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                        id: this.getId(),
                        token: LocalStorage.get('user').access_token
                    })}
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get('user'),
                            query: {
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                id: this.getId(),
                                name: this.state.selectedItem.OriginalFileName
                            },
                            resource: this.getResourceName()
                        }))
                    }}
                />

                <ModalUploadDocument
                    title={"Create " + this.props.title ?? "Document"}
                    className="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    documentTypes={this.props.documentType ? getLookup("DocumentType", "DocumentTypeID", "DocumentType") : ""}
                    onSubmit={(files, params, descriptions, documentType) => {
                        params.id = this.props.id;
                        this.props.dispatch(uploadDocument({
                            user: LocalStorage.get('user'),
                            params: params,
                            files: files,
                            descriptions: descriptions,
                            types: documentType,
                            resource: this.getResourceName(),
                            piggyResource: this.props.piggyResource ?? this.getResourceName(),
                            notificationMessage: "File uploaded"
                        }));
                        this.handleToggleCreateModal();
                    }}
                    translate={this.props.translate}
                    mediaTypesText={this.props.mediaTypesText}
                />

                <ModalConfirm
                    visible={this.state.confirmModalOpen}
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            piggyQuery: this.getQuery(),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                />

                <Transition show={this.state.sendModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.sendModalOpen}
                        onClose={this.handleToggleSendModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                    &#8203;
                    </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        <p>Enter email to send document to:</p>
                                        <p><FieldText
                                            onChange={(name, value) => {
                                                this.setState({
                                                    sendEmail: value
                                                })
                                            }}
                                            value={this.state.sendEmail}
                                        /></p>

                                        <p className={"mt-1 mb-1 text-sm text-secondary-500"}>* Optionally add a message
                                            in addition to the document that will be sent:</p>
                                        <FieldTextarea
                                            onChange={(name, value) => {
                                                this.setState({
                                                    sendEmailMessage: value
                                                })
                                            }}
                                            value={this.state.sendEmailMessage}
                                        />
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button
                                                    type="button"
                                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={this.sendEmail}
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={this.handleToggleSendModal}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        )
    }
}
