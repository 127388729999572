import React, {Component, createRef} from 'react'
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {downloadFile, getResource} from "../../data/actions/resource";
import FieldSelect from "../../components/field-select";
import {
    AnnotationIcon,
    ArrowsExpandIcon as ArrowsExpandSolidIcon,
    CameraIcon,
    ChatIcon,
    DocumentTextIcon,
    DotsVerticalIcon,
    MicrophoneIcon,
    PencilAltIcon,
    PlayIcon,
    StopIcon,
    VideoCameraIcon
} from "@heroicons/react/solid";
import PublicLayout from "../../components/layout";
import Loader from "../../components/loader";
import Button from "../../components/button";
import socketIOClient from "socket.io-client";
import Participant from "./participant"
import {WebRtcPeerRecvonly, WebRtcPeerSendonly} from "./utils/WebRtcPeer";
import LoaderSmall from "../../components/loader-small";
import FieldTextarea from "../../components/field-textarea";
import FieldText from "../../components/field-text";
import {isFirefox, isSafari} from 'react-device-detect';
import DialogDefault from "../../components/dialog-default";
import {ArrowsExpandIcon, BanIcon, PlusSmIcon, XIcon} from "@heroicons/react/outline";
import hark from 'hark'
import {createSecondResource, getSecondResource} from "../../data/actions/secondResource";
import {getProp} from "../../util/util";
import Env from "../../util/env";
import ModalViewDocument from "../../components/modal/modal-view-document";
import {PackedGrid} from 'react-packed-grid'
import MobileDropdown from "./mobile-menu";
import axios from "axios";

const MEDIA_PROXY_URL = process.env.REACT_APP_MEDIASOCKET_URL;

export function isValidURL(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export function ifContainsURL(str) {
    return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(str);
}


const VIDEO_QUALITY = [
    {frameRate: {min: 24, max: 30}},
    {height: {min: 360, max: 1920}},
    {width: {min: 640, max: 1080}},
    // {aspectRatio: {exact: 1.77778}}
];

class RoomPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // Room state
            username: ((this.props.match.params && this.props.match.params.user) ? (this.props.match.params && this.props.match.params.user) : ""),
            joinedRoom: false,
            accessDenied: false,
            loadingRoom: false,

            // UI
            isOptionsDialogVisible: false,
            isAudioVideoOverlayVisible: false,
            isAddDialogVisible: false,
            sidebarContent: "chat", // chat, notes, documents, recordings

            // Chat
            chatValue: "",
            showAutocomplete: false,
            selectedSuggestedItem: "",

            // Chat message list
            chat: [],

            // Participants info map
            participants: {},

            // Available media
            audioInputSelect: [],
            audioOutputSelect: [],
            videoSelect: [],

            videoAvailable: false,
            audioAvailable: false,
            screenAvailable: true,

            candidatePresenter: true,

            rightBarChat: true,
            emptyNote: false,

            // Local media state
            camera: true,
            mic: true,
            screenSharing: false,

            // Recording
            recording: false,
            recordingNote: "",
            recordingStartTime: 0,
            recordingList: [],
            recordingSaveLoading: false,

            // Local media configuration (selected devices etc)
            configuration: {
                audioInputSelect: "",
                audioOutputSelect: "",
                videoSelect: ""
            },
            speakingMap: {},

            // Notes, and misc
            notes: [],

            // Documents
            selectedItem: null,
            viewDocumentModalOpen: false,
        };

        // Video/Audio elements references
        this.setupVideoRef = createRef();

        this.myVideoRef = createRef();

        this.videoRefs = createRef();
        this.videoRefs.current = {}

        this.audioRefs = createRef();
        this.audioRefs.current = {}

        // Participants WebRtc connection(s) map
        this.participants = {};

        this.setupStream = null;

        this.isSafari = isSafari || isFirefox;

        console.log("isSafari", this.isSafari);
    }

    /** Lifecycle
     ================================================================= */
    async componentDidMount() {
        console.log("RoomView:componentDidMount")
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id,
                meeting: (window.location.href.indexOf("meeting") != -1) ? 1 : 0
            },
            resource: Resources.Room
        }));

        await this.getPermissions();
        console.log("AUDIO: ", this.state.audioAvailable)
        console.log("VIDEO: ", this.state.videoAvailable)

        if (!this.isSafari) {
            navigator.permissions.query({name: 'camera'}).then((result) => {
                if (result.state === 'granted') {
                    this.setState({cameraPermission: true})
                } else if (result.state === "prompt") {
                    this.setState({cameraPermission: false})
                }
            });
            navigator.permissions.query({name: 'microphone'}).then((result) => {
                if (result.state === 'granted') {
                    this.setState({microphonePermission: true})
                } else if (result.state === "prompt") {
                    this.setState({microphonePermission: false})
                }
            });
        }

        navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => {
                console.log(deviceInfos);
                let audioInputSelect = [];
                let audioOutputSelect = [];
                let videoSelect = [];


                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];

                    let deviceId = deviceInfo.deviceId;

                    if (deviceInfo.kind === 'audioinput') {
                        audioInputSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1)
                        });
                    } else if (deviceInfo.kind === 'audiooutput') {
                        audioOutputSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Speaker ' + (audioOutputSelect.length + 1)
                        });
                    } else if (deviceInfo.kind === 'videoinput') {
                        videoSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Camera ' + (videoSelect.length + 1)
                        });
                    }
                }

                const configuration = this.getConfig(audioInputSelect, audioOutputSelect, videoSelect);

                this.setState({
                    configuration: configuration,
                    audioInputSelect: audioInputSelect,
                    audioOutputSelect: audioOutputSelect,
                    videoSelect: videoSelect,
                    screenAvailable: true
                }, () => {
                    this.updateSetupStreamState();
                })
            })
            .catch((err) => {
                console.log(err)
            });

        window.addEventListener('beforeunload', () => this.leaveRoom(false))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        document.addEventListener('fullscreenchange', this.fullScreenHandler);
        document.addEventListener('webkitfullscreenchange', this.fullScreenHandler);
        document.addEventListener('mozfullscreenchange', this.fullScreenHandler);
        document.addEventListener('MSFullscreenChange', this.fullScreenHandler);
    }

    componentWillUnmount() {
        console.log("RoomView:componentWillUnmount")
        this.leaveRoom();
    }

    /** Data events
     ================================================================= */
    getMeetingNotes = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.MeetingNotes
        }));
    }

    createMeetingNotes = () => {
        this.props.dispatch(createSecondResource({
            user: LocalStorage.get("user"),
            query: {
                MeetingID: this.props.match.params.id,
                Note: this.state.noteBuffer
            },
            resource: Resources.MeetingNotes
        }));
    }

    getCandidateData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                IsPublic: 1,
                id: this.props.resource.data.CandidateID
            },
            resource: Resources.CandidateDocuments
        }));
    }


    /** Socket message callbacks
     ================================================================= */
    onNewParticipant = (request) => {
        const participants = this.state.participants;
        participants[request.name] = new Participant(request.name, request.role, request.camera, request.mic);

        this.setState({
            participants: participants
        }, () => {
            this.participants[request.name] = this.state.participants[request.name];

            const audio = this.audioRefs.current[request.name];
            const video = this.videoRefs.current[request.name];

            this.receiveAudioVideo(this.participants[request.name], audio, video);
        })
    };

    onExistingParticipants = (msg) => {
        console.log(this.getName() + " registered in room " + this.getId());

        this.participants[this.getName()] = new Participant(this.getName(), 2, this.state.camera, this.state.mic)
        const participants = this.state.participants;

        msg.data.forEach((sender) => {
            participants[sender.name] = new Participant(sender.name, sender.role, sender.camera, sender.mic);
        });

        this.setState({
            loadingRoom: false,
            participants: participants
        }, () => {
            // Setup local stream
            const video = this.myVideoRef.current;
            let constraints;
            constraints = {
                audio: this.state.audioAvailable ? {
                    deviceId: {exact: this.state.configuration.audioInputSelect},
                } : false,
                video: this.state.videoAvailable ? {
                    deviceId: {exact: this.state.configuration.videoSelect},
                    advanced: VIDEO_QUALITY
                } : false
            };

            let options = {
                localVideo: video,
                mediaConstraints: constraints,
                onconnectionstatechange: (state) => {
                    console.log(state)
                },
                onicecandidate: (candidate, wp) => {
                    console.log("Local candidate" + candidate);

                    const message = {
                        id: 'onIceCandidate',
                        candidate: candidate,
                        sender: this.getName()
                    };
                    this.sendMessage(message);
                },
                onSpeechDetection: ((speak) => {
                    this.setState({speaking: speak})
                })
            };

            this.participants[this.getName()].rtcPeer = new WebRtcPeerSendonly(options,
                (error) => {
                    if (error) {
                        return console.error(error);
                    }
                    this.participants[this.getName()].rtcPeer.generateOffer((error, offerSdp, wp) => {
                        const msg = {
                            id: "receiveVideoFrom",
                            sender: this.getName(),
                            sdpOffer: offerSdp
                        };
                        this.sendMessage(msg);
                    });

                    if (!this.state.mic) {
                        this.participants[this.getName()].rtcPeer.micOff();
                    }
                    if (!this.state.camera) {
                        this.participants[this.getName()].rtcPeer.cameraOff();
                    }
                    this.broadcastUserStateUpdate();
                });

            // Setup remote streams
            Object.values(this.state.participants).forEach((participant, i) => {
                this.participants[participant.name] = participant;

                const audio = this.audioRefs.current[participant.name];
                const video = this.videoRefs.current[participant.name];

                this.receiveAudioVideo(this.participants[participant.name], audio, video);
            });
        });
    };

    onParticipantLeft = (request) => {
        console.log('Participant ' + request.name + ' left');

        // Update UI
        const participants = this.state.participants;
        if (participants[request.name]) {
            delete participants[request.name];
            this.setState({
                participants: participants
            }, () => {
                // Clear rtc connection
                if (this.participants[request.name]) {
                    this.participants[request.name].rtcPeer.dispose();
                    delete this.participants[request.name];
                }
            });
        }
    }

    /** User actions
     ================================================================= */
    handleDocumentViewModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewDocumentModalOpen: !this.state.viewDocumentModalOpen
        });
    }

    handleEnterRoom = () => {
        if (!LocalStorage.get('user') && !this.state.username) {
            return;
        }
        this.setState({
            joinedRoom: true,
            loadingRoom: true,
        }, () => {
            if (this.setupStream) {
                this.setupStream.getTracks().forEach((track) => {
                    track.stop()
                });
                this.setupStream = null;
            }
            this.setupRoom();
        })
    };

    leaveRoom = (goBack = false) => {
        console.log("room:leaveRoom")
        if (this.socket) {
            this.sendMessage({
                'id': 'leaveRoom'
            });

            console.log(this.participants)
            for (const key in this.participants) {
                this.participants[key].rtcPeer.dispose();
            }

            this.socket.close();

            this.socket.disconnect();

            goBack && this.props.history.push("/");
        }

        if (this.setupStream) {
            this.setupStream.getTracks().forEach((track) => {
                track.stop()
            });
        }
    };

    cameraToggle = () => {
        this.setState({
            camera: !this.state.camera
        }, () => {
            if (this.state.joinedRoom) {
                if (this.state.camera) {
                    this.participants[this.getName()].rtcPeer.cameraOn();
                } else {
                    this.participants[this.getName()].rtcPeer.cameraOff();
                }
                this.broadcastUserStateUpdate()
            } else {
                if (!!this.setupStream) {
                    this.setupStream.getVideoTracks()[0].enabled = this.state.camera
                }
            }
        });
    };

    micToggle = () => {
        this.setState({
            mic: !this.state.mic
        }, () => {
            if (this.state.joinedRoom) {
                if (this.state.mic) {
                    this.participants[this.getName()].rtcPeer.micOn();
                } else {
                    this.participants[this.getName()].rtcPeer.micOff();
                }
                this.broadcastUserStateUpdate()
            } else {
                if (!!this.setupStream) {
                    this.setupStream.getAudioTracks()[0].enabled = this.state.mic
                }
            }
        })
    }

    screenShareToggle = () => {
        this.setState({
            screenSharing: !this.state.screenSharing
        }, () => {
            if (this.state.screenSharing) {
                this.participants[this.getName()].rtcPeer.screenShareOn(() => {
                    console.log("Screen share end");
                    this.setState({
                        screenSharing: false
                    });
                });
            } else {
                this.participants[this.getName()].rtcPeer.screenShareOff();
            }
        });
    };

    optionsToggle = () => {
        this.setState({
            isOptionsDialogVisible: !this.state.isOptionsDialogVisible
        });
    }

    toggleAudioVideoInfo = () => {
        this.setState({
            isAudioVideoOverlayVisible: !this.state.isAudioVideoOverlayVisible
        });
    }

    toggleAddParticipantDialog = () => {
        this.setState({
            isAddDialogVisible: !this.state.isAddDialogVisible
        });
    }

    startRecording = () => {
        if (!this.state.recordingNote) {
            this.setState({emptyNote: true});
        } else {
            // startRecording
            const message = {
                id: 'startRecording'
            };
            this.sendMessage(message);
            this.setState({
                recordingStartTime: (new Date()).getTime(),
                recording: !this.state.recording,
                emptyNote: false
            })
        }
    }

    stopRecording = () => {
        // stopRecording
        const message = {
            id: 'stopRecording',
            time: (new Date()).getTime() - this.state.recordingStartTime,
            note: this.state.recordingNote
        };
        this.sendMessage(message);
        let recordingList = this.state.recordingList;

        recordingList.push({
            time: (new Date()).getTime() - this.state.recordingStartTime,
            note: this.state.recordingNote
        });

        this.setState({
            recording: !this.state.recording,
            recordingList: recordingList,
            recordingNote: ""
        })
    }

    finishRecording = () => {
        this.setState({
            recordingList: [],
            recordingSaveLoading: true
        });
        this.sendMessage({
            id: 'finishRecording'
        });
    }

    /** WebRtc Helpers
     ================================================================= */
    getPermissions = async () => {
        await navigator.mediaDevices.getUserMedia({video: true})
            .then((stream) => {
                this.setState({
                    videoAvailable: true
                });
                stream.getTracks().forEach((track) => {
                    track.stop()
                });

                if (this.isSafari) {
                    this.setState({
                        cameraPermission: true
                    });
                }
            })
            .catch(() => this.setState({
                videoAvailable: false
            }))

        await navigator.mediaDevices.getUserMedia({audio: true})
            .then(() => {
                this.setState({
                    audioAvailable: true
                });
                if (this.isSafari) {
                    this.setState({
                        microphonePermission: true
                    });
                }
            })
            .catch(() => this.setState({
                audioAvailable: false
            }))
    }

    sendJoinRoomMessage = () => {
        const message = {
            id: 'joinRoom',
            name: this.getName(),
            roomName: this.getId(),
            token: this.getAccessToken(),
            temp_token: this.getTempToken()
        };
        this.sendMessage(message);
    };

    sendChat = (msg) => {
        this.sendMessage({
            id: "chat",
            name: this.getName(),
            roomName: this.getId(),
            msg: msg,
            ToID: this.getId(),
            FromID: this.getId()
        })
    };

    receiveVideoResponse = (result) => {
        this.participants[result.name].rtcPeer.processAnswer(result.sdpAnswer, (error) => {
            if (error) return console.error(error);
        });
    };

    receiveAudioVideo = (participant, audio, video) => {
        const options = {
            remoteAudio: audio,
            remoteVideo: video,
            onicecandidate: (candidate, wp) => {
                console.log("Local candidate" + candidate);

                const message = {
                    id: 'onIceCandidate',
                    candidate: candidate,
                    sender: participant.name
                };
                this.sendMessage(message);
            },
            onSpeechDetection: ((speak) => {
                let speakingMap = this.state.speakingMap;
                speakingMap[participant.name] = speak;
                console.log("SPEAKING MAP", speakingMap);
                this.setState({speakingMap: speakingMap})
            })
        };

        this.participants[participant.name].rtcPeer = new WebRtcPeerRecvonly(options,
            (error) => {
                if (error) {
                    return console.error(error);
                }
                this.participants[participant.name].rtcPeer.generateOffer((error, offerSdp, wp) => {
                    const msg = {
                        id: "receiveVideoFrom",
                        sender: participant.name,
                        sdpOffer: offerSdp
                    };
                    this.sendMessage(msg);
                });
            }
        );
    };

    broadcastUserStateUpdate = () => {
        this.sendMessage({
            id: 'userStateUpdate',
            mic: this.state.audioAvailable ? this.state.mic : false,
            camera: this.state.videoAvailable ? this.state.camera : false
        })
    };

    updateSetupStreamMedia = (name, value) => {

        const config = this.state.configuration;
        config[name] = value;
        this.setState({
                configuration: config
            }, () => {
                (() => this.updateSetupStreamState())();
                (() => this.saveConfig())();
            }
        )
    };

    updateSetupStreamState = () => {
        window.navigator.mediaDevices.getUserMedia({
            audio: this.state.audioAvailable ? {
                deviceId: {exact: this.state.configuration.audioInputSelect},
            } : false,
            video: this.state.videoAvailable ? {
                deviceId: {exact: this.state.configuration.videoSelect},
                advanced: VIDEO_QUALITY
            } : false
        })
            .then((stream) => {
                this.setupStream = stream;
                this.setupVideoRef.current.srcObject = stream

                const detector = hark(stream, {});

                detector.on('speaking', () => {
                    this.setState({
                        userSpeaking: true
                    })
                });

                detector.on('stopped_speaking', () => {
                    this.setState({
                        userSpeaking: false
                    })
                });

                detector.on('volume_change', (volume) => {
                    this.setState({
                        userSpeakingVolume: volume
                    })
                });
            })
            .catch((err) => {
                console.log(err)
            });
    };

    saveConfig = () => {
        LocalStorage.set('chat_room_config', JSON.stringify(this.state.configuration))
    }

    getConfig = (audioInputSelect, audioOutputSelect, videoSelect) => {
        let config = null;
        if (LocalStorage.get('chat_room_config')) {
            config = JSON.parse(LocalStorage.get('chat_room_config'));
        }

        if (!config) {
            config = {
                audioInputSelect: audioInputSelect.length > 0 ? audioInputSelect[0].id : "",
                audioOutputSelect: audioOutputSelect.length > 0 ? audioOutputSelect[0].id : "",
                videoSelect: videoSelect.length > 0 ? videoSelect[0].id : "",
            }
        } else {
            // If previously selected device is missing select the first one
            if (!audioInputSelect.find(it => it.id === config['audioInputSelect'])) {
                config['audioInputSelect'] = audioInputSelect.length > 0 ? audioInputSelect[0].id : "";
            }

            if (!audioOutputSelect.find(it => it.id === config['audioOutputSelect'])) {
                config['audioOutputSelect'] = audioOutputSelect.length > 0 ? audioOutputSelect[0].id : "";
            }

            if (!videoSelect.find(it => it.id === config['videoSelect'])) {
                config['videoSelect'] = videoSelect.length > 0 ? videoSelect[0].id : "";
            }
        }

        return config;
    }

    sendMessage = (message) => {
        console.log('Sending message: ' + JSON.stringify(message));
        this.socket.emit('message', message);
    };

    switchVideoPresenter = () => {
        let presenter = this.state.candidatePresenter;

        this.setState({candidatePresenter: !presenter});
    };

    toggleContentRightBar = () => {
        let rightBarChat = this.state.rightBarChat;

        this.setState({rightBarChat: !rightBarChat});
    }

    toggleViewRightBar = (content = null) => {
        if (content === "documents") {
            this.getCandidateData();
        }
        this.setState({sidebarContent: content});
    }

    /** Helpers
     ================================================================= */
    setupRoom = () => {
        this.socket = socketIOClient(MEDIA_PROXY_URL);

        this.socket.on('connect', () => {
            console.log('ws connect success');
            this.sendJoinRoomMessage();
        });

        this.socket.on('message', parsedMessage => {
            console.info('Room:Received message: ' + parsedMessage.id);

            switch (parsedMessage.id) {
                case 'existingParticipants':
                    this.onExistingParticipants(parsedMessage);
                    break;
                case 'newParticipantArrived':
                    this.onNewParticipant(parsedMessage);
                    break;
                case 'participantLeft':
                    this.onParticipantLeft(parsedMessage);
                    break;
                case 'receiveVideoAnswer':
                    this.receiveVideoResponse(parsedMessage);
                    break;
                case 'iceCandidate':
                    this.participants[parsedMessage.name].rtcPeer.addIceCandidate(parsedMessage.candidate, function (error) {
                        if (error) {
                            console.error("Error adding candidate: " + error);

                        }
                    });
                    break;
                case 'chat':
                    let chats = this.state.chat;
                    chats.push({
                        name: parsedMessage.name,
                        msg: parsedMessage.msg
                    });
                    this.setState({
                        chat: chats
                    });
                    break;
                case 'userStateUpdate':
                    let participants = this.state.participants;
                    console.log(parsedMessage)
                    participants[parsedMessage.name].mic = parsedMessage.data.mic;
                    participants[parsedMessage.name].camera = parsedMessage.data.camera;
                    participants[parsedMessage.name].role = parsedMessage.data.role;
                    this.setState({
                        participants: participants
                    })
                    break;
                case 'finishRecordingDone':
                    this.setState({
                        recordingSaveLoading: false
                    })
                    break;
                case 'denied':
                    this.setState({
                        accessDenied: true
                    })
                    break;
                default:
                    console.error('Unrecognized message', parsedMessage);
            }
        });

        /*
        this.props.dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.RoomChat
        }));
         */
    };

    addToAudioRef = (el, name) => {
        if (el) {
            this.audioRefs.current[name] = (el)
        }
    };

    addToVideoRef = (el, name) => {
        if (el) {
            this.videoRefs.current[name] = (el)
        }
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getName = () => {
        return LocalStorage.get('user') ? (LocalStorage.get('user').Contact.FirstName + ' ' + LocalStorage.get('user').Contact.LastName) : this.state.username;
    };

    getAccessToken = () => {
        return LocalStorage.get('user') ? LocalStorage.get('user').access_token : "";
    }

    getTempToken = () => {
        return (this.props.match.params && this.props.match.params.token);
    }

    debug = () => {

    }

    /** Full screen helpers
     ================================================================= */
    openFullscreen = (id) => {
        const elem = document.getElementById(id);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
        this.setState({isInFullScreen: true})
    };

    closeFullscreen = () => {
        this.setState({isInFullScreen: false})
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    fullScreenToggle = (param) => {
        if (document.fullscreenElement) {
            this.closeFullscreen()
        } else {
            this.openFullscreen(param)
        }
    };

    fullScreenHandler = () => {
        this.setState({isInFullScreen: !this.state.isInFullScreen})
    };

    handleResize = () => {
        if (window.innerWidth > 975) {
            this.setState({screenResizeToggler: false})
        } else {
            this.setState({screenResizeToggler: true})
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;
        const userSpeakingVolume = Math.round(100 + this.state.userSpeakingVolume);

        const audioInputSelect = this.state.audioInputSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const audioOutputSelect = this.state.audioOutputSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const videoSelect = this.state.videoSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const isVideoParticipantEnabled = 1;
        const isAudioParticipantEnabled = 1;

        let layoutStrategyClass = "";
        let layoutWrapStrategyClass = "grid lg:grid-cols-3 grid-cols-2 grid-rows-2 gap-2 p-2 w-full";

        let numberOfParticipants = Object.keys(this.state.participants).length;

        if (numberOfParticipants === 1) {
            numberOfParticipants = 2;
        }
        switch (numberOfParticipants) {
            case 0:
            case 1:
            case 2:
                layoutWrapStrategyClass = "grid lg:grid-cols-2 grid-cols-1 gap-2 p-2 w-full participants-2-width";
                break;
            case 3:
                layoutStrategyClass = "participants-3"
                layoutWrapStrategyClass = "grid lg:grid-cols-8 grid-cols-4 grid-rows-2 gap-2 p-2 participants-3-width";
                break;
            case 4:
                layoutStrategyClass = "participants-4"
                layoutWrapStrategyClass = "grid md:grid-cols-8 grid-cols-4 grid-rows-2 gap-2 p-2 participants-4-width";
                break;
            default:
                layoutStrategyClass = ""
        }

        let presenters = Object.values(this.state.participants)
            .map((participant, i) => {
                const slider = document.querySelector(".conference-participants");
                let isDown = false;
                let startX;
                let scrollLeft;
                let presentersLength = Object.values(this.state.participants).filter(participant => (participant.role == 2))
                presentersLength = presentersLength.length + 1
                if (slider) {
                    slider.addEventListener("mousedown", e => {
                        isDown = true;
                        slider.classList.add("active");
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    });
                    slider.addEventListener("mouseleave", () => {
                        isDown = false;
                        slider.classList.remove("active");
                    });
                    slider.addEventListener("mouseup", () => {
                        isDown = false;
                        slider.classList.remove("active");
                    });
                    slider.addEventListener("mousemove", e => {
                        if (!isDown) return;
                        e.preventDefault();
                        const x = e.pageX - slider.offsetLeft;
                        const walk = x - startX;
                        slider.scrollLeft = scrollLeft - walk;
                    });
                }
                let list = document.getElementById("chat-wrapper");
                if (list) {
                    list.scrollTop = list.offsetHeight;
                }

                return (
                    <div
                        className={"absolute inset-0 rounded-md overflow-hidden" + (!!this.state.speakingMap[participant.name] ? " bg-primary-600" : " bg-white")}>
                        <div id={participant.name}
                             className={"absolute inset-0 border-4 " + (!!this.state.speakingMap[participant.name] ? " border-primary-600" : " border-white")}>
                            <video
                                id={participant.name}
                                className={"rounded-md object-cover h-full w-full bg-gray-600 " + (document.fullscreenElement ? " " : "") + ((!!this.state.screenResizeToggler && presentersLength > 1) ? "video-half-row" : "video-whole-row")}
                                onClick={() => {
                                    this.debug(participant.name);
                                }}
                                muted
                                playsInline autoPlay
                                ref={(el) => this.addToVideoRef(el, participant.name)}
                            >

                            </video>

                            {!participant.camera && (
                                <div
                                    className={"user-cover absolute"}
                                >
                                    <div
                                        className="w-32 h-32 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                        <div
                                            className="text-inverse font-black">{(participant.name).split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                    </div>
                                </div>
                            )}

                            {!!this.state.speakingMap[participant.name] && (
                                <div
                                    className="voice-container absolute top-2 left-2 bg-primary-600 w-8 h-8 rounded-full"
                                    style={{bottom: document.fullscreenElement ? "65px" : "50px"}}>
                                    <div className="voice-animation"></div>
                                </div>
                            )}

                            <audio
                                playsInline autoPlay
                                ref={(el) => this.addToAudioRef(el, participant.name)}/>

                            <div
                                className="absolute text-xl text-center inset-x-0 bottom-0 text-white mb-2">{participant.name}</div>

                            <div className="absolute top-2 right-2"
                                 onClick={() => this.fullScreenToggle(participant.name)} id="fullscreenbtn">
                                {document.fullscreenElement ?
                                    <ArrowsExpandSolidIcon
                                        className="w-8 h-8 p-1.5 text-white hover:bg-secondary-50 rounded-full"/>
                                    :
                                    <ArrowsExpandIcon
                                        className="w-8 h-8 p-1.5 text-white hover:bg-secondary-700 rounded-full"/>
                                }
                            </div>
                        </div>
                        
                        <div className="inside-btn">
                            {!participant.mic && (
                                <div className="relative w-10 h-10">
                                    <MicrophoneIcon
                                        className="absolute top-2 left-2 w-4 h-4 text-gray-400 rounded-full"/>
                                    <BanIcon className="absolute w-8 h-8 text-white rounded-full"/>
                                </div>
                            )}

                            {!participant.camera && (
                                <div className="relative w-10 h-10">
                                    <VideoCameraIcon
                                        className="absolute top-2 left-2 w-4 h-4 text-gray-400 rounded-full"/>
                                    <BanIcon className="absolute w-8 h-8 text-white rounded-full"/>
                                </div>
                            )}
                        </div>
                    </div>
                )
            });

        if (presenters.length === 0) {
            const waiting = (
                <div
                    className="absolute inset-0">
                    {/*participant-wrapper*/}

                    <div className="border-4 rounded-lg absolute top-0 w-full h-full border-white bg-secondary-100">
                        <video
                            onClick={() => {

                            }}
                            muted
                            playsInline autoPlay
                        >
                        </video>
                    </div>
                    <img
                        className={"video-poster-logo absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2"}
                        alt={"logo"}
                        src={"/images/logos/logo.png"}
                    />

                    <div
                        className="text-sm sm:text-base xl:text-xl mt-1 mb-2 text-center text-secondary-800 video-name-presenter">Waiting
                        on user
                    </div>
                </div>
            );

            // Testing
            for (let i = 1; i < numberOfParticipants; i++) {
                presenters.push(waiting)
            }

        }
        const chats = this.state.chat.map((it, i) => {
            let message = it.msg;

            // URL validation
            if (ifContainsURL(message)) {
                let tmp = message.split(" ")
                for (let i = 0; i < tmp.length; i++) {
                    if (isValidURL(tmp[i].trim())) {
                        if (tmp[i].includes("http://")) {
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        } else if (tmp[i].includes("https://")) {
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        } else {
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="http://${tmp[i]}">${tmp[i]}</a>`)
                        }
                    }
                }
                message = tmp.join(" ")
            }

            // Render result
            return (
                <div className="chat-message-single" key={i}>
                    <div className="chat-user"><span className="chat-username-style">{it.name}</span>: <span
                        className="chat-msg"
                        dangerouslySetInnerHTML={{__html: message}}/></div>
                </div>
            )
        });

        const canRecord = this.props.resource?.data?.Record && (Object.keys(this.state.participants).length === 2);

        const docs = getProp(this.props, "secondResource.data.list", []).map((it) => {
            return (<div
                onClick={() => this.handleDocumentViewModal(it)}
                className={"border border-b-secondary-200 p-1 break-words mb-1 hover:cursor-pointer"}>{it.Description}</div>)
        });

        const notes = this.state?.notes.map((it) => {
            return (<div>{it.Note}</div>)
        });

        return (
            <PublicLayout {...this.props} isVideoRoom={!!this.state.joinedRoom}>
                <main className="pb-16 relative height-video bg-inverse">

                    {/**** Access denied screen ***/}
                    {this.state.accessDenied && (
                        <h2>Access denied</h2>
                    )}

                    {/**** Pre conference screen ***/}
                    {!this.state.joinedRoom && (
                        <div className={"justify-center"}>

                            <div className="pt-4 pb-4 relative">
                                <div className="m-auto text-center relative " style={{width: 480}}>
                                    {!!LocalStorage.get('user') && (
                                    <h4 className={"text-xl"}>Welcome, {this.getName()}</h4>
                                    )}

                                    {!LocalStorage.get('user') && (
                                        <>
                                            <h4 className={"text-xl"}>Welcome</h4>
                                            <div
                                                className="sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5">
                                                <p className={"text-left mb-2"}>
                                                    Please tell us your name
                                                </p>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <FieldText
                                                        name={"username"}
                                                        className={"h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"}
                                                        onChange={(name, value) => {
                                                            this.setState({
                                                                username: value
                                                            })
                                                        }}
                                                        value={this.state.username}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {!!this.state.speaking && (
                                    <div className='voice-container absolute'>
                                        <div className="voice-animation"></div>
                                    </div>
                                )}
                            </div>

                            {isVideoParticipantEnabled == 0 && (
                                <div style={{width: "100%", textAlign: "center"}}>
                                    <span
                                        style={{textAlign: "center"}}>{translate("text.participantVideoNotEnabled")}</span>
                                </div>
                            )}

                            <div className="m-auto mt-10 " style={{width: 480}}>
                                <p className="mb-2">Microphone preview:</p>

                                <div className="shadow w-full bg-secondary-50 rounded-md">
                                    <div
                                        className={"text-xs leading-none py-1 text-center text-white rounded transition-all transition-slowest ease" + (userSpeakingVolume > 70 ? " bg-red-600" : " bg-primary-600")}
                                        style={{width: userSpeakingVolume > 30 ? userSpeakingVolume + "%" : 0 + "%"}}>{userSpeakingVolume}%
                                    </div>
                                </div>
                            </div>

                            <div className="m-auto mt-10 mb-2" style={{width: 480}}>
                                Video preview:
                            </div>

                            <div className="w-chat-room mx-auto">
                                <div className="relative video-ratio-3:4 rounded-md overflow-hidden">
                                    <video
                                        className={"preview-video bg-secondary-100 h-full w-full absolute top-0"}
                                        disablePictureInPicture
                                        playsInline
                                        muted={true}
                                        autoPlay
                                        ref={this.setupVideoRef}
                                    />
                                </div>
                            </div>

                            {!!this.props.resource.isLoading && (
                                <div className={"items-center p-4 m-auto text-center mt-10 "}>
                                    <Loader className={"items-center p-4 m-auto"}/>
                                </div>
                            )}

                            {!this.props.resource.isLoading && (
                                <div className="m-auto text-center mt-10 " style={{width: 480}}>

                                    {!this.state.cameraPermission && (
                                        <div
                                            onClick={() => this.toggleAudioVideoInfo()}
                                            className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md text-left cursor-pointer mb-5"
                                            role="alert">
                                            <div className="flex">
                                                <div className="py-1">
                                                    <CameraIcon className="w-6 w-6 mr-4 fill-current"/>
                                                </div>
                                                <div>
                                                    <p className="font-bold">You did not allow browser access to your
                                                        camera</p>
                                                    <p className="text-sm">Click here for more info on how to turn on
                                                        your camera</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!this.state.microphonePermission && (
                                        <div
                                            onClick={() => this.toggleAudioVideoInfo()}
                                            className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md text-left cursor-pointer"
                                            role="alert">
                                            <div className="flex">
                                                <div className="py-1">
                                                    <MicrophoneIcon className="w-6 w-6 mr-4 fill-current"/>
                                                </div>
                                                <div>
                                                    <p className="font-bold">You did not allow browser access to your
                                                        microphone</p>
                                                    <p className="text-sm">Click here for more info on how to turn on
                                                        your microphone</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className='mb-5'>
                                        <Button
                                            onClick={this.handleEnterRoom}
                                            className="w-full justify-center mt-5 mb-5"
                                            disabled={!LocalStorage.get('user') && !this.state.username}
                                            type="primary">
                                            {translate("text.enter_room")}
                                        </Button>
                                    </div>

                                    {this.state.videoAvailable && (
                                        <React.Fragment>
                                            {isVideoParticipantEnabled == 1 && (
                                                <button
                                                    className={(!this.state.camera ? "bg-gray-400 hover:bg-gray-500 " : "bg-primary-700 hover:bg-primary-800 ") + (" m-auto rounded-full inline-block p-6 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                                    title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                    onClick={this.cameraToggle}>
                                                    {!this.state.camera ? <CameraIcon className="h-6 w-6 text-white"/> :
                                                        <CameraIcon className="h-6 w-6 text-white"/>}
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {this.state.audioAvailable && (
                                        <React.Fragment>
                                            {isAudioParticipantEnabled == 1 && (
                                                <button
                                                    className={(!this.state.mic ? "bg-gray-400 hover:bg-gray-500 " : "bg-primary-700 hover:bg-primary-800 ") + (" m-auto rounded-full inline-block p-6 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                                    title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                    onClick={this.micToggle}>
                                                    {!this.state.mic ?
                                                        <MicrophoneIcon className="h-6 w-6 text-white"/> :
                                                        <MicrophoneIcon className="h-6 w-6 text-white"/>}
                                                </button>
                                            )}
                                        </React.Fragment>
                                    )}

                                    <button
                                        className="bg-primary-700 hover:bg-primary-800 m-auto rounded-full inline-block p-6 ml-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        title={translate("text.more_options")}
                                        onClick={this.optionsToggle}
                                    >
                                        <DotsVerticalIcon className="h-6 w-6 text-white"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {/**** Loading Conference screen ***/}
                    {!!this.state.loadingRoom && (
                        <div className={"items-center p-4 m-auto text-center"}>
                            <Loader className={"items-center p-4 m-auto"}/>
                        </div>
                    )}

                    {/**** Conference screen ***/}
                    {this.state.joinedRoom && !this.state.accessDenied && (
                        <React.Fragment>
                            {!this.state.loadingRoom && (
                                <React.Fragment>

                                    <div
                                        className={"videos-main-wrap " + (!!this.state.sidebarContent ? "  " : " full-width-video ")}>


                                        {/*<div*/}
                                        {/*    className={"relative  " + (layoutStrategyClass)}>*/}
                                        <div className="mb-16 mx-2 mt-2">
                                            <PackedGrid
                                                className="video-grid-height w-full"
                                                boxClassName="relative"
                                                boxAspectRatio={1.33}
                                            >

                                                {/*/!* + layoutWrapStrategyClass*!/*/}

                                                {/*    /!*My Video*!/*/}

                                                <div
                                                    className={"absolute inset-0 rounded-md overflow-hidden" + (!!this.state.speaking ? " bg-primary-600" : " bg-white")}>
                                                    <div id={"presenter"}
                                                         className={"absolute inset-0 border-4 " + (!!this.state.speaking ? " border-primary-600" : " border-white")}>
                                                        <video
                                                            onClick={() => this.debug(this.getName())}
                                                            disablePictureInPicture
                                                            playsInline muted autoPlay ref={this.myVideoRef}
                                                            className={"rounded-md object-cover h-full w-full bg-gray-600"}
                                                        >
                                                        </video>

                                                        {(!this.state.camera || !this.state.videoAvailable) && (
                                                            <div
                                                                className={"user-cover absolute"}
                                                            >
                                                                <div
                                                                    className="w-32 h-32 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                                                    <div
                                                                        className="text-inverse font-black">{(this.getName()).split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {!!this.state.speaking && (
                                                            <div
                                                                className="voice-container absolute top-2 left-2 bg-primary-600 w-8 h-8 rounded-full"
                                                                style={{bottom: document.fullscreenElement ? "65px" : "50px"}}>
                                                                <div className="voice-animation"></div>
                                                            </div>
                                                        )}

                                                        <div
                                                            className={"absolute text-xl text-center inset-x-0 bottom-0 text-white mb-2"}
                                                        >
                                                            {this.getName()}
                                                        </div>

                                                        <div
                                                            className="conference-my-controls flex place-content-between z-10">
                                                            <div className="w-80 hidden lg:flex"/>

                                                            <div className="m-auto text-center ">
                                                                <React.Fragment>
                                                                    <div className="conf-icon-btn align-top" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        padding: "5px"
                                                                    }}
                                                                         title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                         onClick={this.optionsToggle}
                                                                    >
                                                                        <div
                                                                            className="rounded-full flex items-center justify-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                height: "40px",
                                                                                background: "#524e4f"
                                                                            }}>
                                                                            <DotsVerticalIcon
                                                                                className={"w-6 h-6 text-white"}/>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>

                                                                {this.state.videoAvailable && (
                                                                    <React.Fragment>

                                                                        <div className="conf-icon-btn"
                                                                             title={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                                             onClick={this.screenShareToggle}>
                                                                            <img
                                                                                alt={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                                                src={!this.state.screenSharing ? ("/images/conf/stop_share_screen_new.png") : ("/images/conf/share_screen_new.png")}/>
                                                                        </div>

                                                                        {isVideoParticipantEnabled == 1 && (
                                                                            <div className="conf-icon-btn"
                                                                                 title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                                 onClick={this.cameraToggle}>
                                                                                <img
                                                                                    alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                                    src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}

                                                                {this.state.audioAvailable && (
                                                                    <React.Fragment>
                                                                        {isAudioParticipantEnabled == 1 && (
                                                                            <div className="conf-icon-btn"
                                                                                 title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                                 onClick={this.micToggle}>
                                                                                <img
                                                                                    alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                                    src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                                            </div>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}

                                                                <React.Fragment>
                                                                    <div className="conf-icon-btn align-top" style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                        padding: "5px"
                                                                    }}
                                                                         title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                         onClick={this.toggleAddParticipantDialog}>
                                                                        <div
                                                                            className="rounded-full flex items-center justify-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                height: "40px",
                                                                                background: "#524e4f"
                                                                            }}>
                                                                            <PlusSmIcon
                                                                                className={"w-6 h-6 text-white"}/>
                                                                        </div>

                                                                    </div>
                                                                </React.Fragment>
                                                            </div>

                                                            <div className="items-center justify-end flex lg:hidden">
                                                                <MobileDropdown
                                                                    candidatePresenter={this.state.candidatePresenter}
                                                                    sidebarContent={this.state.sidebarContent}
                                                                    toggleViewRightBar={this.toggleViewRightBar}
                                                                    translate={translate}
                                                                />
                                                            </div>

                                                            <div
                                                                className="w-80 items-center justify-end hidden lg:flex">
                                                                <buttons
                                                                    title={this.state.candidatePresenter ? translate("text.go_primary_window") : translate("text.go_small_window")}
                                                                    className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2"
                                                                >
                                                                    <ArrowsExpandIcon className="w-5 h-5"/>
                                                                </buttons>

                                                                <buttons
                                                                    title={this.state.sidebarContent === 'chat' ? translate("text.hide_chat") : translate("text.show_chat")}
                                                                    onClick={() => this.toggleViewRightBar(this.state.sidebarContent === 'chat' ? false : "chat")}
                                                                    className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2"
                                                                >
                                                                    <AnnotationIcon className="w-5 h-5"/>
                                                                </buttons>

                                                                {LocalStorage.get('user') && (
                                                                    <>
                                                                        <buttons
                                                                            title={this.state.sidebarContent === 'notes' ? translate("text.hide_notes") : translate("text.show_notes")}
                                                                            onClick={() => this.toggleViewRightBar(this.state.sidebarContent === 'notes' ? false : "notes")}
                                                                            className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2"
                                                                        >
                                                                            <PencilAltIcon className="w-5 h-5"/>
                                                                        </buttons>

                                                                        {canRecord && (
                                                                            <buttons
                                                                                title={this.state.sidebarContent === 'recordings' ? "Hide Recordings" : "View Recordings"}
                                                                                onClick={() => this.toggleViewRightBar(this.state.sidebarContent === 'recordings' ? false : "recordings")}
                                                                                className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2"
                                                                            >
                                                                                <VideoCameraIcon className="w-5 h-5"/>
                                                                            </buttons>
                                                                        )}

                                                                        <buttons
                                                                            title={this.state.sidebarContent === 'documents' ? translate("text.hide_documents") : translate("text.show_documents")}
                                                                            onClick={() => this.toggleViewRightBar(this.state.sidebarContent === 'documents' ? false : "documents")}
                                                                            className="block text-white w-10 h-10 hover:bg-primary-500 rounded-full flex justify-center items-center cursor-pointer mx-2"
                                                                        >
                                                                            <DocumentTextIcon className="w-5 h-5"/>
                                                                        </buttons>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/*  Other Videos* */}
                                                {presenters}
                                            </PackedGrid>
                                        </div>
                                    </div>


                                    {!!this.state.sidebarContent && (
                                        <div className="chat-container w-80">
                                            {/* CHAT SIDEBAR */}
                                            {this.state.sidebarContent === 'chat' && (
                                                <React.Fragment>
                                                    <div
                                                        className="mx-1 mt-1 mb-3 font-bold flex justify-between items-center border-b-2 border-primary-600">
                                                        <div
                                                            className="flex-1 p-2 text-secondary-500 uppercase">Messages
                                                        </div>

                                                        <button
                                                            className="w-8 h-8 flex justify-center items-center hover:bg-secondary-100 rounded-full cursor-pointer"
                                                            onClick={() => this.toggleViewRightBar(false)}
                                                        >
                                                            <XIcon className="w-5 h-5 text-secondary-500"/>
                                                        </button>
                                                    </div>

                                                    <div className="chat-wrapper mx-1 mb-3 rounded-md"
                                                         id="chat-wrapper">
                                                        {(!!chats && chats.length < 1) && (
                                                            <p>{translate("text.chat_is_empty")}</p>
                                                        )}
                                                        {chats}
                                                    </div>

                                                    <div className="chat-send-message mx-1"
                                                         style={{position: "relative"}}>
                                                        <FieldTextarea
                                                            className=" rounded-md mb-3"
                                                            onClick={() => this.setState({emojisToggler: false})}
                                                            onKeyDown={event => {
                                                                if (event.keyCode == 9 || event.keyCode == 38 || event.keyCode == 40) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder={translate("text.write_something")}
                                                            value={this.state.chatValue}
                                                            onChange={(name, value) => {
                                                                this.setState({
                                                                    chatValue: value
                                                                });
                                                            }}
                                                        />

                                                        {this.state.chatValue == "" ?
                                                            <Button
                                                                onClick={null}
                                                                className="justify-center w-full"
                                                                type="primary">
                                                                <ChatIcon className={"w-f h-8 text-white mr-2"}/>
                                                                {translate("text.send_message")}
                                                            </Button>
                                                            :
                                                            <Button
                                                                onClick={() => {
                                                                    if (this.state.chatValue?.trim() !== "") {
                                                                        this.sendChat(this.state.chatValue);
                                                                        this.setState({
                                                                            chatValue: ""
                                                                        })
                                                                    }
                                                                }}
                                                                className="justify-center w-full"
                                                                type="primary">
                                                                <ChatIcon className={"w-8 h-8 text-gray mr-2"}/>
                                                                {translate("text.send_message")}
                                                            </Button>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {this.state.sidebarContent === 'recordings' && (
                                                <React.Fragment>
                                                    <div
                                                        className="mx-1 mt-1 mb-3 font-bold flex justify-between items-center border-b-2 border-primary-600">
                                                        <div
                                                            className="flex-1 p-2 text-secondary-500 uppercase">Recordings
                                                        </div>

                                                        <button
                                                            className="w-8 h-8 flex justify-center items-center hover:bg-secondary-100 rounded-full cursor-pointer"
                                                            onClick={() => this.toggleViewRightBar(false)}
                                                        >
                                                            <XIcon className="w-5 h-5 text-secondary-500"/>
                                                        </button>
                                                    </div>

                                                    <div className={"video-wrap-recordings"}>
                                                        {!!LocalStorage.get('user') && (
                                                            <div className={"mt-2"}>
                                                                <div className="ml-6 mr-6">
                                                                    <div className="">
                                                                        {!this.state.recording && (
                                                                            <Button
                                                                                onClick={!this.state.recordingSaveLoading && this.startRecording}
                                                                                className="justify-center w-full mb-2"
                                                                                type="primary">
                                                                                <VideoCameraIcon
                                                                                    className={"w-f h-8 text-white mr-2"}/>
                                                                                {translate("text.start_recording")}
                                                                            </Button>
                                                                        )}

                                                                        {this.state.recording && (
                                                                            <Button
                                                                                onClick={this.stopRecording}
                                                                                className="justify-center w-full mb-2"
                                                                                type="primary">
                                                                                <StopIcon
                                                                                    className={"w-f h-8 text-white mr-2"}/>
                                                                                {translate("text.stop_recording")}
                                                                            </Button>
                                                                        )}
                                                                    </div>

                                                                    {!this.state.recording && (
                                                                        <React.Fragment>
                                                                            <div>
                                                                                Enter note for the record:
                                                                            </div>
                                                                            <FieldText
                                                                                onChange={(name, value) => this.setState({recordingNote: value})}
                                                                                value={this.state.recordingNote}
                                                                            />
                                                                            <div
                                                                                className={"text-red-700 italic mt-1 text-sm"}>
                                                                                {!this.state.emptyNote ? "" : "Please fill out this field."}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}

                                                                    <h3 className={"mt-2 text-xl font-bold"}>Recordings
                                                                        list:</h3>

                                                                    {(this.state.recordingList.length > 0) && (
                                                                        <div className="recordings-table">
                                                                            <table
                                                                                className="min-w-full divide-y divide-gray-200">
                                                                                <thead className="bg-gray-50">
                                                                                <tr>
                                                                                    <th scope="col"
                                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                        Time
                                                                                    </th>
                                                                                    <th scope="col"
                                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                                        Note
                                                                                    </th>
                                                                                </tr>
                                                                                </thead>
                                                                                <tbody
                                                                                    className="bg-white divide-y divide-gray-200">
                                                                                {this.state.recordingList.map((it) => {
                                                                                    return (
                                                                                        <tr className="bg-white">
                                                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                                                {it.time / 1000}s
                                                                                            </td>
                                                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                                                {it.note}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )}

                                                                    {(this.state.recordingList.length === 0) && (
                                                                        <div className="mt-4">No recordings, click
                                                                            record button to start.</div>
                                                                    )}

                                                                    {(this.state.recordingList.length !== 0) && !this.state.recording && !this.state.recordingSaveLoading && (
                                                                        <Button
                                                                            onClick={this.finishRecording}
                                                                            className="justify-center mt-4 mb-4 w-full"
                                                                            type="primary">
                                                                            <PlayIcon
                                                                                className={"w-f h-8 text-white mr-2"}/>
                                                                            {translate("text.finish_recording")}
                                                                        </Button>
                                                                    )}
                                                                    {this.state.recordingSaveLoading && (
                                                                        <LoaderSmall/>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}

                                            {this.state.sidebarContent === 'documents' && (
                                                <React.Fragment>
                                                    <div
                                                        className="mx-1 mt-1 mb-3 font-bold flex justify-between items-center border-b-2 border-primary-600">
                                                        <div
                                                            className="flex-1 p-2 text-secondary-500 uppercase">Documents
                                                        </div>

                                                        <button
                                                            className="w-8 h-8 flex justify-center items-center hover:bg-secondary-100 rounded-full cursor-pointer"
                                                            onClick={() => this.toggleViewRightBar(false)}
                                                        >
                                                            <XIcon className="w-5 h-5 text-secondary-500"/>
                                                        </button>
                                                    </div>
                                                    {!!this.props.secondResource.isLoading && (
                                                        <LoaderSmall/>
                                                    )}

                                                    {docs}
                                                    {!this.props.secondResource.isLoading && (!docs || docs.length === 0) && (
                                                        <p>No documents available for the candidate.</p>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {this.state.sidebarContent === 'notes' && (
                                                <React.Fragment>
                                                    <div
                                                        className="mx-1 mt-1 mb-3 font-bold flex justify-between items-center border-b-2 border-primary-600">
                                                        <div className="flex-1 p-2 text-secondary-500 uppercase">Notes
                                                        </div>

                                                        <button
                                                            className="w-8 h-8 flex justify-center items-center hover:bg-secondary-100 rounded-full cursor-pointer"
                                                            onClick={() => this.toggleViewRightBar(false)}
                                                        >
                                                            <XIcon className="w-5 h-5 text-secondary-500"/>
                                                        </button>
                                                    </div>

                                                    {notes}

                                                    {(!notes || notes.length === 0) && (
                                                        <p className={"mb-1"}>No notes, maybe write one?</p>
                                                    )}

                                                    <div className="chat-send-message mx-1 mt-1"
                                                         style={{position: "relative"}}>
                                                        <FieldTextarea
                                                            className=" rounded-md mb-3"
                                                            onClick={() => this.setState({emojisToggler: false})}
                                                            onKeyDown={event => {
                                                                if (event.keyCode == 9 || event.keyCode == 38 || event.keyCode == 40) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder={translate("text.write_something")}
                                                            value={this.state.noteValue}
                                                            onChange={(name, value) => {
                                                                this.setState({
                                                                    noteValue: value
                                                                });
                                                            }}
                                                        />

                                                        {this.state.noteValue == "" ?
                                                            <Button
                                                                onClick={null}
                                                                className="justify-center w-full"
                                                                type="primary">
                                                                <ChatIcon className={"w-f h-8 text-white mr-2"}/>
                                                                {translate("text.set_note")}
                                                            </Button>
                                                            :
                                                            <Button
                                                                onClick={() => {
                                                                    if (this.state.noteValue?.trim() !== "") {
                                                                        let notes = this.state.notes;
                                                                        notes.push({
                                                                            Note: this.state.noteValue
                                                                        });
                                                                        axios.post(
                                                                            Env.getApiUrl('api/' + Resources.MeetingNotes),
                                                                            {
                                                                                Note: this.state.noteValue,
                                                                                MeetingID: this.getId()
                                                                            },
                                                                            {
                                                                                headers: {
                                                                                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                                                }
                                                                            }
                                                                        )
                                                                        this.setState({
                                                                            noteValue: "",
                                                                            notes: notes
                                                                        }, () => {
                                                                        });
                                                                    }
                                                                }}
                                                                className="justify-center w-full"
                                                                type="primary">
                                                                <ChatIcon className={"w-8 h-8 text-gray mr-2"}/>
                                                                {translate("text.set_note")}
                                                            </Button>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}

                </main>


                <DialogDefault
                    title={translate("text.audio_video")}
                    widthClass={"max-w-max"}
                    visible={this.state.isOptionsDialogVisible}
                    onClose={this.optionsToggle}
                    translate={translate}
                >
                    <div className="m-auto pb-5">
                        <div className="mt-5">
                            <h5>{translate("text.choose_mic")}</h5>
                            {isAudioParticipantEnabled == 0 && (
                                <span style={{}}>{translate("text.participantAudioNotEnabled")}</span>
                            )}
                            {!this.state.microphonePermission ?
                                <span
                                    style={{color: "red"}}>You did not allow browser access to your microphone.</span> : ""}
                            <FieldSelect
                                className={"form-control box-border"}
                                name={"audioInputSelect"}
                                value={this.state.configuration.audioInputSelect}
                                onChange={this.updateSetupStreamMedia}
                            >{audioInputSelect}
                            </FieldSelect>
                        </div>

                        <div className="mt-5">
                            <h5>{translate("text.choose_speaker")}</h5>
                            <FieldSelect
                                className={"form-control box-border"}
                                name={"audioOutputSelect"}
                                value={this.state.configuration.audioOutputSelect}
                                onChange={this.updateSetupStreamMedia}
                            >{audioOutputSelect}
                            </FieldSelect>
                        </div>

                        <div className="mt-5">
                            <h5>{translate("text.choose_camera")}</h5>
                            {!this.state.cameraPermission ? <span style={{color: "red"}}>You did not allow browser access to your camera</span> : ""}
                            <FieldSelect
                                className={"form-control box-border"}
                                name={"videoSelect"}
                                value={this.state.configuration.videoSelect}
                                onChange={this.updateSetupStreamMedia}
                            >{videoSelect}
                            </FieldSelect>
                        </div>
                    </div>
                </DialogDefault>


                {!!this.state.isAudioVideoOverlayVisible && (
                    <div
                        onClick={() => this.toggleAudioVideoInfo()}
                        className="fixed inset-0 bg-gray-600 bg-opacity-80 transition-opacity z-20 text-white text-center"
                    >
                        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                            <button
                                type="button"
                                className="bg-transparent rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                            >
                                <span className="sr-only">{translate("text.close")}</span>
                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>

                        <div className="pt-36 flex flex-col">
                            <div className="w-max mx-auto mb-6">
                                <img src="/images/chat/audio_video_settings.png" alt=""/>
                            </div>

                            <span className="text-left mx-auto">
                                <p className="text-center text-lg mb-6">
                                    Your
                                    {!this.state.cameraPermission ? " camera " : ""}
                                    {!this.state.cameraPermission && !this.state.microphonePermission ? " and " : ""}
                                    {!this.state.microphonePermission ? " microphone " : ""}
                                    {!this.state.cameraPermission && !this.state.microphonePermission ? " are " : " is "}
                                    blocked.
                                </p>
                                <p className="mb-3">To use Goleir chat room:</p>

                                <ul className="text-sm">
                                    <li>1. Click on the camera blocked icon in your browser's address bar</li>
                                    <li>2. Allow access and then refresh the page</li>
                                </ul>
                            </span>
                        </div>
                    </div>
                )}

                <DialogDefault
                    title={translate("text.add_participant")}
                    widthClass={"w-6/12"}
                    visible={this.state.isAddDialogVisible}
                    onClose={this.toggleAddParticipantDialog}
                    translate={translate}
                >
                    <p>To add new person to a call, send them following link:</p>
                    <p>{window.location.origin + "/room/" + this.getId() + "/" + this.getTempToken()}</p>
                </DialogDefault>

                {LocalStorage.get('user') && (
                    <ModalViewDocument
                        title={"View Document"}
                        className="max-w-2xl"
                        visible={this.state.viewDocumentModalOpen}
                        onClose={this.handleDocumentViewModal}
                        translate={this.props.translate}
                        fileType={getProp(this.state, "selectedItem.OriginalFileName", "") ? getProp(this.state, "selectedItem.OriginalFileName", "").split(".").pop() : ""}
                        filePath={Env.getApiUrl('api/' + Resources.CandidateDocuments, {
                            CandidateDocumentID: this.state.selectedItem?.CandidateDocumentID,
                            token: LocalStorage.get('user').access_token
                        })}
                        onDownload={() => {
                            this.props.dispatch(downloadFile({
                                user: LocalStorage.get('user'),
                                query: {
                                    CandidateDocumentID: this.state.selectedItem?.CandidateDocumentID,
                                    name: this.state.selectedItem.OriginalFileName
                                },
                                resource: Resources.CandidateDocuments
                            }))
                        }}
                    />
                )}
            </PublicLayout>
        )
    }
}

export default connect(state => state)(RoomPage);