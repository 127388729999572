import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    DEFAULT_CRUD_STATE,
    fillFieldsFromData,
    getLookup,
    getProp,
} from "../../util/util";
import {Field} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import {
    PencilIcon,
    PlusIcon,
} from "@heroicons/react/solid";
import {ArchiveIcon} from "@heroicons/react/outline";
import Button from "../../components/button";

class ExtClientContactsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "ContactName",

            columnFilters: {},

            fields: this.getFields('', ['CandidateID', 'ContactID']),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
            userModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    };

    handleToggleCreateContactModal = () => {
        this.setState({
            createContactModelOpen: !this.state.createContactModelOpen
        })
    };

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    };

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    };

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ClientContactID";
    };

    getResourceName = () => {
        return Resources.ExtClientContacts;
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    };

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ContactName: new Field("ContactName", '', ['empty'], false, "text"),
            RoleTitleID: new Field("RoleTitleID", '', ['empty'], false, "select-search", {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            Email: new Field("Email", '', ['empty'], false, "text"),

            CandidateID: new Field("CandidateID", item ? item.ContactName : "", ['empty'], false, 'select-search'),
            ContactID: new Field("ContactID", '', ['empty'], false, 'select-search'),
            ContactNote: new Field("ContactNote", '', [''], false, "textarea"),
            IsBillingContact: new Field("IsBillingContact", '', [], false, 'checkbox')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getFieldsContact = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            TitleID: new Field("TitleID", item?item.RoleTitle:'', ['empty'], false, 'select-search'),

            Address1: new Field("Address1", '', [], false, 'text'),
            Address2: new Field("Address2", '', [], false, 'text'),

            City: new Field("City", '', [], false, 'text'),
            StateID: new Field("StateID", '', [], false, 'select'),
            PostalCode: new Field("PostalCode", '', [], false, 'text'),

            Email: new Field("Email", '', ['empty'], false, 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            ContactNote: new Field("ContactNote", '', [''], false, "textarea"),
            IsBillingContact: new Field("IsBillingContact", '', [], false, 'checkbox')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            TitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            }
        };

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.contacts")}
                    >
                    </PageHeader>

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">

                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                className="w-full"
                                classNameContainer={"md:mr-6 mb-3 w-full md:w-auto"}
                            />

                            <div className="ml-auto mb-3">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateContactModal}
                                    className="btn btn-primary focus:ring-offset-inverse ml-6"
                                >
                                    <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.create")}
                                </button>
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onCustomAction={(it) => {
                                return (
                                    <>
                                        <button
                                            type="button"
                                            title={translate("btn.edit")}
                                            onClick={() => this.handleToggleEditModel(it)}
                                            className="mr-2 my-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                        >
                                            <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                        aria-hidden="true"/>
                                            <span className="hidden md:inline">{translate("btn.edit")}</span>
                                        </button>

                                        {!it.ContactID && (
                                        <Button
                                            type="button"
                                            onClick={() =>  this.handleToggleConfirmModal(it)}
                                            className={"cursor-pointer ml-2 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"}
                                        >
                                            <ArchiveIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                         aria-hidden="true"/>

                                            {translate("Delete")}
                                        </Button>
                                        )}
                                    </>
                                )
                            }}
                        />
                    </div>

                    <ModalSaveResource
                        title={"Edit Contact"}
                        widthClass="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFieldsContact(this.state.selectedItem, ['CandidateID', 'ContactName', 'RoleTitleID', 'Email', 'ContactID'])}
                        onSubmit={(params) => {
                            if (params) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={"Create Contact"}
                        widthClass="max-w-lg"
                        visible={this.state.createContactModelOpen}
                        onClose={this.handleToggleCreateContactModal}
                        fields={this.getFieldsContact()}
                        onSubmit={(params) => {
                            if (params) {
                                params.CandidateID = params.ContactID;
                                delete params.ContactID;
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateContactModal(false);
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />



                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />

                </main>

            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ExtClientContactsPage);