import io from "socket.io-client";

export default class SocketAPI {

    socket;

    connect(host, token) {
        this.socket = io.connect(host, { auth: {
                token: token
            } });
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => resolve());
            this.socket.on('connect_error', (error) => reject(error));
        });
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                this.socket = null;
                resolve();
            });
        });
    }

    emit(event, data) {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject('No socket connection.');
            }

            return this.socket.emit(event, data, (response) => {
                // Response is the optional callback that you can use with socket.io in every request. See 1 above.
                if (response.error) {
                    console.error(response.error);
                    return reject(response.error);
                }

                return resolve();
            });
        });
    }

    on(event, fun) {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject('No socket connection.');
            }

            this.socket.on(event, fun);
            resolve();
        });
    }
}