import React, {Component} from 'react'
import {createResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {getProp, toFrontDateTime} from "../../util/util";
import Resources from "../../data/services/resources";
import socketIOClient from "socket.io-client";
import Env from "../../util/env";

export default class MessagesSmsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: this.props.sortBy ?? "",

            messageValue: ""
        };

        this.scrollRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchSMSData();
        this.initSocket();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.scrollToEnd();
        }

        if (prevProps.messages !== this.props.messages) {
            this.fetchSMSData();
        }
    }

    componentWillUnmount() {
        this.destroySocket();
    }

    initSocket = () => {
        console.log("SocketListener:initSocket");
        // Connect to socket, and authenticate
        this.socket = socketIOClient(Env.getWebsocketUrl(), {
            auth: {
                token: LocalStorage.get('user')?.access_token
            }
        });

        // On connection
        this.socket.on("connect", () => {
            console.log("Socket:connected");
        });

        this.socket.on("OnSMS", (data) => {
            this.fetchSMSData();
        });

    }

    destroySocket = () => {
        console.log("SocketListener:destroySocket");
        if (this.socket) {
            this.socket.disconnect();
            this.socket.close();
            this.socket = null;
        }
    }

    /** Data Events
     ================================================================= */
    fetchSMSData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId(),
                CandidateID: this.getId()
            },
            resource: Resources.CandidateMessageSMS
        }))
    };

    /** UI Events
     ================================================================= */
    handleSendMessage = () => {
        if (!this.state.messageValue) {
            return;
        }

        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                Message: this.state.messageValue,
                CandidateID: this.getId()
            },
            query: {
                id: this.getId(),
                CandidateID: this.getId()
            },
            resource: Resources.CandidateMessageSMS,
            piggyResource: Resources.CandidateMessageSMS
        }))

        this.setState({messageValue: ""}, this.scrollToEnd);
    };

    onEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleSendMessage();
        }
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    scrollToEnd = () => {
        const scrollEl = this.scrollRef.current
        if (!!scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        // const isLoading = getProp(this.props, "resource.isLoading", false);

        const smsList = getProp(this.props, "resource.data.list", []).map((it) => {
            return (
                <div className="w-full">
                    <div
                        className={`mx-4 my-2 w-3/4 ${it.ContactID ? "text-right webkit-text-right float-right" : " float-left"}`}>
                        <div
                            className={`p-2 rounded-lg break-all w-fit-content ${it.ContactID ? 'bg-gray-300' : 'bg-green-300'}`}>
                            <div
                                className='text-sm	font-medium	text-gray-600'>{it.ContactID ? "Me" : "Candidate"} - {toFrontDateTime(it.CreateUpdateDate)}</div>
                            {it.Message}
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div className="">
                <h2 className={"py-2 text-lg font-bold"}>SMS messages</h2>
                <div className=''>

                    <div className="h-50vh overflow-y-auto" ref={this.scrollRef}>
                        {smsList}
                    </div>

                    <div className="w-full flex justify-between">
                        <input name="messageValue"
                               autocomplete="off"
                               onKeyDown={(e) => this.onEnter(e)}
                               onChange={(e) => this.setState({messageValue: e.target.value})}
                               className="flex-grow m-2 py-2 px-4 mr-1 rounded-full border border-gray-300 bg-gray-200 resize-none focus:outline-none"
                               placeholder={translate("text.write_sms_something")}
                               value={this.state.messageValue}
                        />
                        <button
                            onClick={this.handleSendMessage}
                            className="m-2">
                            <svg
                                className="svg-inline--fa text-green-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="paper-plane"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
