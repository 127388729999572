import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";

let timezone = require('./timezone.json')
let dateTimeFormat = require('./datetimeformat.json')

export default class AccountTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            checkboxes: this.getCheckBoxes(),
            canSubmit: false,
            NotificationSetting: {}
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data.Contact", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {

                        let user = LocalStorage.get('user');
                        user.Contact.Timezone = this.state.fields.Timezone.value;
                        user.Contact.DateTimeFormat = this.state.fields.DateTimeFormat.value;
                        LocalStorage.set('user', user);

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get("user"),
                            params: Object.assign({NotificationSetting: Object.keys(this.state.NotificationSetting).map((it) => (
                                    {
                                        NotificationChannelID: it,
                                        NotificationLevelID: 1,
                                        Value: !!this.state.NotificationSetting[it] ? 1 : 0,
                                    }
                            ))}, FieldsManager.getFieldKeyValues(this.state.fields)),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                    });
                }
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data.Contact", null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    handleNotificationSettingChange = (id, val) => {
        let NotificationSetting = this.state.NotificationSetting;

        NotificationSetting[id] = val;

        this.setState({
            NotificationSetting: NotificationSetting,
            canSubmit: true
        })
    }

    handleCheckboxChange = (row, column, value) => {
        let checkboxes = this.state.checkboxes;
        checkboxes[row][column] = value;

        this.setState({
            checkboxes: checkboxes,
            canSubmit: true
        })
    }


    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.User;
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            FirstName: new Field('FirstName', "", ['empty']),
            LastName: new Field('LastName', "", ['empty']),
            Phone: new Field('Phone', "", []),
            Timezone: new Field('Timezone', "", ['empty'], false, 'select'),
            DateTimeFormat: new Field('DateTimeFormat', "", ['empty'], false, 'select')
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getCheckBoxes = (item = null) => {
        let checkboxes =  [
            {
                name: "Info",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0,
            },{
                name: "Notice",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0
            }, {
                name: "Important",
                notification: 0,
                email: 0,
                sms: 0,
                pushNotification: 0,
            }
        ];

        return item || checkboxes;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            Timezone: timezone,
            DateTimeFormat: dateTimeFormat
        };

        const notifications = [{id: 1, Name: "Local"}, {id: 2, Name: "Email"}, {id: 3, Name: "SMS"}].map((it) => {
            return (
                <div className="relative flex items-start mb-1">
                    <div className="flex items-center h-5">
                        <input
                            id={it.id}
                            name={it.id}
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={!!this.state?.NotificationSetting && !!this.state?.NotificationSetting[it.id] && (this.state.NotificationSetting[it.id] != "0")}
                            onChange={(event) => {
                                this.handleNotificationSettingChange(it.id, !!event.target.checked);
                            }}
                        />
                    </div>
                    <div className="ml-3 text-sm">
                        <label htmlFor={it.id} className="font-medium text-gray-700">
                            {it.Name}
                        </label>
                        <span id="comments-description" className="text-gray-500">
                            <span className="sr-only">{it.Name} </span>
                        </span>
                    </div>
                </div>
            );
        })

        const checkboxHeaders = Object.keys(this.state.checkboxes[0]).map((it, i) => (
            <div
                key={"table." + it}
                className={"hidden lg:flex text-sm font-bold text-secondary-500 text-center " + (i ? " justify-center" : "")}>{translate("table." + it)}
            </div>
        ));

        const checkboxes = this.state.checkboxes.map((it, i) => {
            let row = [];

            for (const [key, value] of Object.entries((it))) {
                if (key === 'name') {
                    row.push((
                        <div
                            key={it.name + "_" + key}
                            className="flex text-sm border-t border-dashed lg:border-none pt-5 lg:pt-0">
                            {value}
                        </div>
                    ))
                } else {
                    row.push((
                        <div
                            key={it.name + "_" + key}
                            className="flex lg:justify-center items-center"
                        >
                            <input
                                id={it.name + "_" + key}
                                type="checkbox"
                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded mr-3 cursor-pointer"
                                checked={!!value}
                                onChange={(e) => {
                                    this.handleCheckboxChange(i, key, !value);
                                }}
                            />

                            <label
                                className="lg:hidden text-sm font-medium text-secondary-700 cursor-pointer"
                                for={it.name + "_" + key}
                            >
                                {translate("table." + key)}
                            </label>
                        </div>
                    ))
                }
            }

            return row;
        })

        return (
            <div className="">
                {isLoading && (
                    <div className={"text-center"}>
                        <Loader/>
                    </div>
                )}

                {!isLoading && (
                    <>
                        <form className="space-y-8 divide-y divide-gray-200">
                            {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}

                            <fieldset className="space-y-5">
                                <legend className="sr-only">Notifications</legend>
                                <p className={"mb-1 mt-2 font-bold"}>Notification settings</p>
                                <p className="mb-1 text-sm text-secondary-500">Select on which channel(s) to receive notifications.</p>
                                {/*TODO: Cleanup, rename checkboxes to notifications and rename checkboxes to notifications*/}
                                {/*{notifications}*/}

                                <div className="container mx-auto">
                                    <div className="grid grid-cols-1 lg:grid-cols-4 lg:grid-cols-5 gap-6">
                                        {checkboxHeaders}
                                        {checkboxes}
                                    </div>
                                </div>

                            </fieldset>

                            <p className="m-3 text-sm text-secondary-500">* SMS notification will create additional charges.</p>
                        </form>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.onSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}
