import {delay, put, select, takeEvery} from "redux-saga/effects";
import {hideNotification} from "../actions/ui";

export const getMessagesCount = (state) => state.ui.messagesCount;
export const getMessageDuration = (state) => state.ui.messageDuration;

export function* pushNotificationCall() {
    let messageID = yield select(getMessagesCount);
    const messageDuration = yield select(getMessageDuration);

    if (!!messageDuration) { // 0 for infinite duration
        yield delay((messageDuration * 3000));
        yield put(hideNotification(messageID));
    }
}

export function* watchPushNotification() {
    yield takeEvery('PUSH_NOTIFICATION', pushNotificationCall);
}