import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {DEFAULT_CRUD_STATE, fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import ResourceTable from "../../components/simple-table";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";


class ClientContactsPage extends Component {

    constructor(props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const createDialog = query.get('createDialog');

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "ClientContact",

            columnFilters: {},

            fields: this.getFields(),

            cities: [],
            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: !!createDialog,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.ClientsContacts
        }));
    };


    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleSearchChange = (name, value) => {
        this.setState({
            search: FieldsManager.updateField(this.state.search, name, value),
            paginationPage: 1,
            offset: 0
        }, () => this.fetchData());
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    };

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ClientContactID";
    };

    getResourceName = () => {
        return Resources.ClientsContacts;
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    };

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ClientContact: new Field("ClientContact", '', ['empty'], false, 'text'),
            TitleID: new Field("TitleID", '', ['empty'], false, "select-search", {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            Email: new Field("Email", '', ['empty'], false, 'text'),
            IsBillingContact: new Field("IsBillingContact", '', [], false, 'checkbox'),
            ClientName: new Field("ClientName", '', [], false, 'text'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    {/* Page header */}
                    <PageHeader
                        title={translate("page.title.client-contacts")}
                    >
                    </PageHeader>

                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                className="w-full"
                                classNameContainer={"mr-6 mb-3 w-64"}
                            />
                        </div>

                        <ResourceTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={null}
                        />
                    </div>
                </main>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ClientContactsPage);
