import React, {Component} from 'react'
import {deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import SimpleTable from "../../components/simple-table";
import {fieldsSearchToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {PlusIcon} from "@heroicons/react/solid";
import FieldSearch from "../../components/field-search";
import {FieldsManager} from "../../data/services/fields";
import EmailTemplateDialog from "../../components/email-template-dialog";
import ModalConfirm from "../../components/modal/modal-confirm";

export default class MessagesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: this.props.sortBy ?? "",

            query: "",
            searchFields: this.props.searchFields ?? {},
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            emailTemplateDialog: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    handleToggleEmailDialog = () => {
        this.setState({
            emailTemplateDialog: !this.state.emailTemplateDialog
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            searchFields: JSON.stringify(this.getValidSearchFields())
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = this.props.fields ?? {};

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, disableEdit, email, resource} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const selectSearchFields = fieldsSearchToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchSelectQueryChange, this.props.metadataSearch);

        return (
            <div className="">

                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mr-6 mb-3"}
                    />

                    {!!selectSearchFields && selectSearchFields}

                    <div className="flex-shrink-0 ml-auto mb-3">
                        <button
                            type="button"
                            onClick={this.handleToggleEmailDialog}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </div>
                </div>

                <h2 className={"py-2 text-lg font-bold"}>Email messages</h2>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onEdit={!disableEdit ? this.handleToggleEditModel : null}
                    onDelete={this.handleToggleConfirmModal}
                />

                <EmailTemplateDialog
                    visible={this.state.emailTemplateDialog}
                    onClose={this.handleToggleEmailDialog}
                    CandidateID={this.getId()}
                    translate={translate}
                    dispatch={this.props.dispatch}
                    resource={resource}
                    email={email}
                    query={this.getQuery()}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
