import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {checkPerm, drawTinyField, fieldsToHtml, fillFieldsFromData, getProp, UPDATE_PERM} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Loader from "../../components/loader";
import Button from "../../components/button";

export default class MotivationTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data.candidate/motivation", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        CandidateMotivationID: getProp(this.props, "resource.data.candidate/motivation" + ".CandidateMotivationID", 0),
                        id: this.getId()
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName()
            }));
        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data.candidate/motivation.", {});

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.CandidateSummary;
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (it = null) => {
        let item = Object.assign({}, it);

        const disabled = !checkPerm(this.getResourceName(), UPDATE_PERM);
        const fieldTemplates = {
            DesiredRoleID: new Field("DesiredRoleID", '', [], disabled, 'select'),
            AvailabilityDate: new Field("AvailabilityDate", '', [], disabled, 'date'),
            LocationIDs: new Field("LocationIDs", '', [], disabled, 'multi-select'),

            MotivationForOpportunity: new Field("MotivationForOpportunity", '', [], disabled, 'tiny'),
            RecentAccomplishments: new Field("RecentAccomplishments", '', [], disabled, 'tiny')
        };

        if (item) {
            item.LocationIDs = item?.LocationIDs?.map((it) => {
                return {
                    value: it.StateID,
                    label: it.State
                }
            });
        }
        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const fieldsHtml1 = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(0, 2), translate, this.handleInputChange, {
            DesiredRoleID: LocalStorage.get('lookup', {}).RoleTitle.reduce((memo, item) => {
                memo[item.RoleTitleID] = item.RoleTitle;
                return memo;
            }, {})
        });

        const fieldsHtml2 = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(2, 3), translate, this.handleInputChange, {
            LocationIDs: LocalStorage.get('lookup', {}).State.reduce((memo, item) => {
                memo[item.StateID] = item.State;
                return memo;
            }, {})
        });

        return (
            <div className="">
                {isLoading && (
                    <div className="h-80">
                        <div className={"inset-center"}>
                            <Loader/>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <React.Fragment>

                        <form className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                                <div className="flex -mx-6">
                                    <div className="w-1/2 px-6">
                                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                            {fieldsHtml1}
                                        </div>
                                    </div>

                                    <div className="w-1/2 px-6">
                                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                            {fieldsHtml2}
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-2 mt-2 sm:mt-0 sm:col-span-2">
                                    <label htmlFor={"MotivationForOpportunity"}
                                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                        {translate("field.MotivationForOpportunity")}
                                    </label>
                                    <div className={"form-control w-full max-w-full"}>
                                        {drawTinyField(this.state.fields.MotivationForOpportunity, this.handleInputChange, translate)}
                                    </div>
                                </div>

                                <div className="mb-2 mt-2 sm:mt-0 sm:col-span-2">
                                    <label htmlFor={"RecentAccomplishments"}
                                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                        {translate("field.RecentAccomplishments")}
                                    </label>
                                    <div className={"form-control w-full max-w-full"}>
                                        {drawTinyField(this.state.fields.RecentAccomplishments, this.handleInputChange, translate)}
                                    </div>
                                </div>

                            </div>
                        </form>


                        <div className="pt-5">
                            <div className="flex justify-end">
                                {!this.props.disableEdit && checkPerm(this.getResourceName(), UPDATE_PERM) && (
                                    <>
                                        <Button type="outline"
                                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                                disabled={!this.state.canSubmit}
                                                onClick={this.handleCancel}
                                        >
                                            {translate("btn.cancel")}
                                        </Button>

                                        <Button
                                            type="submit"
                                            className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                                            disabled={!this.state.canSubmit}
                                            onClick={this.state.canSubmit && this.onSubmit}
                                        >
                                            {translate("btn.save")}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>

                    </React.Fragment>
                )}
            </div>
        )
    }
}
