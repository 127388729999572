import React from "react";
import {Link} from "react-router-dom";
import LocalStorage from "../../util/localStorage";

// Requirements, Responsibilities, ClientLocationID, LocationName, AdvDescription
export default function RoleCard({role, token, onApplyToRole, onRemoveRole, onEditRoleApplication, onEditRoleSurvey}) {
    return (
        <>
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-secondary-900">{role.RoleName} </h3>
                <p className="mt-1 max-w-2xl text-sm text-secondary-500">{role.RoleTitle}</p>
            </div>

            <div className="border-t border-secondary-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-secondary-200">

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Requirements</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2"><span
                            dangerouslySetInnerHTML={{__html: role.Requirements}}></span></dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Responsibilities</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2"><span
                            dangerouslySetInnerHTML={{__html: role.Responsibilities}}></span></dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Location</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{role.LocationName}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Description</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2"><span
                            dangerouslySetInnerHTML={{__html: role.AdvDescription}}></span></dd>
                    </div>
                </dl>


                {!LocalStorage.get('user') && !role.Applied && (
                    <Link to={"/register-candidate/" + token}
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm">
                        Apply
                    </Link>
                )}
                {LocalStorage.get('user') && !role.Applied && (
                    <button
                        type="button"
                        onClick={onApplyToRole}
                        className="btn btn-primary mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                    >
                        Apply For The Role
                    </button>
                )}
                {LocalStorage.get('user') && !!role.Applied && (
                    <p>
                        You have applied to this role
                    </p>
                )}
                {LocalStorage.get('user') && !!role.Applied && (
                    <>
                        <button
                            type="button"
                            onClick={onRemoveRole}
                            className="btn btn-primary mb-3 mt-2 bg-red-600 text-white hover:bg-red-700 focus:ring-offset-inverse hover:cursor-pointer"
                        >
                            Remove Application
                        </button>

                        <button
                            type="button"
                            onClick={onEditRoleApplication}
                            className="btn btn-primary ml-2 mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                        >
                            Edit application
                        </button>

                        <button
                            type="button"
                            onClick={onEditRoleSurvey}
                            className="btn btn-primary ml-2 mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                        >
                            Edit survey
                        </button>
                    </>
                )}
            </div>
        </>
    )
}