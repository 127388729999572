import React, {Component, Fragment} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import {ArrowLeftIcon, DotsVerticalIcon} from '@heroicons/react/solid'
import LocalStorage from "../../util/localStorage";
import moment from "moment";
import {connect} from "react-redux";
import Resources from "../../data/services/resources";
import {getContacts, getConversations, getMessages, getSMSMessages} from "../../data/actions/messaging";
import {getProp, toFrontDateTime} from "../../util/util";
import Loader from "../loader";
import {createResource} from "../../data/actions/resource";
import FieldDropdownSelect from "../field-dropdown-select";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function currentDateTimeToUtc() {
    return moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss");
}

class ChatPanel extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Conversations', resource: "Conversations", current: true},
            {name: 'Start New', resource: "Contacts", current: false},
            {name: 'SMS', resource: "SMS", current: false}
        ];

        this.state = {

            tabs: tabs,
            selectedTab: tabs[0].resource,

            messageValue: "",

            messages: [],
            conversations: [],

            convTitle: "",
            convID: null,

            userList: [],
            socketInit: false,

            smsMessageValue: "",
            smsMessages: [],
            selectedSMSCandidate: null
        }

        this.scrollRef = React.createRef();

        this.scrollSMSRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate = (prevProps) => {
        if (!this.state.socketInit && !!this.props.socket) {
            this.setState({socketInit: true}, this.initSocket);
            this.fetchConversations();
        } else if (!prevProps.open && !!this.props.open) {
            console.log("OnOpen")
            this.fetchConversations(true);
        }

        if ((prevProps.messages.data !== this.props.messages.data) && this.props.messages.data) {
            this.setState({
                conversations: getProp(this.props, 'messages.data', [])
            });
        }

        if ((prevProps.messages.messages !== this.props.messages.messages) && this.props.messages.messages) {
            this.setState({
                messages: getProp(this.props, 'messages.messages', [])
            }, this.scrollToEnd);
        }

        if ((prevProps.messages.sms !== this.props.messages.sms) && this.props.messages.sms) {
            this.setState({
                smsMessages: getProp(this.props, 'messages.sms', [])
            }, this.scrollSMSToEnd);
        }
    }

    initSocket = () => {
        console.log("initSocket");

        // New messages listener
        this.props.socket.on("OnMessage", (data) => {
            console.log("OnMessage", data);

            let messages = this.state.messages;
            let conversations = this.state.conversations;

            if (data.IsItMe && (this.state.convID === data.ToID)) {
                messages.push({
                    DateTime: currentDateTimeToUtc(),
                    ID: "message_" + messages.length,
                    ContactID: data.FromID,
                    IsRead: 1,
                    IsItMe: 1,
                    Name: data.FromName,
                    Msg: data.Message,
                    ImagePath: data.ImagePath
                });
            } else if ((this.state.convID === data.FromID)) {
                messages.push({
                    DateTime: currentDateTimeToUtc(),
                    ID: "message_" + messages.length,
                    ContactID: data.FromID,
                    IsRead: 1,
                    IsItMe: 0,
                    Name: data.FromName,
                    Msg: data.Message,
                    ImagePath: data.ImagePath
                });
            } else {
                let found = false;
                conversations = this.state.conversations.map((it) => {
                    if (it.ID === data.FromID) {
                        found = true;
                        it.Unread++;
                        it.IsOnline = 1;
                    }
                    return it;
                });

                if (!data.IsItMe) {
                    if (!found) {
                        conversations.push({
                            DateTime: currentDateTimeToUtc(),
                            ID: data.FromID,
                            ContactID: data.ToID,
                            IsOnline: 1,
                            IsRead: false,
                            Name: data.FromName,
                            Msg: data.Message,
                            Unread: 1
                        })
                    }
                }
            }

            this.setState({
                conversations: conversations,
                messages: messages
            }, this.scrollToEnd);
        })
    }

    /** Data Events
     ================================================================= */
    fetchContacts = () => {
        this.props.dispatch(getContacts({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Contacts
        }));
    };

    fetchConversations = (silent = false) => {
        this.props.dispatch(getConversations({
            user: LocalStorage.get("user"),
            query: {},
            resource: Resources.Messages,
            silent: silent
        }));
    };

    fetchConversation = (idT, name) => {
        const id = (!!idT ? idT : -1);
        this.setState({
            convID: id, convTitle: name, chatContainer: true,
            conversations: this.state.conversations.map((it) => {
                if (it.ID === id) {
                    it.Unread = 0;
                }
                return it;
            })
        }, () => {
            this.props.dispatch(getMessages({
                user: LocalStorage.get("user"),
                resource: Resources.Messages + "/" + id,
                ContactID: id
            }))
        });
    }

    fetchSMS = () => {
        if (!!this.state.selectedSMSCandidate) {
            this.props.dispatch(getSMSMessages({
                user: LocalStorage.get("user"),
                query: {
                    id: this.state.selectedSMSCandidate.value,
                    CandidateID: this.state.selectedSMSCandidate.value
                },
                resource: Resources.CandidateMessageSMS
            }));
        }
    };

    /** UI Events
     ================================================================= */
    handleSendMessage = () => {
        if (!this.state.messageValue) {
            return;
        }
        const SenderContactID = LocalStorage.get('user')?.Contact.ContactID;

        this.props.socket.emit("OnMessage", {
            SenderContactID: SenderContactID,
            Message: this.state.messageValue,
            ReceiverContactID: this.state.convID
        });

        this.setState({messageValue: ""}, this.scrollToEnd);
    };

    handleStartConversations = (id, name) => {
        if (this.state.selectedTab === "Contacts") {
            this.handleTabChange("Conversations");
        }

        this.setState({convID: id, convTitle: name}, () => {
            const hasConv = this.state.conversations.reduce((memo, it) => {
                return memo || (it.ID === id);
            }, false);

            if (!hasConv) {
                let conversations = this.state.conversations;
                conversations.push({
                    DateTime: "",
                    ID: id,
                    IsRead: false,
                    Msg: "",
                    Name: name,
                    Unread: 0
                })

                this.setState({
                    conversations: conversations,
                    messages: [],
                });
            }

            this.fetchConversation(id, name);
        })
    };

    handleCloseConversation = () => {
        this.setState({
            convTitle: "",
            convID: null
        }, () => this.fetchConversations(true))
    };

    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        }, () => {
            if (resource === "Contacts") {
                this.fetchContacts();
            }
            if (resource === "SMS") {
                this.fetchSMS();
            }
        })
    }

    onEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleSendMessage();
        }
    }

    handleSelectSmsCandidate = (name, value) => {
        this.setState({
            selectedSMSCandidate: value
        }, this.fetchSMS)
    }

    handleSendSMSMessage = () => {
        if (!this.state.smsMessageValue) {
            return;
        }

        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                Message: this.state.smsMessageValue,
                CandidateID: this.state.selectedSMSCandidate.value
            },
            resource: Resources.CandidateMessageSMS
        }))

        this.setState({smsMessageValue: ""}, this.scrollSMSToEnd);
    }

    /** Helpers
     ================================================================= */
    getMyName = () => {
        return LocalStorage.get('user')?.Contact.FirstName + " " + LocalStorage.get('user')?.Contact.LastName;
    }

    scrollToEnd = () => {
        const scrollEl = this.scrollRef.current
        if (!!scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    scrollSMSToEnd = () => {
        const scrollEl = this.scrollSMSRef.current
        if (!!scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    renderContact = (item) => {
        return (
            <li key={item.ID}>
                <div className="relative group py-6 px-5 flex items-center">
                    <div onClick={() => this.handleStartConversations(item.ID, item.Name)}
                         className="-m-1 flex-1 block p-1 hover:cursor-pointer">
                        <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true"/>
                        <div className="flex-1 flex items-center min-w-0 relative">
                              <span className="flex-shrink-0 inline-block relative">
                                <div
                                    className="w-10 h-10 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                    <div
                                        className="text-inverse font-black">{item.Name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                </div>
                                <span
                                    className={classNames(
                                        !!item.IsOnline ? 'bg-green-400' : 'bg-gray-300',
                                        'absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white'
                                    )}
                                    aria-hidden="true"
                                />
                              </span>
                            <div className="ml-4 truncate">
                                <p className="text-sm font-medium text-gray-900 truncate">{item.Name}
                                    {!!item.Unread && (
                                        <div
                                            className={"rounded-full py-1 px-2 bg-red-400 text-white inline-block float-right"}
                                        >
                                            {item.Unread}
                                        </div>
                                    )}
                                </p>
                                {!!item.DateTime && (
                                    <p className="text-sm text-gray-500 truncate">{toFrontDateTime(item.DateTime)}
                                        <p className="mb-0 text-semi-muted w-48">{item.Msg}</p>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <Menu as="div" className="ml-2 flex-shrink-0 relative inline-block text-left">
                        {({open}) => (
                            <>
                                <Menu.Button
                                    className="group relative w-8 h-8 bg-white rounded-full inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span className="sr-only">Open options menu</span>
                                    <span className="flex items-center justify-center h-full w-full rounded-full">
                                                                        <DotsVerticalIcon
                                                                            className="w-5 h-5 text-gray-400 group-hover:text-gray-500"
                                                                            aria-hidden="true"
                                                                        />
                                                                      </span>
                                </Menu.Button>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right absolute z-10 top-0 right-9 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <div
                                                        onClick={() => this.handleStartConversations(item.ID, item.Name)}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Send message
                                                    </div>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                </div>
            </li>
        );
    };

    /** Render
     ================================================================= */
    render() {
        const {open, onClose, translate} = this.props;

        // List of conversations (users only for now)
        /*
        "ID": 29,
            "Name": "Jane Doe",
            "IsOnline": 0,
            "DateTime": "2020-09-12 02:00:00",
            "IsRead": true,
            "Msg": "Hello Jane Test Message",
            "Unread": 0,
            "ImagePath": null
         */
        const conversations = this.state.conversations.map((item) => {
            return this.renderContact(item);
        });

        const contacts = getProp(this.props, "messages.contacts.list", []).map((item) => {
            return this.renderContact(item);
        });

        // List of messages for currently selected conversations
        const messages = this.state.messages.map((item) => {
            return (
                <div key={item.ID}
                >
                    <div className={"w-3/4 p-4 " + (!item.IsItMe ? ("float-left") : ("float-right"))}>
                        <div
                            className={"fit-content p-4 rounded-lg " + (!item.IsItMe ? ("bg-primary-600") : ("bg-gray-300 float-right text-right"))}>
                            <div
                                className={"text-xs"}>{item.IsItMe ? this.getMyName() : item.Name} {toFrontDateTime(item.DateTime)}</div>
                            <div className="min-width-zero">
                                <p className="mb-0 truncate list-item-heading"></p>
                            </div>
                            <div className="chat-text-left">
                                <p className="mb-0 text-semi-muted break-all">{item.Msg}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        const smsList = getProp(this.props, "messages.sms.list", []).map((it) => {
            return (
                <div className="w-full">
                    <div
                        className={`mx-4 my-2 w-3/4 ${it.ContactID ? "text-right webkit-text-right float-right" : " float-left"}`}>
                        <div
                            className={`p-2 rounded-lg break-all w-fit-content ${it.ContactID ? 'bg-gray-300' : 'bg-green-300'}`}>
                            <div
                                className='text-sm	font-medium	text-gray-600'>{it.ContactID ? "Me" : "Candidate"} - {toFrontDateTime(it.CreateUpdateDate)}</div>
                            {it.Message}
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" static className="fixed inset-0 overflow-hidden z-10" open={open} onClose={onClose}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Dialog.Overlay className="absolute inset-0"/>

                        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="w-screen max-w-md">
                                    <div className="h-full flex flex-col bg-white shadow-xl">
                                        <div className="p-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">Chat
                                                    Panel</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                                        onClick={() => onClose()}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-b border-gray-200">
                                            <div className="px-6">
                                                <nav className="-mb-px flex space-x-6" x-descriptions="Tab component">
                                                    {this.state.tabs.map((tab) => (
                                                        <button
                                                            key={tab.name}
                                                            onClick={() => this.handleTabChange(tab.resource)}
                                                            className={classNames(
                                                                tab.current
                                                                    ? 'border-primary-500 text-primary-600'
                                                                    : 'border-transparent text-secondary-500 hover:text-secondary-700 hover:border-secondary-200',
                                                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                            )}
                                                        >
                                                            {tab.name}
                                                        </button>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>

                                        {this.props.messages.isLoading && (
                                            <div className={"inset-center"}>
                                                <Loader/>
                                            </div>
                                        )}

                                        {this.state.selectedTab === "Contacts" && (
                                            <div className={"overflow-y-scroll"}>
                                                <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                                                    {contacts}
                                                </ul>
                                            </div>
                                        )}

                                        {this.state.selectedTab === "Conversations" && (
                                            <>
                                                {!!this.state.convID && (
                                                    <div className={"m-2 hover:cursor-pointer"}
                                                         onClick={this.handleCloseConversation}>
                                                        <ArrowLeftIcon
                                                            className="h-8 w-8 inline-block" aria-hidden="true"
                                                        />
                                                        <span>Back</span>
                                                    </div>
                                                )}

                                                <div className={"overflow-y-scroll"} ref={this.scrollRef}>
                                                    {!this.state.convID && (
                                                        <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                                                            {conversations}
                                                        </ul>
                                                    )}

                                                    {!!this.state.convID && (
                                                        <>
                                                            {messages}
                                                        </>
                                                    )}
                                                </div>

                                                {!!this.state.convID && (
                                                    <div className="w-48 flex justify-between">
                                                        <input name="messageValue"
                                                               autoComplete="off"
                                                               onKeyDown={(e) => this.onEnter(e)}
                                                               onChange={(e) => this.setState({messageValue: e.target.value})}
                                                               className="w-64 sm:w-auto sm:flex-grow m-2 py-2 px-4 mr-1 rounded-full border border-gray-300 bg-gray-200 resize-none focus:outline-none"
                                                               placeholder={translate("text.write_sms_something")}
                                                               value={this.state.messageValue}
                                                        />
                                                        <button
                                                            onClick={this.handleSendMessage}
                                                            className="m-2">
                                                            <svg
                                                                className="svg-inline--fa text-green-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="paper-plane"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {this.state.selectedTab === "SMS" && (
                                            <>
                                                <div className=''>
                                                    <p className="mt-2 mb-1 ml-3 text-sm text-secondary-500">Select
                                                        candidate to start, or continue texting:</p>
                                                    <div
                                                        className="m-2 ml-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                                        <label htmlFor={"CandidateID"}
                                                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                                            {translate("field.CandidateID")}
                                                        </label>
                                                        <FieldDropdownSelect
                                                            onChange={this.handleSelectSmsCandidate}
                                                            value={this.state.selectedSMSCandidate}
                                                            className="form-react-select h-9 w-56 mb-8"
                                                            defaultOptions={true}
                                                            loadOptions={
                                                                (inputValue, callback) => {
                                                                    axios.get(
                                                                        Env.getApiUrl("/api/" + Resources.Candidates, {query: inputValue}),
                                                                        {
                                                                            headers: {
                                                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                                            }
                                                                        }
                                                                    )
                                                                        .then((response) => {
                                                                            const result = processResponse(response);
                                                                            if (result && result.status === 0) {
                                                                                const list = result.data.list.map((it) => {
                                                                                    return {
                                                                                        value: it.CandidateID,
                                                                                        label: it.FirstName + " " + it.LastName
                                                                                    };
                                                                                });
                                                                                callback(list);
                                                                            }
                                                                        })
                                                                        .catch((error) => {
                                                                        });
                                                                }
                                                            }
                                                        />
                                                    </div>

                                                    <div className="h-50vh overflow-y-auto" ref={this.scrollSMSRef}>
                                                        {smsList}

                                                        {!this.props.messages.isLoading && !!this.state.selectedSMSCandidate && (smsList.length === 0) && (
                                                            <>
                                                                <p className="m-3 text-center text-sm text-secondary-500">No
                                                                    messages, start texting by typing in the box
                                                                    bellow</p>
                                                            </>
                                                        )}
                                                    </div>

                                                    {!!this.state.selectedSMSCandidate && (
                                                        <div className="w-full flex justify-between">
                                                            <input name="messageValue"
                                                                   autoComplete="off"
                                                                   onKeyDown={(e) => this.onEnter(e)}
                                                                   onChange={(e) => this.setState({smsMessageValue: e.target.value})}
                                                                   className="flex-grow m-2 py-2 px-4 mr-1 rounded-full border border-gray-300 bg-gray-200 resize-none focus:outline-none"
                                                                   placeholder={translate("text.write_sms_something")}
                                                                   value={this.state.smsMessageValue}
                                                            />
                                                            <button
                                                                onClick={this.handleSendSMSMessage}
                                                                className="m-2">
                                                                <svg
                                                                    className="svg-inline--fa text-green-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="paper-plane"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 512 512"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

export default connect(state => state)(ChatPanel);