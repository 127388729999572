import React, {Component} from "react";
import Dialog from "./dialog"
import * as RactDom from "react-dom";

class DialogCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            dialogIsClosing: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show !== prevProps.show && !this.state.dialogIsClosing && !!this.state.showDialog) {
            this.closeDialog();
        }

        if (!!this.props.show && !this.state.dialogIsClosing && !this.state.showDialog) {
            this.setState({
                showDialog: true
            })
        }
    }

    closeDialog = (target = null, nextSibling = null) => {
        // Close only on backdrop click, if it's on the top of the stack
        if ((!!target && !target.classList.contains('js-modal-backdrop')) || !!nextSibling) return null;

        const hideDialogTimeout = this.props.noHideAnimation ? 0 : 300;

        // Don't start the animation if it's already started
        if (!this.state.dialogIsClosing) {
            this.setState({
                dialogIsClosing: true
            }, () => {
                setTimeout(() => {
                    if (this.props?.onClose && !!this.props.show) this.props.onClose();

                    this.setState({
                        showDialog: false,
                        dialogIsClosing: false
                    })
                }, hideDialogTimeout)

            });
        }
    }

    render() {
        return RactDom.createPortal(
            <React.Fragment>
                {!!this.state.showDialog && (
                    <Dialog
                        closeSelf={this.closeDialog}
                        isHideAnimationActive={this.state.dialogIsClosing}
                        {...this.props}
                    />
                )}
            </React.Fragment>,
            document.getElementById('modal-root')
        )
    }
}

export default DialogCustom;