import React, {useEffect, useState} from 'react'
import {EyeIcon, PencilIcon, RefreshIcon} from "@heroicons/react/solid";
import Pagination from "../pagination"
import Loader from "../loader";
import {ArchiveIcon} from "@heroicons/react/outline";
import Table from "./table"
import TableMobile from "./table-mobile"
import {classNames} from "../../util/util";


export default function SimpleTable({
                                        fields,
                                        data,
                                        onRowClick,
                                        onView,
                                        onEdit,
                                        onDelete,
                                        onRestore,
                                        onCustomAction,
                                        onOffsetChange,
                                        onSortChange,
                                        sort,
                                        sortBy,
                                        onUpdateColumnFilter,
                                        columnFilters,
                                        translate,
                                        limit,
                                        offset,
                                        count,
                                        paginationPage,
                                        isLoading,
                                        bulkSelectKey,
                                        onBulkSelect,
                                        bulkSelectedItems,
                                        onSelectAllOnPage,
                                        onSubmitRowColor
                                    }) {

    const [isMobileView, setIsMobileView] = useState(undefined);

    const maxCellWidthAuto = " md:w-px";

    useEffect(() => {
        const smallScreenBreakpoint = 768;

        const watchWindowSize = () => {

            if (window.innerWidth >= smallScreenBreakpoint) {
                if (isMobileView) {
                    setIsMobileView(false);
                }
            }

            if (window.innerWidth < smallScreenBreakpoint) {
                if (!isMobileView) {
                    setIsMobileView(true);
                }
            }
        }

        window.addEventListener('resize', watchWindowSize);

        if (isMobileView === undefined) {
            watchWindowSize();
        }

        return () => window.removeEventListener('resize', watchWindowSize);
    }, [isMobileView])


    const haveActions = (!!onEdit || !!onView || !!onDelete || !!onCustomAction);

    const getActions = (it, cellClasses = "table-cell") => {
        return (
            <td className={classNames(cellClasses, maxCellWidthAuto, "whitespace-wrap md:whitespace-nowrap space-x-2 py-2")}>
                {!!onCustomAction && onCustomAction(it)}
                {onView && (
                    <button
                        title="View"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onView(it);
                        }}
                        className=" inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <EyeIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                 aria-hidden="true"/>
                        <span className="hidden md:inline">View</span>
                    </button>
                )}
                {onEdit && (
                    <button
                        title="Edit"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onEdit(it);
                        }}
                        className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                    aria-hidden="true"/>

                        <span className="hidden md:inline">Edit</span>
                    </button>
                )}
                {onDelete && !it.ArchivedDate && (
                    <button
                        title="Delete"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onDelete(it);
                        }}
                        className="inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-red-50 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-red-500"
                    >
                        <ArchiveIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-red-400"
                                     aria-hidden="true"/>

                        <span className="hidden md:inline">Delete</span>
                    </button>
                )}

                {onRestore && !!it.ArchivedDate && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onRestore(it);
                        }}
                        className="mr-2 inline-flex items-center px-4 py-2 border border-primary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                    >
                        <RefreshIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" aria-hidden="true"/>
                        Restore
                    </button>
                )}
            </td>
        )
    };

    const itemIsSelected = (it) => {
        if (!it[bulkSelectKey]) {
            return false;
        }

        const id = it[bulkSelectKey].toString();

        return bulkSelectedItems.includes(id);
    }

    const checkIfAllOnPageSelected = (data, selected) => {
        const dataKeys = Object.keys(data);

        if (dataKeys.length !== selected.length) {
            return false;
        }

        for (let key of data) {
            if (!selected.includes(key[bulkSelectKey].toString())) {
                return false;
            }
        }
        return true;
    }

    const getCellClasses = (ColorID = 0, addClass = "table-cell", isHeaderCell = false) => {
        let colorClasses = "";

        switch(ColorID) {
            case 1:
                colorClasses = classNames("border-blue-200 bg-blue-300 text-blue-900");
                break;
            case 2:
                colorClasses = "border-red-200 bg-red-300 text-red-900";
                break;
            case 3:
                colorClasses = "border-yellow-200 bg-yellow-300 text-yellow-900";
                break;
            case 4:
                colorClasses = "border-green-200 bg-green-300 text-green-900";
                break;
            case 5:
                colorClasses = "border-violet-200 bg-violet-300 text-violet-900";
                break;
            case 6:
                colorClasses = "border-lime-200 bg-lime-300 text-lime-900";
                break;
            case 7:
                colorClasses = "border-pink-200 bg-pink-300 text-pink-900";
                break;
            case 8:
                colorClasses = "border-orange-200 bg-orange-300 text-orange-900";
                break;
            case 9:
                colorClasses = "border-indigo-200 bg-indigo-300 text-indigo-900";
                break;
            default:
                colorClasses = isHeaderCell ? "bg-secondary-50" : "";
        }

        return classNames(colorClasses, addClass);
    }

    return (
        <>
            <div className="">
                <div className="mx-auto">
                    <div className="flex flex-col">
                        <div
                            className={"align-middle min-w-full overflow-x-auto md:shadow overflow-hidden md:rounded-lg relative"}>

                            {!isMobileView && (
                                <Table
                                    onSortChange={onSortChange}
                                    sort={sort}
                                    data={data}
                                    sortBy={sortBy}
                                    onUpdateColumnFilter={onUpdateColumnFilter}
                                    columnFilters={columnFilters}
                                    haveActions={haveActions}
                                    onView={onView}
                                    onEdit={onEdit}
                                    onRowClick={onRowClick}
                                    getActions={getActions}
                                    checkIfAllOnPageSelected={checkIfAllOnPageSelected}
                                    bulkSelectKey={bulkSelectKey}
                                    bulkSelectedItems={bulkSelectedItems}
                                    onBulkSelect={onBulkSelect}
                                    onSelectAllOnPage={onSelectAllOnPage}
                                    itemIsSelected={itemIsSelected}
                                    isLoading={isLoading}
                                    fields={fields}
                                    getCellClasses={getCellClasses}
                                    translate={translate}
                                />
                            )}

                            {!!isMobileView && (
                                <TableMobile
                                    fields={fields}
                                    data={data}
                                    haveActions={haveActions}
                                    getActions={getActions}
                                    getCellClasses={getCellClasses}
                                    bulkSelectKey={bulkSelectKey}
                                    onBulkSelect={onBulkSelect}
                                    bulkSelectedItems={bulkSelectedItems}
                                    onSubmitRowColor={onSubmitRowColor}
                                    isLoading={isLoading}
                                    translate={translate}
                                />
                            )}

                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}

                            {/* Pagination */}
                            <Pagination
                                className={"md:sticky left-0 rounded-md md:rounded-none" + (!!isLoading ? " invisible" : "")}
                                limit={limit}
                                offset={offset}
                                count={count}
                                paginationPage={paginationPage}
                                updateOffset={onOffsetChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}