import React, {Component} from "react";
import LocalStorage from "../../util/localStorage";
import {Link} from "react-router-dom";
import {HomeIcon, LogoutIcon} from "@heroicons/react/solid";
import moment from "moment";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-secondary-100 min-h-full text-default">
                    <div
                        className="relative z-10 flex-shrink-0 flex h-20 bg-secondary-100 border-b-2 border-primary-600">
                        <div className="flex-1 px-4 flex justify-start items-center">
                            <div
                                className="h-16 from-secondary-100 to-inverse flex items-center flex-shrink-0 text-white">
                                <div className="w-32">
                                    <a href={this.props.logoHref ?? "https://app.goleir.com"}>
                                        <img src="/images/logos/logo.png" alt="GoLeir"  className={'w-16 h-16'}/>
                                    </a>
                                </div>
                            </div>

                            <div className="flex">
                                {!!LocalStorage.get('user') && this.props.isVideoRoom && (
                                    <button
                                        className={""}
                                        onClick={() => this.props.history.push("/calendar")}
                                        title={"Dashboard"}
                                    >
                                        <HomeIcon
                                            className={"w-6 h-6 text-primary-600 "}/>
                                    </button>
                                )}

                                {!LocalStorage.get('user') && this.props.isVideoRoom && (
                                    <button
                                        className={""}
                                        onClick={() => this.props.history.push("/login")}
                                        title={"Leave Room"}
                                    >
                                        <LogoutIcon
                                            className={"w-6 h-6 text-primary-600"}/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div>
                        {this.props.children}
                    </div>
                    {!this.props.isVideoRoom && (
                        <div className="text-secondary-900 py-3 text-center text-sm">
                            <span>©{moment().year()} GoLeir | All rights reserved. | <Link
                                className="border-transparent hover:opacity-70 text-primary-700"
                                to={"/privacy-policy"}>Privacy</Link></span>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
