import {classNames} from "../../util/util";
import React, {useEffect, useRef, useState} from "react";
import NavResponsiveMenu from "./nav-responsive-menu";

const NavResponsive = (props) => {
    const {tabs, onTabChange} = props;

    let tabsClone = JSON.parse(JSON.stringify(tabs));

    const containerRef = useRef(null);
    const navRef = useRef(null);

    const [removedItemsNo, setRemovedItemsNo] = useState(null)
    const [menuItemsPosition, setMenuItemsPosition] = useState([])

    const isOverflowing = () => {
        const nav = navRef.current;
        const container = containerRef.current

        let isOverflowing = nav.clientWidth < container.scrollWidth;
        let hasRoom = menuItemsPosition[menuItemsPosition.length - (removedItemsNo)] && menuItemsPosition[menuItemsPosition.length - removedItemsNo] < container.scrollWidth

        if (isOverflowing) {
            setRemovedItemsNo(removedItemsNo + 1)
        }

        if (hasRoom) {
            setRemovedItemsNo(removedItemsNo - 1)
        }
    }

    useEffect(() => {
        const navChildNodes = navRef.current.childNodes;
        let menuItemsRightPosition = [];

        navChildNodes.forEach(it => {
            const itemPosition = it.getBoundingClientRect()

            menuItemsRightPosition.push(itemPosition.right)
        })

        setMenuItemsPosition(menuItemsRightPosition);

        isOverflowing();
    }, [])

    useEffect(() => {
        // loop until is not overflowed. May cause few re-renders of the component on initial load, but
        // since component is very small it should not have any performance impact
        isOverflowing();
    }, [removedItemsNo])

    useEffect(() => {
        window.addEventListener('resize', isOverflowing);

        return () => window.removeEventListener('resize', isOverflowing);
    }, [removedItemsNo]);

    const menuList = tabsClone.splice(tabsClone.length - removedItemsNo,
        removedItemsNo)

    return (
        <div className="relative pr-10">
            <div
                className="overflow-hidden"
                ref={containerRef}
            >
                <nav
                    ref={navRef}
                    className="-mb-px flex space-x-8 pt-2"
                    aria-label="Tabs"
                >
                    {tabsClone.map((tab) => (
                        <button
                            key={tab.name}
                            onClick={() => onTabChange(tab.resource)}
                            className={classNames(
                                tab.current
                                    ? 'border-primary-500 text-primary-600'
                                    : 'border-transparent text-secondary-700 hover:text-primary-600 hover:border-primary-500',
                                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                            )}
                        >
                            {tab.name}
                            {tab.count ? (
                                <span
                                    className={classNames(
                                        tab.current ? 'bg-primary-100 text-primary-600' : 'bg-secondary-100 text-secondary-900',
                                        'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}
                                >
                              {tab.count}
                            </span>
                            ) : null}
                        </button>
                    ))}
                </nav>
            </div>

            {!!removedItemsNo && (
                <NavResponsiveMenu
                    menuList={menuList}
                    onTabChange={onTabChange}
                />
            )}
        </div>
    )
}

export default NavResponsive;