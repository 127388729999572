import React, {Component} from "react";
import {connect} from "react-redux";
import {getResource, getResourceDetails} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp, toFrontDate} from "../../util/util";
import {Field} from "../../data/services/fields";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";
import PublicLayout from "../../components/layout";
import {ChevronRightIcon, MailIcon} from "@heroicons/react/solid";
import Loader from "../../components/loader";
import Env from "../../util/env";
import Slideover from "../../components/slideover";
import {Link} from "react-router-dom";
import {deleteSecondResource, getSecondResource, updateSecondResource} from "../../data/actions/secondResource";
import LayoutDashboard from "../../components/layout-dashboard";

class RecruiterPublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 20,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "RecruiterProfileID",
            query: "",

            offsetPending: 0,
            limitPending: 20,
            paginationPagePending: 1,
            sortPending: "ASC",
            sortByPending: "",
            queryPending: "",

            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (!!LocalStorage.get('user')) {
            this.fetchPendingData()
        }
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    fetchPendingData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQueryPending(),
            resource: Resources.ExtCandidateInvites
        }));
    };

    fetchRolesData = (item) => {
        this.props.dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                CompanyID: item.CompanyID
            },
            resource: Resources.RecruiterProfileRoles
        }));
    }

    acceptRecruiterInvite = (item) => {
        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get("user"),
            params: {
                CompanyID: item.CompanyID
            },
            query: this.getQueryPending(),
            resource: Resources.ExtCandidateInvites,
            piggyResource: Resources.ExtCandidateInvites,
            notificationMessage: "Connection accepted, expect to be contacted soon, meanwhile feel free to browse available roles."
        }));
        this.handleToggleViewModal(null)
    };

    disconnectFromRecruiter = (item) => {
        this.props.dispatch(deleteSecondResource({
            user: LocalStorage.get("user"),
            query: {
                CompanyID: item.CompanyID
            },
            piggyQuery: this.getQueryPending(),
            resource: Resources.ExtCandidateInvites,
            piggyResource: Resources.ExtCandidateInvites,
            notificationMessage: "Connection with recruiter deleted. You will no longer see role from this recruiter."
        }));
        this.handleToggleViewModal(null)
    };

    connectWithRecruiter = (item) => {
        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get("user"),
            params: {
                CompanyID: item.CompanyID
            },
            query: this.getQueryPending(),
            resource: Resources.ExtCandidateInvites,
            piggyResource: Resources.ExtCandidateInvites,
            notificationMessage: "Connection request send, expect to be contacted soon, meanwhile feel free to browse available roles."
        }));
        this.handleToggleViewModal(null)
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handlePendingQueryChange = (name, value) => {
        this.setState({
            queryPending: value
        }, this.fetchPendingData)
    }

    handleToggleViewModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        }, () => {
            if (this.state.viewModalOpen) {
                this.fetchRolesData(this.state.selectedItem)
            }
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RecruiterProfileID";
    }

    getResourceName = () => {
        return Resources.RecruiterProfileList;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query
        }
    }

    getQueryPending = () => {
        return {
            limit: this.state.limitPending,
            offset: this.state.offsetPending,
            sort: this.state.sortPending,
            sortBy: this.state.sortByPending,
            query: this.state.queryPending
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            RecruiterName: new Field("RecruiterName", '', ['empty'], false, 'text'),
            About: new Field("About", '', [], false, 'textarea'),
            IndustryIDs: new Field("IndustryIDs", '', [], false, 'text'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const data = getProp(this.props, "resource.data.list", []);

        const pendingData = getProp(this.props, "secondResource.data.list", []);

        const pendingList = pendingData.map((it, i) => {
            return (
                <li key={i}
                    className={"hover:cursor-pointer"}
                    onClick={() => this.handleToggleViewModal(it)}
                >
                    <span className="block hover:bg-gray-50">
                        <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        alt={""}
                                        className="h-12 w-12 rounded-full"
                                        src={Env.getApiUrl('api/' + Resources.CompanyImage, {
                                            CompanyID: it.CompanyID
                                        }) + "&date=" + new Date().getTime()}
                                        onError={(event) => event.target.style.display = 'none'}
                                    />
                                </div>
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                        <p className="text-sm font-medium text-primary-600 truncate">{it.RecruiterName}</p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">

                                            {!it.Connected && (
                                                <div className="ml-2 flex-shrink-0 flex">
                                                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                        {"Pending Invite"}
                                                    </p>
                                                </div>
                                            )}
                                            {!!it.Connected && (
                                                <div className="ml-2 flex-shrink-0 flex">
                                                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {"Connected On " + toFrontDate(it.ConnectedDateTime)}
                                                    </p>
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                    <div className="hidden md:block">
                                        <div>
                                            <p className="text-sm text-gray-900">
                                                {it.About}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                        </div>
                    </span>
                </li>
            );
        });

        const list = data.map((it, i) => {
            return (
                <li key={i}
                    className={"hover:cursor-pointer"}
                    onClick={() => this.handleToggleViewModal(it)}
                >
                    <span className="block hover:bg-gray-50">
                        <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        alt={""}
                                        className="h-12 w-12 rounded-full"
                                        src={Env.getApiUrl('api/' + Resources.CompanyImage, {
                                            CompanyID: it.CompanyID
                                        }) + "&date=" + new Date().getTime()}
                                        onError={(event) => event.target.style.display = 'none'}
                                    />
                                </div>
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                    <div>
                                        <p className="text-sm font-medium text-primary-600 truncate">{it.RecruiterName}</p>
                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                            <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span className="truncate">{it.IndustryIDs}</span>
                                        </p>
                                    </div>
                                    <div className="hidden md:block">
                                        <div>
                                            <p className="text-sm text-gray-900">
                                                {it.About}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                        </div>
                    </span>
                </li>
            );
        });

        const roles = getProp(this.props, "resource.detailsData.list", [])
            .map((it) => {

                return (
                    <div>
                        {it.RoleName}
                    </div>
                )
            });

        const pendingContent = (
            <>
                {/* Page content */}
                <div className="p-4 sm:p-6 lg:p-8">
                    <div className="flex flex-wrap">
                        <FieldSearch
                            onChange={this.handlePendingQueryChange}
                            name={"query"}
                            value={this.state.queryPending}
                            placeholder="Search"
                            classNameContainer={"mr-6 mb-3"}
                        />
                    </div>

                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Browse recruiters that already connected, or have pending invites</h3>
                    </div>
                    {!!this.props.secondResource.isLoading && (
                        <div className={"inset-center"}>
                            <Loader/>
                        </div>
                    )}
                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                            {pendingList}
                        </ul>
                        {(!pendingList || pendingList.length === 0) && !this.props.secondResource.isLoading && (
                            <p className={"p-1 m-1"}>{"No invites pending, or connected recruiters."}</p>
                        )}
                    </div>
                </div>
            </>
        );

        const content = (
            <>
                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />
                        </div>

                        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Connect with other recruiters</h3>
                        </div>
                        {!!this.props.resource.isLoading && (
                            <div className={"inset-center"}>
                                <Loader/>
                            </div>
                        )}
                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                            <ul className="divide-y divide-gray-200">
                                {list}
                            </ul>
                            {(!list || list.length === 0) && !this.props.resource.isLoading && (
                                <p className={"p-1 m-1"}>{"No new recruiters."}</p>
                            )}
                        </div>
                    </div>

                <Slideover
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                >
                    <p className="mb-2 font-medium text-primary-600 truncate">{this.state.selectedItem?.RecruiterName}</p>

                    <p className="text-sm text-gray-900 mb-2">
                        {this.state.selectedItem?.About}
                    </p>

                    {!!this.props.resource.isLoading && (
                        <div className={"inset-center"}>
                            <Loader/>
                        </div>
                    )}

                    {!this.props.resource.isLoading && (
                        <>
                            {!this.state.selectedItem?.Connected && (
                                <>
                                    {!LocalStorage.get('user') && (
                                        <Link to={"/register-candidate/" + getProp(this.props, "resource.detailsData.token", [])}
                                              className="btn btn-primary mb-3 focus:ring-offset-inverse hover:cursor-pointer">
                                            Connect with the recruiter
                                        </Link>
                                    )}
                                    {LocalStorage.get('user') && (
                                        <button
                                            type="button"
                                            onClick={() => !this.state.selectedItem?.InviteSend ? this.acceptRecruiterInvite(this.state.selectedItem) : this.connectWithRecruiter(this.state.selectedItem)}
                                            className="my-1 btn btn-primary mb-3 focus:ring-offset-inverse hover:cursor-pointer"
                                        >
                                            Connect with the recruiter
                                        </button>
                                    )}
                                </>
                            )}

                            {!!this.state.selectedItem?.Connected && (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => this.disconnectFromRecruiter(this.state.selectedItem)}
                                        className="my-1 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                                    >
                                        Disconnect from the recruiter
                                    </button>
                                </>
                            )}

                            <h3 className={"bold mb-2 text-lg"}>Latest available roles:</h3>
                            {roles}
                            {(!roles || roles.length === 0) && (
                                <p className={"p-1 m-1"}>{"No public roles available for this recruiter."}</p>
                            )}
                        </>
                    )}
                </Slideover>
            </>
        );

        if (!LocalStorage.get('user') ) {
            return (
                <PublicLayout {...this.props}>
                    <main className="flex-1 relative pb-8 z-0 overflow-y-auto">


                    <PageHeader
                        title={"Recruiter list"}
                    />
                    {content}
                    </main>
                </PublicLayout>
            );
        }

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    <PageHeader
                        title={"Recruiter list"}
                    />
                    {pendingContent}

                    {content}
                </main>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(RecruiterPublicPage);