import React from 'react';

const QuestionMatrix = (props) => {
    const {matrixQuestions, index, translate, preview, onChange, name, value} = props;

    const setValue = (i, rowKey) => {
        let newValue = {};

        if (typeof value === 'object') {
            newValue = value;
        }

        newValue[i] = rowKey;

        return newValue;
    }

    const getValue = (i, key) => {
        return value && value[i] == key;
    }

    const getQuestionMatrix = () => {
        const questions = matrixQuestions.map(it => {
            let question = it.RoleSurveyItemName;
            if (!!preview) question = it

            return {
                question,
                very_satisfied: 0,
                satisfied: 0,
                neither: 0,
                dissatisfied: 0,
                very_dissatisfied: 0
            }
        })

        const checkboxHeaders = Object.keys(questions[0]).map((it, i) => {
            return (
                <div
                    key={"table." + it}
                    className={"hidden lg:flex text-sm font-bold text-secondary-500 text-center " + (i ? " justify-center" : "")}>{translate("table." + it)}
                </div>
            )
        });

        const checkboxes = questions.map((it, i) => {
            let row = [];

            for (const [key, value] of Object.entries((it))) {
                if (key === 'question') {
                    row.push((
                        <div
                            key={it.question + "_" + key}
                            className="flex text-sm border-t border-dashed lg:border-none pt-5 lg:pt-0">
                            {value}
                        </div>
                    ))
                } else {
                    row.push((
                        <div
                            key={it.question + "_" + key}
                            className="flex lg:justify-center items-center"
                        >
                            <input
                                id={it.question + "_" + key}
                                name={it.question}
                                type="radio"
                                className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded-full mr-3 cursor-pointer"
                                onChange={preview ? () => null : () => onChange(name, setValue(i + 1, key))}
                                checked={getValue(i+1, key)}
                            />

                            <label
                                className="lg:hidden text-sm font-medium text-secondary-700 cursor-pointer"
                                for={it.question + "_" + key}
                            >
                                {translate("table." + key)}
                            </label>
                        </div>
                    ))
                }
            }

            return row;
        })


        return (
            <React.Fragment>
                {checkboxHeaders}

                {checkboxes}
            </React.Fragment>
        )
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-6 pb-4">
            {getQuestionMatrix()}
        </div>
    )
}

export default QuestionMatrix;