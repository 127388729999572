import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";

export default class RecruiterTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields));
                    this.setState({canSubmit: false}, () => {
                        params.RecruiterProfileID = getProp(this.props, "resource.data.RecruiterProfileID", null);
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get("user"),
                            params: params,
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName(),

                            file: params?.ImagePath ? params?.ImagePath[0] : null,
                            fileResource: Resources.CompanyImage
                        }));
                    });
                }
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data", null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.RecruiterProfile;
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            ShowInPublic: new Field('ShowInPublic', "", [], false, 'checkbox'),
            RecruiterName: new Field('RecruiterName', "", ['empty']),
            About: new Field('About', "", [], false, 'textarea'),
            IndustryIDs: new Field('IndustryIDs', "", [], false, 'text')
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {};

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                <form className="space-y-8 divide-y divide-gray-200">
                    {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}
                </form>

                {!isLoading && (
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Button type="outline"
                                    className="btn btn-outline focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.handleCancel}
                            >
                                {translate("btn.cancel")}
                            </Button>

                            <Button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                disabled={!this.state.canSubmit}
                                onClick={this.state.canSubmit && this.onSubmit}
                            >
                                {translate("btn.save")}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
