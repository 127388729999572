import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

function HandleOutsideClick(ref, onToggleDropdown) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onToggleDropdown();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

function OutsideClickHandler(props) {
    useEffect(() => {
        const escFunction = (event) => {
            if (event.keyCode === 27) {
                props.onToggleDropdown()
            }
        }

        document.addEventListener("keydown", escFunction, false);

        // returned function will be called on component unmount
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        }
    }, [])


    const wrapperRef = useRef(null);
    HandleOutsideClick(wrapperRef, props.onToggleDropdown);

    return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideClickHandler.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideClickHandler;
