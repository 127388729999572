import {CheckIcon, FilterIcon} from '@heroicons/react/solid'
import React, {Component} from 'react'
import FiltersMenuDropdown from "./filters-menu-dropdown";
import FieldText from "../../field-text";
import {Field, FieldsManager} from "../../../data/services/fields";
import {MinusCircleIcon} from "@heroicons/react/outline";

export default class FiltersMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                Filter: new Field('Filter', '', ['']),
            },
            isDropDownVisible: false,
            options: this.props.options,
            selectedOptions: this.props.selectedOptions ?? []
        };

        this.dropDownButtonRef = React.createRef();
        this.dropDownMenuRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */

    componentDidUpdate(prevProps, prevState, snapshot) {
        // This line allows late binding of options, used in the case where City list is being loaded in async manner
        if (Object.keys(prevProps.options).length !== Object.keys(this.props.options).length) {
            alert("Test")
            this.setState({
                options: this.props.options
            })
        }

        if ((this.props.columnFilters !== prevProps.columnFilters) && !Object.keys(this.props.columnFilters).length ) {
            this.setState({
                selectedOptions:  [],
                options: this.props.options
            })
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    filterOptions = (option, query) => {
        if (!query) {
            return true
        }
        const words = query.split(" ");

        let hit = false;
        words.every(word => {
            if (option.name.toLowerCase().includes(word) || option.name.toLowerCase().includes(word)) {
                hit = true;
                return false
            }

            return true;
        })

        return hit;
    }

    positionDropDownMenu = () => {
        let buttonElement = this.dropDownButtonRef?.current?.getBoundingClientRect();
        if (buttonElement && this.dropDownMenuRef.current) {
            this.dropDownMenuRef.current.style.left = (buttonElement?.left + 80) + "px";
            this.dropDownMenuRef.current.style.top = (buttonElement?.top + 25) + "px";
        }
    }

    toggleDropdown = () => {
        this.setState({
            isDropDownVisible: !this.state.isDropDownVisible
        }, () => {
            this.positionDropDownMenu()
        });
    }

    toggleSelect = (current) => {
        let update = this.state.options.map(option => {
            if (option === current) {
                option.selected = !option.selected;
            }
            return option;
        });

        let selected = Object.values(update).filter(option => option.selected).map(option => {
            return option.value;
        });

        this.setState({
            options: update,
            selectedOptions: selected
        }, () => {
            this.props.onUpdateColumnFilter({options: selected, name: this.props.column})
        });
    }

    clearFilter = () => {
        let update = this.state.options.map(option => {
            option.selected = false;
            return option
        });

        this.setState({
            options: update,
            selectedOptions: []
        }, () => {
            this.props.onUpdateColumnFilter({options: [], name: this.props.column})
        });
    }

    sortOptions( a, b ) {
        if ( a.name < b.name ){
            return -1;
        }
        if ( a.name > b.name ){
            return 1;
        }
        return 0;
    }



    /** Render
     ================================================================= */
    render() {
        const {header, translate, rowsNo} = this.props;
        const filterQuery = this.state.fields.Filter.value.toLowerCase();
        const options = this.state.options.filter(option => this.filterOptions(option, filterQuery)).sort(this.sortOptions);

        return (
            <div className="w-full relative" ref={this.dropDownButtonRef}>
                {!this.state.isDropDownVisible && (
                    <button
                        onClick={this.toggleDropdown}
                        className={`text-white group pl-3 pr-2 py-1 mr-1 rounded-md inline-flex items-center uppercase font-medium hover:text-opacity-100 hover:bg-secondary-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <span className="whitespace-nowrap">
                            {header}
                            <FilterIcon
                                className={`ml-1 h-3 w-3 group-hover:text-opacity-80 transition ease-in-out duration-150 inline` + (this.state.selectedOptions.length ? " text-primary-600" : " text-gray-50")}
                                aria-hidden="true"
                            />
                        </span>
                    </button>
                )}

                {this.state.isDropDownVisible && (
                    <React.Fragment>
                        <FiltersMenuDropdown
                            onToggleDropdown={this.toggleDropdown}
                        >
                            <button
                                onClick={this.toggleDropdown}
                                className={`relative text-white group pl-3 pr-2 py-1 mr-1 rounded-md inline-flex items-center uppercase font-medium hover:text-opacity-100 hover:bg-secondary-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                            >
                                <span className="whitespace-nowrap">
                                    {header}

                                    <FilterIcon
                                        className={`ml-1 h-3 w-3 group-hover:text-opacity-80 transition ease-in-out duration-150 inline` + (this.state.selectedOptions.length ? " text-primary-600" : " text-gray-50")}
                                        aria-hidden="true"
                                    />
                                </span>
                            </button>

                            <div
                                ref={this.dropDownMenuRef}
                                className={" z-10 w-screen w-72 px-4 mt-3 transform sm:px-0 fixed -translate-y-1 left-1/2 -translate-x-16"}>
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative bg-white divide-y divide-secondary-200">
                                        <div className="p-2 pl-10 relative">
                                            <div
                                                className="absolute left-1.5 top-4"
                                                title={this.state.selectedOptions.length ? "Clear all selected" : ""}
                                            >
                                                <MinusCircleIcon
                                                    onClick={this.clearFilter}
                                                    className={`ml-1 h-5 w-5 ` + (this.state.selectedOptions.length ? "cursor-pointer text-secondary-600" : "text-secondary-200")}
                                                    aria-hidden="true"
                                                />
                                            </div>

                                            <FieldText
                                                className="form-control"
                                                onChange={this.handleInputChange}
                                                {...this.state.fields.Filter}
                                                placeholder={translate("field.placeholder.filter_options")}
                                            />
                                        </div>

                                        <ul className="w-full max-h-60 overflow-auto">
                                            {options.map((option, i) => (
                                                <li
                                                    onClick={() => this.toggleSelect(option)}
                                                    className="text-gray-900 cursor-pointer select-none relative py-2 pl-10 pr-4 hover:bg-primary-600 hover:text-white"
                                                >
                                                    <>
                                                        <span
                                                            title={option.name}
                                                            className={`${
                                                                option.selected ? 'font-medium' : 'font-normal'
                                                            } block truncate`}
                                                        >
                                                            {option.name}
                                                        </span>

                                                        {option.selected ? (
                                                            <span
                                                                className="absolute inset-y-0 left-0 flex items-center pl-3"
                                                            >
                                                                  <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                                                                </span>
                                                        ) : null}
                                                    </>
                                                </li>
                                            ))}

                                            {!options.length && (
                                                <li
                                                    className="text-gray-900 cursor-pointer select-none relative py-2 pl-10 pr-4 hover:bg-primary-600 hover:text-white"
                                                >
                                                    <>
                                                        <span
                                                            className={`block truncate`}
                                                        >
                                                            {translate('text.no_results')}
                                                        </span>

                                                    </>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </FiltersMenuDropdown>
                    </React.Fragment>
                )}
            </div>
        )
    }
}
