import React, {Component} from "react";

export default class FieldPassword extends Component {

    render() {
        const addClass = this.props.className ? " " + this.props.className : "";
        const className = addClass + (this.props.errorMessage ? " is-invalid" : "") + (this.props.errorMessage ? " form-error" : "");

        return (
            <React.Fragment>
                <input type={"password"}
                       onChange={(event) => {
                           this.props.onChange(this.props.name, event.target.value);
                       }}
                       name={this.props.name}
                       value={this.props.value}
                       placeholder={this.props.placeholder}
                       disabled={this.props.disabled}
                       className={className}
                />
                {this.props.errorMessage && (
                    <div className={"text-red-700 italic mt-1 text-sm"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </div>
                )}
            </React.Fragment>
        )
    }
}