import {PaperClipIcon} from '@heroicons/react/solid'
import {toFrontDate} from "../../util/util";
import {downloadFile} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import Env from "../../util/env";
import React from "react";

export default function CandidateCard({candidate, dispatch, RoleID}) {
    return (
        <>
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-secondary-900">Candidate Information</h3>
                <p className="mt-1 max-w-2xl text-sm text-secondary-500">Personal details and application.</p>
            </div>
            {candidate.ImagePath && (
                <div className="border-t border-secondary-200 px-4 py-5">
                    <img
                        className={"m-auto w-48"}
                        src={Env.getApiUrl('api/' + Resources.CandidateImage, {
                            id: candidate.CandidateID,
                            token: LocalStorage.get('user').access_token
                        })}
                        alt={candidate.FirstName + " " + candidate.LastName}/>
                </div>
            )}
            <div className="border-t border-secondary-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-secondary-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Headline</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.Headline}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Full name</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.FirstName} {candidate.LastName}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Email</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.Email}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Title</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.Title}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Address</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.Address1}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">City</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.City}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">State</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.State}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Phone</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{candidate.Phone}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-secondary-500">Date Of Birth</dt>
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-2">{toFrontDate(candidate.DateOfBirth)}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:px-6">
                        <dd className="mt-1 text-sm text-secondary-900 sm:mt-0 sm:col-span-3">
                            {!!dispatch && !!RoleID && (
                                <ul className="border border-secondary-200 rounded-md divide-y divide-secondary-200">
                                    <li
                                        onClick={() => {
                                            dispatch(downloadFile({
                                                user: LocalStorage.get('user'),
                                                query: {
                                                    id: candidate.CandidateID,
                                                    RoleID: RoleID,
                                                    name: candidate.FirstName + " " + candidate.LastName + ".pdf"
                                                },
                                                resource: Resources.CandidatePackage
                                            }))
                                        }}
                                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                        <a href="#" className="w-0 flex-1 flex items-center">
                                            <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-secondary-400"
                                                           aria-hidden="true"/>
                                            <span
                                                className="ml-2 flex-1 w-0 truncate font-medium text-primary-600 hover:text-primary-500">Download Package</span>
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </dd>
                    </div>
                </dl>
            </div>
        </>
    )
}