import {Dialog, Transition} from "@headlessui/react";
import React, {Component, Fragment} from "react";
import {XIcon} from '@heroicons/react/outline'
import FieldText from "../field-text";
import {FieldsManager} from "../../data/services/fields";
import Dropzone from 'react-dropzone';
import FieldSelect from "../field-select";
import {fieldsToHtml} from "../../util/util";

export default class ModalUploadDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields,
            files: [],
            Descriptions: {},
            DocumentType: {},
            dropzoneActive: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields,
                files: [],
                Descriptions: {},
                DocumentType: {},
                dropzoneActive: false
            })
        }
    }

    handleDescChange = (id, value) => {
        const desc = this.state.Descriptions;
        desc[id] = value;
        this.setState({Descriptions: desc});
    };

    handleTypeChange = (id, value) => {
        const type = this.state.DocumentType;
        type[id] = value;
        this.setState({DocumentType: type});
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleSubmit = (event) => {
        event && event.preventDefault();
        if (this.state.files.length === 0) {
            return;
        }

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if ((this.state.files.length > 0) && FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.onSubmit) {
                    this.props.onSubmit(this.state.files, FieldsManager.getFieldKeyValues(this.state.fields), this.state.Descriptions, this.state.DocumentType)
                }
            }
        })
    };

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    render() {
        const {onClose, visible, title, translate} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {});

        const files = this.state.files.map((item, i) => {
            return (
                <div
                    key={i}
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5">

                    {!!this.props.documentTypes && (
                        <React.Fragment>
                            <label htmlFor={i} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Type*
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <FieldSelect
                                    name={i}
                                    className="form-react-select h-9"
                                    values={this.props.documentTypes}
                                    onChange={this.handleTypeChange}
                                    errorMessage={this.state.fields.DocumentTypeID.errorMessage}
                                    {...this.state.DocumentType[i]}
                                />
                            </div>
                        </React.Fragment>
                    )}

                    <label htmlFor={i} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {item.name}
                    </label>
                    <div className="mt-1 mb-3 sm:mt-0 sm:col-span-2">
                        <FieldText
                            name={i}
                            className="max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md border"
                            value={this.state.Descriptions[i]}
                            onChange={this.handleDescChange}
                        />
                    </div>
                </div>
            )
        });

        return (
            <Transition show={visible} as={Fragment}>
                <Dialog as="div" static className="fixed z-20 inset-0 overflow-y-auto" open={visible} onClose={onClose}>
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                      </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() => onClose(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <Dropzone
                                                onDrop={(acceptedFiles) => {
                                                    this.setState({
                                                        files: this.state.files.concat(acceptedFiles)
                                                    });
                                                }}
                                                onDragEnter={this.onDragEnter}
                                                onDragLeave={this.onDragLeave}
                                                onDropAccepted={this.onDrop}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <div className="mt-1 sm:mt-0" {...getRootProps()}>
                                                        <div
                                                            className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    stroke="currentColor"
                                                                    fill="none"
                                                                    viewBox="0 0 48 48"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label
                                                                        htmlFor="file-upload"
                                                                        className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                                    >
                                                                        <span>Upload a file</span>
                                                                        <input {...getInputProps()} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">{this.props.mediaTypesText ? this.props.mediaTypesText : "PDF, DOC, XLSX, PNG, JPG, GIF up to 10MB"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>

                                            {files}

                                            {fieldsHtml}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={this.handleSubmit}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => onClose(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}
