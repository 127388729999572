import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon} from '@heroicons/react/outline'

class HelpPage extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    /** Render
     ================================================================= */
    render() {
        // const {translate} = this.props;

        const features = [
            {
                name: 'Feature 1 title',
                description:
                    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
                icon: GlobeAltIcon,
            },
            {
                name: 'Feature 2 title',
                description:
                    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
                icon: ScaleIcon,
            },
            {
                name: 'Feature 3 title',
                description:
                    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
                icon: LightningBoltIcon,
            },
            {
                name: 'Feature 4 title',
                description:
                    'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
                icon: AnnotationIcon,
            },
        ]

        return (
            <LayoutDashboard {...this.props}>
                <main className="pb-16 relative min-h-screen bg-inverse">
                    <div className="custom-max-width mx-auto sm:p-6 lg:p-8">
                        <div className="py-12 bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="lg:text-center">
                                    <h2 className="text-base text-primary-600 font-semibold tracking-wide uppercase">GoLeir</h2>
                                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Promo title text
                                    </p>
                                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                        By Recruiters For Recruiters
                                    </p>
                                </div>

                                <div className="mt-10">
                                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                        {features.map((feature) => (
                                            <div key={feature.name} className="relative">
                                                <dt>
                                                    <div
                                                        className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                                        <feature.icon className="h-6 w-6" aria-hidden="true"/>
                                                    </div>
                                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                                </dt>
                                                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(HelpPage);