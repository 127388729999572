import {Dialog, Switch, Transition} from "@headlessui/react";
import React, {Component, Fragment} from "react";
import {MailIcon, XIcon} from '@heroicons/react/outline'
import {Field, FieldsManager} from "../../data/services/fields";
import {classNames, fieldsToHtml} from "../../util/util";
import Resources from "../../data/services/resources";

export default class ModalMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(false),
            RoleMessage: false
        };
    }

    /*
    -Select type of message (Role or communication)
-If Role, show dialog to pick Role
-Role message will include a message area as well as the formatted Description, Requirements, and Responsibilities)
-If communication, only display a message area
-If the user selects LinkedIn, connect to linkedIn and send the message as an InMessage
-if email, send the message via email
     */

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    getFields = (RoleMessage) => {
        return (!RoleMessage) ? {
            Message: new Field("Message", '', ['empty'], false, 'textarea')
        } : {
            RoleID: new Field("RoleID", '', ['empty'], false, 'select-search'),
            Message: new Field("Message", '', ['empty'], false, 'textarea')
        };
    };

    getValidFields = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.fields);
        } else {
            this.setState({fields});
        }
    }

    render() {

        const {onClose, onSubmit, visible, title, translate} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName + " / " + item.ClientName
                })
            }
        });

        return (
            <Transition show={visible} as={Fragment}>
                <Dialog as="div" static className="fixed inset-0 z-20 overflow-y-auto" open={visible} onClose={onClose}>
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() => onClose(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div
                                        className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <MailIcon className="h-6 w-6 text-primary-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <Switch.Group as="div" className={"flex items-center mt-2 mb-2"}>
                                            <Switch
                                                checked={!!this.state.RoleMessage}
                                                onChange={(value) => this.setState({
                                                    RoleMessage: value,
                                                    fields: this.getFields(value),
                                                })}
                                                className={classNames(
                                                    !!this.state.RoleMessage ? 'bg-primary-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                                                )}
                                            >
                                                <span className="sr-only">{translate("field.show_archived")}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        !!this.state.RoleMessage ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-sm font-medium text-gray-900 mr-2">Send message about specific role (Information about role will be included in the email)</span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <div className="mt-2">
                                            {fieldsHtml}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => onSubmit(this.getValidFields())}
                                    >
                                        Send
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => onClose(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}
