import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {getProp} from "../../util/util";
import {CogIcon} from "@heroicons/react/solid";
import PageHeader from "../../components/layout-dashboard/page-header";
import {BadgeCheckIcon} from "@heroicons/react/outline";

class NotificationsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, notifications} = this.props;

        const notificationList = getProp(notifications, "data.list", []);


        return (
            <LayoutDashboard {...this.props}>
                <PageHeader
                    title={translate("page.title.notifications")}
                />

                <div className="p-4 sm:p-6 lg:p-8">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-xl mx-auto">
                            <div className="text-left">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    className="btn btn-outline focus:ring-offset-inverse capitalize mr-3 mb-3"
                                >
                                    <BadgeCheckIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.mark_all_as_read")}
                                </button>

                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    className="btn btn-outline focus:ring-offset-inverse capitalize mb-3"
                                >
                                    <CogIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.notifications_settings")}
                                </button>
                            </div>

                            <ul role="list" className="divide-y divide-gray-200 p-4 bg-white rounded-md">
                                {notificationList.map((notificationItem) => (
                                    <li key={notificationItem.id}
                                        className="py-4 flow-root px-3 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer">
                                        <div className="flex space-x-3">
                                            <img className="h-6 w-6 rounded-full"
                                                 src={notificationItem.person.imageUrl} alt=""/>
                                            <div className="flex-1 space-y-1">
                                                <div className="flex items-center justify-between">
                                                    <h3 className="text-sm font-medium">
                                                        {notificationItem.person.name}

                                                        {notificationItem.unread && <span
                                                            className="inline-flex items-center px-3 py-0.5 rounded-full text-xs uppercase bg-green-100 text-green-600 font-bold ml-3">New</span>}
                                                    </h3>

                                                    <p className="text-sm text-gray-500">{notificationItem.time}</p>
                                                </div>
                                                <p className="text-sm text-gray-500">
                                                    {notificationItem.content} <span
                                                    className="font-bold text-gray-800">{notificationItem.target}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>


                            <p className="text-center mt-5 text-secondary-400">Infinite load or pagination</p>

                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(NotificationsPage);