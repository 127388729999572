export function getNotificationList(data) {
    return {
        type: 'GET_NOTIFICATION_LIST',
        data: data
    }
}

export function doneGetNotificationList(data) {
    return {
        type: 'DONE_GET_NOTIFICATION_LIST',
        data
    }
}

export function errorGetNotificationList(data) {
    return {
        type: 'ERROR_GET_NOTIFICATION_LIST',
        data
    }
}