import React from 'react';
import FieldRadio from "../../field-radio";

const LikertScales = (props) => {
    const {preview, name, value, desiredAnswer} = props;

    const values = [
        {label: "Strongly Agree", value: 5},
        {label: "Strongly disagree", value: 1},
        {label: "Agree", value: 4},
        {label: "Disagree", value: 2},
        {label: "Neither agree nor disagree", value: 3},
    ]

    return (

        <div className="grid grid-cols-2 gap-4 max-w-lg px-4">
            {values.map(it => {
                let labelClasses = "text-secondary-800";

                if (preview) {
                    labelClasses = props.desiredAnswer > it.value ? "text-red-600" : "text-primary-600";
                }

                return (
                    <label>
                        <FieldRadio
                            className={"mr-3 " + (!!preview ? "" : "cursor-pointer")}
                            name={name}
                            disabled={!!preview} value={props.desiredAnswer == it.value}
                            onChange={!!preview ? () => null : () => props.onChange(name, it.value)}
                            value={desiredAnswer ? desiredAnswer == it.value : value == it.value}
                        />

                        <span className={labelClasses + (!!preview ? "" : " cursor-pointer")}>{it.label}</span>
                    </label>
                )
            })}
        </div>
    )
}

export default LikertScales;