import React, {Component} from 'react'
import Layout from "../../components/layout";
import {connect} from "react-redux";
import Privacy from "../../components/privacy-policy";

class PrivacyPolicyPublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <Layout {...this.props}>
                <main className="pb-16 relative min-h-screen bg-inverse">
                    <div className="custom-max-width mx-auto sm:p-6 lg:p-8">
                        <Privacy translate={translate}/>
                    </div>
                </main>
            </Layout>
        )
    }
}

export default connect(state => state)(PrivacyPolicyPublicPage);