import React, {useRef, useState} from "react";
import {classNames} from "../../util/util";
import ModalDefault from "../modal/modal-default";
import DropZone from "../dropzone";
import CropImage from "../crop-image";
import CameraIcon from "@heroicons/react/outline/CameraIcon";
import CameraCanvas from "../camera-canvas";
import Env from "../../util/env";
import Resources from "../../data/services/resources";
import LocalStorage from "../../util/localStorage";

function takePhoto(video) {
    const scaleFactor = 1;

    const w = video.videoWidth * scaleFactor;
    const h = video.videoHeight * scaleFactor;
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }
            blob.name = "CameraPhoto";
            let fileImageObject = new File([blob], "CameraPhoto");
            resolve(fileImageObject);
        }, 'image/png');
    })
}

export default function ContactPhoto({translate, imageID, addClass, onGetImage}) {
    let imageFile = undefined;
    const videoRef = useRef(null);

    const [avatar, setAvatar] = useState(undefined);

    const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState(false);
    const [isPreviewDialogVisible, setIsPreviewDialogVisible] = useState(false);

    const togglePhotoDialog = () => {
        setIsPhotoDialogOpen(!isPhotoDialogOpen);
        imageFile = undefined;
        setAcceptedFile(null);
        setIsPhotoView(false);
    }

    const [acceptedFile, setAcceptedFile] = useState(null);

    const acceptFile = (files) => {
        const file = files[0];
        setAcceptedFile(file)
    }

    const handleApplyCropClick = () => {
        setAvatar(imageFile);
        onGetImage(imageFile);
        togglePhotoDialog();
        imageFile = undefined;
        setAcceptedFile(null);
    }

    const handleApplyImage = () => {
        if (!!acceptedFile) {
            handleApplyCropClick();
        }
    }

    const getCroppedFile = (file) => {
        imageFile = file;
    }

    const handleRevertAvatar = () => {
        setAcceptedFile(null);
        setAvatar(null);
    }

    const [isPhotoView, setIsPhotoView] = useState(null);

    const togglePhotoViewClick = () => {
        setIsPhotoView(!isPhotoView);
        imageFile = undefined;
        setAcceptedFile(null);
    }

    async function handleTakePhoto() {
        setAcceptedFile(await takePhoto(videoRef.current));
        setIsPhotoView(false);
    }

    const isAvatarClickable = !!avatar || !!imageID;

    const handleAvatarClick = () => {
        if (isAvatarClickable) {
            setIsPreviewDialogVisible(true);
        }
    }

    return (
        <React.Fragment>
            <div className={classNames("flex items-center space-x-5", addClass)}>
                <button
                    onClick={handleAvatarClick}
                    className={
                        classNames( isAvatarClickable ? "hover:ring-4 focus:ring-4 ring-secondary" : "cursor-default", "inline-block h-12 w-12 rounded-full overflow-hidden bg-body")
                    }
                >
                    {!imageID && !avatar && (
                        <svg className="h-full w-full text-secondary-dark" fill="currentColor"
                             viewBox="0 0 24 24">
                            <path
                                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                        </svg>
                    )}

                    {!!avatar && (
                        <img src={URL.createObjectURL(avatar)} alt="avatar"/>
                    )}

                    {!!imageID && !avatar && (
                        <img src={  Env.getApiUrl("api/" + Resources.ExtCandidateImage, {
                            token: LocalStorage.get('user')?.access_token,
                            ContactID: imageID
                        })} alt="avatar" />
                    )}
                </button>

                <button
                    type="button"
                    onClick={togglePhotoDialog}
                    className="btn btn-outline-secondary"
                >
                    {translate("text.change")}
                </button>

                {!!avatar && (
                    <button
                        type="button"
                        onClick={handleRevertAvatar}
                        className="btn btn-text text-default-soft hover:text-link"
                    >
                        {translate("btn.revert")}
                    </button>
                )}
            </div>

            <ModalDefault
                show={!!isPhotoDialogOpen}
                widthClass={"max-w-3xl"}
                title="Change photo"
                hideOverlay={true}

                buttonLabel={translate("btn.apply")}
                buttonDisabled={!acceptedFile}
                onButtonClick={handleApplyImage}

                secondaryButtonLabel={
                    !!isPhotoView ? translate("btn.cancel") :
                        <div className="flex items-center">
                            <CameraIcon className={"w-5 h-5 text-default-soft mr-2"}/>

                            {translate("btn.take_photo")}
                        </div>
                }
                secondaryButtonDisabled={false}
                onSecondaryButtonClick={togglePhotoViewClick}

                closeButtonLabel={translate("btn.close")}
                onClose={togglePhotoDialog}
                isLoading={false}
            >
                <div className="h-[calc(100vh-8rem)]">
                    {!acceptedFile && !isPhotoView && (
                        <div className="p-6 relative h-full flex items-center justify-center">
                            <DropZone
                                onAcceptFiles={acceptFile}
                                translate={translate}
                            />
                        </div>
                    )}

                    {acceptedFile && (
                        <div className="relative h-full flex items-center justify-center bg-black">
                            <CropImage
                                file={acceptedFile}
                                getCroppedFile={getCroppedFile}
                            />
                        </div>
                    )}

                    {!acceptedFile && isPhotoView && (
                        <CameraCanvas
                            videoRef={videoRef}
                            onVideoClick={handleTakePhoto}
                            errorMessage={translate("error.CAMERA_ERROR")}
                        >
                            <div className="absolute bottom-0 left-0 right-0">
                                <p className="py-6 text-center text-lg text-white">
                                    {translate("text.click_to_take_photo")}
                                </p>
                            </div>
                        </CameraCanvas>
                    )}
                </div>
            </ModalDefault>

            <ModalDefault
                show={!!isPreviewDialogVisible}
                widthClass={"max-w-3xl"}
                title={translate("text.ProfileImagePreview")}

                closeButtonLabel={translate("btn.close")}
                onClose={() => setIsPreviewDialogVisible(false)}
            >
                <div className={'p-3 flex justify-center h-dialog-body h-[47rem] h-max-auto'}>
                    {!!avatar && (
                        <img src={URL.createObjectURL(avatar)} alt="avatar"/>
                    )}

                    {!!imageID && !avatar && (
                        <img src={  Env.getApiUrl("api/" + Resources.ExtCandidateImage, {
                            token: LocalStorage.get('user')?.access_token,
                            ContactID: imageID,
                            id: imageID
                        })} alt="avatar" />
                    )}
                </div>
            </ModalDefault>
        </React.Fragment>
    )
}