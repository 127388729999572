import React, {Component} from 'react'
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../../util/util";
import {Field, FieldsManager} from "../../../data/services/fields";
import Loader from "../../../components/loader";
import Button from "../../../components/button";

export default class CompensationTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "dialogResource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getDialogResourceName()
        }))
    };

    onSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            this.props.dispatch(updateDialogResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        RoleCompensationID: getProp(this.props, "dialogResource.data.RoleCompensationID", 0)
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: this.getDialogResourceName(),
                piggyResource: this.getDialogResourceName()
            }));
        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "dialogResource.data", {});

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getDialogResourceName = () => {
        return Resources.RoleCompensation;
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            SalaryRange: new Field("SalaryRange", '', [], false, 'text'),
            SalaryBonus: new Field("SalaryBonus", '', [], false, 'text'),
            LTI: new Field("LTI", '', [], false, 'text'),
            Vacation: new Field("Vacation", '', [], false, 'text'),
            BenefitTypeID: new Field("BenefitTypeID", '', [], false, 'select'),
            Comments: new Field("Comments", '', [], false, 'textarea')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange);

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                <form className="space-y-8 divide-y divide-gray-200">
                    {fieldsHtml}
                </form>

                {!isLoading && (
                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Button type="outline"
                                    className="btn btn-outline focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.handleCancel}
                            >
                                {translate("btn.cancel")}
                            </Button>

                            <Button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                disabled={!this.state.canSubmit}
                                onClick={this.state.canSubmit && this.onSubmit}
                            >
                                {translate("btn.save")}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
