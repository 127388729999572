import React, {Component} from 'react';
import withFetching from './fetch-wrapper';

import {
    AudioViewer,
    CsvViewer,
    DocxViewer,
    PDFViewer,
    PhotoViewerWrapper,
    UnsupportedViewer,
    VideoViewer,
    XlsxViewer,
} from './drivers';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        const container = document.getElementById('pg-viewer');
        let height = container ? container.clientHeight : 0;
        let width = container ? container.clientWidth : 0;

        this.setState({height, width});
    }

    getDriver() {
        switch (this.props.fileType) {
            case 'csv': {
                return withFetching(CsvViewer, this.props);
            }
            case 'xlsx': {
                const newProps = Object.assign({}, this.props, {responseType: 'arraybuffer'});
                return withFetching(XlsxViewer, newProps);
            }
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'bmp':
            case 'png': {
                return PhotoViewerWrapper;
            }
            case 'pdf': {
                return PDFViewer;
            }
            case 'docx': {
                return DocxViewer;
            }
            case 'mp3': {
                return AudioViewer;
            }
            case 'webm':
            case 'mp4': {
                return VideoViewer;
            }
            default: {
                return UnsupportedViewer;
            }
        }
    }

    render() {
        const Driver = this.getDriver(this.props);
        return (
            <div className="pg-viewer-wrapper">
                <div className="pg-viewer" id="pg-viewer">
                    <Driver {...this.props} width={this.state.width} height={this.state.height}/>
                </div>
            </div>
        );
    }
}

export default Index;
