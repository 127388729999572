import React, {Component} from 'react'
import {createResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fieldsToCells, fieldsToEmptyCells, getLookup, getProp, classNames} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Loader from "../../components/loader";
import {
    AcademicCapIcon,
    EyeIcon,
    InformationCircleIcon,
    LinkIcon,
    MailIcon,
    RefreshIcon,
    SearchIcon
} from "@heroicons/react/solid";
import Pagination from "../../components/pagination";
import IconSort from "../../components/icon/sort";
import Slideover from "../../components/slideover";
import {getSecondResource} from "../../data/actions/secondResource";
import FieldDropdownSelect from "../../components/field-dropdown-select";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import CandidateCard from "../../components/candidate-card";
import {Link} from "react-router-dom";
import FieldSelectSearch from "../../components/field-select-search";
import {MinusIcon, PlusIcon as PlusIconOutline} from '@heroicons/react/outline'
import FieldText from "../../components/field-text";
import MultiSelect from "../../components/field-multi-select";
import {SendEmailDialog} from "./sendEmailDialog";

export default class SearchTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "FirstName",

            fields: this.getFields(),

            fieldsSearch: this.getSearchFields(),

            fieldsExp: [this.getFieldsExp()],
            fieldsSkills: [this.getFieldsSkill()],
            fieldsSurvey: [this.getFieldSurvey()],

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,

            sendEmailDialog: false,

            selectedItemsMap: {},

            selectedRole: null,
            noRoleSelectedError: false,
            fillRoleFields: true
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading && !!this.state.fillRoleFields) {
            const role = getProp(this.props, "resource.data.role", {});

            this.setState({
                fillRoleFields: false,
                fieldsExp: getProp(role, "Experience", [])
                    .map((it) => this.getFieldsExp(it)),
                fieldsSkills: getProp(role, "Skills", [])
                    .map((it) => this.getFieldsSkill(it))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.setState({
            selectedItemsMap: {}
        }, () => {
            this.props.dispatch(getResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                resource: Resources.CandidatesSearch
            }))
        })
    };

    fetchCandidateData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.state.selectedItem.CandidateID
            },
            resource: Resources.Candidate
        }));
    };

    assignCandidates = () => {
        if (!this.state.selectedRole) {
            this.setState({
                noRoleSelectedError: true
            })
        } else {
            const candidates = Object.keys(this.state.selectedItemsMap);

            this.setState({
                selectedItemsMap: {},
                noRoleSelectedError: false
            }, () => {
                const params = {
                    Candidates: candidates,
                    RoleID: this.state.selectedRole.value
                };

                this.props.dispatch(createResource({
                    user: LocalStorage.get("user"),
                    query: this.getQuery(),
                    params: params,
                    notificationMessage: `Selected candidates have been added to the role, and informed via email.`,
                    resource: Resources.RoleCandidatesAssign,
                    piggyResource: this.getResourceName()
                }));
            });
        }
    }

    sendNotificationEmails = (Subject, Content) => {
        if (!this.state.selectedRole) {
            this.setState({
                noRoleSelectedError: true,
                sendEmailDialog: false
            });
        } else {
            const candidates = Object.keys(this.state.selectedItemsMap);

            this.setState({
                selectedItemsMap: {},
                noRoleSelectedError: false,
                sendEmailDialog: false,
            }, () => {

                const params = {
                    Subject: Subject,
                    Content: Content,
                    Candidates: candidates,
                    RoleID: this.state.selectedRole.value
                };

                this.props.dispatch(createResource({
                    user: LocalStorage.get("user"),
                    query: this.getQuery(),
                    params: params,
                    notificationMessage: `Selected candidates have been notified via email.`,
                    resource: Resources.RoleCandidatesNotify,
                    piggyResource: this.getResourceName()
                }));
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        });
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        });
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        }, this.fetchCandidateData);
    }

    handleInputChange = (name, value) => {
        this.setState({
            fieldsSearch: FieldsManager.updateField(this.state.fieldsSearch, name, value),
            paginationPage: 1,
            offset: 0
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            offset: 0,
            limit: 10,
            paginationPage: 1
        }, this.fetchData);
    }

    handleCandidateSelect = (id, email) => {
        const selectedItemsMap = this.state.selectedItemsMap;

        if (!!selectedItemsMap[id]) {
            delete selectedItemsMap[id];
        } else {
            selectedItemsMap[id] = email;
        }

        this.setState({
            selectedItemsMap: selectedItemsMap
        });
    }

    handleRoleSelect = (value) => {
        this.setState({
            selectedRole: value,
            paginationPage: 1,
            offset: 0,
            fillRoleFields: true
        }, this.fetchData)
    }

    handleAddExpField = () => {
        const fields = this.state.fieldsExp;
        fields.push(this.getFieldsExp());
        this.setState({
            fieldsExp: fields
        })
    }

    handleRemoveExpField = (i) => {
        const fields = this.state.fieldsExp;
        fields.splice(i, 1);
        this.setState({
            fieldsExp: fields
        })
    }

    handleAddSkillField = () => {
        const fields = this.state.fieldsSkills;
        fields.push(this.getFieldsSkill());
        this.setState({
            fieldsSkills: fields
        })
    }

    handleRemoveSkillField = (i) => {
        const fields = this.state.fieldsSkills;
        fields.splice(i, 1);
        this.setState({
            fieldsSkills: fields
        })
    }

    handleAddSurveyField = () => {
        const fields = this.state.fieldsSurvey;
        fields.push(this.getFieldSurvey());
        this.setState({
            fieldsSurvey: fields
        })
    }

    handleRemoveSurveyField = (i) => {
        const fields = this.state.fieldsSurvey;
        fields.splice(i, 1);
        this.setState({
            fieldsSurvey: fields
        })
    }

    handleToggleSendEmail = () => {
        this.setState({
            sendEmailDialog: !this.state.sendEmailDialog
        });
    }

    handleSelectAll = () => {
        const selectedItemsMap = this.state.selectedItemsMap;

        getProp(this.props, "resource.data.list", []).forEach((it) => {
            selectedItemsMap[it.CandidateID] = it.Email;
        })

        this.setState({
            selectedItemsMap: selectedItemsMap
        });
    }

    handleDeSelectAll = () => {
        this.setState({
            selectedItemsMap: {}
        });
    }

    handleResetAll = () => {
        this.setState({
            fieldsSearch: this.getSearchFields(),

            fieldsExp: [],
            fieldsSkills: [],
            fieldsSurvey: []
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "CandidateID";
    }

    getResourceName = () => {
        return Resources.Candidates;
    }

    getQuery = () => {
        return Object.assign({
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0,
            RoleID: this.state.selectedRole ? this.state.selectedRole.value : ""
        }, FieldsManager.getFieldKeyValues(this.state.fieldsSearch), {
            Exp: this.state.fieldsExp.filter(field => (!!field.RoleTitleID.value && !!field.RoleTitle.value)).map((field) => {
                return field.RoleTitleID.value + "|" + field.RoleTitle.value
            }).join(","),
            Skills: this.state.fieldsSkills
                .filter(field => (!!field.SkillID.value && !!field.Skill.value))
                .map((field) => {
                    return field.SkillID.value + "|" + field.Skill.value
                })
                .join(","),
            CandidateStateIDs: (this.state.fieldsSearch.StateID?.value ?? []).map((it) => it.value).join(",")
        })
    }

    getFields = () => ({
        FirstName: new Field("FirstName", '', ['empty'], false, "text"),
        LastName: new Field("LastName", '', ['empty'], false, "text"),
        Email: new Field("Email", '', ['empty'], false, "text"),
        TitleID: new Field("TitleID", '', [], false, 'select'),
        StateID: new Field("StateID", '', [''], false, "select"),
        Phone: new Field("Phone", '', [''], false, "text"),
        MatchPercentage: new Field("MatchPercentage", '', [''], false, 'gauge', {omitSort: true})
    });

    getSearchFields = () => ({
        StateID: new Field("StateID", [], [], false, "multi-select"),
        Zipcode: new Field("Zipcode", '', [''], false, "text"),
        Radius: new Field("Radius", '', [''], false, "text"),
        query: new Field("query", '', [''], false, "text"),
    });

    getFieldsExp = (item = null) => ({
        RoleTitleID: new Field("RoleTitleID", item?.RoleTitleID ?? '', ['empty'], false, "select"),
        RoleTitle: new Field("RoleTitle", item?.YearsExperience ?? '', ['float_or_empty'], false, "text")
    });

    getFieldsSkill = (item = null) => ({
        SkillID: new Field("SkillID", item?.SkillID ?? '', ['empty'], false, "select"),
        Skill: new Field("Skill", item?.YearsExperience ?? '', ['float_or_empty'], false, "checkbox")
    });

    getFieldSurvey = () => ({
        RoleTitleID: new Field("RoleTitleID", '', ['empty'], false, "select"),
        RoleTitle: new Field("RoleTitle", '', ['float_or_empty'], false, "text")
    });

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const fields = this.getFields();

        const columns = Object.keys(fields).map((it, i) => {
            const itemHasSort = !fields[it]?.metadata?.omitSort;
            return (
                <th
                    onClick={itemHasSort ? () => this.handleUpdateSort(it) : null}
                    className={classNames("px-4 py-2 bg-black text-left text-xs font-medium text-white uppercase tracking-wider", itemHasSort ? "cursor-pointer" : "")}>
                    <div className="flex">
                        <div className="mr-3">
                            {translate("table." + it)}
                        </div>

                        {itemHasSort && (
                            <IconSort
                                className={"inline-block min-w-icon ml-auto"}
                                active={this.state.sortBy === it}
                                sort={this.state.sort}
                            />
                        )}
                    </div>
                </th>
            )
        });

        const placeholderRows = [...Array(5).keys()].map((it, i) => {

            const fieldRows = fieldsToEmptyCells(fields);

            return (
                (
                    <tr key={i} className={i % 2 === 0 ? 'bg-inverse' : 'bg-secondary-50'}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-white"></td>
                        {fieldRows}

                        <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                            <button
                                className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                            >
                                <EyeIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" aria-hidden="true"/>
                                View
                            </button>
                        </td>
                    </tr>
                )
            )
        });

        const row = data.map((it, i) => {

            const fieldRows = fieldsToCells(fields, it);

            return (
                (
                    <tr key={i} className={i % 2 === 0 ? 'bg-inverse' : 'bg-secondary-50'}>
                        <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                            <div className="flex items-center h-5">
                                <input
                                    id={"select_" + i}
                                    name={"select_" + i}
                                    value={i}
                                    type="checkbox"
                                    checked={this.state.selectedItemsMap[it.CandidateID]}
                                    onClick={() => this.handleCandidateSelect(it.CandidateID, it.Email)}
                                    className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-secondary-300 rounded"
                                />
                            </div>
                        </td>

                        {fieldRows}

                        <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                            <button
                                type="button"
                                onClick={() => this.handleToggleView(it)}
                                className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                            >
                                <EyeIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" aria-hidden="true"/>
                                View
                            </button>
                        </td>
                    </tr>
                )
            )
        });

        const skills = this.state.fieldsSkills.map((field, i) => {
            return (
                <div className="grid grid-cols-7 mb-2">
                    <FieldSelectSearch
                        className={"col-span-4"}
                        onChange={(name, value) => {
                            let fieldsSkills = this.state.fieldsSkills;
                            fieldsSkills[i].SkillID.value = value;

                            this.setState({
                                fieldsSkills: fieldsSkills
                            })
                        }}
                        value={field.SkillID.value}
                        values={getLookup("Skill", "SkillID", "Skill")}
                    />
                    <div className="col-span-2 ml-2 items-center">
                        <FieldText
                            type="text"
                            onChange={(name, value) => {
                                let fieldsSkills = this.state.fieldsSkills;
                                fieldsSkills[i].Skill.value = value;

                                this.setState({
                                    fieldsSkills: fieldsSkills
                                })
                            }}
                            value={field.Skill.value}
                            autoComplete="given-name"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-secondary-300 rounded-md"
                        />
                    </div>

                    <div className="col-span-1 ml-2">
                        <button
                            type="button"
                            onClick={() => this.handleRemoveSkillField(i)}
                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            <MinusIcon className="h-4 w-4" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            )
        });

        const exps = this.state.fieldsExp.map((field, i) => {
            return (
                <div className="grid grid-cols-7 mb-2">
                    <FieldSelectSearch
                        className={"col-span-4"}
                        onChange={(name, value) => {
                            let fieldsExp = this.state.fieldsExp;
                            fieldsExp[i].RoleTitleID.value = value;

                            this.setState({
                                fieldsExp: fieldsExp
                            })
                        }}
                        value={field.RoleTitleID.value}
                        values={getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}
                    />

                    <div className="col-span-2 ml-2 items-center">
                        <FieldText
                            type="text"
                            onChange={(name, value) => {
                                let fieldsExp = this.state.fieldsExp;
                                fieldsExp[i].RoleTitle.value = value;

                                this.setState({
                                    fieldsExp: fieldsExp
                                })
                            }}
                            value={field.RoleTitle.value}
                            autoComplete="number"
                            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-secondary-300 rounded-md"
                        />
                    </div>

                    <div className="col-span-1 ml-2">
                        <button
                            type="button"
                            onClick={() => this.handleRemoveExpField(i)}
                            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            <MinusIcon className="h-4 w-4" aria-hidden="true"/>
                        </button>
                    </div>
                </div>
            )
        });

        const actionButtonsDisabled = (Object.values(this.state.selectedItemsMap).length === 0) || !this.state.selectedRole?.value;

        return (
            <div className="flex space-x-3 md:mt-0">
                <div className="grid grid-cols-4">
                    <div className={"col-span-4 mb-10 border-b 2xl:border-none"}>
                        <div
                            className="grid grid-cols-4 sm:items-start sm:border-b sm:border-secondary-200 pt-5 pb-5 block">
                            <div className="col-span-4">
                                <h3 className="text-lg leading-6 font-medium text-secondary-900">Role selection</h3>
                                <p className="mt-1 mb-1 text-sm text-secondary-500">Select role to search candidates
                                    for:
                                </p>
                            </div>
                            <FieldDropdownSelect
                                onChange={(ignore, value) => this.handleRoleSelect(value)}
                                className="col-span-4 sm:col-span-2"
                                defaultOptions={true}
                                value={this.state.selectedRole}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl("api/" + Resources.Roles, Object.assign({}, {query: inputValue})),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                }
                                            }
                                        )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return {
                                                        value: it.RoleID,
                                                        label: it.RoleName
                                                    };
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                    }
                                }
                            />
                        </div>

                        <form
                            onSubmit={this.handleSearch}>
                            <div className="space-y-8 divide-y divide-secondary-200">
                                <div className={"grid grid-cols-4"}>

                                    {/*Qualifications*/}
                                    <div className="pt-6 col-span-4 xl:col-span-3">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-secondary-900">Qualifications</h3>
                                            <p className="mt-1 text-sm text-secondary-500">Enter minimum desired values</p>
                                        </div>
                                        <div className="mt-6 grid grid-cols-2">
                                            <div className="col-span-2 xl:col-span-1">
                                                <p className={"mb-1 text-secondary-900"}>Experience</p>
                                                {exps}
                                                <button
                                                    type="button"
                                                    onClick={this.handleAddExpField}
                                                    className="mt-2 inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                >
                                                    Add experience requirement
                                                    <PlusIconOutline className="h-4 w-4" aria-hidden="true"/>
                                                </button>
                                            </div>

                                            <div className={"col-span-2 xl:col-span-1"}>
                                                <p className={"mb-1 text-secondary-900"}>Skills</p>
                                                {skills}

                                                <button
                                                    type="button"
                                                    onClick={this.handleAddSkillField}
                                                    className="mt-2 inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                >
                                                    Add skill requirement
                                                    <PlusIconOutline className="h-4 w-4" aria-hidden="true"/>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    {/*Locations*/}
                                    <div className="pt-6 col-span-4 xl:col-span-1 mr-6">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-secondary-900">Locations</h3>
                                            <p className="mt-1 text-sm text-secondary-500">Select state(s), or select
                                                radius in miles from the given zipcode</p>
                                        </div>
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-6">
                                                <label htmlFor="StateID"
                                                       className="block text-sm font-medium text-secondary-700">
                                                    State
                                                </label>
                                                <div className="mt-1">
                                                    <MultiSelect
                                                        onChange={this.handleInputChange}
                                                        {...this.state.fieldsSearch.StateID}
                                                        values={getLookup("State", "StateID", "State")}
                                                        all={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                            <div className="sm:col-span-3">
                                                <label htmlFor="Zipcode"
                                                       className="block text-sm font-medium text-secondary-700">
                                                    Zipcode
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(event) => {
                                                            this.handleInputChange("Zipcode", event.target.value);
                                                        }}
                                                        value={this.state.fieldsSearch.Zipcode.value}
                                                        type="text"
                                                        name="Zipcode"
                                                        id="Zipcode"
                                                        autoComplete="postal-code"
                                                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-secondary-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                            <div className="sm:col-span-3">
                                                <label htmlFor="Radius"
                                                       className="block text-sm font-medium text-secondary-700">
                                                    Radius
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="Radius"
                                                        id="Radius"
                                                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-secondary-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className={"col-span-4"}>

                        <div className={"grid grid-cols-5 pb-4"}>

                            <div className="relative rounded-md shadow-sm col-span-3 sm:col-span-2">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <SearchIcon className="h-5 w-5 text-secondary-400" aria-hidden="true"/>
                                </div>
                                <input
                                    type="search"
                                    name="search"
                                    id="search"
                                    onChange={(event) => {
                                        this.handleInputChange("query", event.target.value);
                                    }}
                                    value={this.state.query}
                                    className="focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 sm:text-sm border-secondary-300 rounded-md"
                                    placeholder="Free Text Search"
                                />
                            </div>
                        </div>

                        <div className="items-center text-center">
                            <button
                                onClick={this.handleSearch}
                                type="submit"
                                className="mr-2 mb-2 sm:mb-0 inline-flex items-center justify-center py-4 px-16 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <SearchIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                                Search
                            </button>

                            <button
                                type="button"
                                onClick={this.handleResetAll}
                                className={"inline-flex items-center justify-center py-4 px-14 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"}
                            >
                                <RefreshIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                                Reset Filters
                            </button>
                        </div>
                        <p className="text-sm text-secondary-500 mr-2 pt-1 mb-8 text-center">* After selecting filter values hit search to get new results.</p>

                        <div className="">
                            <p className="mt-1 text-sm text-secondary-500">
                                {!!this.state.noRoleSelectedError && (
                                    <span className={"text-red-500 mr-2 inline-block"}>
                                        Select role to attach candidates to.
                                    </span>
                                )}
                                Select one or more candidates from the list and add invite them to apply to the role, or send them
                                notification email related to the selected role.
                                <div className={"inline-block xl:ml-4 ml-0 mt-2 xl:mt-0"}>
                                    <button
                                        type="button"
                                        onClick={!actionButtonsDisabled && this.assignCandidates}
                                        className={(actionButtonsDisabled ? "bg-gray-600 hover:bg-gray-600 " : "bg-primary-600 hover:bg-primary-700 ") + "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"}
                                    >
                                        <AcademicCapIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                                        Attach to Role
                                    </button>

                                    <button
                                        type="button"
                                        onClick={!actionButtonsDisabled && this.handleToggleSendEmail}
                                        className={(actionButtonsDisabled ? "bg-gray-600 hover:bg-gray-600 " : "bg-primary-600 hover:bg-primary-700 ") + "ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"}
                                    >
                                        <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true"/>
                                        Send email
                                    </button>
                                </div>
                            </p>

                            <div className="hidden sm:block">
                                <div className="flex-1 min-w-0 mt-2">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>

                                </div>
                                <div className="flex flex-col mt-2">
                                    <div
                                        className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg relative">

                                        {/* Table */}
                                        {isLoading && (
                                            <div className={"inset-center"}>
                                                <Loader/>
                                            </div>
                                        )}

                                        <table className="min-w-full divide-y divide-secondary-200">
                                            <thead>
                                            <tr>
                                                <th
                                                    className="px-6 py-3 bg-black text-left text-xs font-medium text-white uppercase tracking-wider">
                                                    <span
                                                        className={"cursor-pointer mr-1 hover:text-primary-600"}
                                                        onClick={this.handleSelectAll}>
                                                        All
                                                    </span>
                                                    |
                                                    <span
                                                        className={"inline-block ml-1 cursor-pointer hover:text-primary-600"}
                                                        onClick={this.handleDeSelectAll}>
                                                        Clear
                                                    </span>
                                                </th>
                                                {columns}
                                                <th className="px-6 py-3 bg-black text-left text-xs font-medium text-white uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-inverse divide-y divide-secondary-200">
                                            {row}

                                            {isLoading && (row.length === 0) && placeholderRows}

                                            {!isLoading && (row.length === 0) && (
                                                <tr>
                                                    <td className={"items-center"}
                                                        colSpan={Object.keys(fields).length + 1}>
                                                        <div className="rounded-md bg-blue-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <InformationCircleIcon
                                                                        className="h-5 w-5 text-secondary-400"
                                                                        aria-hidden="true"/>
                                                                </div>
                                                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                                                    <p className="text-sm text-black-700">No data</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>

                                        {/* Pagination */}
                                        <Pagination
                                            className="sticky left-0"
                                            limit={this.state.limit}
                                            offset={this.state.offset}
                                            count={count}
                                            paginationPage={this.state.paginationPage}
                                            updateOffset={this.handleUpdateOffset}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SendEmailDialog
                    dispatch={this.props.dispatch}
                    translate={translate}
                    sendEmailDialog={this.state.sendEmailDialog}
                    selectedItemsMap={this.state.selectedItemsMap}
                    sendNotificationEmails={this.sendNotificationEmails}
                    handleToggleSendEmail={this.handleToggleSendEmail}
                    resource={this.props.resource}
                />

                <Slideover
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleView}
                >
                    <CandidateCard
                        candidate={this.state.selectedItem}
                        dispatch={this.props.dispatch}
                        RoleID={this.state.selectedRole?.value}
                    />
                    <Link
                        target={"_blank"}
                        to={"contact/" + (this.state.selectedItem?.CandidateID)}
                        className="float-right inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        See more info
                        <LinkIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true"/>
                    </Link>
                </Slideover>
            </div>
        )
    }
}
