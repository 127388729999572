import React, {Component} from "react";
import {fieldsToHtml, scrollErrorIntoView} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import DialogCustom from "../dialog-custom";

export default class ModalSaveResource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields
        }

        this.cancelButtonRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);

        validatedFields = !!this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    render() {
        const {onSubmit, onClose, visible, translate, title, className, metadata, onCustomActions} = this.props;

        const addClass = className ? " " + className : ""

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <DialogCustom
                show={!!visible}
                title={title}
                widthClass={this.props.widthClass ?? "max-w-xl"}
                className={addClass}
                translate={translate}
                onClose={onClose}
                closeButtonLabel={translate('Close')}
                primaryButtonLabel={translate("btn.save")}
                onPrimaryButtonClick={() => onSubmit(this.getValidFields())}
                onCustomActions={onCustomActions}
            >
                <div className="space-y-8 divide-y divide-secondary-200">
                    <div key={this.props.bodyKey} className="mt-10 sm:mt-5 space-y-6 sm:space-y-5">
                        {fieldsHtml}
                    </div>
                </div>
            </DialogCustom>
        );
    }
}
