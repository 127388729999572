import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import SimpleTable from "../../../components/simple-table";
import {checkPerm, CREATE_PERM, DEFAULT_CRUD_STATE, fillFieldsFromData, getProp} from "../../../util/util";
import {Field} from "../../../data/services/fields";
import ModalSaveResource from "../../../components/modal/modal-save-resource";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import {MailIcon} from "@heroicons/react/outline";

export default class CandidateInterviewTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "Date",

            fields: this.getFields(),
            selectedItems: {},

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            confirmFeedbackModalOpen: false,
            confirmInvitationModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleResend = (item) => {
        let params = {
            [this.getPrimaryKey()]: item[this.getPrimaryKey()],
            resend: 1
        };
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleView = (item) => {
        this.props.history.push("/room/" + item[this.getPrimaryKey()] + "/" + item.AutoToken);
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "RoleCandidateInterviewID";
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.RoleCandidateInterview;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = []) => {
        const fieldTemplates = {
            CandidateID: new Field("CandidateID", item ? (item.FirstName + ' ' + item.LastName) : "", ['empty'], false, 'select'),
            ClientContactID: new Field("ClientContactID", item ? item.ClientContactName : "", ['empty'], false, 'select'),
            StartDate: new Field("StartDate", '', ['empty'], false, 'datetime'),
            EndDate: new Field("EndDate", '', ['empty'], false, 'datetime'),
            Description: new Field("Description", '', ['empty'], false, 'textarea'),
            OrderNum: new Field("OrderNum", '', ['integer'], false, 'text'),
            InterviewEmailSent: new Field("InterviewEmailSent", '', [], false, 'checkbox'),
            FeedbackEmailSent: new Field("FeedbackEmailSent", '', [], false, 'checkbox')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleItemSelection = (it) => {
        let items = this.state.selectedItems;

        if (items[it[this.getPrimaryKey()]]) {
            delete items[it[this.getPrimaryKey()]];
        } else {
            items[it[this.getPrimaryKey()]] = it;
        }

        this.setState({selectedItems: items})
    }

    handleToggleConfirmFeedbackModal = () => {
        this.setState({
            confirmFeedbackModalOpen: !this.state.confirmFeedbackModalOpen
        })
    }

    handleToggleConfirmInvitationsModal = () => {
        this.setState({
            confirmInvitationModalOpen: !this.state.confirmInvitationModalOpen
        })
    }

    handleSelectAllOnPage = (data, selected) => {
        let selectedItems = {}

        if (!selected) {
            selectedItems = data.reduce((memo, it) => {memo[it.RoleCandidateInterviewID] = it; return memo}, {});
        }

        this.setState({selectedItems});
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const candidatesList = Object.values(getProp(this.props, "dialogResource.data.candidates", {})).reduce((memo, it) => {memo[it.CandidateID] = it.Candidate; return memo;}, {});
        const contactsList = Object.values(getProp(this.props, "dialogResource.data.contacts", {})).reduce((memo, it) => {memo[it.ClientContactID] = it.ClientContactName; return memo;}, {});

        const metadata = {
            CandidateID: candidatesList,
            ClientContactID: contactsList
        };

        const hasItemsSelected = !!Object.keys(this.state.selectedItems).length;

        return (
            <div className="">
                <div className="sm:mt-6 sm:flex sm:flex-wrap">
                    <div className="sm:flex-shrink-0 ml-auto mb-3 align-top sm:flex">
                        <button
                            className={"btn mr-3 sm:mb-0 mb-1 " + (hasItemsSelected ? " btn-primary" : " btn-disabled")}
                            onClick={() => hasItemsSelected ? this.handleToggleConfirmInvitationsModal() : null}
                        >
                            <MailIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            Send Invitations
                        </button>

                        <div className="sm:inline block sm:mb-0 mb-1">
                            <button
                                className={"btn mr-3 " + (hasItemsSelected ? " btn-primary" : " btn-disabled")}
                                onClick={() => hasItemsSelected ? this.handleToggleConfirmFeedbackModal() : null}
                            >
                                <MailIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                Send Feedback
                            </button>
                        </div>

                        {checkPerm(this.getResourceName(), CREATE_PERM) && (
                            <button
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    bulkSelectKey={this.getPrimaryKey()}
                    bulkSelectedItems={Object.keys(this.state.selectedItems)}
                    onBulkSelect={this.handleItemSelection}
                    onSelectAllOnPage={this.handleSelectAllOnPage}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={this.handleView}
                    onEdit={this.handleToggleEditModel}
                    onDelete={this.handleToggleConfirmModal}
                />

                <ModalSaveResource
                    title={"Edit Interview"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem, ['InterviewEmailSent', 'FeedbackEmailSent'])}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={"Create Interview"}
                    widthClass="w-full md:w-1/2"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields('', ['InterviewEmailSent', 'FeedbackEmailSent'])}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <ModalConfirm
                    title={"Send Invitations for Feedback"}
                    onClose={this.handleToggleConfirmFeedbackModal}
                    onConfirm={() => {
                        this.props.dispatch(updateDialogResource({
                            user: LocalStorage.get("user"),
                            query: this.getQuery(),
                            params: {
                                Items: this.state.selectedItems
                            },
                            resource: Resources.CandidateRoleInterviewFeedbackInvitation,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmFeedbackModal(false)
                    }}
                    visible={this.state.confirmFeedbackModalOpen}
                    btnColor="blue"
                    iconColor="blue"
                >
                    <p className="text-lg text-secondary-500 mt-1">Invitation email for Feedback will be sent to
                        following client contacts:</p>
                    {Object.values(this.state.selectedItems).map((it, i) => (
                        <li className={"text-lg text-primary-600 pt-2 mt-2" + (!!i ? " border-t border-secondary-200" : "")}>{i + 1}. {it.ClientContactName}</li>
                    ))}

                    <p className="text-xs text-secondary-500 mt-6 font-bold">* This action will create set of questions
                        from current list of feedback questions. If feedback email was sent previously only email with
                        link will be re-sent and feedback questions will not be updated.</p>
                </ModalConfirm>

                <ModalConfirm
                    title={"Send Invitations for Interview"}
                    onClose={this.handleToggleConfirmInvitationsModal}
                    onConfirm={() => {
                        this.props.dispatch(updateDialogResource({
                            user: LocalStorage.get("user"),
                            query: this.getQuery(),
                            params: {
                                Items: this.state.selectedItems
                            },
                            resource: Resources.CandidateRoleInterviewInvitation,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmInvitationsModal(false)
                    }}
                    visible={this.state.confirmInvitationModalOpen}
                    btnColor="blue"
                    iconColor="blue"
                >
                    <p className="text-lg text-secondary-500">Invitation email for Interview will be sent to following
                        candidates:</p>
                    <ol>
                        {Object.values(this.state.selectedItems).map((it, i) => (
                            <li className={"text-lg text-primary-600  pt-2 mt-2" + (!!i ? " border-t border-secondary-200" : "")}>{i + 1}. {it.FirstName + " " + it.LastName}</li>
                        ))}
                    </ol>
                </ModalConfirm>
            </div>
        )
    }
}
