import {Dialog, Transition} from "@headlessui/react";
import React, {Component, Fragment} from "react";
import {ArrowsExpandIcon, XIcon} from '@heroicons/react/outline'
import screenfull from 'screenfull';
import ReactPlayer from 'react-player';
import {findDOMNode} from "react-dom";
import {CogIcon, DownloadIcon, PauseIcon, PlayIcon, VolumeOffIcon, VolumeUpIcon} from "@heroicons/react/solid";
import Button from "../button";


function Duration({className, seconds}) {
    return (
        <time dateTime={`P${Math.round(seconds)}S`} className={className}>
            {format(seconds)}
        </time>
    )
}

function format(seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
}

function pad(string) {
    return ('0' + string).slice(-2)
}

export default class ModalVideoPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null,
            pip: false,
            playing: true,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            isFileLoaded: false,
            isSpeedMenuVisible: false,
            storedVolume: 0
        }

        this.load(props.filePath)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.visible && this.props.visible) {
            this.load(this.props.filePath);
            this.handlePause();
        }
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }

    handlePlayPause = () => {
        this.setState({playing: !this.state.playing})
    }

    handleStop = () => {
        this.setState({url: null, playing: false})
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({light: !this.state.light})
    }

    handleToggleLoop = () => {
        this.setState({loop: !this.state.loop})
    }

    handleVolumeChange = e => {
        let muted = this.state.muted;
        if (e.target.value > 0) {
            muted = false;
        }

        this.setState({volume: parseFloat(e.target.value), muted})
    }

    handleToggleMuted = () => {
        let volume;
        let storedVolume = this.state.storedVolume;

        if (!this.state.muted) {
            storedVolume = this.state.volume;
            volume = 0;
        } else {
            volume = this.state.storedVolume;
        }

        this.setState({
            muted: !this.state.muted,
            volume: volume,
            storedVolume: storedVolume
        })
    }

    handleSetPlaybackRate = e => {
        this.setState({playbackRate: parseFloat(e.target.value)})
    }

    handleTogglePIP = () => {
        this.setState({pip: !this.state.pip})
    }

    handlePlay = () => {
        console.log('onPlay')
        this.setState({playing: true})
    }

    handleEnablePIP = () => {
        console.log('onEnablePIP')
        this.setState({pip: true})
    }

    handleDisablePIP = () => {
        console.log('onDisablePIP')
        this.setState({pip: false})
    }

    handlePause = () => {
        this.setState({playing: false})
    }

    handleSeekMouseDown = e => {
        this.setState({seeking: true})
    }

    handleSeekChange = e => {
        this.setState({played: parseFloat(e.target.value)})
    }

    handleSeekMouseUp = e => {
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        console.log('onEnded')
        this.setState({playing: this.state.loop})
    }

    handleTimestampClick = (timestamp) => {
        this.player.seekTo(timestamp);
    }

    handleDuration = (duration) => {
        this.setState({duration})
    }

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }

    render() {
        const {onClose, visible, title, bookmarks, onDownload} = this.props;

        const {
            url,
            playing,
            controls,
            light,
            volume,
            muted,
            loop,
            played,
            duration,
            playbackRate,
            pip
        } = this.state

        const bookmarksList = bookmarks.map((it) => {
            return (
                <div className="">
                    <Button type="link" className="cursor-pointer"
                            onClick={() => this.handleTimestampClick(it.time)}>{it.desc}</Button>
                </div>
            )
        });

        return (
            <Transition show={visible} as={Fragment}>
                <Dialog as="div" static className="fixed z-20 inset-0 overflow-y-auto" open={visible} onClose={onClose}>
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                      </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className={"inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6"}>
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        onClick={() => onClose()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                <div
                                    onClick={onDownload}
                                    className="hover:cursor-pointer hidden sm:block absolute top-0 right-0 pt-1 pl-1 h-8 w-8 mt-3 mr-12 rounded-full bg-primary-100"
                                    title="Download"
                                >
                                    <DownloadIcon className="h-6 w-6 text-primary-600" aria-hidden="true"/>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </Dialog.Title>
                                        <div className="mt-2 w-full">
                                            <div className='player-wrapper'>
                                                <ReactPlayer
                                                    ref={this.ref}
                                                    className='react-player'
                                                    width='100%'
                                                    height='100%'
                                                    url={url}
                                                    pip={pip}
                                                    playing={playing}
                                                    controls={controls}
                                                    light={light}
                                                    loop={loop}
                                                    playbackRate={playbackRate}
                                                    volume={volume}
                                                    muted={muted}
                                                    onReady={() => console.log('onReady')}
                                                    onStart={() => console.log('onStart')}
                                                    onPlay={this.handlePlay}
                                                    onEnablePIP={this.handleEnablePIP}
                                                    onDisablePIP={this.handleDisablePIP}
                                                    onPause={this.handlePause}
                                                    onBuffer={() => console.log('onBuffer')}
                                                    onSeek={e => console.log('onSeek', e)}
                                                    onEnded={this.handleEnded}
                                                    onError={e => console.log('onError', e)}
                                                    onProgress={this.handleProgress}
                                                    onDuration={this.handleDuration}
                                                />
                                            </div>

                                            <div className="relative pt-1">
                                                <input
                                                    className="video-input-range focus:outline-none cursor-pointer w-full relative z-10"
                                                    type='range'
                                                    min={0} max={0.999999} step='any'
                                                    value={played}
                                                    onMouseDown={this.handleSeekMouseDown}
                                                    onChange={this.handleSeekChange}
                                                    onMouseUp={this.handleSeekMouseUp}
                                                />

                                                <progress className="video-progress h-2 w-full absolute left-0 top-2.5"
                                                          max={1} value={played}/>

                                                <div className="flex">
                                                    <div className="space-x-2 flex items-center">
                                                        <button
                                                            className="rounded-full focus:outline-none focus:ring-2 ring-primary-600"
                                                            onClick={this.handlePlayPause}
                                                        >
                                                            {playing
                                                                ? <PauseIcon className="w-9 h-9 text-primary-500"/>
                                                                : <PlayIcon className="w-9 h-9 text-primary-500"/>
                                                            }
                                                        </button>

                                                        <span className="group inline-flex h-9 items-center">
                                                            <button
                                                                className="volume-button rounded-full focus:outline-none relative focus:ring-2 ring-primary-600 p-0.5 w-9 h-9"
                                                                onClick={this.handleToggleMuted}
                                                            >
                                                                {this.state.muted
                                                                    ? <VolumeOffIcon
                                                                        className="w-7 h-7 text-primary-500"/>
                                                                    :
                                                                    <VolumeUpIcon className="w-7 h-7 text-primary-500"/>
                                                                }
                                                            </button>

                                                            <div
                                                                className="inline-block w-0 group-hover:w-20 pl-4 h-9 overflow-hidden transition transition-width opacity-0 group-hover:opacity-100 flex items-center">
                                                                <input className="w-full sound-input-range" type='range'
                                                                       min={0} max={1} step='any' value={volume}
                                                                       onChange={this.handleVolumeChange}/>
                                                            </div>
                                                        </span>

                                                        <div className="inline-flex h-9 align-top pt-1.5">
                                                            <Duration seconds={duration * played}/> <span
                                                            className="mx-2">/</span> <Duration seconds={duration}/>
                                                        </div>
                                                    </div>

                                                    <div className="ml-auto space-x-2">
                                                        <span className="relative">
                                                            {this.state.isSpeedMenuVisible && (
                                                                <div
                                                                    className="absolute bg-inverse shadow-md py-2 transform -translate-y-6 bottom-full text-center z-20">
                                                                    <button
                                                                        className="px-2 py-1 cursor-pointer hover:bg-primary-500 hover:text-white w-full"
                                                                        onClick={this.handleSetPlaybackRate}
                                                                        value={1}>x1
                                                                    </button>
                                                                    <button
                                                                        className="px-2 py-1 cursor-pointer hover:bg-primary-500 hover:text-white w-full"
                                                                        onClick={this.handleSetPlaybackRate}
                                                                        value={1.5}>x1.5
                                                                    </button>
                                                                    <button
                                                                        className="px-2 py-1 cursor-pointer hover:bg-primary-500 hover:text-white w-full"
                                                                        onClick={this.handleSetPlaybackRate}
                                                                        value={2}>x2
                                                                    </button>
                                                                </div>
                                                            )}

                                                            <button
                                                                className="rounded-full focus:ring-2 ring-primary-600 focus:outline-none"
                                                                onClick={() => this.setState({isSpeedMenuVisible: !this.state.isSpeedMenuVisible})}>
                                                                <CogIcon className="w-9 h-9 text-primary-500"/>
                                                            </button>
                                                        </span>

                                                        <button
                                                            className="rounded-full focus:ring-2 ring-primary-600 focus:outline-none relative -top-0.5 p-0.5 w-9 h-9"
                                                            onClick={this.handleClickFullscreen}>
                                                            <ArrowsExpandIcon className="w-8 h-8 text-primary-500"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            {!!bookmarksList.length && (
                                                <div className="mt-5">
                                                    <h3>Bookmarks:</h3>
                                                    {bookmarksList}
                                                </div>
                                            )}

                                            {/*<table>*/}
                                            {/*    <tbody>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Controls</th>*/}
                                            {/*        <td>*/}
                                            {/*            <button onClick={this.handleStop}>Stop</button>*/}
                                            {/*            <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>*/}
                                            {/*            <button onClick={this.handleClickFullscreen}>Fullscreen</button>*/}
                                            {/*            {light &&*/}
                                            {/*            <button onClick={() => this.player.showPreview()}>Show preview</button>}*/}
                                            {/*            {ReactPlayer.canEnablePIP(url) &&*/}
                                            {/*            <button onClick={this.handleTogglePIP}>{pip ? 'Disable PiP' : 'Enable PiP'}</button>}*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Speed</th>*/}
                                            {/*        <td>*/}
                                            {/*            <button onClick={this.handleSetPlaybackRate} value={1}>1x</button>*/}
                                            {/*            <button onClick={this.handleSetPlaybackRate} value={1.5}>1.5x</button>*/}
                                            {/*            <button onClick={this.handleSetPlaybackRate} value={2}>2x</button>*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Seek</th>*/}
                                            {/*        <td>*/}
                                            {/*            <input*/}
                                            {/*                type='range' min={0} max={0.999999} step='any'*/}
                                            {/*                value={played}*/}
                                            {/*                onMouseDown={this.handleSeekMouseDown}*/}
                                            {/*                onChange={this.handleSeekChange}*/}
                                            {/*                onMouseUp={this.handleSeekMouseUp}*/}
                                            {/*            />*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Volume</th>*/}
                                            {/*        <td>*/}
                                            {/*            <input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>*/}
                                            {/*            <label htmlFor='controls'>Controls</label>*/}
                                            {/*        </th>*/}
                                            {/*        <td>*/}
                                            {/*            <input id='controls' type='checkbox' checked={controls} onChange={this.handleToggleControls} />*/}
                                            {/*            <em>&nbsp; Requires player reload</em>*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>*/}
                                            {/*            <label htmlFor='muted'>Muted</label>*/}
                                            {/*        </th>*/}
                                            {/*        <td>*/}
                                            {/*            <input id='muted' type='checkbox' checked={muted} onChange={this.handleToggleMuted} />*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>*/}
                                            {/*            <label htmlFor='loop'>Loop</label>*/}
                                            {/*        </th>*/}
                                            {/*        <td>*/}
                                            {/*            <input id='loop' type='checkbox' checked={loop} onChange={this.handleToggleLoop} />*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>*/}
                                            {/*            <label htmlFor='light'>Light mode</label>*/}
                                            {/*        </th>*/}
                                            {/*        <td>*/}
                                            {/*            <input id='light' type='checkbox' checked={light} onChange={this.handleToggleLight} />*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Played</th>*/}
                                            {/*        <td><progress max={1} value={played} /></td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>Loaded</th>*/}
                                            {/*        <td><progress max={1} value={loaded} /></td>*/}
                                            {/*    </tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}

                                            {/*{this.renderLoadButton(this.props.filePath, 'mp4')}*/}

                                            {/*<table>*/}
                                            {/*    <tbody>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>url</th>*/}
                                            {/*        <td className={!url ? 'faded' : ''}>*/}
                                            {/*            {(url instanceof Array ? 'Multiple' : url) || 'null'}*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>playing</th>*/}
                                            {/*        <td>{playing ? 'true' : 'false'}</td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>volume</th>*/}
                                            {/*        <td>{volume.toFixed(3)}</td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>played</th>*/}
                                            {/*        <td>{played.toFixed(3)}</td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>loaded</th>*/}
                                            {/*        <td>{loaded.toFixed(3)}</td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>duration</th>*/}
                                            {/*        <td><Duration seconds={duration} /></td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>elapsed</th>*/}
                                            {/*        <td><Duration seconds={duration * played} /></td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr>*/}
                                            {/*        <th>remaining</th>*/}
                                            {/*        <td><Duration seconds={duration * (1 - played)} /></td>*/}
                                            {/*    </tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => onClose()}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}
