import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getLookupValue, getProp, isNotificationVisible, numberWithCommas, toFrontDate} from "../../util/util";
import PageHeader from "../../components/layout-dashboard/page-header";
import Loader from "../../components/loader";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import RoleModal from "../client-single-page/roles";
import FieldDropdownSelect from "../../components/field-dropdown-select";
import {Field, FieldsManager} from "../../data/services/fields";
import Env from "../../util/env";
import axios from "axios";
import {processResponse} from "../../data/services/api-util";

class FinancialPage extends Component {

    constructor(props) {
        super(props);

        let Client =  {
            value: getProp(this.props, "resource.data.0.ClientID", ""),
            label: getProp(this.props, "resource.data.0.ClientName", "")
        };

        this.state = {
            retractedClients: {},
            searchFields: {
                ClientID: new Field("ClientID", Client, [])
            },

            selectedItem: null,
            viewModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */
    toggleExpand = (id) => {
        let retractedClients = this.state.retractedClients;

        if (retractedClients[id]) {
            delete retractedClients[id];
        } else {
            retractedClients[id] = true;
        }

        this.setState({
            retractedClients: retractedClients
        })
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleSearchFields = (name, value) => {
        const searchFields = FieldsManager.updateField(this.state.searchFields, name, value);
        this.setState({searchFields: searchFields}, this.fetchData);
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.FinancialReport;
    }

    getQuery = () => {
        return {
            ClientID: this.state.searchFields.ClientID?.value?.value,
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data", []);
        const isLoading = getProp(this.props, "resource.isLoading", false);


        console.log(getProp(this.props, "resource.data.0.ClientName"))
        console.log(getProp(this.props, "resource.data.0.ClientID"))

        // temp fix. data may hold wrong data type from previous page
        const clients = Array.isArray(data) && data.map((it) => {

            const jobs = it.Items.map((it) => {

                const jobPricing = it.Pricing.Items?.map((ot) => {
                    return (
                        <tr>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{getLookupValue('PaymentType', 'PaymentTypeID', ot.PaymentTypeID, 'PaymentType')}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{numberWithCommas(ot.PaymentAmount)}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{toFrontDate(ot.DueDate)}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{toFrontDate(ot.ActualDate)}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2"}>{ot.Comments}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{ot.IsEstimate ? "Yes" : "No"}</td>
                            <td className={"whitespace-wrap font-medium text-xs md:whitespace-nowrap space-x-2 py-2 text-center"}>{ot.IsInvoiced ? "Yes" : "No"}</td>
                        </tr>
                    )
                });

                return (
                    <li className="py-4">
                        <div className="ml-3">
                            <p
                                onClick={() => {
                                    this.handleToggleView(it)
                                }}
                                className="cursor-pointer text-sm font-medium text-gray-900">
                                {it.RoleName}
                            </p>
                            <p className="text-sm text-gray-500">Pending
                                Amount: {numberWithCommas(it.Pricing.Total ? it.Pricing.Total : 0)}
                            </p>
                            {!!jobPricing && jobPricing.length > 0 && (
                                <div className={"align-middle min-w-full overflow-x-auto md:shadow overflow-hidden md:rounded-lg relative mt-4 mb-4"}>
                                    <table className="min-w-full divide-y divide-secondary-200">
                                        <thead>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Payment Type</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Amount</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Due Date</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Actual Date</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Comments</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Is Estimate</th>
                                            <th className={"bg-black text-center text-xs font-medium text-white uppercase tracking-wider p-2"}>Is Invoiced</th>
                                        </thead>
                                        <tbody className="bg-inverse divide-y divide-secondary-200">
                                            {jobPricing}
                                        </tbody>
                                    </table>
                                </div>
                            )}


                        </div>
                    </li>
                )
            });

            return (
                <div className={"w-full"}>
                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 w-full">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {this.state.retractedClients[it.ClientID] && (
                                <ChevronDownIcon
                                    onClick={() => {
                                        this.toggleExpand(it.ClientID)
                                    }}
                                    className="hover:cursor-pointer h-8 w-8 text-gray-500 inline-block mr-1"
                                    aria-hidden="true"/>
                            )}
                            {!this.state.retractedClients[it.ClientID] && (
                                <ChevronUpIcon
                                    onClick={() => {
                                        this.toggleExpand(it.ClientID)
                                    }}
                                    className="hover:cursor-pointer h-8 w-8 text-gray-500 inline-block mr-1"
                                    aria-hidden="true"/>
                            )}

                            <Link to={"/client/" + it.ClientID}>{it.ClientName}</Link>
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">

                        </p>
                    </div>
                    {!this.state.retractedClients[it.ClientID] && (
                        <ul className="divide-y divide-gray-200">
                            {jobs}
                        </ul>
                    )}
                </div>
            )
        });

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.financial")}
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="inline-block mr-4">
                            <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                Client
                            </label>
                            <FieldDropdownSelect
                                {...this.state.searchFields.ClientID}
                                onChange={this.handleSearchFields}
                                isClearable={false}
                                name={"ClientID"}
                                className="select-search h-9 w-56 mb-8"
                                defaultOptions={true}
                                loadOptions={
                                    (inputValue, callback) => {
                                        axios.get(
                                            Env.getApiUrl("/api/" + Resources.FinancialReportClients, {query: inputValue}),
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                }
                                            }
                                        )
                                            .then((response) => {
                                                const result = processResponse(response);
                                                if (result && result.status === 0) {
                                                    const list = result.data.list.map((it) => {
                                                        return {
                                                            value: it.ClientID,
                                                            label: it.ClientName
                                                        };
                                                    });
                                                    callback(list);
                                                }
                                            })
                                            .catch((error) => {
                                            });
                                    }
                                }
                            />
                        </div>

                        <div className="inline-block">
                            <button
                                onClick={() => (this.state.searchFields.ClientID?.value.value)
                                    ? this.setState({
                                        searchFields: {
                                            ClientID: new Field("ClientID", '', [])
                                        },
                                    }, () => this.fetchData())
                                    : null
                                }
                                className={"btn " + ((this.state.searchFields.ClientID?.value?.value) ? "btn-primary" : " btn-disabled")}
                            >
                                Clear all filters
                            </button>
                        </div>

                        <div className="flex flex-wrap">
                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}

                            <div className={"w-full"}>
                                {clients}
                            </div>
                        </div>

                    </div>
                </main>

                <RoleModal
                    {...this.props}
                    topPosition={true}
                    id={getProp(this.state.selectedItem, "RoleID", 0)}
                    selectedTab={Resources.RolePricing}
                    visible={!!this.state.viewModalOpen}
                    onClose={this.handleToggleView}
                    disableCloseOnOutsideClick={isNotificationVisible(this.props.ui)}
                />

            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(FinancialPage);