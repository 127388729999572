import React, {Component} from "react";

export default class FieldRadio extends Component {

    render() {
        const addClass = this.props.className ? " " + this.props.className : "";
        return (
            <React.Fragment>
                <input
                    type={"radio"}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    checked={!!this.props.value}
                    name={this.props.name}
                    value={!!this.props.value}
                    className={"text-primary-600 focus:ring-primary-600" + addClass}
                    disabled={this.props.disabled}
                />
                {this.props.errorMessage && (
                    <div className="field-error-text">
                        <img src="/images/forms/Error.png" alt="error icon" title={`${this.props.errorMessage}`}/>
                    </div>
                )}

            </React.Fragment>
        )
    }
}