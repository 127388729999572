import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {classNames, DEFAULT_CRUD_STATE, fillFieldsFromData, getProp} from "../../util/util";
import {Field} from "../../data/services/fields";
import ResourceTable from "../../components/simple-table";
import PageHeader from "../../components/layout-dashboard/page-header";
import {ChevronDoubleLeftIcon} from "@heroicons/react/solid";
import {Dialog, Transition} from "@headlessui/react";
import { RadioGroup } from '@headlessui/react'

class CandidateDuplicatesPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "FirstName",

            fields: this.getFields(),
            selectedItem: null,
            viewModalOpen: false,

            currentSelection: null,
            currentSelectionList: []
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.CandidatesDuplicates
        }));
    };

    handleMerge = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: {
                CandidateID1: this.state.selectedItem?.CandidateID1,
                CandidateID2: this.state.selectedItem?.CandidateID2,
                MergeID: this.state.currentSelection.ID
            },
            query: this.getQuery(),
            resource: Resources.CandidatesDuplicates,
            piggyResource: Resources.CandidatesDuplicates,
            notificationMessage: "Candidate entries have been merged."
        }));
        this.handleToggleViewModal(0);
    }

    /** UI Events
     ================================================================= */
    handleToggleViewModal = (it) => {
        const currentSelectionList = !!it ? [
            {
                ID: it.CandidateID1,
                name: it.ContactName1,
                description: it.Email1 + "(" + it.Phone1 + ")"
            },
            {
                ID: it.CandidateID2,
                name: it.ContactName2,
                description: it.Email2 + "(" + it.Phone2 + ")"
            }
        ] : [];
        this.setState({
            viewModalOpen: !this.state.viewModalOpen,
            selectedItem: it,
            currentSelectionList: currentSelectionList
        }, () => {
            this.setState({
                currentSelection: !!it ? currentSelectionList[0] : null
            })
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleLinkClick = (link) => {
        this.props.history.push(link);
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "CandidateID";
    }

    getResourceName = () => {
        return Resources.Candidate;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ContactName1: new Field("ContactName1", '', ['empty'], false, 'text', {onCellClick: (it) => this.handleLinkClick("/contact/" + it.CandidateID1)}),
            Email1: new Field("Email1", '', ['empty'], false, 'text'),
            Phone1: new Field("Phone1", '', ['empty'], false, 'text'),

            ContactName2: new Field("ContactName2", '', ['empty'], false, 'text', {onCellClick: (it) => this.handleLinkClick("/contact/" + it.CandidateID2)}),
            Email2: new Field("Email2", '', ['empty'], false, 'text'),
            Phone2: new Field("Phone2", '', ['empty'], false, 'text')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    {/* Page header */}
                    <PageHeader
                        title={translate("page.title.duplicate_contacts")}
                    >
                    </PageHeader>

                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="flex flex-wrap">

                        </div>

                        <ResourceTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={null}
                            onDelete={null}
                            onRestore={null}
                            onCustomAction={(it) => {
                                return (
                                    <button
                                        onClick={() => this.handleToggleViewModal(it)}
                                        type="button"
                                        className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                    >
                                        <ChevronDoubleLeftIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                        Merge
                                    </button>
                                )
                            }}
                        />
                    </div>

                    <Transition show={this.state.viewModalOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            id="modal"
                            className="fixed inset-0 z-20 overflow-y-auto"
                            static
                            open={this.state.viewModalOpen}
                            onClose={this.handleToggleViewModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                    &#8203;
                    </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div
                                        className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                        <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                            <p>You are about to merge the two following candidates:</p>
                                            <p className={"mt-1 mb-1 text-sm text-secondary-500"}>* Selected candidate will be kept, while all data from second will be copied over to the selected one:</p>

                                            <RadioGroup value={this.state.currentSelection} onChange={(item) => {
                                                this.setState({
                                                    currentSelection: item
                                                })
                                            }}>
                                                <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                                                <div className="bg-white rounded-md -space-y-px">
                                                    {this.state.currentSelectionList.map((setting, settingIdx) => (
                                                        <RadioGroup.Option
                                                            key={setting.name}
                                                            value={setting}
                                                            className={({ checked }) =>
                                                                classNames(
                                                                    settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                                                    settingIdx === 1 ? 'rounded-bl-md rounded-br-md' : '',
                                                                    checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
                                                                    'relative border p-4 flex cursor-pointer focus:outline-none'
                                                                )
                                                            }
                                                        >
                                                            {({ active, checked }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300',
                                                                            active ? 'ring-2 ring-offset-2 ring-primary-500' : '',
                                                                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    >
                                                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                                    </span>
                                                                    
                                                                    <div className="ml-3 flex flex-col">
                                                                        <RadioGroup.Label
                                                                            as="span"
                                                                            className={classNames(checked ? 'text-primary-900' : 'text-gray-900', 'block text-sm font-medium')}
                                                                        >
                                                                            {setting.name}
                                                                        </RadioGroup.Label>

                                                                        <RadioGroup.Description
                                                                            as="span"
                                                                            className={classNames(checked ? 'text-primary-700' : 'text-gray-500', 'block text-sm')}
                                                                        >
                                                                            {setting.description}
                                                                        </RadioGroup.Description>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </RadioGroup.Option>
                                                    ))}
                                                </div>
                                            </RadioGroup>
                                            
                                            <p>Are you sure?</p>
                                        </div>

                                        <div className="pt-5">
                                            <div className="flex justify-end">
                                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                    <button
                                                        type="button"
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                        onClick={this.handleMerge}
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                        onClick={this.handleToggleViewModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </main>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(CandidateDuplicatesPage);