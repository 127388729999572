import {Popover, Transition} from '@headlessui/react'
import React, {Fragment} from 'react'
import {classNames} from '../../util/util'
import ChevronDownIcon from "@heroicons/react/solid/ChevronDownIcon";

export default function PopOver(props) {
    const {className, widthClass, positionClass, btnLabel, btnLabelClass, btnClass, BtnIcon, btnIconClass, children, chevronIcon, btnCustomHtml} = props;
    const defaultWidth = "max-w-xl";
    const defaultPosition = "-translate-x-1/2 left-1/2" // change to middle, left, right
    return (

        <Popover className={!!className ? className : "relative"}>
            {({open}) => (
                <>
                    <Popover.Button
                        className={classNames(open ? '' : 'text-opacity-90', !!btnClass ? btnClass : "text-white group bg-secondayr-700 px-3 py-2 rounded-md inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75")}
                    >
                        {!!BtnIcon && (
                            <BtnIcon
                                className={classNames(open ? '' : 'text-opacity-70', !!btnIconClass ? btnIconClass : "ml-2 h-5 w-5 text-gray-400 group-hover:text-opacity-80 transition ease-in-out duration-150")}
                                aria-hidden="true"
                            />
                        )}

                        {!!btnLabel && (
                            <span className={btnLabelClass}>{btnLabel}</span>
                        )}

                        {!!chevronIcon && (
                            <ChevronDownIcon className="ml-2 h-5 w-5 text-secondary-500 group-hover:text-opacity-80 transition ease-in-out duration-150" />
                        )}

                        {btnCustomHtml}
                    </Popover.Button>

                    <Transition
                        as={Fragment}
                        show={open}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            className={classNames("absolute z-50 px-4 mt-3 transform sm:px-0 w-screen", !!widthClass ? widthClass : defaultWidth, !!positionClass ? positionClass : defaultPosition)}>
                            <div
                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                {children}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    )
}