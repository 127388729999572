import React, {Component} from "react";
import {logout} from "../../data/actions/user";

export default class Logout extends Component {

    render() {
        return (
            <React.Fragment>
                <a title={this.props.title}
                   href={"#"}
                   className={this.props.className} onClick={() => {
                    this.props.dispatch(logout({}));
                }}>
                    {this.props.translate("app.logout")}
                </a>
            </React.Fragment>
        )
    }
}