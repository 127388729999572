import React, {Component} from 'react'
import {BriefcaseIcon, LocationMarkerIcon, PencilIcon, PhoneIcon,} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../util/util";
import ContactsTab from "./contactsTab";
import LocationsTab from "./locationsTab";
import RolesTab from "./rolesTab";
import {getSecondResource} from "../../data/actions/secondResource";
import DocumentsTab from "../common/documentsTab";
import {Field} from "../../data/services/fields";
import {updateResource} from "../../data/actions/resource";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import Env from "../../util/env";
import OverviewTab from "./overviewTab";
import NavResponsive from "../../components/nav-responsive";
import PageHeader from "../../components/layout-dashboard/page-header";

class ClientSinglePage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: "Overview", resource: Resources.ClientOverview, current: true},
            {name: 'Contacts', resource: Resources.ClientContacts, current: false},
            {name: 'Locations', resource: Resources.ClientLocations, current: false},
            {name: 'Roles', resource: Resources.ClientRoles, current: false},
            {name: 'Invoices', resource: Resources.ClientInvoices, current: false},
            {name: 'Documents', resource: Resources.ClientDocuments, current: false},
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,

            editModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.secondResource.data === null) && (!!this.props.secondResource.data)) {
            LocalStorage.getAndSave('clientsHistory', [], (value) => {
                const entryMap = {};
                value.unshift({
                    id: this.props.match.params.id,
                    name: this.props.secondResource.data.ClientName
                });
                return value.filter((item) => {
                    if (entryMap[item.id] === undefined) {
                        entryMap[item.id] = true;
                        return true;
                    }
                    return false;
                }).slice(0, 5);
            });
        }

        if (this.getId() !== prevProps.match.params.id) {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Client
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getFields = () => {
        const fieldTemplates = {
            ClientName: new Field("ClientName", '', ['empty'], false, "text"),
            OrganizationTypeID: new Field("OrganizationTypeID", '', ['empty'], false, "select"),

            AddressName: new Field("AddressName", '', ['empty'], false, "text"),
            AddressName2: new Field("AddressName2", '', [], false, "text"),
            CityName: new Field("CityName", '', ['empty'], false, "text"),
            StateID: new Field("StateID", '', ['empty'], false, "select"),
            PostalCode: new Field("PostalCode", '', ['empty'], false, "text"),
            CountryID: new Field("CountryID", '', ['empty'], false, "select"),
            AreaCode: new Field("AreaCode", '', ['empty'], false, "text"),
            PhoneNumber: new Field("PhoneNumber", '', ['empty'], false, "text"),
            PhoneExtension: new Field("PhoneExtension", '', ['empty'], false, "text"),
            ImagePath: new Field("ImagePath", '', [], false, 'image')
        };

        const item = getProp(this.props, "secondResource.data", {});

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const Client = getProp(this.props, "secondResource.data", {});

        return (
            <LayoutDashboard {...this.props}>
                {/* Page heading */}
                <PageHeader
                    title={translate("page.title.client")}
                >
                    <button
                        onClick={this.handleToggleEditModal}
                        className="btn btn-primary mt-5 sm:mt-0"
                    >
                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                        Edit
                    </button>
                </PageHeader>

                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between">
                        {!Client.ImagePath && (
                            <div
                                className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                <div
                                    className="text-inverse font-black">{(Client.ClientName + "").split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                            </div>
                        )}
                        {Client.ImagePath && (
                            <div
                                className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none overflow-hidden">
                                <img
                                    className="object-cover h-16 w-16"
                                    src={Env.getApiUrl('api/' + Resources.ClientImage, {
                                        id: this.getId(),
                                        token: LocalStorage.get('user').access_token
                                    })}
                                />
                            </div>
                        )}

                        <div className="flex-1 min-w-0">
                            <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                {Client.ClientName}
                            </h1>

                            {(!resource.isLoading || Client.ClientName) && (
                                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                   aria-hidden="true"/>
                                        {Client.AreaCode} {Client.PhoneNumber} {Client.PhoneExtension}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                            aria-hidden="true"/>
                                        {Client.AddressName}, {Client.CityName}, {Client.State}, {Client.PostalCode}
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-secondary-500">
                                        <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                       aria-hidden="true"/>
                                        {Client.OrganizationType}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="mt-5 flex xl:mt-0 xl:ml-4">
                            <span className="hidden sm:block">

                            </span>
                        </div>
                    </div>
                </header>

                <main className="pb-16 relative custom-min-h-tab bg-inverse">
                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("text." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                </label>

                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                    onChange={e => this.handleTabChange(e.target.value)}
                                >
                                    {this.state.tabs.map((tab) => (
                                        <option
                                            key={tab.name}
                                            value={tab.resource}
                                        >
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="border-b-2 border-primary-500 mt-6"></div>
                            </div>

                            <div className="hidden sm:block">
                                <div className="border-b border-secondary-200">
                                    <NavResponsive
                                        tabs={this.state.tabs}
                                        onTabChange={this.handleTabChange}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* Content */}
                        <div className="mt-6">
                            {this.state.selectedTab === Resources.ClientOverview && (
                                <OverviewTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientContacts && (
                                <ContactsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientLocations && (
                                <LocationsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientRoles && (
                                <RolesTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    dialogResource={this.props.dialogResource}
                                    resource={this.props.resource}
                                    ui={this.props.ui}
                                    history={this.props.history}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientInvoices && (
                                <DocumentsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    disableCreate={true}

                                    fields={{
                                        RoleID: new Field("RoleID", '', ['empty'], false, 'select-search'),
                                        InvoiceDate: new Field("InvoiceDate", '', [], false, 'date'),
                                    }}
                                    resourceName={Resources.ClientInvoices}
                                    primaryKey={"ClientInvoiceID"}
                                    title={"Invoice"}
                                />
                            )}

                            {this.state.selectedTab === Resources.ClientDocuments && (
                                <DocumentsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    resourceName={Resources.ClientDocuments}
                                    piggyResource={Resources.ClientDocuments}
                                    primaryKey={"ClientDocumentID"}
                                    title={"Document"}
                                />
                            )}
                        </div>
                    </div>

                    <ModalSaveResource
                        title={"Edit Client"}
                        widthClass="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                params.id = this.getId();
                                params.ClientID = this.getId();
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.Client,
                                    secondPiggyResource: Resources.Client,

                                    file: params?.ImagePath ? params?.ImagePath[0] : null,
                                    fileResource: Resources.ClientImage
                                }));
                                this.handleToggleEditModal(false)
                            }
                        }}
                        translate={this.props.translate}
                    />
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ClientSinglePage);