import React, {Component} from 'react'
import {
    AtSymbolIcon,
    BriefcaseIcon,
    CurrencyDollarIcon,
    LocationMarkerIcon,
    PencilIcon,
    PhoneIcon,
    TruckIcon, UserAddIcon, UserGroupIcon, UserRemoveIcon
} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {classNames, fillFieldsFromData, getLookupWithFilter, getProp} from "../../util/util";
import CredentialTab from "./../candidate-single-page/credentialTab";
import MotivationTab from "./../candidate-single-page/motivationTab";
import {createSecondResource, deleteSecondResource, getSecondResource} from "../../data/actions/secondResource";
import ResumeTab from "./../candidate-single-page/resumeTab";
import SkillsTab from "./../candidate-single-page/skillsTab";
import {Field} from "../../data/services/fields";
import {createResource, updateResource} from "../../data/actions/resource";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ReferenceTab from "./../candidate-single-page/referenceTab";
import Env from "../../util/env";
import DocumentsTab from "../common/documentsTab";
import {SocialIcon} from 'react-social-icons';
import ResourceListTab from "../../components/resource-list-tab";
import ModalCreateVersion from "../../components/modal/modal-create-version";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSelect from "../../components/field-select";
import ListBox from "../../components/listbox";

class ExtCandidateSinglePage extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: 'Experience', resource: Resources.ExtCandidateResume, current: true},
            {name: 'Skills', resource: Resources.ExtCandidateSkills, current: false},
            {name: 'Credentials', resource: Resources.ExtCandidateCredentials, current: false},
            {name: 'Motivation', resource: Resources.ExtCandidateMotivation, current: false},
            {name: 'References', resource: Resources.ExtCandidateReferences, current: false},
            {name: 'Documents', resource: Resources.ExtCandidateDocuments, current: false},
            {name: 'Media', resource: Resources.ExtCandidateMedia, current: false},
            {name: "Edit Perms", resource: Resources.CandidateExtRecruiterPerm, current: false}
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,

            selectedVersionID: 0,

            editModalOpen: false,
            msgModalOpen: false,

            versionModalOpen: false,
            versionDeleteModalOpen: false,
            versionSwitchModalOpen: false
        };
    };

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.ExtCandidate
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        })
    };

    handleToggleMsgModal = () => {
        this.setState({
            msgModalOpen: !this.state.msgModalOpen
        })
    };

    handleToggleVersionModal = () => {
        this.setState({
            versionModalOpen: !this.state.versionModalOpen
        })
    }

    handleToggleDeleteVersionModal = () => {
        this.setState({
            versionDeleteModalOpen: !this.state.versionDeleteModalOpen
        })
    }

    handleToggleVersionSwitchModal = () => {
        this.setState({
            versionSwitchModalOpen: !this.state.versionSwitchModalOpen
        })
    }

    handleVersionSwitch = (value) => {
        const tab = this.state.selectedTab;
        this.setState({
            selectedTab: null,
            selectedVersionID: value
        }, () => {
            this.handleTabChange(tab);
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getFields = () => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', ['empty'], false, 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),

            Credentials: new Field("Credentials", '', [], false, 'text'),
            TitleID: new Field("TitleID", '', [], false, 'select'),
            Headline: new Field("Headline", '', [], false, 'textarea'),

            Address1: new Field("Address1", '', [], false, 'text'),
            Address2: new Field("Address2", '', [], false, 'text'),
            City: new Field("City", '', [], false, 'text'),
            StateID: new Field("StateID", '', [], false, 'select'),
            PostalCode: new Field("PostalCode", '', [], false, 'text'),

            Relocate: new Field("Relocate", '', [], false, 'checkbox'),
            LinkedinProfileURL: new Field("LinkedinProfileURL", '', [], false, 'text'),

            ImagePath: new Field("ImagePath", '', [], false, 'avatar')
        };

        const item = getProp(this.props, "secondResource.data", {});

        return fillFieldsFromData(fieldTemplates, item);
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const candidate = getProp(this.props, "secondResource.data", {});

        let documentDocType = getLookupWithFilter('MediaType', 'MediaTypeID', 'MediaType',
            item => item.IsVideoType == 0,
            {});

        return (
            <LayoutDashboard {...this.props}>

                {/* Page heading */}
                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="mx-auto p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between">
                        {!candidate.ImagePath && (
                            <div
                                className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                <div
                                    className="text-inverse font-black">{(candidate.FirstName + " " + candidate.LastName).split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                            </div>
                        )}

                        {candidate.ImagePath && (
                            <React.Fragment>
                                <div
                                    className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none overflow-hidden">
                                    <img
                                        className="object-cover h-16 w-16"
                                        src={Env.getApiUrl('api/' + Resources.ExtCandidateImage, {
                                            id: this.getId(),
                                            token: LocalStorage.get('user').access_token
                                        }) + "&date=" + new Date().getTime()}
                                        alt=""
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        {(!resource.isLoading || candidate.FirstName) && (
                            <div className="flex-1 min-w-0">
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                    {candidate.FirstName} {candidate.LastName}
                                </h1>
                                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
                                    {!!candidate.Title && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Title"}>
                                            <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Title}
                                        </div>
                                    )}

                                    {!!candidate.Phone && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Phone"}>
                                            <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.AreaCode} {candidate.Phone} {candidate.PhoneExtension}
                                        </div>
                                    )}

                                    {!!candidate.Email && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Email"}>
                                            <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Email}
                                        </div>
                                    )}

                                    {(!!candidate.Address1 || !!candidate.Address2) && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Location"}>
                                            <LocationMarkerIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Address1 ? (candidate.Address1 + ",") : ""} {candidate.Address2 ? (candidate.Address2 + ",") : ""} {candidate.City}, {candidate.State}{candidate.PostalCode ? (", " + candidate.PostalCode) : ""}
                                        </div>
                                    )}

                                    {!!candidate.Headline && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Headline"}>
                                            <CurrencyDollarIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                            {candidate.Headline}
                                        </div>
                                    )}

                                    {!!candidate.LinkedinProfileURL && (
                                        <div
                                            className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                            title={"Headline"}>
                                            <SocialIcon className="social-icon-w-30" url={candidate.LinkedinProfileURL}
                                                        target={"_blank"}/>
                                        </div>
                                    )}

                                    <div className="mt-2 flex items-center text-sm text-secondary-500 cursor-help mr-8"
                                         title={"Relocate"}>
                                        <TruckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"/>
                                        {candidate.Relocate ? "Yes" : "No"}
                                    </div>


                                    <div
                                        className="mt-2 flex flex-1 items-center text-sm text-primary-800 mr-2"
                                    >
                                        <span className={"flex mr-2"}>Profile Version</span>

                                        <ListBox
                                            name="ProfileVersion"
                                            options={getProp(candidate, "availableVersions", []).map(it => {
                                                let item = {}
                                                item.label = it.ProfileName;
                                                item.value = it.CandidateProfileID;
                                                return item;
                                            })}
                                            onChange={(option) => {
                                                this.handleVersionSwitch(option)
                                            }}
                                            value={this.state.selectedVersionID}
                                        />

                                        <div
                                            onClick={this.handleToggleVersionModal}
                                            className="ml-2 mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                            title={"Add Profile"}>
                                            <UserAddIcon
                                                className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                        </div>

                                        {(this.state.selectedVersionID != 0) && (<>
                                            <div
                                                onClick={this.handleToggleDeleteVersionModal}
                                                className="mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                                title={"Remove Profile"}>
                                                <UserRemoveIcon
                                                    className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                            </div>

                                            <div
                                                onClick={this.handleToggleVersionSwitchModal}
                                                className="mt-2 flex items-center text-sm text-orange-800 cursor-pointer mr-2"
                                                title={"Switch base profile"}>
                                                <UserGroupIcon
                                                    className="flex-shrink-0 h-5 w-5 text-secondary-400"/>
                                            </div>
                                        </>)}

                                    </div>

                                </div>
                            </div>
                        )}

                        <div className="mt-5 flex xl:mt-0 xl:ml-4">
                            <button
                                onClick={this.handleToggleEditModal}
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                            >
                                <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                Edit
                            </button>
                        </div>
                    </div>
                </header>

                <main className="pt-8 pb-16 sm:px-6 lg:px-8 px-4 relative bg-inverse custom-min-h-tab">
                    <div className="">
                        <div className="">
                            {/* Page title */}
                            <h2 className="hidden sm:block text-lg font-medium text-secondary-900">{translate("text." + this.state.selectedTab)}</h2>

                            {/* Tabs */}
                            <div className="sm:hidden">
                                <label htmlFor="tabs" className="sr-only">
                                    {translate("text.select_a_tab")}
                                </label>

                                <select
                                    id="tabs"
                                    name="tabs"
                                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-secondary-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                                    defaultValue={this.state.tabs.find((tab) => tab.current).name}
                                    onChange={e => this.handleTabChange(e.target.value)}
                                >
                                    {this.state.tabs.map((tab) => (
                                        <option
                                            key={tab.name}
                                            value={tab.resource}
                                        >
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>

                                <div className="border-b-2 border-primary-500 mt-6">
                                </div>
                            </div>

                            <div className="hidden sm:block">
                                <div className="border-b border-secondary-200">
                                    <nav className="-mb-px flex space-x-8 overflow-y-auto pt-2" aria-label="Tabs">
                                        {this.state.tabs.map((tab) => (
                                            <button
                                                key={tab.name}
                                                onClick={() => this.handleTabChange(tab.resource)}
                                                className={classNames(
                                                    tab.current
                                                        ? 'border-primary-500 text-primary-600'
                                                        : 'border-transparent text-secondary-500 hover:text-secondary-700 hover:border-secondary-200',
                                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                            >
                                                {tab.name}
                                                {tab.count ? (
                                                    <span
                                                        className={classNames(
                                                            tab.current ? 'bg-primary-100 text-primary-600' : 'bg-secondary-100 text-secondary-900',
                                                            'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                        )}
                                                    >
                                                  {tab.count}
                                                </span>
                                                ) : null}
                                            </button>
                                        ))}
                                    </nav>
                                </div>
                            </div>

                        </div>

                        {/* Content */}
                        <div className="mt-6 ">
                            {this.state.selectedTab === Resources.ExtCandidateResume && (
                                <ResumeTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateResume}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateSkills && (
                                <SkillsTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateSkills}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateCredentials && (
                                <CredentialTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateCredentials}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateMotivation && (
                                <MotivationTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateMotivation}
                                    disableEdit={false}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateReferences && (
                                <ReferenceTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateReferences}
                                    title={"Reference"}

                                    query={{CandidateProfileID: this.state.selectedVersionID}}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateMedia && (
                                <DocumentsTab
                                    id={this.getId()}
                                    documentTypes={documentDocType}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    resourceName={Resources.ExtCandidateMedia}
                                    primaryKey={"CandidateMediaID"}
                                    title={"Media"}
                                    mediaTypesText={translate("text.video_formats")}
                                />
                            )}

                            {this.state.selectedTab === Resources.ExtCandidateDocuments && (
                                <DocumentsTab
                                    id={this.getId()}
                                    documentTypes={documentDocType}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    documentType={true}
                                    fields={{
                                        IsPublic: new Field("IsPublic", '', [], false, 'checkbox')
                                    }}
                                    resourceName={Resources.ExtCandidateDocuments}
                                    primaryKey={"CandidateDocumentID"}
                                    title={"Document"}
                                />
                            )}

                            {this.state.selectedTab === Resources.CandidateExtRecruiterPerm && (
                                <ResourceListTab
                                    id={this.getId()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    dialogTitle={"Recruiter Edit Permissions"}

                                    sortBy={"CandidateEditPermID"}
                                    primaryKey={"CandidateEditPermID"}
                                    resourceName={Resources.CandidateExtRecruiterPerm}
                                    fields={{
                                        RecruiterID: new Field("RecruiterID", '', ['empty'], false, 'select-search')
                                    }}
                                    metadata={{
                                        RecruiterID: {
                                            api: 'api/' + Resources.ExtCandidateInvites,
                                            searchMap: (item) => ({
                                                value: item.CompanyID,
                                                label: item.RecruiterName
                                            })
                                        }
                                    }}
                                    disableEdit={true}
                                />
                            )}

                        </div>
                    </div>

                    <ModalSaveResource
                        title={"Edit Contact"}
                        widthClass="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                console.log(params);
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.ExtCandidate,
                                    secondPiggyResource: Resources.ExtCandidate,
                                    file: params?.ImagePath ?? null,
                                    fileResource: Resources.ExtCandidateImage
                                }));
                                this.handleToggleEditModal(false)
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalCreateVersion
                        title={"Create Profile Version"}
                        visible={this.state.versionModalOpen}
                        onClose={this.handleToggleVersionModal}
                        onSubmit={(params) => {
                            if (params) {
                                params.CandidateID = this.getId();
                                params.id = this.getId();
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get("user"),
                                    params: params,
                                    resource: Resources.RoleVersionCreate,
                                    notificationMessage: "New profile version is created",
                                    query: this.getQuery(),
                                    piggyResource: Resources.ExtCandidate
                                }));
                                this.handleToggleVersionModal()
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalConfirm
                        title={"Switch Base Profile"}
                        text={"Are you sure you want to make current profile base?"}
                        visible={this.state.versionSwitchModalOpen}
                        onClose={this.handleToggleVersionSwitchModal}
                        btnColor={'blue'}
                        iconColor={'blue'}
                        onConfirm={() => {
                            let params = {};
                            params.CandidateID = this.getId();
                            params.id = this.getId();
                            params.CandidateProfileID = this.state.selectedVersionID;

                            this.props.dispatch(createSecondResource({
                                user: LocalStorage.get("user"),
                                params: params,
                                resource: Resources.RoleVersionSwitch,
                                notificationMessage: "Profile is set a as base one.",
                                query: this.getQuery(),
                                piggyResource: Resources.ExtCandidate,
                                piggyQuery: this.getQuery()
                            }));

                            this.handleToggleVersionSwitchModal(false);
                            this.handleVersionSwitch(0);
                        }}
                    />

                    <ModalConfirm
                        title={"Delete Profile Version"}
                        text={"Are you sure you want to delete current profile?"}
                        visible={this.state.versionDeleteModalOpen}
                        onClose={this.handleToggleDeleteVersionModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteSecondResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    CandidateProfileID: this.state.selectedVersionID
                                }, this.getQuery()),
                                resource: Resources.RoleVersionCreate,
                                notificationMessage: "Profile version is deleted",
                                piggyResource: Resources.ExtCandidate,
                                piggyQuery: this.getQuery()
                            }));
                            this.handleToggleDeleteVersionModal(false);
                            this.handleVersionSwitch(0);
                        }}
                    />

                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ExtCandidateSinglePage);