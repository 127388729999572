import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    classNames,
    DEFAULT_CRUD_STATE,
    fieldsToHtml,
    fillFieldsFromData,
    filterColors,
    getLookup,
    getProp
} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import RoleModal from "../client-single-page/roles";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import ArchivedSwitch from "../../components/archived-switch";
import PageHeader from "../../components/layout-dashboard/page-header";
import {
    BookmarkIcon,
    ClipboardCopyIcon,
    CollectionIcon,
    LockClosedIcon,
    PhoneOutgoingIcon
} from "@heroicons/react/outline";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {PlusIcon} from "@heroicons/react/solid";
import {getSecondResource} from "../../data/actions/secondResource";
import DialogDefault from "../../components/dialog-default";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import PopOver from "../../components/popover";
import ColorSwatchIcon from "@heroicons/react/outline/ColorSwatchIcon";
import CheckCircleIcon from "@heroicons/react/solid/CheckCircleIcon";

class RolesPage extends Component {

    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search);
        const RoleStatusID = params.get('stateFilter');
        const createDialog = params.get('createDialog');
        const openRoleDialog = params.get('openRole');

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "RoleName",

            fields: this.getFields('', 'ClientID'),

            columnFilters: {},
            selectedItems: {},
            colorFilter: [],

            search: {
                RoleStatusID: new Field("RoleStatusID", RoleStatusID ?? '', [], false, 'select'),
            },

            selectedItem: !!openRoleDialog ? {"RoleID": openRoleDialog} : null,
            selectedRecruiter: null,

            createModalOpen: !!createDialog,
            viewModalOpen: !!openRoleDialog,
            editModalOpen: false,
            confirmModalOpen: false,
            recruiterModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
        this.fetchSecondData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    fetchSecondData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            query: {},
            resource: Resources.RolesCount
        }));
    }

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: Resources.Role,
            piggyResource: this.getResourceName()
        }));
    }

    submitRowColor = (ColorID) => {
        if (!Object.keys(this.state.selectedItems).length) return;

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedItems),
                ColorID: ColorID
            },
            notificationMessage: `Row color updated`,
            resource: Resources.RolesColor,
            query: this.getQuery(),
            piggyResource: Resources.Roles,
        }));

        this.setState({selectedItems: {}})
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    }

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters,
            limit: this.state.limit,
            offset: 0,
            paginationPage: 1
        }, () => this.fetchData())
    };

    handleCopyToClipboard = (link) => {
        this.setState({urlCopied: true}, () => {
            let textField = window.document.createElement('textarea')
            textField.innerText = link;
            window.document.body.appendChild(textField);
            textField.select();
            textField.setSelectionRange(0, 99999);
            window.document.execCommand('copy');
            textField.remove();
        });

        setTimeout(() => {
            this.setState({urlCopied: false})
        }, 2000)
    }

    handleSearchChange = (name, value) => {
        this.setState({
            search: FieldsManager.updateField(this.state.search, name, value),
            paginationPage: 1,
            offset: 0
        }, () => this.fetchData());
    };

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.Roles;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            RoleStatusID: this.state.search.RoleStatusID.value,
            columnFilters: JSON.stringify(this.state.columnFilters),
            colorIDs: JSON.stringify(this.state.colorFilter),
        }
    }

    getFields = (item = null, excludeField = null) => {

        const fieldTemplates = {
            ClientID: new Field("ClientID", '', ['empty'], false, 'select-search'),
            ClientName: new Field("ClientName", '', [], false, 'hidden'),
            RecruiterID: new Field("RecruiterID", '', [], false, 'select', {onCellClick: (it) => this.toggleRecruiterModal(it)}),
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select-search', {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            SpecialityID: new Field("SpecialityID", '', [], false, 'select', {filterOptions: getLookup("Speciality", "SpecialityID", "Speciality")}),
            ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
            ContactID: new Field("ContactID", item ? (item.Contact) : "", [], false, 'select-search'),
            RoleStatusID: new Field("RoleStatusID", '', ['empty'], false, 'select', {filterOptions: getLookup("RoleStatus", "RoleStatusID", "RoleStatus")}),
            PostDate: new Field("PostDate", '', [], false, 'date'),
            FillDate: new Field("FillDate", '', [], false, 'date'),
            FilledCandidateID: new Field("FilledCandidateID", item ? (item.FilledCandidate) : "", [], false, 'select-search', {}, {isClearable: true, menuPlacement: "top"}),
            AgreedSalary: new Field("AgreedSalary", '', ['float_or_empty'], false, 'text'),
            RefNumber: new Field("RefNumber", '', [], false, 'text'),
        };

        delete fieldTemplates[excludeField];

        return fillFieldsFromData(fieldTemplates, item);
    };

    getShareLink = () => {
        return window.location.origin + '/roles/' + getProp(this.props, "resource.data.token", []);
    }

    toggleRecruiterModal = (it = null) => {
        this.setState({
            recruiterModalOpen: !this.state.recruiterModalOpen,
            selectedRecruiter: it
        })
    }

    handleItemSelection = (it) => {
        let items = this.state.selectedItems;

        if (items[it[this.getPrimaryKey()]]) {
            delete items[it[this.getPrimaryKey()]];
        } else {
            items[it[this.getPrimaryKey()]] = it;
        }

        this.setState({selectedItems: items})
    }

    handleSelectAllOnPage = (data, selected) => {
        let selectedItems = {}

        if (!selected) {
            selectedItems = data.reduce((memo, it) => {
                memo[it[this.getPrimaryKey()]] = it;
                return memo
            }, {});
        }

        this.setState({selectedItems});
    }

    updateColorFilters = (i) => {
        let colorFilter = this.state.colorFilter;

        const index = colorFilter.indexOf(i);
        if (index > -1) {
            colorFilter.splice(index, 1);
        } else {
            colorFilter.push(i)
        }

        this.setState({
            offset: 0,
            paginationPage: 1,
            colorFilter
        }, () => this.fetchData())
    }

    clearColorFilter = () => {
        this.setState({
            colorFilter: []
        }, () => this.fetchData())
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const isItemSelected = Object.keys(this.state.selectedItems).length;

        const colorPicker = filterColors.map((it, i) => (
            <button
                onClick={() => this.submitRowColor(i)}
                disabled={!isItemSelected}
                className={classNames(
                    "w-6 h-6 rounded-full ring-offset-inverse ring-primary-500 focus:ring-2 focus:ring-offset-2",
                    it === "transparent" ? "bg-transparent border border-primary" : it,
                    isItemSelected ? "" : "opacity-25 cursor-default"
                )}
            />)
        )

        const colorFilter = filterColors.map((it, i) => (
            <button
                onClick={() => this.updateColorFilters(i)}
                className={classNames(
                    "w-6 h-6 rounded-full ring-offset-inverse ring-primary-500 focus:ring-2 focus:ring-offset-2 flex items-center justify-center",
                    it === "transparent" ? "bg-transparent border border-primary" : it,
                )}
            >
                {this.state.colorFilter.includes(i)
                    ? <CheckIcon className={classNames("w-5 h-5", !!i ? "text-white" : "text-primary-600")}/>
                    : null}
            </button>)
        )

        const cards = [
            {
                name: "All Roles",
                RoleStatusID: "",
                icon: CollectionIcon,
                amount: getProp(this.props, "secondResource.data.allCount", 0)
            },
            {
                name: 'Open roles',
                RoleStatusID: 1,
                icon: ClipboardCopyIcon,
                amount: getProp(this.props, "secondResource.data.openCount", 0)
            },
            {
                name: 'Interviewing roles',
                RoleStatusID: 2,
                icon: PhoneOutgoingIcon,
                amount: getProp(this.props, "secondResource.data.interviewCount", 0)
            },
            {
                name: 'Filled roles',
                RoleStatusID: 3,
                icon: LockClosedIcon,
                amount: getProp(this.props, "secondResource.data.closedCount", 0)
            },
        ];

        const metadata = {
            ClientID: {
                api: 'api/' + Resources.Clients,
                query: {},
                searchMap: (item) => ({
                    value: item.ClientID,
                    label: item.ClientName
                })
            },
            ClientLocationID: {
                api: 'api/' + Resources.ClientLocations,
                query: {id: !!this.state.selectedItem ? this.state.selectedItem['ClientID'] : 0},
                searchMap: (item) => ({
                    value: item.ClientLocationID,
                    label: item.LocationName
                })
            },
            ContactID: {
                api: 'api/' + Resources.ClientContacts,
                query: {id: !!this.state.selectedItem ? this.state.selectedItem['ClientID'] : 0},
                searchMap: (item) => ({
                    value: item.ClientContactID,
                    label: item.ClientContact
                })
            },
            FilledCandidateID: {
                api: 'api/' + Resources.Candidates,
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName,
                })
            },
            RoleTitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            },
            RecruiterID: getProp(this.props, "resource.data.recruiters", []).reduce((memo, it) => {
                memo[it.ContactID] = it.FirstName + " " + it.LastName;
                return memo
            }, {})
        };

        const metadataAdd = {
            ClientID: {
                api: 'api/' + Resources.Clients,
                query: {},
                searchMap: (item) => ({
                    value: item.ClientID,
                    label: item.ClientName
                })
            },
            ClientLocationID: {
                api: 'api/' + Resources.ClientLocations,
                query: (fieldsCpy) => {
                    const client = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        id: client?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientLocationID,
                    label: item.LocationName
                }),
            },
            ContactID: {
                api: 'api/' + Resources.ClientContacts,
                query: (fieldsCpy) => {
                    const client = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        id: client?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientContactID,
                    label: item.ClientContact
                })
            },
            FilledCandidateID: {
                api: 'api/' + Resources.Candidates,
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName,
                })
            },
            RoleTitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            },
            RecruiterID: getProp(this.props, "resource.data.recruiters", []).reduce((memo, it) => {
                memo[it.ContactID] = it.FirstName + " " + it.LastName;
                return memo
            }, {})
        }

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.roles")}
                    >
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </PageHeader>

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">

                        {/* Overview cards */}
                        <div className="mb-6 lg:mb-8">
                            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                {/* Card */}
                                {cards.map((card) => (
                                    <div key={card.name} className="bg-inverse overflow-hidden shadow rounded-lg">
                                        <div className="p-5">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <card.icon className="h-6 w-6 text-secondary-400"
                                                               aria-hidden="true"/>
                                                </div>
                                                <div className="ml-5 w-0 flex-1">
                                                    <dl>
                                                        <dt className={"text-sm  text-secondary-500 truncate " + (this.state.search.RoleStatusID.value == card.RoleStatusID ? ("font-bold text-primary-700") : "font-medium")}>{card.name}</dt>
                                                        <dd>
                                                            <div
                                                                className="text-lg font-medium text-secondary-900">{card.amount}</div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="bg-secondary-50 px-5 py-3">
                                            <div className="text-sm">
                                                <div
                                                    onClick={() => this.handleSearchChange('RoleStatusID', card.RoleStatusID)}
                                                    className="cursor-pointer font-medium text-primary-700 hover:text-primary-900">
                                                    {translate("text.view_all")}
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-wrap">
                            <div className="flex flex-wrap sm:space-x-6 md:mr-3 md:w-auto w-full">
                                <FieldSearch
                                    onChange={this.handleQueryChange}
                                    name={"query"}
                                    value={this.state.query}
                                    placeholder="Search"
                                    className="w-full"
                                    classNameContainer={"mb-3 w-full w-auto"}
                                />

                                <div className="relative ml-auto sm:ml-0">
                                    {!!this.state.colorFilter.length && (
                                        <span className="flex h-3 w-3 -top-1 -right-1 z-10 absolute">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75" />
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-500" />
                                        </span>
                                    )}

                                    <PopOver
                                        btnClass={classNames("btn btn-outline")}
                                        BtnIcon={ColorSwatchIcon}
                                        btnIconClass={classNames("h-5 w-5 relative")}
                                        btnLabelClass={"btn btn-outline"}
                                        chevronIcon={true}
                                        widthClass={"max-w-[14rem]"}
                                        positionClass="translate-x-0 right-0"
                                    >
                                        <div className="bg-inverse rounded-md pt-4 space-y-3">
                                            <p className="px-4 text-gray-600">Filter by color:</p>

                                            <div className="grid gap-4 grid-cols-5 px-4">
                                                {colorFilter}
                                            </div>

                                            <div className="flex border-t border-secondary-200 py-2 px-4">
                                                <button
                                                    onClick={() => this.clearColorFilter()}
                                                    className="btn btn-outline ml-auto"
                                                    disabled={!this.state.colorFilter.length}
                                                >
                                                    Clear all
                                                </button>
                                            </div>
                                        </div>
                                    </PopOver>
                                </div>

                                <div className="w-full sm:w-auto mb-3">
                                    <button
                                        onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query || this.state.colorFilter.length)
                                            ? this.setState({
                                                columnFilters: {},
                                                query: "",
                                                colorFilter: []
                                            }, () => this.fetchData())
                                            : null
                                        }
                                        className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query || this.state.colorFilter.length ? "btn-primary" : " btn-disabled")}
                                    >
                                        Clear all filters
                                    </button>
                                </div>

                                <div className="md:flex items-center space-x-3 mb-3 hidden">
                                    {colorPicker}
                                </div>
                            </div>

                            <div className="md:ml-auto md:flex">
                                <div className={"mb-3 mr-3 flex items-center space-x-2"}>
                                    <div className={"align-middle inline-block"}>
                                        Share
                                    </div>

                                    <LinkedinShareButton
                                        title={"Roles"}
                                        summary={""}
                                        source={this.getShareLink()}
                                        url={this.getShareLink()}
                                    >
                                        <LinkedinIcon size={32} round={true}/>
                                    </LinkedinShareButton>

                                    <TwitterShareButton
                                        title={"Roles"}
                                        summary={""}
                                        via={this.getShareLink()}
                                        url={this.getShareLink()}
                                    >
                                        <TwitterIcon size={32} round={true}/>
                                    </TwitterShareButton>

                                    <FacebookShareButton
                                        quote={""}
                                        url={this.getShareLink()}
                                    >
                                        <FacebookIcon size={32} round={true}/>
                                    </FacebookShareButton>

                                </div>

                                <span className="relative z-0 inline-flex shadow-sm rounded-md md:mr-6 mb-3">
                                    <div
                                        className="relative w-full md:w-auto inline-flex items-center px-4 py-2 mb-3 mb-0 rounded-md rounded-r-none border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
                                    >
                                        <BookmarkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>

                                        <div className="truncate whitespace-nowrap max-w-[calc(100vw-145px)]">
                                            {this.getShareLink()}
                                        </div>
                                    </div>

                                    <button
                                        title={translate("text.copy_url_to_clipboard")}
                                        type="button"
                                        onClick={() => this.handleCopyToClipboard(this.getShareLink())}
                                        className="inline-flex relative items-center px-3 py-2  mb-3 mb-0 rounded-md rounded-l-none -ml-px border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
                                    >
                                        {!this.state.urlCopied && (
                                            <ClipboardCopyIcon className="w-5 h-5" />
                                        )}

                                        {this.state.urlCopied && (
                                            <CheckCircleIcon className="w-5 h-5 text-primary-600" />
                                        )}
                                    </button>
                                </span>

                                <ArchivedSwitch
                                    classNameContainer="h-9 mb-2"
                                    translate={translate}
                                    value={this.state.archived}
                                    onChange={(val) => this.setState({archived: val}, this.fetchData)}
                                />
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}
                            columnFilters={this.state.columnFilters}

                            bulkSelectKey={this.getPrimaryKey()}
                            bulkSelectedItems={Object.keys(this.state.selectedItems)}
                            onBulkSelect={this.handleItemSelection}
                            onSelectAllOnPage={this.handleSelectAllOnPage}
                            onSubmitRowColor={this.submitRowColor}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleToggleEditModel}
                            onEdit={this.handleToggleView}
                            onDelete={this.handleToggleConfirmModal}
                            onRestore={this.handleRestore}
                        />{/* Switched view and edit on purpose */}
                    </div>

                    {this.state.viewModalOpen && (
                        <RoleModal
                            {...this.props}
                            topPosition={true}
                            id={getProp(this.state.selectedItem, this.getPrimaryKey(), 0)}
                            visible={!!this.state.viewModalOpen}
                            onClose={this.handleToggleView}
                        />
                    )}

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: Resources.Role,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />

                    <DialogDefault
                        title={"Recruiter Card"}
                        visible={this.state.recruiterModalOpen}
                        widthClass={"max-w-xl"}
                        onClose={() => this.toggleRecruiterModal()} // prevents data loss during closing animation
                        translate={translate}
                    >
                        <h2 className="text-lg mb-3 mt-4">{"Contact info for " + this.state.selectedRecruiter?.Recruiter}</h2>

                        {fieldsToHtml(Object.values(Object.assign({}, {
                            Recruiter: new Field("RecruiterName", this.state.selectedRecruiter?.Recruiter, [], false, 'readonly'),
                            RecruiterEmail: new Field("RecruiterEmail", this.state.selectedRecruiter?.RecruiterEmail, [], false, 'readonly'),
                            RecruiterPhone: new Field("RecruiterPhone", this.state.selectedRecruiter?.RecruiterPhone, [], false, 'readonly')
                        })), translate)}

                        <div className="mb-3"/>
                    </DialogDefault>
                </main>

                <ModalSaveResource
                    title={"Edit Role"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    validateFields={(fields) => {
                        if ((fields.RoleStatusID.value === 3) && (!fields.FilledCandidateID.value || !fields.FilledCandidateID.value.value)) {
                            fields.FilledCandidateID.errorMessage = ["fields.errors.empty"]
                        }
                        return fields;
                    }}
                    handleInputChange={(fields, name, value) => {
                        if (name === "ClientID") {
                            fields = FieldsManager.updateField(fields, "ContactID", "")
                            fields = FieldsManager.updateField(fields, "ClientLocationID", "")
                            fields.ContactID.props.setKey = "ContactID" + value;
                            fields.ClientLocationID.props.setKey = "ClientLocationID" + value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.state.selectedItem[this.getPrimaryKey()];
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Role,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)

                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={"Create Role"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    validateFields={(fields) => {
                        if ((fields.RoleStatusID.value === 3) && (!fields.FilledCandidateID.value || !fields.FilledCandidateID.value.value)) {
                            fields.FilledCandidateID.errorMessage = ["fields.errors.empty"]
                        }
                        return fields;
                    }}
                    handleInputChange={(fields, name, value) => {
                        if (name === "ClientID") {
                            fields = FieldsManager.updateField(fields, "ContactID", "")
                            fields = FieldsManager.updateField(fields, "ClientLocationID", "")
                            fields.ContactID.props.setKey = "ContactID" + value;
                            fields.ClientLocationID.props.setKey = "ClientLocationID" + value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = params.ClientID;

                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Role,
                                piggyResource: Resources.Roles
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataAdd}
                />
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(RolesPage);