import React, {Component} from "react";
import {connect} from "react-redux";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../util/util";
import {Field} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import FieldSearch from "../../components/field-search";
import PageHeader from "../../components/layout-dashboard/page-header";
import PublicLayout from "../../components/layout";
import Slideover from "../../components/slideover";
import RoleCard from "../../components/role-card";

class RolesPublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "RoleName",

            query: "",
            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleToggleViewModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.RolesShared;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            token: this.props.match.params && this.props.match.params.token
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select'),
            LocationName: new Field("LocationName", '', [], false, 'text'),
            // Requirements, Responsibilities, ClientLocationID, LocationName, AdvDescription
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const company = getProp(this.props, "resource.data.Company", {});
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <PublicLayout {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                    {!this.props.resource.isLoading && (
                        <PageHeader
                            title={translate("page.title.roles") + " - " + company.CompanyName}
                        />
                    )}

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleToggleViewModal}
                        />{/* Switched view and edit on purpose */}
                    </div>
                </main>

                <Slideover
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                >
                    <RoleCard
                        token={this.props.match.params && this.props.match.params.token}
                        role={this.state.selectedItem}
                        dispatch={this.props.dispatch}
                    />
                </Slideover>

            </PublicLayout>
        );
    }
}

export default connect(state => state)(RolesPublicPage);