import React, {Fragment, useCallback, useEffect, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {classNames} from "../../util/util";

export default function Modal(props) {
    const {initialFocusRef, children, hideOverlay} = props;
    const refDialog = useRef(null);

    const isLastModalInStack = () => {
        const lastModalInStack = document.getElementById('headlessui-portal-root').lastChild;

        return lastModalInStack === refDialog.current.parentElement.parentElement;
    }

    let {show, widthClass, onBackdropClick} = props;

    if (!show) show = false; // must be boolean

    if (!onBackdropClick) onBackdropClick = () => null

    // Close on ESC key
    useEffect(() => {
        if (show) {
            document.addEventListener("keydown", escFunction, false);
        }

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [show]);

    const escFunction = useCallback((event) => {
        if (show && event.keyCode === 27) {
            if (isLastModalInStack()) {
                props.onClose();
            }
        }
    }, [show]);

    widthClass = widthClass ?? "sm:max-w-lg sm:w-full";

    return (
        <Transition.Root show={!!show} as={Fragment}>
            <Dialog as="div" className="fixed z-40 inset-0 overflow-y-auto" initialFocus={initialFocusRef}
                    onClose={onBackdropClick}>
                <div ref={refDialog}
                     className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:py-0 sm:px-8">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay
                            className={classNames(hideOverlay ? "hidden" : "", "fixed inset-0 bg-black bg-opacity-50 transition-opacity")}/>
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div id={'modal'}
                             className={classNames(widthClass, "sm:w-full inline-block align-bottom bg-popup rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle bg-white")}>
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
