import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import LocalStorage from "./util/localStorage";
import NotFoundView from "./views/not-found";
import RegisterView from "./views/register";
import CandidateSinglePage from "./views/candidate-single-page"
import HomeView from "./views/home";
import DashboardView from "./views/dashboard";
import ClientsPage from "./views/clients-page";
import ClientSinglePage from "./views/client-single-page";
import RecruitingToolsPage from "./views/recruting-tools-page";
import PrivacyPolicyPage from "./views/privacy-policy-page";
import PrivacyPolicyPublicPage from "./views/privacy-policy-page/privacyPublicPage";
import HelpPage from "./views/help-page";
import NotificationsPage from "./views/notifications-page";
import RolesPage from "./views/roles-page";
import ReportsPage from "./views/reports-page";
import CandidatesPage from "./views/candidates-page";
import SettingsPage from "./views/settings-page";
import RoomPage from "./views/room-page";
import RolesPublicPage from "./views/roles-public-page";
import ImportPage from "./views/import-page";
import FinancialPage from "./views/financial-page";
import FeedbackPublicPage from "./views/feedback-public-page";
import UsersPage from "./views/users-page";
import RolesExtPage from "./views/ext-candidate-roles-page";
import {checkPerm, READ_PERM} from "./util/util";
import Resources from "./data/services/resources";
import ExtHomePage from "./views/ext-candidate-home-page";
import ExtCandidateSinglePage from "./views/ext-candidate-profile-page";
import RolePublicPage from "./views/role-public-page";
import SocketListener from "./socket-listener";
import TasksPage from "./views/tasks";
import LoggedDevicesPage from "./views/logged-devices-page";
import ExtClientHomePage from "./views/ext-client-home-page";
import ExtClientRolesPage from "./views/ext-client-roles-page";
import ExtClientContactsPage from "./views/ext-client-contacts-page";
import RecruiterPublicPage from "./views/recruiter-profiles-page";
import ClientContactsPage from "./views/client-contacts-page";
import CandidateDuplicatesPage from "./views/candidate-duplicates-page";
import RegisterCandidateView from "./views/register-candidate";
import PaymentManagementPage from "./views/Payment";
import IdleModal from "./components/idle-modal";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: checkPerm(Resources.Clients, READ_PERM) ? "/dashboard" : (checkPerm(Resources.ExtRoles, READ_PERM) ? "/dash-board" : "/client-board"),
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

class RouterListener extends Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {

        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    {/* Login, register, and reset password routes */}
                    <PublicRoute exact path="/" component={(props) => getComponent(Login, props)}/>
                    <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                    <PublicRoute exact path="/register" component={(props) => getComponent(RegisterView, props)}/>
                    <PublicRoute exact path="/register-candidate/:token"
                                 component={(props) => getComponent(RegisterCandidateView, props)}/>
                    <PublicRoute exact path="/register-candidate/:token/:role_id"
                                 component={(props) => getComponent(RegisterCandidateView, props)}/>

                    <PublicRoute exact path="/register-candidate"
                                 component={(props) => getComponent(RegisterCandidateView, props)}/>

                    <PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPassword, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                    {/* HR routes */}
                    <PrivateRoute exact path="/dashboard" component={(props) => getComponent(DashboardView, props)}/>
                    <PrivateRoute exact path="/calendar" component={(props) => getComponent(HomeView, props)}/>
                    <PrivateRoute exact path="/tasks" component={(props) => getComponent(TasksPage, props)}/>
                    <PrivateRoute exact path="/interviews" component={(props) => getComponent(TasksPage, props)}/>
                    <PrivateRoute exact path="/contacts" component={(props) => getComponent(CandidatesPage, props)}/>
                    <PrivateRoute exact path="/contacts/duplicates" component={(props) => getComponent(CandidateDuplicatesPage, props)}/>
                    <PrivateRoute exact path="/client-contacts"
                                  component={(props) => getComponent(ClientContactsPage, props)}/>
                    <PrivateRoute exact path="/contact/:id"
                                  component={(props) => getComponent(CandidateSinglePage, props)}/>
                    <PrivateRoute exact path="/clients" component={(props) => getComponent(ClientsPage, props)}/>
                    <PrivateRoute exact path="/client/:id"
                                  component={(props) => getComponent(ClientSinglePage, props)}/>
                    <PrivateRoute exact path="/search" component={(props) => getComponent(RecruitingToolsPage, props)}/>
                    <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsPage, props)}/>
                    <PrivateRoute exact path="/financials" component={(props) => getComponent(FinancialPage, props)}/>
                    <PrivateRoute exact path="/roles" component={(props) => getComponent(RolesPage, props)}/>
                    <PrivateRoute exact path="/import" component={(props) => getComponent(ImportPage, props)}/>
                    <PrivateRoute exact path="/settings" component={(props) => getComponent(SettingsPage, props)}/>
                    <PrivateRoute exact path="/help" component={(props) => getComponent(HelpPage, props)}/>
                    <PrivateRoute exact path="/privacypolicy"
                                  component={(props) => getComponent(PrivacyPolicyPage, props)}/>
                    <PrivateRoute exact path="/users" component={(props) => getComponent(UsersPage, props)}/>
                    <PrivateRoute exact path="/devices" component={(props) => getComponent(LoggedDevicesPage, props)}/>

                    {/* Candidate routes */}
                    <PrivateRoute exact path="/dash-board" component={(props) => getComponent(ExtHomePage, props)}/>
                    <PrivateRoute exact path="/roles-board" component={(props) => getComponent(RolesExtPage, props)}/>
                    <PrivateRoute exact path="/candidate-profile"
                                  component={(props) => getComponent(ExtCandidateSinglePage, props)}/>
                    <PrivateRoute exact path="/notifications"
                                  component={(props) => getComponent(NotificationsPage, props)}/>

                    {/* Client routes */}
                    <PrivateRoute exact path="/client-board" component={(props) => getComponent(ExtClientHomePage, props)}/>
                    <PrivateRoute exact path="/client-roles-board" component={(props) => getComponent(ExtClientRolesPage, props)}/>
                    <PrivateRoute exact path="/ext-client-contacts" component={(props) => getComponent(ExtClientContactsPage, props)}/>

                    {/* Payment management routes */}
                    <PrivateRoute exact path="/payment" component={(props) => getComponent(PaymentManagementPage, props)}/>

                    {/* Meeting routes */}
                    <NeutralRoute exact path="/room/:id/:user/:token"
                                  component={(props) => getComponent(RoomPage, props)}/>
                    <NeutralRoute exact path="/room/:id/:token"
                                  component={(props) => getComponent(RoomPage, props)}/>
                    <NeutralRoute exact path="/meeting-room/:id/:user/:token"
                                  component={(props) => getComponent(RoomPage, props)}/>
                    <NeutralRoute exact path="/meeting-room/:id/:token"
                                  component={(props) => getComponent(RoomPage, props)}/>

                    {/* Public routes */}
                    <NeutralRoute exact path="/roles/:token"
                                  component={(props) => getComponent(RolesPublicPage, props)}/>
                    <NeutralRoute exact path="/recruiters"
                                  component={(props) => getComponent(RecruiterPublicPage, props)}/>
                    <NeutralRoute exact path="/role/:url" component={(props) => getComponent(RolePublicPage, props)}/>
                    <NeutralRoute exact path="/feedback/:token"
                                  component={(props) => getComponent(FeedbackPublicPage, props)}/>
                    <NeutralRoute exact path="/privacy-policy"
                                  component={(props) => getComponent(PrivacyPolicyPublicPage, props)}/>

                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>

                <SocketListener
                    translate={(key, args) => translator.translate(key, args)}
                />
            </RouterListener>

            <IdleModal
                history={history} dispatch={store.dispatch}
                translate={(key, args) => translator.translate(key, args)}/>
        </Router>
    );
}
