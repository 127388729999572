import {Fragment, useEffect, useState} from 'react'
import {SearchIcon} from '@heroicons/react/solid'
import {EmojiSadIcon} from '@heroicons/react/outline'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {classNames} from '../../util/util'
import DocumentSearchIcon from "@heroicons/react/outline/DocumentSearchIcon";

export default function CommandPalette(props) {
    const {history, items, resourceName} = props;

    // const getResourceName = () => {
    //     return resourceName ?? Resources.CommandPalette;
    // }

    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');

    const fetchData = () => {
        //
    }

    useEffect(() => {
        function toggleCommandPalette(e) {
            if ((e.ctrlKey && e.key === "k") || (e.metaKey && e.key === "k")) {
                e.preventDefault();
                setOpen(true);
            }

            if (e.key === "Escape") {
                setOpen(false);
            }
        }

        document.addEventListener('keydown', toggleCommandPalette);

        return () => {
            document.removeEventListener('keydown', toggleCommandPalette);
        }
    }, []);

    useEffect(() => {
        if (!!query) {
            fetchData();
        }
    }, [query])

    const filteredItems =
        query === ''
            ? []
            : items.filter((item) => {
                return item.name.toLowerCase().includes(query.toLowerCase())
            })

    const groups = filteredItems.reduce((groups, item) => {
        return {...groups, [item.category]: [...(groups[item.category] || []), item]}
    }, {})

    return (
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')}>
            <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Combobox
                        as="div"
                        className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                        onChange={(item) => (history.push(item.href))}
                    >
                        <div className="relative">
                            <SearchIcon
                                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            <Combobox.Input
                                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                                placeholder="Search..."
                                onChange={(event) => setQuery(event.target.value)}
                            />
                        </div>

                        {query === '' && (
                            <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                                <DocumentSearchIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                                <p className="mt-4 font-semibold text-gray-900">Search for app pages</p>
                                <p className="mt-2 text-gray-500">Quickly access any app page.</p>
                            </div>
                        )}

                        {filteredItems.length > 0 && (
                            <Combobox.Options static
                                              className="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2">
                                {Object.entries(groups).map(([category, items]) => (
                                    <li key={category}>
                                        <h2 className="bg-gray-100 py-2.5 px-4 text-xs font-semibold text-gray-900">{category}</h2>
                                        <ul className="mt-2 text-sm text-gray-800">
                                            {items.map((item) => (
                                                <Combobox.Option
                                                    key={item.id}
                                                    value={item}
                                                    className={({active}) =>
                                                        classNames('cursor-default select-none px-4 py-2', active && 'bg-primary-600 text-white')
                                                    }
                                                >
                                                    {item.name}
                                                </Combobox.Option>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </Combobox.Options>
                        )}

                        {query !== '' && filteredItems.length === 0 && (
                            <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                                <EmojiSadIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
                                <p className="mt-4 font-semibold text-gray-900">No results found</p>
                                <p className="mt-2 text-gray-500">We could’t find anything with that term. Please try
                                    again.</p>
                            </div>
                        )}
                    </Combobox>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
