import React, {useState} from 'react'
import {fieldsToCells, classNames} from "../../util/util";
import CheckIcon from "@heroicons/react/outline/CheckIcon";
import ChevronDoubleUpIcon from "@heroicons/react/outline/ChevronDoubleUpIcon";
import PlusIcon from "@heroicons/react/outline/PlusIcon";

const TableMobile = (props) => {
    const {data, fields, getCellClasses, bulkSelectKey, onBulkSelect, onSubmitRowColor, bulkSelectedItems, translate, isLoading, haveActions} = props;

    const [isSelectMode, toggleSelectMode] = useState(false);
    const [isTagMode, toggleTagMode] = useState(false);
    const [isTableCollapsed, toggleTableCollapsed] = useState(false)

    const colors = ["border border-primary", "bg-blue-600", "bg-red-600", "bg-yellow-400", "bg-green-600", "bg-violet-600", "bg-lime-600", "bg-pink-600", "bg-orange-600", "bg-indigo-600"];

    const hasSelectedItems = !!bulkSelectedItems && !!Object.keys(bulkSelectedItems).length ;

    const colorPicker = colors.map((it, i) => (
        <button
            disabled={!hasSelectedItems}
            onClick={() => hasSelectedItems ? onSubmitRowColor(i) : null}
            className={classNames(
                "w-6 h-6 m-3 rounded-full ring-offset-inverse ring-primary-500 focus:ring-2 focus:ring-offset-2",
                it === "transparent" ? "bg-transparent border border-primary" : it,
                hasSelectedItems ? "" : "opacity-25 cursor-default"
            )}
        />)
    )

    const itemIsSelected = (it) => {
        if (!it[bulkSelectKey]) {
            return false;
        }

        const id = it[bulkSelectKey].toString();

        return bulkSelectedItems.includes(id);
    }

    const tables = data.map((it) => {
        let fieldsClone = fields;

        if (isTableCollapsed) {
            fieldsClone = {[Object.keys(fields)[0]]: Object.values(fields)[0]}
        }

        const isSelected = (isSelectMode || isTagMode) && itemIsSelected(it);

        const smallTable = Object.keys(fieldsClone).filter(it => !fieldsClone[it]?.metadata?.hideTable).map((fieldName, i) => {
            return (
                <tr className="">
                    <td
                        className={getCellClasses(
                            it.ColorID,
                            classNames("border-secondary-200 p-3 text-left text-secondary-900 border-t border-r font-bold w-32", (!i ? " border-t-0" : "")),
                            true)}
                    >
                        {translate("table." + fieldName)}
                    </td>

                    {fieldsToCells({
                        [fieldName]: fields[fieldName]},
                        it,
                        getCellClasses(it.ColorID, "border-secondary-200 p-3 bg-inverse" + (i ? " border-t" : "")))}
                </tr>
            )
        });

        if (haveActions) {
            smallTable.push(
                <tr className="bg-inverse border-t">
                    <td
                        className={getCellClasses(it.ColorID, "border-secondary-200 p-3 text-left text-secondary-900 border-t border-r font-bold w-32 border-b-0", true)}
                    >
                        Actions
                    </td>

                    {props.getActions(it, getCellClasses(it.ColorID))}
                </tr>
            )
        }

        return (
            <div className="relative overflow-hidden rounded-lg border mb-4">
                {(isSelectMode || isTagMode) &&
                    <>
                        <div className="absolute z-10 inset-0 bg-black bg-opacity-25">
                            <button
                                onClick={() => onBulkSelect(it)}
                                className={classNames("btn w-14 h-14 rounded-full border-2 border-white absolute top-3 right-3", isSelected ? "btn-primary" : "btn-transparent")}
                            >
                                {isSelected
                                 ? <CheckIcon className={classNames("w-5 h-5 text-white")}/>
                                 : <PlusIcon className={classNames("w-5 h-5 text-white")}/>
                                }
                            </button>
                        </div>
                    </>
                }

                <table className="sm:bg-white shadow-lg w-full">
                    {smallTable}
                </table>
            </div>
        )
    });

    const generateRandomSkeletonWidth = () => {
        let classes = ['w-6/12', 'w-7/12', 'w-8/12', 'w-9/12', 'w-10/12', 'w-11/12', 'w-full']
        return classes[Math.floor(Math.random() * classes.length)];
    }

    const renderSkeletonTable = (n = 4) => {

        const rows = [];

        for (let i = 1; i <= n; i++) {
            rows.push(
                <tr className="">
                    <td
                        className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold w-32 h-12" + (i !== 1 ? " border-t" : "")}
                    >
                        <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                    </td>

                    <td className={"border-secondary-200 hover:bg-gray-100 p-3 bg-inverse" + (i !== 1 ? " border-t" : "")}>
                        <div className={"h-4 bg-secondary-200 rounded " + (generateRandomSkeletonWidth())}/>
                    </td>
                </tr>
            )
        }

        return (
            <div className="overflow-hidden rounded-lg border my-6">
                <table className="text-sm table-mobile overflow-x-auto w-full animate-pulse rounded-lg overflow-hidden">
                    {rows}
                </table>
            </div>
        );
    }

    return (
        <React.Fragment>
            {!isLoading && (
                <div className="text-sm table-mobile overflow-x-auto">
                    <div className="flex w-full items-center justify-between mb-6 ">
                        {!!tables.length && (
                            <React.Fragment>
                                <p className="text-lg">Table options</p>

                                <div className="space-x-3 p-2 flex items-center">
                                    {/*currently not in use*/}

                                    {/*<button*/}
                                    {/*    onClick={() => toggleSelectMode(!isSelectMode)}*/}
                                    {/*    className={classNames("btn btn-outline border-2", isSelectMode ? "bg-primary-600 border-primary-600 hover:bg-primary-700" : "")}*/}
                                    {/*>*/}
                                    {/*    <CheckIcon className={classNames("w-5 h-5 ", isSelectMode ? "text-white" : "text-primary-600")}/>*/}
                                    {/*</button>*/}

                                    {!!onSubmitRowColor && (
                                        <button
                                            onClick={() => toggleTagMode(!isTagMode)}
                                            className={classNames("btn btn-outline flex justify-center border-2", isTagMode ? "border-primary-600" : "")}
                                        >
                                            <div className="w-5 h-5 grid gap-1 grid-cols-2">
                                                <div className="bg-red-600 w-2 h-2"/>
                                                <div className="bg-blue-600 w-2 h-2"/>
                                                <div className="bg-purple-600 w-2 h-2"/>
                                                <div className="bg-yellow-300 w-2 h-2"/>
                                            </div>
                                        </button>
                                    )}

                                    <button
                                        onClick={() => toggleTableCollapsed(!isTableCollapsed)}
                                        className={classNames("btn btn-outline flex justify-center border-2", isTableCollapsed ? "border-primary-600" : "")}
                                    >
                                        <ChevronDoubleUpIcon className={classNames("w-5 h-5 text-secondary-700", isTableCollapsed ? "rotate-180" : "")} />
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {isTagMode && (
                        <div className="relative">
                            {!hasSelectedItems && (
                                <p className="absolute inset-center">Please select an item first</p>
                            )}

                            <div className="flex flex-wrap items-center p-3 w-[16.5rem] mx-auto">
                                {colorPicker}
                            </div>
                        </div>
                    )}

                    {tables}

                    {isTagMode && (
                        <div className="relative">
                            {!hasSelectedItems && (
                                <p className="absolute inset-center">Please select an item first</p>
                            )}

                            <div className="flex flex-wrap items-center p-3 w-[16.5rem] mx-auto">
                                {colorPicker}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {!!isLoading && (
                renderSkeletonTable()
            )}

        </React.Fragment>
    );
}

export default TableMobile;