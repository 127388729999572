import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneGetContacts,
    doneGetConversations,
    doneGetMessages,
    doneGetSMSMessages,
    errorGetContacts,
    errorGetConversations,
    errorGetMessages,
    errorGetSMSMessages,
} from "../actions/messaging";

export function* getMessagesCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getWebsocketResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetMessages(result.data));
    } else {
        yield put(errorGetMessages(result.data));
    }
}

export function* watchGetMessages() {
    yield takeLatest('GET_MESSAGES', getMessagesCall);
}

export function* getConversationsCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getWebsocketResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetConversations(result.data));
    } else {
        yield put(errorGetConversations(result.data));
    }
}

export function* watchGetConversations() {
    yield takeLatest('GET_CONVERSATIONS', getConversationsCall);
}

export function* getContactsCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getWebsocketResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetContacts(result.data));
    } else {
        yield put(errorGetContacts(result.data));
    }
}

export function* watchGetContacts() {
    yield takeLatest('GET_CONTACTS', getContactsCall);
}

export function* getSmsMessageCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSMSMessages(result.data));
    } else {
        yield put(errorGetSMSMessages(result.data));
    }
}

export function* watchGetSmsMessage() {
    yield takeLatest('GET_SMS_MESSAGES', getSmsMessageCall);
}


