import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    checkPerm, classNames,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    getProp,
    getUserLabel,
    READ_PERM,
    toBackDate
} from "../../util/util";
import {CalendarIcon, CheckIcon, PlusIcon, VideoCameraIcon} from '@heroicons/react/solid'
import Calendar from "../../components/calendar";
import moment from "moment";
import AddToCalendar from 'react-add-to-calendar';
import Env from "../../util/env";
import CalendarNew from "../../components/calendar";
import PopOver from "../../components/popover";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";

class ExtClientHomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "FirstName",

            currMonth: moment().month(),
            currYear: moment().year(),

            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: false,
            editModalOpen: false
        };

        this.isExt = !checkPerm(Resources.Roles, READ_PERM);
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: {},
            resource: Resources.ExtClientDashboard
        }))
    };

    fetchCalendarData = (year, month) => {
        this.setState({
            currMonth: month,
            currYear: year,
        }, this.fetchData)
    }

    /** UI Events
     ================================================================= */
    handleNextMonth = () => {
        // Zero based month counting, 11 is December
        this.setState({
            currMonth: (this.state.currMonth + 1 > 11) ? 0 : this.state.currMonth + 1,
            currYear: (this.state.currMonth + 1 > 11) ? (this.state.currYear + 1) : this.state.currYear
        })
    }

    handlePrevMonth = () => {
        this.setState({
            currMonth: (this.state.currMonth - 1 < 0) ? 11 : this.state.currMonth - 1,
            currYear: (this.state.currMonth - 1 < 0) ? (this.state.currYear - 1) : this.state.currYear
        })
    }

    handleCalendarEventTap = (it) => {
        if (it.metadata.RoleCandidateInterviewID) {
            this.props.history.push("room/" + it.metadata.RoleCandidateInterviewID + "/" + it.metadata.AutoToken);
        } else if (it.metadata.Meeting) {
            this.handleToggleEditModal(it.metadata.Meeting);
        } else {
            this.handleToggleView(it.metadata);
        }
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {};
    };

    getEventColor = (time, duration, item) => {
        const type = this.getEventType(item);

        const hasStarted = moment().isAfter(time);
        const hasEnded = moment().isAfter(time.add(duration, "minutes"));
        const isInProgress = hasStarted && !hasEnded;
        const isNextDayOrAfter = moment().isSameOrAfter(time, "day");
        const isSameDay = moment().isSame(time, "day");
        //const isPending = !hasStarted && !hasEnded;

        switch (type) {
            case "task":
            case "interview":
                if (item.TaskStatusID === 2) {
                    return "green"
                } else if (isNextDayOrAfter) {
                    return "red";
                } else if (hasStarted) {
                    return "cyan";
                } else {
                    return "gray";

                }
            case "meeting":
            case "video":
                if (isInProgress) {
                    return "cyan"
                } else if (hasEnded) {
                    return "green"
                } else {
                    return "gray"
                }
            case "milestone":
                if (isSameDay) {
                    return "cyan"
                } else if (hasEnded) {
                    return "green"
                } else {
                    return "gray"
                }
            default:
                return "cyan"
        }
    }

    getEventColorClass = (color) => {
        switch (color) {
            case "green":
                return {
                    bg50: "bg-green-50",
                    hoverBg100: "hover:bg-green-100",
                    text500: "text-green-500",
                    text700: "text-green-700",
                    groupHoverText700: "group-hover:text-green-700",
                    bg400: "bg-green-400"
                };
            case "red":
                return {
                    bg50: "bg-red-50",
                    hoverBg100: "hover:bg-red-100",
                    text500: "text-red-500",
                    text700: "text-red-700",
                    groupHoverText700: "group-hover:text-red-700",
                    bg400: "bg-red-400"
                };
            case "indigo":
                return {
                    bg50: "bg-indigo-50",
                    hoverBg100: "hover:bg-indigo-100",
                    text500: "text-indigo-500",
                    text700: "text-indigo-700",
                    groupHoverText700: "group-hover:text-indigo-700",
                    bg400: "bg-indigo-400"
                };
            case "cyan":
                return {
                    bg50: "bg-cyan-50",
                    hoverBg100: "hover:bg-cyan-100",
                    text500: "text-cyan-500",
                    text700: "text-cyan-700",
                    groupHoverText700: "group-hover:text-cyan-700",
                    bg400: "bg-cyan-400"
                };
            case "purple":
                return {
                    bg50: "bg-purple-50",
                    hoverBg100: "hover:bg-purple-100",
                    text500: "text-purple-500",
                    text700: "text-purple-700",
                    groupHoverText700: "group-hover:text-purple-700",
                    bg400: "bg-purple-400"
                };
            case "blue":
                return {
                    bg50: "bg-blue-50",
                    hoverBg100: "hover:bg-blue-100",
                    text500: "text-blue-500",
                    text700: "text-blue-700",
                    groupHoverText700: "group-hover:text-blue-700",
                    bg400: "bg-blue-400"
                };
            case "yellow":
                return {
                    bg50: "bg-yellow-50",
                    hoverBg100: "hover:bg-yellow-100",
                    text500: "text-yellow-600",
                    text700: "text-yellow-700",
                    groupHoverText700: "group-hover:text-yellow-900",
                    bg400: "bg-yellow-400"
                };
            case "gray":
                return {
                    bg50: "bg-gray-50",
                    hoverBg100: "hover:bg-gray-100",
                    text500: "text-gray-500",
                    text700: "text-gray-700",
                    groupHoverText700: "group-hover:text-gray-700",
                    bg400: "bg-gray-400"
                };
            default:
                return {
                    bg50: "bg-primary-50",
                    hoverBg100: "hover:bg-primary-100",
                    text500: "text-primary-500",
                    text700: "text-primary-700",
                    groupHoverText700: "group-hover:text-primary-700",
                    bg400: "bg-primary-400"
                };
        }
    }

    getEventType = (it) => {
        if (it?.TaskTypeID === 1) {
            return "task";
        } else if (it?.TaskTypeID === 2) {
            return "interview";
        } else if (it?.RoleCandidateInterviewID) {
            return "video"
        }

        return "milestone";
    }

    getEventIcon = (it) => {
        if (it.RoleCandidateInterviewID) {
            return VideoCameraIcon;
        } else if (it.MeetingID) {
            return CalendarIcon;
        } else if (it.TaskID) {
            return CheckIcon;
        }
        return null;
    }

    getEventLabel = (it) => {
        if (it.RoleCandidateInterviewID) {
            return "Interview room";
        } else if (it.MeetingID) {
            return "Edit meeting";
        } else if (it.TaskID) {
            return "Edit task";
        }
        return "Edit";
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const userMenuBtnLabel = getUserLabel();

        const events = getProp(this.props, "resource.data.dates", [])
            .reduce((memo, it) => {

                const date = moment.utc(it.Date, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone).format("YYYY-MM-DD");
                const time = moment.utc(it.Date, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone)

                let duration = null;
                if (it.EndDate) {
                    const endTime = moment.utc(it.EndDate, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone)
                    duration = endTime.diff(time, 'minutes')
                }

                if (!memo[date]) {
                    memo[date] = [];
                }

                it.Duration = !!duration && duration > 0 ? duration : 15;

                memo[date].push({
                    name: !!it.TaskID ? it.TaskTitle : it.Name,
                    icon: this.getEventIcon(it),
                    date: date,
                    time: time.format("HH:mm"),
                    timeEnd: time.clone().add(it.Duration, "minutes").format("HH:mm"),
                    Duration: it.Duration,
                    displayTime: time.format("h:mm a"),
                    durationDisplay: time.format("h:mm") + " - " + time.add(it.Duration, "minutes").format("h:mma"),
                    color: this.getEventColorClass(this.getEventColor(time, it.Duration, it)),
                    metadata: {
                        RoleCandidateInterviewID: it.RoleCandidateInterviewID ?? false,
                        Meeting: it.MeetingID ? it : false,
                        Task: it.TaskID ? it : false,
                        RoleID: it.RoleID,
                        AutoToken: it.AutoToken ?? false,
                        fromCalendar: true,
                        Notes: it.Description,
                        EventBtnLabel: this.getEventLabel(it)
                    }
                })
                return memo;
            }, []);

        const legend = [
            {
                Desc: "Milestone",
            },
            {
                Desc: "Task/Interview",
                Icon: CheckIcon,
            },
            {
                Desc: "Video interview",
                Icon: VideoCameraIcon,
            },
            {
                Desc: "Meeting",
                Icon: CalendarIcon,
            }
        ].map(it => (
            <div
                className="pt-1 flex items-center justify-between px-4">
                <dt className="flex items-center text-sm text-gray-600">
                    {!!it.Icon && (
                        <it.Icon className={"w-5 h-5 mr-3 text-gray-400"}/>
                    )}

                    {!it.Icon && (
                        <div className="w-5 h-5 flex items-center justify-center mr-3">
                            <div className={"w-2 h-2 bg-gray-400 rounded-full"} />
                        </div>
                    )}
                    <span>{it.Desc}</span>
                </dt>
            </div>
        ));

        const legendColor = [
            {
                Desc: "Not started/Pending",
                Color: "bg-gray-400",
            },
            {
                Desc: "In progress",
                Color: "bg-primary-600",
            },
            {
                Desc: "Done/Completed",
                Color: "bg-green-600",
            },
            {
                Desc: "Overdue",
                Color: "bg-red-600",
            }
        ].map(it => (
            <div
                className="pt-1 flex items-center justify-between px-4">
                <dt className="flex items-center text-sm text-gray-600">
                    <div className={"w-5 h-5 mr-3 rounded-md " + it.Color}/>
                    <span>{it.Desc}</span>
                </dt>
            </div>
        ));

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0">
                    {/* Page header */}
                    <div className="bg-inverse border-b-2 border-primary">
                        <div className="px-4 sm:px-6 lg:custom-max-width lg:mx-auto lg:px-8">
                            <div
                                className="py-4 sm:py-6 md:flex md:items-center md:justify-between">
                                <div className="flex-1 min-w-0">
                                    {/* Profile */}
                                    <div className="md:flex items-center">
                                        <div
                                            className="w-16 h-16 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                            <div
                                                className="text-inverse font-black">{userMenuBtnLabel.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                        </div>

                                        <div className="flex-1">
                                            <div className="mt-2 sm:mt-0 flex items-center">
                                                <h1 className="sm:ml-3 text-2xl font-bold leading-7 text-secondary-900 sm:leading-9 sm:truncate">
                                                    {translate("text.welcome")} {userMenuBtnLabel}
                                                </h1>
                                            </div>
                                        </div>

                                        <div
                                            className="flex mr-2 select-none">
                                            <img
                                                className="object-cover h-16"
                                                src={Env.getApiUrl('api/' + Resources.CompanyImage, {
                                                    token: LocalStorage.get('user').access_token
                                                }) + "&date=" + new Date().getTime()}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 sm:p-6 lg:p-8">

                        {/*Calendar*/}
                        <div className="sm:mb-6 lg:mb-8">
                            <CalendarNew
                                onFetchData={this.fetchCalendarData}
                                events={events}
                                translate={this.props.translate}
                                activeDay={this.state.activeDay}
                                setActiveDay={this.setActiveDay}
                                onEventClick={this.handleCalendarEventTap}
                                onSquashedEventsClick={this.handleSquashedEventsClick}
                                onMoreClick={this.handleMoreClick}
                                hasCreatePerm={false}
                                hasDeletePerm={false}
                                hasUpdatePerm={false}
                                calendarHeight={"max-h-[calc(100vh-16rem)]"}
                                actions={[ // siplify to create only and remove actions
                                    {
                                        icon: PlusIcon,
                                        visible: checkPerm(Resources.Dashboard, CREATE_PERM),
                                        label: "Create",
                                        className: "btn btn-primary",
                                        onClick: this.handleToggleCreateModal
                                    }
                                ]}
                                customViewMenuItems={[{
                                    id: "list-view",
                                    onMenuItemClick: this.handleToggleCalendarView,
                                    name: translate("btn.list_view")
                                }]}
                                infoIcon={(
                                    <PopOver
                                        btnClass={classNames("btn btn-text p-0 ml-2")}
                                        BtnIcon={InformationCircleIcon}
                                        btnIconClass={classNames("h-5 w-5 relative text-primary-500")}
                                        widthClass={"max-w-[20rem]"}
                                        positionClass="translate-x-0 left-2"
                                    >
                                        <div className="bg-inverse rounded-md pt-3">
                                            <div className="space-y-1 pb-3">
                                                <p className="pb-1 px-4 text-sm text-gray-600 font-bold">Icons</p>

                                                {legend}
                                            </div>

                                            <div className="border-t border-gray-200 space-y-1 py-3">
                                                <p className="pb-1 px-4 text-sm text-gray-600 font-bold">Colors</p>

                                                {legendColor}
                                            </div>
                                        </div>
                                    </PopOver>
                                )}
                            />
                        </div>

                    </div>
                </main>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ExtClientHomePage);