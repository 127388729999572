import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource,} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SimpleTable from "../../components/simple-table";
import {fillFieldsFromData, getLookup, getProp, isNotificationVisible} from "../../util/util";
import {Field} from "../../data/services/fields";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import RoleModal from "./roles/index";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import FieldSearch from "../../components/field-search";

export default class LocationsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "RoleName",

            columnFilters: {},
            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getPrimaryKey = () => {
        return "RoleID";
    };

    getResourceName = () => {
        return Resources.ClientRoles;
    };

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    }

    getFields = (item = null) => {

        const fieldTemplates = {
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select-search', {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            SpecialityID: new Field("SpecialityID", '', [], false, 'select', {filterOptions: getLookup("Speciality", "SpecialityID", "Speciality")}),
            ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
            ContactID: new Field("ContactID", item ? (item.FirstName ? (item.FirstName + " ") : "" + item.LastName ? item.LastName : "") : "", [], false, 'select-search'),
            RoleStatusID: new Field("RoleStatusID", '', ['empty'], false, 'select', {filterOptions: getLookup("RoleStatus", "RoleStatusID", "RoleStatus")}),
            PostDate: new Field("PostDate", '', [], false, 'date'),
            FillDate: new Field("FillDate", '', [], false, 'date'),
            FilledCandidateID: new Field("FilledCandidateID", item ? (item.FilledCandidate) : "", [], false, 'select-search'),
            AgreedSalary: new Field("AgreedSalary", '', ['float_or_empty'], false, 'text'),
            RefNumber: new Field("RefNumber", '', [], false, 'text')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            ClientLocationID: {
                api: 'api/' + Resources.ClientLocations,
                query: {id: this.getId()},
                searchMap: (item) => ({
                    value: item.ClientLocationID,
                    label: item.LocationName
                })
            },
            ContactID: {
                api: 'api/' + Resources.ClientsContacts,
                query: {id: this.getId(), ClientID: this.getId()},
                searchMap: (item) => ({
                    value: item.ClientContactID,
                    label: item.ClientContact
                })
            },
            FilledCandidateID: {
                api: 'api/' + Resources.Candidates,
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName,
                })
            },
            RoleTitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            }
        };

        return (
            <div className="">
                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"

                        classNameContainer={"mr-6 mb-3"}
                    />

                    <div className="mr-6 w-full sm:w-auto mb-3">
                        <button
                            onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query)
                                ? this.setState({
                                    columnFilters: {},
                                    query: ""
                                }, () => this.fetchData())
                                : null
                            }
                            className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query ? "btn-primary" : " btn-disabled")}
                        >
                            Clear all filters
                        </button>
                    </div>

                    <div className="flex-shrink-0 ml-auto mb-3">
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}
                    onUpdateColumnFilter={this.handleUpdateColumnFilter}
                    columnFilters={this.state.columnFilters}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}


                    onView={this.handleToggleEditModel}
                    onEdit={this.handleToggleView}
                    onDelete={this.handleToggleConfirmModal}
                />{/* Switched view and edit on purpose */}
                {!!this.state.viewModalOpen && (
                <RoleModal
                    topPosition={true}
                    {...this.props}
                    id={getProp(this.state.selectedItem, this.getPrimaryKey(), 0)}
                    visible={!!this.state.viewModalOpen}
                    onClose={this.handleToggleView}
                    disableCloseOnOutsideClick={isNotificationVisible(this.props.ui)}
                />
                )}

                <ModalSaveResource
                    title={"Create Role"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    validateFields={(fields) => {
                        if ((fields.RoleStatusID.value === 3) && (!fields.FilledCandidateID.value || !fields.FilledCandidateID.value.value)) {
                            fields.FilledCandidateID.errorMessage = ["fields.errors.empty"]
                        }
                        return fields;
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params.ClientID = this.getId();
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Role,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={"Edit Role"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    validateFields={(fields) => {
                        if ((fields.RoleStatusID.value === 3) && (!fields.FilledCandidateID.value || !fields.FilledCandidateID.value.value)) {
                            fields.FilledCandidateID.errorMessage = ["fields.errors.empty"]
                        }
                        return fields;
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Role,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.Role,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                    metadata={metadata}
                />
            </div>
        )
    }
}
