import React, {Component, Fragment} from 'react'
import {createResource, deleteResource, downloadFile, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SimpleTable from "../../components/simple-table";
import {
    checkPerm,
    DELETE_PERM,
    fillFieldsFromData,
    getLookup,
    getProp, isNotificationVisible, READ_PERM,
    UPDATE_PERM
} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {MailIcon, PaperClipIcon, PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import RoleModal from "../client-single-page/roles";
import FieldSearch from "../../components/field-search";
import {Dialog, Transition} from "@headlessui/react";
import FieldText from "../../components/field-text";
import ModalViewDocument from "../../components/modal/modal-view-document";
import Env from "../../util/env";

export default class NewRolesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "ClientName",

            columnFilters: {},
            query: "",
            archived: false,

            fields: this.getFields(),


            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false,

            packageModalOpen: false,
            sendPackageEmailModalOpen: false,
            sendPackageEmail: ""
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    sendPackageEmail = (item, email) => {
        const candidate = getProp(this.props, "candidate", {});
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                id: this.getId(),
                RoleID: item.RoleID,
                Email: email
            },
            notificationMessage: "Email for candidate " + candidate.FirstName + " " + candidate.LastName + " sent to " + email,
            resource: Resources.CandidatePackage
        }));
        this.handleToggleSendPackageEmail();
    }

    handleRoleVersionCreate = (item) => {
        const candidate = getProp(this.props, "candidate", {});
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                id: this.getId(),
                RoleID: item.RoleID,
            },
            notificationMessage: "New version for candidate " + candidate.FirstName + " " + candidate.LastName + " has been created",
            resource: Resources.RoleVersionCreate
        }));
    }

    handleRoleVersionDelete = (item) => {
        const candidate = getProp(this.props, "candidate", {});
        this.props.dispatch(deleteResource({
            user: LocalStorage.get("user"),
            params: {
                id: this.getId(),
                RoleID: item.RoleID,
            },
            notificationMessage: "Version for candidate " + candidate.FirstName + " " + candidate.LastName + " has been created",
            resource: Resources.RoleVersionCreate
        }));
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    handleToggleSendPackageEmail = (item = null) => {
        this.setState({
            selectedItem: item,
            sendPackageEmailModalOpen: !this.state.sendPackageEmailModalOpen
        })
    }

    handleDownloadPackage = (item) => {
        this.setState({
            packageModalOpen: !this.state.packageModalOpen,
            selectedItem: item
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.CandidateNewRoles;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            ClientName: new Field("ClientName", '', ['empty'], false, 'text'),
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select-search', {filterOptions: getLookup("RoleTitle", "RoleTitleID", "RoleTitle")}),
            SpecialityID: new Field("SpecialityID", '', [], false, 'select', {filterOptions: getLookup("Speciality", "SpecialityID", "Speciality")}),
            ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
            ContactID: new Field("ContactID", item ? (item.FirstName + " " + item.LastName) : "", [], false, 'select-search'),
            RoleStatusID: new Field("RoleStatusID", '', [], false, 'select', {filterOptions: getLookup("RoleStatus", "RoleStatusID", "RoleStatus")}),
            Applied: new Field("Applied", '', [], false, 'checkbox', {
                hideDialog: true
            }),
            CandidateProfileID: new Field("CandidateProfileID", '', ['empty'], false, 'select-search'),
            PostDate: new Field("PostDate", '', [], false, 'date'),
            FillDate: new Field("FillDate", '', [], false, 'date'),
            FilledCandidateID: new Field("FilledCandidateID", item ? (item.FilledCandidate) : "", [], false, 'select-search', {}, {menuPlacement: "top"}),
            RoleCandidateStatusID: new Field("RoleCandidateStatusID", item ? (item.RoleCandidateStatusID) : "", [], false, 'select', {filterOptions: getLookup("RoleCandidateStatus", "RoleCandidateStatusID", "RoleCandidateStatus")})
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getFieldsAdd = (item = null) => {
        const fieldTemplates = {
            RoleID: new Field("RoleID", '', ['empty'], false, 'select-search'),
            Note: new Field("Note", '', [], false, 'textarea'),
            CandidateProfileID: new Field("CandidateProfileID", 0, ['empty'], false, 'select-search'),
            RoleCandidateStatusID: new Field("RoleCandidateStatusID", '', [], false, 'select', {}, {menuPlacement: "top"})
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters,
            limit: this.state.limit,
            offset: 0,
            paginationPage: 1
        }, () => this.fetchData())
    };

    getSearchFields = () => ({
        RoleTitleID: new Field("RoleTitleID", '', [], false, "select"),
        RoleStatusID: new Field("RoleStatusID", '', [], false, 'select')
    });

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const candidate = getProp(this.props, "candidate", {});

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            ClientLocationID: {
                api: 'api/' + Resources.ClientLocations,
                //query: {id: this.getId()},
                query: {id: !!this.state.selectedItem ? this.state.selectedItem['ClientID'] : 0},
                searchMap: (item) => ({
                    value: item.ClientLocationID,
                    label: item.LocationName
                })
            },
            ContactID: {
                api: 'api/' + Resources.ClientContacts,
                query: {id: !!this.state.selectedItem ? this.state.selectedItem['ClientID'] : 0},
                searchMap: (item) => ({
                    value: item.ClientContactID,
                    label: item.ClientContact
                })
            },
            FilledCandidateID: {
                api: 'api/' + Resources.Candidates,
                //query: {},
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName,
                })
            },
            RoleTitleID: {
                api: 'api/' + Resources.CreatableRoleTitle,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleTitleID,
                    label: item.RoleTitle,
                })
            },
            CandidateProfileID: {
                api: 'api/' + Resources.RoleVersionCreate,
                query: {
                    id: this.getId()
                },
                searchMap: (item) => ({
                    value: item.CandidateProfileID,
                    label: item.ProfileName,
                })
            },
        };

        const metadataAdd = {
            CandidateProfileID: {
                api: 'api/' + Resources.RoleVersionCreate,
                query: {
                    id: this.getId()
                },
                searchMap: (item) => ({
                    value: item.CandidateProfileID,
                    label: item.ProfileName,
                })
            },
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {id: this.getId()},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName + " / " + item.ClientName
                })
            }
        }

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">

                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"

                        classNameContainer={"mr-6 mb-3"}
                    />

                    <div className="mr-6 w-full sm:w-auto mb-3">
                        <button
                            onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query)
                                ? this.setState({
                                    columnFilters: {},
                                    query: ""
                                }, () => this.fetchData())
                                : null
                            }
                            className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query ? "btn-primary" : " btn-disabled")}
                        >
                            Clear all filters
                        </button>
                    </div>

                    <div className="flex-shrink-0 ml-auto mb-3">
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.add")}
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}
                    onUpdateColumnFilter={this.handleUpdateColumnFilter}
                    columnFilters={this.state.columnFilters}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={(checkPerm(Resources.Role, UPDATE_PERM)) ? this.handleToggleEditModel : null}
                    onEdit={(checkPerm(Resources.Role, UPDATE_PERM)) ? this.handleToggleView : null}
                    onDelete={(checkPerm(Resources.Role, DELETE_PERM)) ? this.handleToggleConfirmModal : null}
                    onCustomAction={(it) => {
                        return (
                            <>
                                {(checkPerm(Resources.CandidatePackage, READ_PERM)) && (
                                    <>
                                        <button
                                            onClick={() => this.handleToggleSendPackageEmail(it)}
                                            type="button"
                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        >
                                            <MailIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                            Send Package
                                        </button>

                                        <button
                                            onClick={() => this.handleDownloadPackage(it)}
                                            type="button"
                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        >
                                            <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                            Download Package
                                        </button>
                                    </>
                                )}


                                {/* Add an checkbox to select which profile version will be a valid one for this role, use base or e */}
                            </>
                        )
                    }}
                />{/* Switched view and edit on purpose */}

                {this.state.viewModalOpen && (
                    <RoleModal
                        {...this.props}
                        id={getProp(this.state.selectedItem, this.getPrimaryKey(), 0)}
                        visible={!!this.state.viewModalOpen}
                        onClose={this.handleToggleView}
                        disableCloseOnOutsideClick={isNotificationVisible(this.props.ui)}
                    />
                )}

                <ModalSaveResource
                    title={"Apply to Role"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFieldsAdd()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = params.RoleID;
                            params.CandidateID = this.getId();
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.RoleCandidates,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataAdd}
                />

                <ModalSaveResource
                    title={"Edit Role"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem, ['ClientName', "RoleCandidateStatusID", "CandidateProfileID"])}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Role,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.Role,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                    metadata={metadata}
                />

                <ModalViewDocument
                    title={"View Package Document"}
                    className="max-w-2xl"
                    visible={this.state.packageModalOpen}
                    onClose={this.handleDownloadPackage}
                    translate={this.props.translate}
                    fileType={"pdf"}
                    token={LocalStorage.get('user').access_token}
                    filePath={Env.getApiUrl('api/' + Resources.CandidatePackage, {
                        id: this.getId(),
                        RoleID: this.state.selectedItem?.RoleID,
                        name: candidate.FirstName + " " + candidate.LastName + ".pdf"
                    })}
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get("user"),
                            query: {
                                id: this.getId(),
                                RoleID: this.state.selectedItem?.RoleID,
                                name: candidate.FirstName + " " + candidate.LastName + ".pdf"
                            },
                            resource: Resources.CandidatePackage
                        }))
                    }}
                />

                <Transition show={this.state.sendPackageEmailModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.sendPackageEmailModalOpen}
                        onClose={this.handleToggleSendPackageEmail}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                    &#8203;
                    </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        <p>Enter email to send package to:</p>

                                        <p className={"mt-1 mb-1 text-sm text-secondary-500"}>* Email will contain
                                            information
                                            about {this.state.selectedItem?.FirstName} {this.state.selectedItem?.LastName}</p>
                                        <FieldText
                                            onChange={(name, value) => {
                                                this.setState({
                                                    sendPackageEmail: value
                                                })
                                            }}
                                            value={this.state.sendPackageEmail}
                                        />
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button
                                                    type="button"
                                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={() => this.sendPackageEmail(this.state.selectedItem, this.state.sendPackageEmail)}
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={this.handleToggleSendPackageEmail}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        )
    }
}
