import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../util/util";
import {PlusIcon} from '@heroicons/react/solid'
import {Field, FieldsManager} from "../../data/services/fields";
import ResourceTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import ArchivedSwitch from "../../components/archived-switch";
import PageHeader from "../../components/layout-dashboard/page-header";

class TasksPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "",

            fields: this.getFields(),

            columnFilters: {},
            query: "",
            archived: false,

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,

            remountFields: 0,
            roleID: 0
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Tasks
        }));
    };

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: this.getResourceName(),
            piggyResource: Resources.Tasks
        }));
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleFormInputChange = (fields, name, value) => {
        if ("RoleID" === name) {
            this.setState({
                remountFields: this.state.remountFields + 1,
                roleID: value.value
            })
            fields = FieldsManager.updateField(fields, "CandidateID", "");
        }

        return FieldsManager.updateField(fields, name, value);
    };

    handleSearchChange = (name, value) => {
        this.setState({
            search: FieldsManager.updateField(this.state.search, name, value),
            paginationPage: 1,
            offset: 0
        }, () => this.fetchData());
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            remountFields: 0,
            roleID: 0
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    }

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /**
     * @param {{CandidateID:string}} item
     */
    handleEdit = (item) => {
        this.props.history.push("/contact/" + item.CandidateID)
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "TaskID";
    }

    getResourceName = () => {
        return Resources.Tasks;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            interviews: (this.props.location.pathname === "/interviews") ? 2 : 1,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    }

    getFields = (item = null, excludeFields = []) => {
        const fieldTemplates = {
            TaskTitle: new Field("TaskTitle", '', ['empty'], false, 'text'),
            TaskTypeID: new Field("TaskTypeID", (this.props.location.pathname === "/interviews")?2:1, ['empty'], true, 'select'),
            TaskStatusID: new Field("TaskStatusID", 1, ['empty'], false, 'select'),
            RoleID: new Field("RoleID", '', [''], false, 'select-search'),
            CandidateID: new Field("CandidateID", '', [], false, 'select-search'),
            TaskDate: new Field("TaskDate", '', [], false, 'datetime'),

            Description: new Field("Description", '', [], false, 'textarea')
        };

        /*if (this.props.location.pathname === "/interviews") {
            excludeFields.push('TaskStatusID');
        }*/

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getRoleParams = () => {
        if (!!this.state.roleID && !!this.state.remountFields) return {RoleID: this.state.roleID};

        return {};
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName + " / " + item.ClientName,
                    ClientID: item.ClientID
                })
            },
            CandidateID: {
                api: 'api/' + Resources.Candidates,
                query: this.getRoleParams(),
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName
                })
            }
        }

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    {/* Page header */}
                    <PageHeader
                        title={translate((this.props.location.pathname === "/interviews")?"page.title.interviews":"page.title.tasks")}
                    >
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </PageHeader>

                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                className="w-full"
                                classNameContainer={"mr-6 mb-3 w-64"}
                            />

                            <ArchivedSwitch
                                classNameContainer="h-9 md:ml-auto mb-2"
                                translate={translate}
                                value={this.state.archived}
                                onChange={(val) => this.setState({archived: val}, this.fetchData)}
                            />
                        </div>

                        <ResourceTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onEdit={this.handleToggleEditModel}
                            onDelete={this.handleToggleConfirmModal}
                            onRestore={this.handleRestore}
                        />
                    </div>
                </main>

                <ModalSaveResource
                    bodyKey={this.state.remountFields}
                    title={"Create " + ((this.props.location.pathname === "/interviews")?"Interview":"Task")}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields('', ['ClientName'])}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Tasks,
                                piggyResource: Resources.Tasks
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    bodyKey={this.state.remountFields}
                    title={"Edit " + ((this.props.location.pathname === "/interviews")?"Interview":"Task")}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem, ['ClientName'])}
                    handleInputChange={this.handleFormInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.Tasks,
                            piggyResource: Resources.Tasks
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </LayoutDashboard>
        );
    }
}

export default  connect(state => state)(TasksPage);