import { Listbox } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

export default function ListBox(props) {
    let {value, options, onChange} = props;

    let selected = {};

    options = options.map(it => {
        it.selected = false;

        if (it.value == value) {
            it.selected = true;
            selected = it;
        }

        return it;
    })

    options.sort((a, b) => (a.label > b.label) ? 1 : -1)

    return (
        <div className="w-72 relative z-index-5">
            <Listbox value={selected} onChange={onChange}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                        <span className="block truncate">{selected.label}</span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>

                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {options.map((option, optionIdx) => (
                                <Listbox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                        `${active ? 'text-primary-900 bg-primary-100' : 'text-gray-900'}
                                        cursor-pointer select-none relative py-2 pl-10 pr-4`
                                    }
                                    value={option.value}
                                >
                                    {({ active }) => (

                                        <>
                                          <span
                                              className={`${
                                                  selected ? 'font-medium' : 'font-normal'
                                              } block truncate`}
                                          >
                                            {option.label}
                                          </span>
                                            {option.value == value ? (
                                                <span
                                                    className={`${
                                                        active ? 'text-primary-600' : 'text-primary-600'
                                                    }
                                                    absolute inset-y-0 left-0 flex items-center pl-3`}
                                                >
                                                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>

                </div>
            </Listbox>
        </div>
    )
}
