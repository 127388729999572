import {SearchIcon} from "@heroicons/react/solid";
import React from "react";
import {XIcon} from "@heroicons/react/outline";

export default function FieldSearch(props) {
    return (
        <div className={(props.classNameContainer ?? "")}>
            <label htmlFor={props.name} className="sr-only">
                Search
            </label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-secondary-400" aria-hidden="true"/>
                </div>

                <span>
                    <input
                        type="search"
                        onKeyDown={(e) => props.handleKeyDown ? props.handleKeyDown(e) : null}
                        onChange={(event) => {
                            props.onChange(props.name, event.target.value);
                        }}
                        name={props.name}
                        value={props.value}
                        placeholder={props.placeholder}
                        disabled={props.disabled}
                        className={"focus:ring-primary-500 focus:border-primary-500 block pl-10 text-sm border-secondary-300 rounded-md " + (props.className ?? "")}
                    />

                    {!!props.value && (
                        <button
                            type="button"
                            className="bg-transparent absolute right-3 top-2.5 bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => props.onChange(props.name, "")}
                        >
                            <span className="sr-only">Remove question</span>
                            <XIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    )}
                </span>
            </div>
        </div>
    );
}