import React, {Component} from 'react'
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldSearch from "../../components/field-search";
import SimpleTable from "../../components/simple-table";

export default class ReportsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "RoleName",

            columnFilters: {},
            query: "",
            archived: false,

            fields: this.getFields(),

            fieldsSearch: this.getSearchFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fieldsSearch: FieldsManager.updateField(this.state.fieldsSearch, name, value)}, this.fetchData);
    };

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters,
            limit: this.state.limit,
            offset: 0,
            paginationPage: 1
        }, () => this.fetchData())
    };

    handleStateFilterChange = (name, value) => {
        this.setState({
            stateFilter: value
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "RoleID";
    }

    getResourceName = () => {
        return Resources.Roles;
    }

    getQuery = () => {
        return Object.assign({
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }, FieldsManager.getFieldKeyValues(this.state.fieldsSearch));
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            ClientName: new Field("ClientName", '', [], false, 'text'),
            RoleName: new Field("RoleName", '', ['empty'], false, 'text'),
            RoleTitleID: new Field("RoleTitleID", '', [], false, 'select'),
            RoleStatusID: new Field("RoleStatusID", '', ['empty'], false, 'select', {filterOptions: getLookup("RoleStatus", "RoleStatusID", "RoleStatus")}),
            StateID: new Field("StateID", '', [], false, 'select', {filterOptions: getLookup("State", "StateID", "State")}),
            ClientLocationID: new Field("ClientLocationID", '', [], false, 'select-search'),
            PostDate: new Field("PostDate", '', [], false, 'date'),
            FillDate: new Field("FillDate", '', [], false, 'date')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSearchFields = () => ({
        StateID: new Field("StateID", '', [], false, "select")
    });

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex space-x-3">
                    <div>
                        <p className="mt-1 mb-1 text-sm text-secondary-500">Select filter parameters to start:</p>
                    </div>
                </div>

                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mb-3 mr-6"}
                    />

                    <div className="mr-6 w-full sm:w-auto mb-3">
                        <button
                            onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query)
                                ? this.setState({
                                    columnFilters: {},
                                    query: ""
                                }, () => this.fetchData())
                                : null
                            }
                            className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query ? "btn-primary" : " btn-disabled")}
                        >
                            Clear all filters
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}
                    onUpdateColumnFilter={this.handleUpdateColumnFilter}
                    columnFilters={this.state.columnFilters}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={this.handleToggleEditModel}
                />{/* Switched view and edit on purpose */}


            </div>
        )
    }
}
