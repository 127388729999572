import React, {Component} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import {Fragment} from "react";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import {drawTinyField, fieldsToHtml, getProp} from "../../util/util";
import FieldText from "../field-text";
import {createResource, getResourceDetails} from "../../data/actions/resource";

const Mailto = ({ email, subject = '', body = '', children, onClick }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a onClick={onClick} href={`mailto:${email}${params}`}>{children}</a>;
};

export default class EmailTemplateDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            RoleID: 0,
            EmailTemplateID: 0,

            Subject: "",
            Content: "",
            fields: {
                EmailTemplateID: new Field("EmailTemplateID", '', [], false, 'select-search'),
                RoleID: new Field("RoleID", '', [], false, 'select-search'),
            }
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchEmailTemplate();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                Subject: getProp(this.props, "resource.detailsData.subject", ""),
                Content: getProp(this.props, "resource.detailsData.content", ""),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchEmailTemplate = () => {
        this.props.dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                RoleID: this.state.fields.RoleID.value?.value,
                CandidateID: this.props.CandidateID,
                EmailTemplateID: this.state.fields.EmailTemplateID.value?.value
            },
            resource: Resources.EmailTemplatesParse
        }))
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)}, this.fetchEmailTemplate);
    };

    /** Render
     ================================================================= */
    render() {

        const {onClose, visible, translate} = this.props;

        const selectSearchFields = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            EmailTemplateID: {
                api: 'api/' + Resources.EmailTemplates,
                query: {},
                searchMap: (item) => ({
                    value: item.EmailTemplateID,
                    label: item.EmailTemplateName
                })
            },
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName
                })
            }
        });


        return (
            <Transition show={visible} as={Fragment}>
                <Dialog
                    as="div"
                    id="modal"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    static
                    open={visible}
                    onClose={onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                    &#8203;
                    </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="max-w-4xl theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                    <p>Compose email for {this.props.email}</p>

                                    {selectSearchFields}

                                    <p>Subject</p>
                                    <FieldText
                                        className={"w-full"}
                                        value={this.state.Subject}
                                        onChange={(name, value) => {
                                            this.setState({
                                                Subject: value
                                            })
                                        }}
                                    />

                                    <p>Content</p>
                                    {drawTinyField({
                                        name: "Content",
                                        value: this.state.Content
                                    }, (name, value) => {
                                        this.setState({
                                            Content: value
                                        })
                                    })}
                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-end">

                                        <Mailto
                                            email={this.props.email}
                                            subject={this.state.Subject}
                                            body={this.state.Content}
                                            children={(
                                                <div
                                                    className="mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                >
                                                    Compose
                                                </div>
                                            )}
                                            onClick={() => {
                                                this.props.dispatch(createResource({
                                                    user: LocalStorage.get("user"),
                                                    query: this.props.query,
                                                    params: {
                                                        RoleID: this.state.RoleID,
                                                        DoNotSend: 1,
                                                        CandidateID: this.props.CandidateID,
                                                        Subject: this.state.Subject,
                                                        Message: this.state.Content
                                                    },
                                                    resource: Resources.CandidateMessage,
                                                    piggyResource: Resources.CandidateMessage
                                                }));
                                                onClose();
                                            }}
                                        />

                                        <button
                                            type="button"
                                            className="btn btn-outline focus:ring-offset-secondary-50"
                                            onClick={onClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}
