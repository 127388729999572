import React, {Component, Fragment} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {ChevronRightIcon, MenuAlt2Icon, XIcon} from '@heroicons/react/outline'
import {BrowserRouter, Link} from "react-router-dom";
import {
    checkPasswordStrength,
    checkPerm,
    classNames,
    getProp,
    getUserLabel,
    READ_PERM,
    setDocumentTitle
} from "../../util/util";
import Logout from "../logout";
import LocalStorage from "../../util/localStorage";
import {logout, logoutClear, resetUserMessage, updatePassword} from "../../data/actions/user";
import Notification from "../notification";
import {hideNotification, idleTimer} from "../../data/actions/ui";
import FieldPassword from "../field-password";
import {Field, FieldsManager} from "../../data/services/fields";
import moment from "moment";
import Loader from "../loader";
import Resources from "../../data/services/resources";
import {ChatIcon} from "@heroicons/react/solid";
import {showChatPanel} from "../../data/actions/messaging";
import {getNotificationList} from "../../data/actions/notifications";
import NotificationsDropdown from "../notifications-dropdown";
import IdleLogoutDialog from "../idle-modal/idle-logout-dialog";
import ModalConfirm from "../modal/modal-confirm";
import Env from "../../util/env";
import CommandPalette from "../command-palette";

export default class LayoutDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            isSidebarOpen: false,
            ThemeDark: false,
            sidebarIsCollapsed: false,
            sidebarAnimation: false,
            page: "",
            changePasswordModalOpen: false,

            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            no_match: false,
            passwordStrength: {
                passed: 0,
                strength: "",
                color: "white"
            },
            idleTimer: false,
            selectedMenuDropdownItem: null
        }
    }

    componentDidMount() {
        // False parameter prevents collapsed sidebar from 'jumping' when changing page
        this.changeTheme(LocalStorage.get("IS_THEME_DARK"), false);

        this.props.dispatch(getNotificationList({
            user: LocalStorage.get("user")
        }));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== this.props.location.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }

        if (LocalStorage.get('idleTimer') && !this.state.idleTimer) {
            this.setState({idleTimer: true});
        }
    }

    setSidebarOpen = (open) => {
        this.setState({
            isSidebarOpen: open
        })
    }

    changeTheme = (isDark, sidebarAnimation = true) => {
        this.setState({
            ThemeDark: isDark,
            sidebarAnimation: sidebarAnimation
        }, () => {
            if (this.state.ThemeDark) {
                document.documentElement.classList.add('theme-dark');
                document.documentElement.classList.remove('theme-starter');
                LocalStorage.set('IS_THEME_DARK', true);
            } else {
                document.documentElement.classList.remove('theme-dark');
                document.documentElement.classList.add('theme-starter');
                LocalStorage.set('IS_THEME_DARK', false);
            }
        })
    }

    changePasswordSubmit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (this.state.passwordStrength.strength < 4) {
                return;
            }
            if (this.state.fields.password.value !== this.state.fields.password_confirm.value) {
                this.setState({
                    no_match: true
                });
            } else if (!this.state.fields.password.errorMessage && !this.state.fields.password_confirm.errorMessage) {
                this.props.dispatch(updatePassword({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields)
                }))
            }
        })
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});

        if (name === 'password') {
            this.setState({
                passwordStrength: checkPasswordStrength(value)
            })
        }
    };

    handleChangePassword = () => {
        this.setState({
            changePasswordModalOpen: !this.state.changePasswordModalOpen
        })
    }

    handleOpenMenuDropdown = (name = null) => {
        this.setState({
            selectedMenuDropdownItem: name
        })
    }

    handleActionClick = (Name, Data, id) => {
        // NOOP
    }

    handleConfirmLogout = () => {
        this.props.dispatch(logout({}))
    }

    render() {
        const {translate} = this.props;

        let currentPage = this.props.match.path.substring(1)
        currentPage = currentPage.substring(0, currentPage.indexOf('/')) || currentPage;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let currentSubTab = urlParams.get('selectedSubTab')

        let userNavigation = [
            {name: 'Users', href: '/users', resource: Resources.Users},
            {name: 'Logged devices', href: '/devices', resource: Resources.UserDevices},
            {name: "Billing", href: "/payment", resource: Resources.Payment},
            {
                name: 'Change Password', action: () => {
                    this.setState({
                        changePasswordModalOpen: true
                    })
                }, resource: Resources.User
            }
        ];

        if (!checkPerm(Resources.Clients, READ_PERM)) {
            userNavigation = [
                {
                    name: 'Change Password', action: () => {
                        this.setState({
                            changePasswordModalOpen: true
                        })
                    }
                }
            ]
        }

        const mainNav = checkPerm(Resources.Clients, READ_PERM) ? [
            {id: 1, name: 'Calendar', category: 'CALENDAR', href: '/calendar'},
            {id: 2, name: 'Tasks', category: 'CALENDAR', href: '/tasks'},
            {id: 3, name: 'Interviews', category: 'CALENDAR', href: '/interviews'},

            {id: 4, name: 'New Role', category: 'ROLES', href: '/roles?createDialog=true'},
            {id: 5, name: 'Roles', category: 'ROLES', href: '/roles'},

            {id: 6, name: 'Add Candidate Contact', category: 'CONTACTS', href: '/contacts?createDialog=true'},
            {id: 7, name: 'Client Contacts', category: 'CONTACTS', href: '/contacts'},
            {id: 8, name: 'Candidates', category: 'CONTACTS', href: '/contacts'},
            {id: 9, name: 'Search Candidates', category: 'CONTACTS', href: '/search'},

            {id: 10, name: 'New Client', category: 'CLIENTS', href: '/clients?createDialog=true'},
            {id: 11, name: 'Clients', category: 'CLIENTS', href: '/clients'},

            {id: 12, name: 'Client Reports', category: 'REPORTS', href: '/reports'},
            {id: 13, name: 'Financials', category: 'REPORTS', href: '/financials'},

            {id: 13, name: 'Global Settings', category: 'SETTINGS', href: '/settings'},
            {id: 13, name: 'Contact Import', category: 'SETTINGS', href: '/import?selectedSubTab=Contacts'},
            {id: 13, name: 'Connections Import', category: 'SETTINGS', href: '/import?selectedSubTab=Connections'},
            {id: 13, name: 'CSV Import', category: 'SETTINGS', href: '/import?selectedSubTab=CSV'},
        ] : [
            {id: 1, name: 'Dashboard', category: 'DASHBOARD', href: '/dash-board'},
            {id: 2, name: 'Roles', category: 'ROLES', href: '/roles-board'},
            {id: 3, name: 'Profile', category: 'PROFILE', href: '/candidate-profile'},
            {id: 3, name: 'Recruiters', category: 'RECRUITERS', href: '/recruiters'},
        ]

        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg = it.message;
            let type = it.type;
            // eslint-disable-next-line default-case
            switch (it.message) {
                case 'CREATE_RESOURCE':
                case 'CREATE_DIALOG_RESOURCE':
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                case 'UPDATE_DIALOG_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                case 'DELETE_DIALOG_RESOURCE':
                    msg = "Entry deleted";
                    break;
            }
            return (
                <Notification
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    title={it.notificationTitle ?? "Notification"}
                    message={msg}
                    type={type}
                    translate={translate}
                />
            )
        });

        const userMenuBtnLabel = getUserLabel();

        const roles = LocalStorage.get('rolesHistory', []).map((item, i) => (
            <BrowserRouter forceRefresh={true}>
                <Link to={`/roles?openRole=${item.id}`}
                      className={"hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"}>
                    {item.name}
                </Link>
            </BrowserRouter>
        ));

        const contacts = LocalStorage.get('contactsHistory', []).map((item, i) => (
            <Link to={`/contact/${item.id}`}
                  className={"hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"}>
                <div className="dropdown-link-padding">{item.name}</div>
            </Link>
        ));

        const clients = LocalStorage.get('clientsHistory', []).map((item, i) => (
            <Link to={`/client/${item.id}`}
                  className={"hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"}>
                <div className="dropdown-link-padding">{item.name}</div>
            </Link>
        ));

        return (
            <>
                <div className="h-screen flex bg-secondary-100">
                    <Transition.Root show={this.state.isSidebarOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            static
                            className="fixed inset-0 flex z-40 lg:hidden"
                            open={this.state.isSidebarOpen}
                            onClose={() => this.setSidebarOpen(true)}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="transition-opacity ease-linear duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity ease-linear duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                            </Transition.Child>
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                {/* Dynamic sidebar for mobile */}
                                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-sidebar">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-inverse"
                                                onClick={() => this.setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XIcon className="h-6 w-6 text-inverse" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>

                                    <div className="flex-shrink-0 flex items-center px-4 text-inverse">
                                        <Link to="/calendar">
                                            <div className="w-32">
                                                <img src="/images/logos/logo-inverse.png" alt="GoLeir"/>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="mt-5 flex-1 h-0">
                                        <nav
                                            className="mt-5 flex-shrink-0 h-full divide-y divide-sidebar-light overflow-y-auto"
                                            aria-label="Sidebar">
                                            <div className="px-2 space-y-1">

                                                {checkPerm(Resources.Clients, READ_PERM) && (
                                                    <React.Fragment>
                                                        <Link to="/dashboard"
                                                              className={"hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("dashboard" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Dashboard
                                                        </Link>
                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" }>
                                                            CALENDAR
                                                        </div>
                                                        <Link to="/calendar"
                                                              className={"pl-6  hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("calendar" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Calendar
                                                        </Link>
                                                        <Link to="/tasks"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("tasks" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Tasks
                                                        </Link>
                                                        <Link to="/interviews"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("interviews" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Interviews
                                                        </Link>

                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            ROLES
                                                        </div>
                                                        <BrowserRouter forceRefresh={true}>
                                                            <Link to="/roles?createDialog=true"
                                                                  className={"pl-6 text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                                New Role
                                                            </Link>
                                                        </BrowserRouter>
                                                        <Link to="/roles"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("roles" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Roles
                                                        </Link>

                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            CONTACTS
                                                        </div>
                                                        <BrowserRouter forceRefresh={true}>
                                                            <Link to="/contacts?createDialog=true"
                                                                  className={"pl-6 text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                                Add Candidate Contact
                                                            </Link>
                                                        </BrowserRouter>
                                                        <Link to="Client Contacts"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("client-contacts" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Client Contacts
                                                        </Link>


                                                        <Link to="/contacts"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("contacts" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Candidates
                                                        </Link>
                                                        <Link to="/search"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("search" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Search Candidates
                                                        </Link>

                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            CLIENTS
                                                        </div>
                                                        <BrowserRouter forceRefresh={true}>
                                                            <Link to="/clients?createDialog=true"
                                                                  className={"pl-6 text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                                New Client
                                                            </Link>
                                                        </BrowserRouter>
                                                        <Link to="/clients"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("clients" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Clients
                                                        </Link>

                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            REPORTS
                                                        </div>
                                                        <Link to="/reports"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("reports" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Client Reports
                                                        </Link>
                                                        <Link to="/financials"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("financials" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Financials
                                                        </Link>

                                                        <div
                                                            className={"text-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            SETTINGS
                                                        </div>
                                                        <Link to="/settings"
                                                              className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("settings" === currentPage ? " text-white" : " text-sidebar-light")}>
                                                            Global Settings
                                                        </Link>
                                                        <BrowserRouter forceRefresh={true}>
                                                            <Link to="/import?selectedSubTab=Contacts"
                                                                  className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("Contacts" === currentSubTab ? " text-white" : " text-sidebar-light")}>
                                                                Contact Import
                                                            </Link>
                                                        </BrowserRouter>
                                                        <BrowserRouter forceRefresh={true}>
                                                            <Link to="/import?selectedSubTab=Connections"
                                                                  className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("Connections" === currentSubTab ? " text-white" : " text-sidebar-light")}>
                                                                Connections Import
                                                            </Link>
                                                        </BrowserRouter>
                                                        <Link
                                                            to="/import?selectedSubTab=CSV"
                                                            className={"pl-6 hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md" + ("CSV" === currentSubTab ? " text-white" : " text-sidebar-light")}>
                                                            CSV Import
                                                        </Link>
                                                    </React.Fragment>
                                                )}

                                                {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtDashboard, READ_PERM)) && (
                                                    <React.Fragment>
                                                        <Link to="/dash-board"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            DASHBOARD
                                                        </Link>
                                                        <Link to="/roles-board"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            ROLES
                                                        </Link>
                                                        <Link to="/candidate-profile"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            PROFILE
                                                        </Link>
                                                        <Link to="/recruiters"
                                                            className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            RECRUITERS
                                                        </Link>
                                                        <Link to="/settings"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            SETTINGS
                                                        </Link>
                                                    </React.Fragment>
                                                )}

                                                {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtClientDashboard, READ_PERM)) && (
                                                    <React.Fragment>
                                                        <Link to="/client-board"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            DASHBOARD
                                                        </Link>
                                                        <Link to="/client-roles-board"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            ROLES
                                                        </Link>
                                                        <Link to="/ext-client-contacts"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            CONTACTS
                                                        </Link>
                                                        <Link to="/settings"
                                                              className={"text-sidebar-light hover:text-white hover:bg-sidebar-light group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"}>
                                                            SETTINGS
                                                        </Link>
                                                    </React.Fragment>
                                                )}

                                            </div>

                                        </nav>
                                    </div>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </Dialog>
                    </Transition.Root>

                    {/* Static sidebar for desktop */}
                    <div
                        className="absolute hidden z-20 lg:flex h-20 items-center flex-shrink-0 px-4 text-white">
                        <div className="w-16">
                            {checkPerm(Resources.Clients, READ_PERM) && (
                                <Link to="/dashboard">
                                    <img src={"/images/logos/logo" + (!!this.state.ThemeDark ? "-inverse" : "") + ".png"} className={'w-16 h-16'}
                                         alt="GoLeir"/>
                                </Link>
                            )}

                            {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtDashboard, READ_PERM)) && (
                                <Link to="/dash-board">
                                    <img src={"/images/logos/logo" + (!!this.state.ThemeDark ? "-inverse" : "") + ".png"} className={'w-16 h-16'} alt="GoLeir"/>
                                </Link>
                            )}

                            {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtClientDashboard, READ_PERM)) && (
                                <Link to="/client-board">
                                    <img src={"/images/logos/logo" + (!!this.state.ThemeDark ? "-inverse" : "") + ".png"} className={'w-16 h-16'} alt="GoLeir"/>
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col w-0 flex-1 overflow-hidden ">
                        <div className="relative z-10 flex-shrink-0 flex h-20 bg-secondary-100 shadow border-b-2 border-primary">
                            <button
                                type="button"
                                className="px-4 border-r border-secondary-200 text-secondary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 lg:hidden"
                                onClick={() => this.setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                            <div className="flex-1 px-4 flex justify-between">
                                <div className="flex-1 flex justify-center">
                                    <nav onMouseLeave={() => this.handleOpenMenuDropdown()}
                                         className="hidden justify-center items-center lg:flex lg:space-x-4 xl:space-x-8"
                                         aria-label="Global">

                                        {(checkPerm(Resources.Clients, READ_PERM)) && (
                                            <>
                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("calendar")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "calendar" ? null : "calendar")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("calendar" === currentPage || "interviews" === currentPage || "tasks" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        CALENDAR <span><ChevronRightIcon className={"w-4 h-4 text-primary"}/></span>
                                                    </div>
                                                    {this.state.selectedMenuDropdownItem === "calendar" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <Link to="/calendar"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-0">Calendar</Link>
                                                                <Link to="/tasks"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-1">Tasks</Link>
                                                                <Link to="/interviews"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-2">Interviews</Link>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>

                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("roles")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "roles" ? null : "roles")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("roles" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        ROLES <span><ChevronRightIcon className={" w-4 h-4 text-primary"}/></span>
                                                    </div>

                                                    {this.state.selectedMenuDropdownItem === "roles" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <BrowserRouter forceRefresh={true}>
                                                                    <Link to="/roles?createDialog=true"
                                                                          className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                          role="menuitem" tabIndex="-1"
                                                                          id="menu-item-0">New Role</Link>
                                                                </BrowserRouter>
                                                                <Link to="/roles"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-1">Roles</Link>

                                                                {(!!roles && roles.length > 0) && (
                                                                    <div className={"w-full border-t-4 border-black-700"}></div>
                                                                )}
                                                                {roles}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("contacts")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "contacts" ? null : "contacts")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("contacts" === currentPage || "contact" === currentPage || "search" === currentPage || "client-contacts" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        CONTACTS <span><ChevronRightIcon className={" w-4 h-4 text-primary"}/></span>
                                                    </div>

                                                    {this.state.selectedMenuDropdownItem === "contacts" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <BrowserRouter forceRefresh={true}>
                                                                    <Link to="/contacts?createDialog=true"
                                                                          className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                          role="menuitem" tabIndex="-1"
                                                                          id="menu-item-0">Add Candidate Contact</Link>
                                                                </BrowserRouter>
                                                                <Link to="/client-contacts"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1" id="menu-item-1">Client
                                                                    Contacts</Link>
                                                                <Link to="/contacts"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-2">Candidates</Link>
                                                                <Link to="/search"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1" id="menu-item-3">Search
                                                                    Candidates</Link>

                                                                {(!!contacts && contacts.length > 0) && (
                                                                    <div
                                                                        className={"w-full border-t-4 border-black-700"}></div>
                                                                )}
                                                                {contacts}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("clients")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "clients" ? null : "clients")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("clients" === currentPage || "client" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        CLIENTS <span><ChevronRightIcon className={" w-4 h-4 text-primary"}/></span>
                                                    </div>

                                                    {this.state.selectedMenuDropdownItem === "clients" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <BrowserRouter forceRefresh={true}>
                                                                    <Link to="/clients?createDialog=true"
                                                                          className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                          role="menuitem" tabIndex="-1"
                                                                          id="menu-item-0">New Client</Link>
                                                                </BrowserRouter>
                                                                <Link to="/clients"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-1">Clients</Link>
                                                                {(!!clients && clients.length > 0) && (
                                                                    <div
                                                                        className={"w-full border-t-4 border-black-700"}></div>
                                                                )}
                                                                {clients}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("reports")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "reports" ? null : "reports")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("reports" === currentPage || "report" === currentPage || "financials" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        REPORTS <span><ChevronRightIcon className={" w-4 h-4 text-primary"}/></span>
                                                    </div>

                                                    {this.state.selectedMenuDropdownItem === "reports" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <Link to="/reports"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1" id="menu-item-0">Client
                                                                    Reports</Link>
                                                                <Link to="/financials"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1"
                                                                      id="menu-item-1">Financials</Link>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <div
                                                        onMouseEnter={() => this.handleOpenMenuDropdown("settings")}
                                                        onClick={() => this.handleOpenMenuDropdown(this.state.selectedMenuDropdownItem === "settings" ? null : "settings")}
                                                        className={'cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm border-b-2 hover:border-primary-600' + (("settings" === currentPage || "import" === currentPage) ? " border-primary-600" : " border-secondary-100")}
                                                    >
                                                        SETTINGS <span className={''}><ChevronRightIcon className={" w-4 h-4 text-primary"}/></span>
                                                    </div>
                                                    {this.state.selectedMenuDropdownItem === "settings" && (
                                                        <div
                                                            onMouseLeave={() => this.handleOpenMenuDropdown()}
                                                            className="origin-top-left absolute right-0 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                                                            role="menu" aria-orientation="vertical"
                                                            aria-labelledby="menu-button"
                                                            tabIndex="-1">
                                                            <div className="py-1" role="none">
                                                                <Link to="/settings"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1" id="menu-item-0">Global
                                                                    Settings</Link>
                                                                <BrowserRouter forceRefresh={true}>
                                                                    <Link to="/import?selectedSubTab=Contacts"
                                                                          className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                          role="menuitem" tabIndex="-1"
                                                                          id="menu-item-1">Contact Import</Link>
                                                                </BrowserRouter>
                                                                <BrowserRouter forceRefresh={true}>
                                                                    <Link to="/import?selectedSubTab=Connections"
                                                                          className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                          role="menuitem" tabIndex="-1"
                                                                          id="menu-item-2">Connections Import</Link>
                                                                </BrowserRouter>
                                                                <BrowserRouter forceRefresh={true}>
                                                                <Link to="/import?selectedSubTab=CSV"
                                                                      className="hover:bg-primary-600 hover:text-white text-gray-700 block px-4 py-2 text-sm"
                                                                      role="menuitem" tabIndex="-1" id="menu-item-3">CSV
                                                                    Import</Link>
                                                                </BrowserRouter>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtDashboard, READ_PERM)) && (
                                            <React.Fragment>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/dash-board"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1"
                                                          id="menu-item-0">Dashboard</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/roles-board"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Roles</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/candidate-profile"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Profile</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/recruiters"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Recruiters</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/settings"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Settings</Link>
                                                </div>
                                            </React.Fragment>
                                        )}

                                        {(!checkPerm(Resources.Clients, READ_PERM) && checkPerm(Resources.ExtClientDashboard, READ_PERM)) && (
                                            <React.Fragment>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/client-board"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1"
                                                          id="menu-item-0">Dashboard</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/client-roles-board"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Roles</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/ext-client-contacts"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Contacts</Link>
                                                </div>
                                                <div className="relative inline-block text-left">
                                                    <Link to="/settings"
                                                          className="cursor-pointer text-gray-900 py-2 inline-flex items-center text-sm font-medium border-b-2 hover:border-primary-600 border-secondary-100"
                                                          role="menuitem" tabIndex="-1" id="menu-item-0">Settings</Link>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </nav>
                                </div>

                                <div className="flex items-center">
                                    {!!LocalStorage.get('user') && (
                                        <div className={'flex justify-center items-center'}>
                                            <img
                                                className="object-contain h-16 w-16 mr-3"
                                                src={Env.getApiUrl('api/' + Resources.CompanyImage, {
                                                    token: LocalStorage.get('user').access_token
                                                }) + "&date=" + new Date().getTime()}
                                                alt=""
                                                onError={(event) => event.target.style.display = 'none'}
                                            />
                                        </div>
                                    )}
                                    <NotificationsDropdown
                                        onClick={() => {

                                        }}
                                        onActionClick={this.handleActionClick}
                                        notifications={this.props?.notifications?.data?.notifications}
                                        unreadCount={this.props?.notifications?.data?.unreadCount ?? 0}
                                    />

                                    <button
                                        onClick={() => this.props.dispatch(showChatPanel())}
                                        className="bg-secondary-100 p-1 rounded-full text-secondary-400 hover:text-secondary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-secondary-100">
                                        <span className="sr-only">View messages</span>
                                        <ChatIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>

                                    {/* Profile dropdown */}
                                    <Menu as="div" className="ml-3 relative dark">
                                        {({open}) => (
                                            <>
                                                <div>
                                                    <Menu.Button
                                                        className="max-w-xs bg-transparent flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ring-offset-secondary-100 text-secondary-900">
                                                        <span className="sr-only">Open user menu</span>
                                                        <div
                                                            className="w-10 h-10 bg-primary rounded-full flex justify-center items-center mr-2 select-none">
                                                            <div
                                                                className="text-inverse font-black">{userMenuBtnLabel.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                                        </div>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6"
                                                             viewBox="0 0 10 6" className="mr-2">
                                                            <path fill="currentColor"
                                                                  d="M8.292893.292893c.390525-.390524 1.023689-.390524 1.414214 0 .390524.390525.390524 1.023689 0 1.414214l-4 4c-.390525.390524-1.023689.390524-1.414214 0l-4-4c-.390524-.390525-.390524-1.023689 0-1.414214.390525-.390524 1.023689-.390524 1.414214 0L5 3.585786 8.292893.292893z"/>
                                                        </svg>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="theme-dark-popup origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-inverse ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                            <Menu.Item key="user-name">
                                                                <div className="px-4 py-3 border-b border-gray-200 mb-2" role="none">
                                                                    <p className="text-sm" role="none">
                                                                        Signed in as
                                                                    </p>
                                                                    <p className="font-semibold text-gray-900 truncate"
                                                                       role="none">
                                                                        {userMenuBtnLabel}
                                                                    </p>
                                                                </div>
                                                            </Menu.Item>

                                                        {userNavigation.filter(item => checkPerm(item.resource, READ_PERM)).map((item) => (
                                                            <Menu.Item key={item.name}>
                                                                {({active}) => {
                                                                    if (item.action) {
                                                                        return (
                                                                            <div
                                                                                onClick={item.action}
                                                                                className={classNames(
                                                                                    active ? 'bg-secondary-100' : '',
                                                                                    'cursor-pointer block px-4 py-2 text-sm text-secondary-700'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return (
                                                                        <Link
                                                                            to={item.href}
                                                                            className={classNames(
                                                                                active ? 'bg-secondary-100' : '',
                                                                                'block px-4 py-2 text-sm text-secondary-700'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </Link>
                                                                    );
                                                                }}
                                                            </Menu.Item>
                                                        ))}
                                                        <Menu.Item key={"logout"}>
                                                            {({active}) => (
                                                                <Logout
                                                                    className={classNames(
                                                                        active ? 'bg-secondary-100' : '',
                                                                        'block px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-100'
                                                                    )}
                                                                    history={this.props.history}
                                                                    translate={this.props.translate}
                                                                    dispatch={this.props.dispatch}/>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                </div>

                            </div>

                        </div>

                        <main className="flex-1 relative overflow-y-auto focus:outline-none">
                            {this.props.children}

                            {!this.props.noFooter && (
                                <div className={"text-secondary-900 py-3 text-center text-sm" + (!!this.props.footerBorder ? " border-t" : "")}>
                                    <span>©{moment().year()} GoLeir | All rights reserved. | <Link
                                        className="border-transparent hover:opacity-70 text-primary-700"
                                        to={"/privacy-policy"}>Privacy</Link></span>
                                </div>
                            )}
                        </main>
                    </div>
                </div>

                {/* Update Password Dialog */}
                <Transition show={this.state.changePasswordModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.changePasswordModalOpen}
                        onClose={this.handleChangePassword}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                                </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-sm p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        {this.props.user.isLoading && (
                                            <div className={"flex justify-center items-center w-full h-48 text-center"}>
                                                <Loader/>
                                            </div>
                                        )}
                                        {!this.props.user.isLoading && !this.props.user.reset && (
                                            <>
                                                <p>Enter new password:</p>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password}
                                                    className="h-9 block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.new_password")}/>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                    className="h-9 block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500  sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.password_confirm")}/>

                                                <span className={"passwordStrength"}
                                                      style={{backgroundColor: this.state.passwordStrength.color}}></span>

                                                {this.state.no_match && (
                                                    <div
                                                        className={"text-red-500"}>{translate("reset_password.no_match")}</div>
                                                )}
                                            </>
                                        )}

                                        {!this.props.user.isLoading && this.props.user.reset && (
                                            <div>
                                                <p
                                                    className={"text-lg m-3"}
                                                >
                                                    {translate("reset_password.success_new_pass")}
                                                </p>
                                                <button
                                                    type="button"
                                                    className="m-auto mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={() => {
                                                        this.props.dispatch(resetUserMessage());
                                                        this.handleChangePassword();
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {!this.props.user.isLoading && !this.props.user.reset && (
                                        <div className="flex justify-end">
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button
                                                    type="button"
                                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={this.changePasswordSubmit}
                                                >
                                                    Confirm

                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={this.handleChangePassword}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <ModalConfirm
                    type="info"
                    visible={this.props.ui.idleTimer ?? this.state.idleTimer}
                    onConfirm={this.handleConfirmLogout}
                    buttonLabel={translate("btn.go_to_login")}
                    hideCancelBtn={true}
                    title={translate("You have been logged out due to inactivity")}
                    translate={translate}
                    onClose={this.handleConfirmLogout}
                >
                    <IdleLogoutDialog
                        dispatch={this.props.dispatch}
                    />
                </ModalConfirm>

                <CommandPalette
                    history={this.props.history}
                    items={mainNav}
                />

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </>
        );
    }
}
