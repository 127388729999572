import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp, toFrontDate} from "../../util/util";
import PageHeader from "../../components/layout-dashboard/page-header";
import Loader from "../../components/loader";
import {ChevronRightIcon, CurrencyDollarIcon} from '@heroicons/react/solid'
import ModalConfirm from "../../components/modal/modal-confirm";
import moment from "moment";
import ModalViewDocument from "../../components/modal/modal-view-document";
import FileSaver from "file-saver";
import axios from "axios";
import DialogDefault from "../../components/dialog-default";
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import "../register/payment.css";
import {Link} from "react-router-dom";

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#007a64',
            color: '#007a64',
            fontWeight: 500,
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#00a88b',
            },
            '::placeholder': {
                color: '#00a88b',
            },
            borderColor: '#00a88b',
            padding: 10
        },
        invalid: {
            iconColor: 'red',
            color: 'red',
        },
    },
};

const ErrorMessage = ({children}) => (
    <div className="ErrorMessage" role="alert">
        <svg width="16" height="16" viewBox="0 0 17 17">
            <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
            />
            <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
            />
        </svg>
        {children}
    </div>
);

const CardField = ({onChange}) => (
    <div>
        <CardElement options={CARD_OPTIONS} onChange={onChange} className="card-element"/>
    </div>
);

const DEFAULT_STATE = {
    error: null,
    cardComplete: false,
    processing: false,
    paymentMethod: null,
    quantity: 1
};

class InjectedStripe extends Component {
    constructor(props) {
        super(props);
        this.stripePromise = loadStripe('pk_test_51Jx7ohCX1EqdpeEcetLKlxiCG4SNgX414re1abRVLYd94wxkNdMgR6tQllC6yfPvYjMKXKi7xw0KimPBFCE6iXMX00KlLU912G');

    }
    render() {
        return (
            <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                    {({stripe, elements}) => (
                        <PaymentManagementPage stripe={stripe} elements={elements} {...this.props} />
                    )}
                </ElementsConsumer>
            </Elements>
        )
    }
}

export default connect(state => state)(InjectedStripe);

class PaymentManagementPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_STATE,

            cancelModalOpen: false,
            viewModalOpen: false,
            updateModalOpen: false,
            updatePaymentModalOpen: false,
            quantity: null
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const Quantity = getProp(this.props, "resource.data.subscription.Quantity", null);
        if ((this.state.quantity === null) && !!Quantity) {
            this.setState({
                quantity: Quantity
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }));
    };

    modifySubscription = (quantity = 1) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            params: {
                Quantity: quantity
            },
            notificationMessage: "Subscription Modified",
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    };

    updatePayment = async (paymentMethodId) => {
        const {stripe, elements} = this.props;
        const {error, cardComplete} = this.state;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardElement);

        if (card == null) {
            return;
        }

        if (error) {
            card.focus();
            return;
        }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card
        });

        console.log(payload);

        if (payload.error) {
            this.setState({error: payload.error});
        } else {
            this.handleUpdatePayment();
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                params: {
                    paymentMethodId: payload.paymentMethod
                },
                notificationMessage: "Credit Card Updated",
                resource: this.getResourceName() + "/method",
                piggyResource: this.getResourceName()
            }));
        }
    };

    /** UI Events
     ================================================================= */
    handleCancelSubscription = () => {
        this.setState({
            cancelModalOpen: !this.state.cancelModalOpen
        })
    }

    handleRenewSubscription = () => {

    };

    handleUpdatePayment = () => {
        this.setState({
            updatePaymentModalOpen: !this.state.updatePaymentModalOpen
        })
    };

    handleModifySubscription = () => {
        this.setState({
            updateModalOpen: !this.state.updateModalOpen
        })
    };

    handleViewInvoice = (path = null) => {
        window.open(path, '_blank');
    };

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.Payment;
    }

    getQuery = () => {
        return {}
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const info = getProp(this.props, "resource.data", []);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const positions = getProp(this.props, "resource.data.invoices.data", []);

        const upcoming = getProp(this.props, "resource.data.subscription.upcoming.lines.data", []);

        const card  = getProp(this.props, "resource.data.subscription.payment.card", []);

        const userCount = getProp(this.props, "resource.data.subscription.UserCount", 0);

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative pb-8 z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.payment")}
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}

                            <div className={"w-full"}>
                                {!isLoading && (
                                <>
                                <div className={"mb-3"}>
                                    <h2 className="text-2xl font-extrabold text-gray-900 my-3">Current Plan</h2>
                                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                                            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                <dt className="text-sm font-medium text-gray-500 truncate">Info:</dt>
                                                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                                                    {info?.subscription?.Quantity} seats - ${info?.subscription?.Quantity * info?.subscription?.Amount} / per month
                                                </dd>
                                            </div>

                                            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                <dt className="text-sm font-medium text-gray-500 truncate">Status:</dt>
                                                <dd className="mt-1 text-3xl font-semibold text-gray-900">{!!info?.subscription?.ExpiryDate ? (
                                                    <span>
                                                        <span>Expires at {toFrontDate(info?.subscription?.ExpiryDate)}</span>
                                                        <button
                                                            type="button"
                                                            className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-primary-500 bg-primary-600 hover:bg-primary-700"}
                                                            onClick={() => this.handleRenewSubscription()}
                                                        >
                                                            Renew
                                                        </button>
                                                    </span>
                                                ) : "Active"}</dd>
                                            </div>

                                            <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                                                <dt className="text-sm font-medium text-gray-500 truncate">Manage</dt>
                                                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                                                    <button
                                                        type="button"
                                                        className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-primary-500 bg-primary-600 hover:bg-primary-700"}
                                                        onClick={() => this.handleUpdatePayment()}
                                                    >
                                                        Update Payment Method
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-primary-500 bg-primary-600 hover:bg-primary-700"}
                                                        onClick={() => this.handleModifySubscription()}
                                                    >
                                                        Add/Remove Seats
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-red-500 bg-red-600 hover:bg-red-700"}
                                                        onClick={() => this.handleCancelSubscription()}
                                                    >
                                                        Cancel Subscription
                                                    </button>
                                                </dd>
                                            </div>
                                        </dl>
                                </div>

                                <h2 className="text-2xl font-extrabold text-gray-900 my-3">Upcoming Invoice on {moment.unix(getProp(this.props, "resource.data.subscription.upcoming.next_payment_attempt", 0)).format("MM/DD/YYYY")}</h2>
                                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {upcoming.map((position) => (
                                            <li key={position.id}>
                                                <div className="px-2 py-2 flex items-center sm:px-2">
                                                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                                        <div className="truncate">
                                                            <div className="flex text-sm">
                                                                <p className="font-medium">{position.description}</p>
                                                            </div>
                                                            <div className="mt-1 flex">
                                                                <div className="flex items-center text-sm text-gray-500">
                                                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    <p>
                                                                        {position.amount / 100}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}

                                        <div className="px-2 py-2 flex items-center sm:px-2">
                                            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div className="truncate">
                                                    <div className="flex text-sm">
                                                        <p className="text-lg font-bold">Total</p>
                                                    </div>
                                                    <div className="mt-1 flex">
                                                        <div className="flex items-center text-lg text-gray-500">
                                                            <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            <p>
                                                                {upcoming.reduce((memo, it) => memo + it.amount, 0) / 100}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>

                                </div>

                                <h2 className="text-2xl font-extrabold text-gray-900 my-3">Past Invoices</h2>

                                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {positions.map((position) => (
                                            <li key={position.id}
                                                className={"hover:cursor-pointer"}
                                                onClick={() => this.handleViewInvoice(position.invoice_pdf)}
                                            >
                                                <div className="px-4 py-4 flex items-center sm:px-6">
                                                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                                        <div className="truncate">
                                                            <div className="flex text-sm">
                                                                <p className="font-medium text-indigo-600 truncate">#{position.number}</p>
                                                                <p className="ml-1 flex-shrink-0 font-normal text-gray-500"> - {moment.unix(position.created).format("MM/DD/YYYY")}</p>
                                                            </div>
                                                            <div className="mt-2 flex">
                                                                <div className="flex items-center text-sm text-gray-500">
                                                                    <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    <p>
                                                                        {position.total / 100}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="ml-5 flex-shrink-0">
                                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                </>
                            )}
                            </div>
                        </div>
                    </div>

                    <DialogDefault
                        title={translate("text.update_payment")}
                        widthClass={"max-w-max"}
                        visible={this.state.updatePaymentModalOpen}
                        onClose={() => this.handleUpdatePayment()}
                        translate={translate}
                    >
                        <div className="m-auto pb-5 w-full min-w-96">
                            <div className="sm:flex sm:flex-col sm:align-center">
                                <p className="mt-1 mb-1 text-sm text-gray-500">
                                    Update credit card information
                                </p>
                            </div>

                            <div>
                                <div
                                    className={"text-md font-bold"}
                                >Current card</div>
                                <div className="items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                    <span>{card.brand?.toUpperCase()}</span> <span>Ends with: **** {card.last4}</span> <span>Expires {card.exp_month}/{card.exp_year}</span>
                                </div>
                            </div>
                            <div className="custom-number-input h-10 w-full flex flex-row">
                                <div className={"w-full mt-2"}>
                                    <CardField
                                        onChange={(event) => {
                                            this.setState({
                                                error: event.error,
                                                cardComplete: event.complete,
                                            });
                                        }}
                                    />
                                </div>

                                {this.state.error && <ErrorMessage>{this.state.error.message}</ErrorMessage>}
                            </div>

                            <div className="mt-10 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-primary-500 bg-primary-600 hover:bg-primary-700"}
                                    onClick={() => {this.updatePayment()}}
                                >
                                    Update
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => this.handleUpdatePayment()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </DialogDefault>

                    <DialogDefault
                        title={translate("text.modify_subscription")}
                        widthClass={"max-w-max"}
                        visible={this.state.updateModalOpen}
                        onClose={() => this.handleModifySubscription()}
                        translate={translate}
                    >
                        <div className="m-auto pb-5">
                            <div className="sm:flex sm:flex-col sm:align-center">
                                <p className="mt-1 mb-1 text-sm text-gray-500">
                                    149$ per seat per month. You can add or remove users at any time, we will prorate difference for upcoming billing cycle.
                                </p>
                            </div>

                            <div className="custom-number-input h-10 w-full flex flex-row">
                                <div
                                    className="flex flex-row h-10 w-9/12 rounded-lg relative bg-transparent mt-1">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                quantity:
                                                    (this.state.quantity <= userCount) ? userCount :
                                                    (this.state.quantity == 1) ? 1 : this.state.quantity - 1
                                            })
                                        }}
                                        className="bg-primary hover:bg-primary-700 text-inverse border border-transparent font-btn h-full w-20 rounded-l cursor-pointer outline-none">
                                        <span className="m-auto text-2xl font-thin">−</span>
                                    </button>
                                    <input type="number"
                                           className="outline-none focus:outline-none text-center w-full font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                                           name="custom-input-number" value={this.state.quantity}></input>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                quantity: this.state.quantity + 1
                                            })
                                        }}
                                        className="bg-primary hover:bg-primary-700 text-inverse border border-transparent font-btn h-full w-20 rounded-r cursor-pointer">
                                        <span className="m-auto text-2xl font-thin">+</span>
                                    </button>
                                </div>
                                <div className={"ml-2 mt-2"}>
                                    <span>Total</span> <span>{this.state.quantity * 149}$</span>
                                </div>
                            </div>

                            <p className="mt-3 text-sm text-gray-500">
                                <span>Minimum number of seats is {userCount}, if you want to lower your seat count please remove one of the users first.</span>
                                <Link to={"/users"}>Manage Users</Link>
                            </p>

                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className={"w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm focus:ring-primary-500 bg-primary-600 hover:bg-primary-700"}
                                    onClick={() => {this.handleModifySubscription(); this.modifySubscription(this.state.quantity)}}
                                >
                                    Modify
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => this.handleModifySubscription()}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </DialogDefault>

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to cancel subscription?"}
                        onClose={this.handleCancelSubscription}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: {},
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleCancelSubscription(false)
                        }}
                        visible={this.state.cancelModalOpen}
                    />

                    <ModalViewDocument
                        title={"View Invoice"}
                        className="max-w-2xl"
                        visible={this.state.viewModalOpen}
                        onClose={this.handleViewInvoice}
                        translate={this.props.translate}

                        fileType={"pdf"}
                        filePath={this.state?.invoicePath}
                        onDownload={() => {
                            axios({
                                url: this.state?.invoicePath,
                                method: 'GET',
                                responseType: 'blob',
                            }).then((response) => {
                                FileSaver.saveAs(new Blob([response.data]), "invoice.pdf");
                            }).catch((error) => {
                            });
                        }}
                    />
                </main>
            </LayoutDashboard>
        );
    }
}