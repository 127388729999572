import React, {Component} from "react";
import {connect} from "react-redux";
import Layout from "../../components/layout";
import {Field, FieldsManager} from "../../data/services/fields";
import FieldText from "../../components/field-text";
import FieldPassword from "../../components/field-password";
import Button from "../../components/button";
import {login, resetUserMessage} from "../../data/actions/user";
import {Link} from "react-router-dom";
import LoaderSmall from "../../components/loader-small";
import {setDocumentTitle} from "../../util/util";

import {EyeIcon, EyeOffIcon} from '@heroicons/react/outline'

class LoginView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: new Field('username', '', ['empty']),
                password: new Field('password', '', ['empty'])
            },
            page: "",
            isPasswordVisible: false
        };
    }

    componentDidMount() {
        this.props.dispatch(resetUserMessage());
    }

    componentDidUpdate = (prevProps, prevState) => {
        if ((prevProps.user.data === null) && (!!this.props.user.data)) {
            this.props.history.push("/");
        }

        if (this.state.page !== this.props.location.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleEyeClick = () => {
        this.setState({isPasswordVisible: !this.state.isPasswordVisible})
    }

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    submit = (event) => {
        event && event.preventDefault();

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6Ldy-L4dAAAAACJq8uE4XEPoOv3okBhST1JZHyCE', {action: 'validate_captcha'}).then((token) => {
                this.submitLogin(token);
            });
        });
    };

    submitLogin = (token) => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (!this.state.fields.password.errorMessage && !this.state.fields.username.errorMessage) {
                this.props.dispatch(login({
                    username: this.state.fields.username.value,
                    password: this.state.fields.password.value,
                    captcha_token: token
                }));
            }
        });
    }

    render() {
        const {translate} = this.props;

        return (
            <Layout logoHref={"https://goleir.com"} {...this.props}>
                <div className="px-6 pb-6 pt-12 sm:px-14 sm:pb-14 sm:pt-24">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg pt-8 px-8 pb-2 max-w-sm mx-auto">
                        <div className="mx-auto py-8 max-w-sm text-center text-90">
                            <img className="mx-auto w-40" src="/images/logos/logo.png" alt="GoLeir"/>
                        </div>

                        <form onKeyPress={this.submitForm}>
                            <form>
                                <div className="mb-6 ">
                                    <label className="block font-bold mb-2 text-secondary-900"
                                           htmlFor="email">Email</label>
                                    <FieldText
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.username}
                                        placeholder={"email@domain.com"}
                                        type={"email"}
                                    />
                                </div>

                                <div className="mb-6">
                                    <label className="block font-bold mb-2 text-secondary-900"
                                           htmlFor="email">Password</label>
                                    <div className="relative">
                                        {!this.state.isPasswordVisible && (
                                            <FieldPassword
                                                className="form-control"
                                                onChange={this.handleInputChange} {...this.state.fields.password}
                                                placeholder={"Enter your password"}
                                            />
                                        )}

                                        {!!this.state.isPasswordVisible && (
                                            <FieldText
                                                className="form-control"
                                                onChange={this.handleInputChange} {...this.state.fields.password}
                                                placeholder={"Enter your password"}
                                            />
                                        )}

                                        <Button
                                            type="link"
                                            onClick={() => this.handleEyeClick()}
                                            className="p-0 w-5 h-5 absolute rounded-full top-2.5 right-2.5 text-secondary-400 cursor-pointer"
                                        >
                                            {this.state.isPasswordVisible ? <EyeOffIcon/> : <EyeIcon/>}
                                        </Button>
                                    </div>
                                </div>
                                {!this.props.user.isLoading && (
                                    <React.Fragment>
                                        <div className="">
                                            <div className="">
                                                <Button
                                                    className="w-full justify-center capitalize hover:cursor-pointer"
                                                    type="primary"
                                                    onClick={this.submit}>
                                                    {translate("text.sign_in")}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="flex-1 mt-2 sm:mt-0 sm:flex items-center text-center sm:text-left justify-between">
                                            <div className="flex items-center">
                                                <input id="remember_me" name="remember_me" type="checkbox"
                                                       className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"/>
                                                <label htmlFor="remember_me"
                                                       className="ml-2 block text-sm text-gray-900">
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="text-sm">
                                                <Link className="btn btn-link px-0" to={"/reset-password"}>
                                                    Forgot Your Password?
                                                </Link>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {this.props.user.isLoading && (
                                    <div className="mt-3 flex rounded-btn bg-secondary-100 mb-6">
                                        <LoaderSmall text="Logging In"/>
                                    </div>
                                )}
                            </form>

                            <div className="relative mt-1 mb-1">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">Don't have an account?</span>
                                </div>
                            </div>

                            <div className="mb-4 mt-4 flex justify-center">
                                <div className="inline-flex rounded-md shadow">
                                    <Link
                                        to="/register"
                                        className="text-center sm:text-left inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
                                    >
                                        I'm recruiter
                                    </Link>
                                </div>
                                <div className="ml-3 inline-flex">
                                    <Link
                                        to="/register-candidate"
                                        className="text-center sm:text-left inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-primary-700 bg-primary-100 hover:bg-primary-200"
                                    >
                                        Looking for a job
                                    </Link>
                                </div>
                            </div>

                            {this.props.user.error && (
                                <div className="px-6 py-3 mt-6 flex rounded-btn bg-red-100 text-red-700">
                                    <svg className="text-red-700 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                              clipRule="evenodd"/>
                                    </svg>

                                    {translate(this.props.user.errorMessage)}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(state => state)(LoginView);
