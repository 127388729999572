import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {fieldsToHtml, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Loader from "../../components/loader";
import Button from "../../components/button";

export default class DesiredRolesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data." + this.getResourceName(), {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        CandidateDesiredRoleID: getProp(this.props, "resource.data." + this.getResourceName() + ".CandidateDesiredRoleID", 0),
                        id: this.getId()
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName()
            }));
        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data." + this.getResourceName(), null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.CandidateDesiredRole;
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            DesiredRoleID: new Field("DesiredRoleID", '', [], false, 'select'),

            LocationIDs: new Field("LocationIDs", '', [], false, 'multi-select'),
            AvailabilityDate: new Field("AvailabilityDate", '', [], false, 'date'),
            RelocationIssues: new Field("RelocationIssues", '', [], false, 'textarea'),
            DesiredSalary: new Field("DesiredSalary", '', ['float'], false, 'text'),
            DesiredBonus: new Field("DesiredBonus", '', ['float'], false, 'text'),
            DesiredLTI: new Field("DesiredLTI", '', [], false, 'text'),
            DesiredVacation: new Field("DesiredVacation", '', [], false, 'text'),
            DesiredBenefitID: new Field("DesiredBenefitID", '', [], false, 'select'),
            SalaryComments: new Field("SalaryComments", '', [], false, 'textarea'),
        };

        if (item) {
            item.LocationIDs = item?.LocationIDs.map((it) => {
                return {
                    value: it.StateID,
                    label: it.State
                }
            });
        }
        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const fieldsHtml1 = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(0, 4), translate, this.handleInputChange, {
            LocationIDs: LocalStorage.get('lookup', {}).State.reduce((memo, item) => {
                memo[item.StateID] = item.State;
                return memo;
            }, {}),
            DesiredRoleID: LocalStorage.get('lookup', {}).RoleTitle.reduce((memo, item) => {
                memo[item.RoleTitleID] = item.RoleTitle;
                return memo;
            }, {})
        });
        const fieldsHtml2 = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)).slice(4, 10), translate, this.handleInputChange, {
            DesiredBenefitID: LocalStorage.get('lookup', {}).BenefitType.reduce((memo, item) => {
                memo[item.BenefitTypeID] = item.BenefitType;
                return memo;
            }, {}),
        });

        return (
            <div className="">
                {isLoading && (
                    <div className="h-80">
                        <div className={"inset-center"}>
                            <Loader/>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <React.Fragment>

                        <form className="space-y-8 divide-y divide-gray-200">
                            <div className="flex -mx-6">
                                <div className="w-1/2 px-6">
                                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        {fieldsHtml1}
                                    </div>
                                </div>
                                <div className="w-1/2 px-6">
                                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        {fieldsHtml2}
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.onSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                            </div>
                        </div>

                    </React.Fragment>
                )}
            </div>
        )
    }
}