import LocalStorage from "./localStorage";
import React from "react";
import FieldDate from "../components/field-date";
import FieldText from "../components/field-text";
import FieldTextarea from "../components/field-textarea";
import FieldCheckbox from "../components/field-checkbox";
import FieldSelectSearch from "../components/field-select-search";
import moment from "moment-timezone";
import FieldDropdownSelect from "../components/field-dropdown-select";
import axios from "axios";
import Env from "./env";
import {processResponse} from "../data/services/api-util";
import {Link} from "react-router-dom";
import Dropzone from "react-dropzone";
import {Editor} from '@tinymce/tinymce-react';
import {SocialIcon} from "react-social-icons";
import MultiSelect from "../components/field-multi-select";
import {DocumentTextIcon} from "@heroicons/react/outline";
import Resources from "../data/services/resources";
import FieldCreatableSelect from "../components/field-creatable-async";
import ContactPhoto from "../components/contact-photo";

export const READ_PERM = 1;
export const CREATE_PERM = 2;
export const UPDATE_PERM = 4;
export const DELETE_PERM = 8;

export const DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS = "image/jpg,image/jpeg, image/png";

export const DEFAULT_CRUD_STATE = {
    offset: 0,
    limit: 10,
    sort: "ASC",
    sortBy: "",
    query: "",
    paginationPage: 1,
    archived: false
};

export const filterColors = ["transparent", "bg-blue-600", "bg-red-600", "bg-yellow-400", "bg-green-600", "bg-violet-600", "bg-lime-600", "bg-pink-600", "bg-orange-600", "bg-indigo-600"];

export const documentMimes = "image/jpg,image/jpeg, image/png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/xml, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function numberWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 2, ".", ",");
    }

    return n;
}

export function integerWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 0, "", ",");
    }

    return "";
}

export function numberWithCommasToBack(n) {
    if (n) {
        let parts = n.toString().split(".");
        return Number(parts[0].replace(/,/g, "") + (parts[1] ? "." + parts[1] : (n.toString().includes(".") ? "." : "")));
    }

    return n;
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function getLookup(name, key, value) {
    const lookup = LocalStorage.get('lookup', {});
    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getLookupWithFilter(name, key, value, filter) {
    const lookup = LocalStorage.get('lookup', {});
    return lookup[name] && lookup[name].filter((item) => filter(item)).reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getLookupValue(name, key, value, type) {
    return LocalStorage.get('lookup')[name].reduce(
        (memo, item) => {
            if (item[key] == value) {
                return item[type];
            }
            return memo;
        }, "")
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "No Data";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") : "No Data");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "No Data";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD") : "No Data");
}

export function toFrontDate(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("MM/DD/YYYY") : "No Data");
}

export function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("MM/DD/YYYY h:mm a") : "No Data");
}

export function toFrontDateTimeFromUTC(utcDate) {
    return (!!utcDate ? moment.utc(utcDate, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get('user').Contact.Timezone).format(LocalStorage.get('user').Contact.DateTimeFormat) : "");
}

export function toFrontDateFromUTC(utcDate) {
    const userDateTimeFormat = LocalStorage.get('user').Contact.DateTimeFormat;
    const userDateFormat = !!userDateTimeFormat ? userDateTimeFormat.split(" ")[0] : "YYYY-MM-DD";

    return (!!utcDate ? moment.utc(utcDate, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get('user').Contact.Timezone).format(userDateFormat) : "");
}

export function toBackDateFromUTC(localDate) {
    const userDateTimeFormat = LocalStorage.get("user")?.Contact?.Timezone ?? "UTC";

    return moment.tz(localDate, userDateTimeFormat).utc().format("YYYY-MM-DD HH:mm:ss");
}

export function fieldsToCells(fields, item, classNames = "table-cell") {

    return Object.keys(fields).filter(
        it => !fields[it]?.metadata?.hideTable
    ).map((key, i) => {
        let onCellClick = fields[key]?.metadata?.onCellClick;

        switch (key) {
            case 'ClientName':
                return (
                    <td className={classNames} key={i}><Link className="text-current underline border-b-black hover:border-b-2 " to={"/client/" + item.ClientID}>{item[key]}</Link></td>
                );
            case 'CandidateID':
                return (
                    <td className={classNames} key={i}>
                        {checkPerm(Resources.Candidate, READ_PERM) && (
                            <Link className="text-current underline border-b-black hover:border-b-2 " to={"/contact/" + item.CandidateID}>{!!item['FirstName'] ? (item['FirstName'] + " " + item['LastName']) : ""}</Link>
                        )}
                        {!checkPerm(Resources.Candidate, READ_PERM) && (
                            !!item['FirstName'] ? (item['FirstName'] + " " + item['LastName']) : ""
                        )}
                    </td>
                );
            case 'ContactName':
                return (
                    <td className={classNames} key={i}>
                        {checkPerm(Resources.Candidate, READ_PERM) && (
                            <Link className="text-current underline border-b-black hover:border-b-2 " to={"/contact/" + item.CandidateID}>{item[key]}</Link>
                        )}
                        {!checkPerm(Resources.Candidate, READ_PERM) && (
                            <span>{item[key]}</span>
                        )}
                    </td>
                );
            case 'ContactID':
                return (
                    <td className={classNames}
                        key={i}>{item['Contact'] ?? ((item['FirstName'] ? (item['FirstName'] + " ") : "") + " " + (item['LastName'] ? item['LastName'] : ""))}</td>
                );

            case 'ClientContactID':
                return (
                    <td className={classNames} key={i}>{item.ClientContactName}</td>
                );
            case 'ContactIDs':
                return (
                    <td className={classNames} key={i}>{item[key]?.map((it) => {
                        return it.FirstName + " " + it.LastName
                    }).join(", ")}</td>
                );
            default:
        }
        switch (fields[key].type) {
            case 'join':
                let joinValue = "";
                if (fields[key].name === "LocationJoin") {
                    joinValue = (item['City'] ? (item['City'] + (item['State'] ? ", " : "")) : "") + (item['State'] ? item['State'] : "");
                } else if (fields[key].name === "Recruiter") {
                    joinValue = item['FirstName'] + " " + item['LastName'];
                }
                return (
                    <td className={classNames} key={i}>{joinValue}</td>
                );
            case 'hidden':
                return null;
            case 'select':
                let value = "";
                if (fields[key].name === "UpdatedByContactID") {
                    value = item['FirstName'] + " " + item['LastName'];
                } else {
                    value = item[key.replace("ID", "")];
                }
                return (
                    <td
                        className={classNames}
                        key={i}

                    >
                        <span
                            onClick={!!onCellClick ? () => onCellClick(item) : null}
                            className={!!onCellClick ? " text-current cursor-pointer underline border-b-black hover:border-b-2" : ""}>
                        {value}
                        </span>
                    </td>
                );
            case 'date':
                return (
                    <td className={classNames} key={i}>{toFrontDate(item[key])}</td>
                );
            case 'datetime':
                return (
                    <td className={classNames} key={i}>{toFrontDateTime(item[key])}</td>
                );
            case 'datetimezone':
                value = toFrontDateTimeFromUTC(item[key]);
                break;
            case 'checkbox':
                return (
                    <td className={classNames} key={i}>
                        <span
                            className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full" + (item[key] ? " bg-primary-100 text-primary-800" : " bg-red-100 text-red-800")}>
                            {!!item[key] ? "Yes" : "No"}
                        </span>
                    </td>
                );
            case 'select-search':
            case 'select-search-create':
                if (key === "CandidateID") {
                    return (
                        <td className={classNames} key={i}>{item['FirstName']} {item['LastName']}</td>
                    );
                }

                return (
                    <td className={classNames} key={i}>{item[key.replace("ID", "")]}</td>
                );
            case 'textarea':
                return (
                    // temp delete " table-td-textarea"
                    <td className={classNames} key={i} title={item[key]}>
                        {item[key]}
                    </td>
                );
            case 'image':
                return (
                    <td className={classNames} key={i}>
                        <img className="h-12 w-12 rounded-full border border-primary-600 object-cover" src={item[key]}
                             onError={(event) => event.target.style.display = 'none'}
                             alt=""/>
                    </td>
                );
            case 'gauge':
                return (
                    <td className={"md:w-px " + classNames} key={i}>
                        <img className="inline-block h-12 w-22 object-cover" src={"/images/gauge/gauge-" + item[key] + ".svg"}
                             onError={(event) => event.target.style.display = 'none'}
                             alt=""/>
                    </td>
                );
            case 'custom':
                return fields[key]?.metadata?.render(item);
            default:
                if (key === 'LinkedinProfileURL') {
                    return (
                        <td className={classNames + " text-center"} key={i}>
                            {!!item[key] && (
                                <SocialIcon
                                    url={item[key]}
                                    target={"_blank"}
                                    className="social-icon-w-30"
                                />
                            )}
                        </td>
                    );
                }

                switch (fields[key].validate[0]) {
                    case 'float':
                    case 'float_or_empty':
                        return (
                            <td className={classNames} key={i}>{numberWithCommas(item[key])}</td>
                        )
                    case 'integer':
                    case 'integer_or_empty':
                    case 'integer_positive':
                        return (
                            <td className={classNames} key={i}>{integerWithCommas(item[key])}</td>
                        )
                    default:
                        break;
                }

                return (
                    <td
                        className={classNames + (!!onCellClick ? " cursor-pointer text-primary-600" : "")}

                        key={i}
                        onClick={!!onCellClick ? () => onCellClick(item) : null}
                    >
                        {item[key]}
                    </td>
                );
        }
    })
}

export function fieldsToEmptyCells(fields) {
    const classNames = "px-4 py-2 whitespace-nowrap text-sm text-secondary-500";
    return Object.keys(fields).map((key, i) => {
        return (
            <td className={classNames} key={i}>
            </td>
        )
    })
}

export function fieldsSearchToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy.reduce((memo, item) => {
        let field;
        if (item.type === 'custom') {
            field = selects[item.name];
        } else if (item.type === 'select-search') {
            field = (
                <FieldDropdownSelect
                    onChange={handleInputChange}
                    {...item}
                    className="w-64 inline-block mr-6 mb-3"
                    placeholder={translate("filter." + item.name)}
                    defaultOptions={true}
                    isClearable={item.props.isClearable ?? true}
                    loadOptions={
                        (inputValue, callback) => {
                            axios.get(
                                Env.getApiUrl(selects[item.name].api, Object.assign(!!selects[item.name].query ? selects[item.name].query : {}, {query: inputValue})),
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                    }
                                }
                            )
                                .then((response) => {
                                    const result = processResponse(response);
                                    if (result && result.status === 0) {
                                        const list = result.data.list.map((it) => {
                                            return selects[item.name].searchMap(it);
                                        });
                                        callback(list);
                                    }
                                })
                                .catch((error) => {
                                });
                        }
                    }
                />
            );
        } else if (item.type === "select") {
            let values;
            if (!!selects[item.name]) {
                values = selects[item.name];
            } else {
                if (item.name === "TitleID") {
                    values = getLookup("RoleTitle", "RoleTitleID", "RoleTitle");
                } else {
                    values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
                }
            }

            field = (
                <FieldSelectSearch
                    placeholder={translate("filter." + item.name)}
                    className="w-64 inline-block mr-6 mb-3"
                    values={values}
                    all={true}
                    onChange={handleInputChange}
                    {...item}
                />
            );
        }

        memo.push(field);

        return memo;
    }, []);
}

export function fieldsToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy
        .filter(
            it => !it?.metadata?.hideDialog && (!selects[it.name]?.visibleFilter || !selects[it.name].visibleFilter(fieldsCpy))
        )
        .reduce((memo, item, i) => {
            const req = (item.validate.includes("empty") || item.validate.includes("integer") || item.validate.includes("integer_positive") || item.validate.includes("float"));
            let formGroupClass = "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5";

            if (!!item?.metadata?.formGroupClass) {
                formGroupClass = item.metadata.formGroupClass;
            }

            let field;
            if (item.type === 'custom') {

                field = selects[item.name](fieldsCpy, handleInputChange);
            } else if (item.type === 'select-search-create') {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <FieldCreatableSelect
                            onChange={handleInputChange}
                            {...item}
                            className="form-react-select h-9 w-56"
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(selects[item.name].api, Object.assign(!
                                                !selects[item.name].query ? (
                                                (typeof selects[item.name].query === "function") ? selects[item.name].query(fieldsCpy) : selects[item.name].query)
                                            : {},
                                            {query: inputValue})
                                        ),
                                        {
                                            headers: !!LocalStorage.get('user') ? {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            } : {}
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return selects[item.name].searchMap(it);
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }
                            }
                            {...item.props}
                            setKey={item.name}
                        />
                    </div>
                );
            } else if (item.type === 'select-search') {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <FieldDropdownSelect
                            onChange={handleInputChange}
                            {...item}
                            className="form-react-select min-h-9 w-56"
                            defaultOptions={true}
                            isClearable={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(selects[item.name].api, Object.assign(!
                                                !selects[item.name].query ? (
                                                (typeof selects[item.name].query === "function") ? selects[item.name].query(fieldsCpy) : selects[item.name].query)
                                            : {},
                                            {query: inputValue})
                                        ),
                                        {
                                            headers: !!LocalStorage.get('user') ? {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            } : {}
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return selects[item.name].searchMap(it);
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }
                            }
                            setKey={item.name}
                            {...item.props}
                        />
                    </div>
                );
            } else if (item.type === 'hidden') {
                field = null;
            } else if (item.type === 'tiny') {
                field =
                    (
                        <div
                            className={formGroupClass}>
                            <label htmlFor={item.key}
                                   className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                {translate("field." + item.name)}{req ? "*" : ""}
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                {drawTinyField(item, handleInputChange, translate)}
                            </div>
                        </div>
                    );
            } else if (item.type === 'readonly') {
                field = (
                    <div key={item.name} className={formGroupClass}>
                        <dt className="font-medium text-gray-500">{translate("field." + item.name)}</dt>
                        <dd className="mt-1 text-gray-900">{item.value}</dd>
                    </div>
                );
            }else if (item.type === "textarea") {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldTextarea
                                className="form-control h-28"
                                onChange={handleInputChange}
                                addClass={"form-control"}
                                placeholder={item?.metadata?.placeHolder}
                                {...item}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === "checkbox") {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="">
                            <FieldCheckbox onChange={handleInputChange} {...item} />
                        </div>
                    </div>
                );
            } else if (item.type === "select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                } else {
                    if (item.name === "TitleID") {
                        values = getLookup("RoleTitle", "RoleTitleID", "RoleTitle");
                    } else {
                        values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
                    }
                }

                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldSelectSearch
                                className="form-react-select h-9"
                                values={values}
                                onChange={handleInputChange}
                                {...item}
                                {...item.props}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === "datetime" || item.type === "datetimezone") {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldDate
                                className={"h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"}
                                onChange={handleInputChange}
                                showTimeSelect={true}
                                {...item}
                                {...item.props}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === "date") {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldDate
                                className={"h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"}
                                onChange={handleInputChange}
                                showTimeSelect={false}
                                {...item}
                                dateFormat={"MM/dd/yyyy"}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === 'avatar') {
                field = (
                    <ContactPhoto
                        addClass="col-span-2"
                        imageID={item?.value}
                        onGetImage={(value) => {
                            handleInputChange(item.name, value)
                        }}
                        translate={translate}
                    />
                )
            } else if (item.type === 'image') {
                let images = item?.value;
                const thumbs = (!!images && Array.isArray(images)) ? images.map(file => (
                    <div
                        key={i}
                        className={formGroupClass}>
                        <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <img
                                alt={"File Preview"}
                                src={file.preview}
                            />
                        </div>
                    </div>
                )) : null;
                field = (
                    <div className={formGroupClass}>
                        <label className="block text-sm font-medium text-gray-700">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                handleInputChange(item.name, acceptedFiles.map(file => Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                })))
                            }}
                            multiple={false}
                            accept={'image/jpeg, image/png'}
                            onDragEnter={() => {

                            }}
                            onDragLeave={() => {

                            }}
                            onDropAccepted={() => {

                            }}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div className="mt-1 sm:mt-0 sm:col-span-2" {...getRootProps()}>
                                    <div
                                        className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input {...getInputProps()} />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">{"PNG or JPG up to 10MB"}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>

                        {thumbs}
                    </div>
                )
            } else if (item.type === 'document') {
                let documents = item?.value;
                const thumbs = (!!documents && Array.isArray(documents)) ? documents.map(file => (
                    <div
                        key={i}
                        className={formGroupClass}
                    >
                        <div className="flex text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            <DocumentTextIcon className="w-5 h-5 text-gray-600 mr-2" /> {file.name}
                        </div>
                    </div>
                )) : null;
                field = (
                    <div className={formGroupClass}>
                        <label className="block text-sm font-medium text-gray-700">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                handleInputChange(item.name, acceptedFiles)
                            }}
                            multiple={false}
                            accept={documentMimes}
                            onDragEnter={() => {

                            }}
                            onDragLeave={() => {

                            }}
                            onDropAccepted={() => {

                            }}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div className="mt-1 sm:mt-0 sm:col-span-2" {...getRootProps()}>
                                    <div
                                        className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                            </svg>

                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input {...getInputProps()} />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">{"Document file up to 10MB"}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>

                        {!!thumbs && (
                            <React.Fragment>
                                <label className="block text-sm font-medium text-gray-700 mt-6">
                                    Documents to upload:
                                </label>

                                {thumbs}
                            </React.Fragment>
                        )}
                    </div>
                )
            } else if (item.type === 'photo') {
                field = (
                    <div
                        key={translate("field." + item.name)}
                        className={formGroupClass}
                    >
                        <label className="block text-sm font-medium text-gray-700">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <label  htmlFor={item.name} className="mt-1 flex items-center w-40 cursor-pointer">
                            <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12 object-cover">
                                {!item?.value?.preview && (
                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                        ></path>
                                    </svg>
                                )}

                                {!!item?.value?.preview && (
                                    <img className="" src={item.value.preview} alt="" />
                                )}
                            </span>

                            <span
                                className="ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                Change
                            </span>

                            <input
                                id={item.name}
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => handleInputChange(item.name, Object.assign(e.target.files[0], {preview: URL.createObjectURL(e.target.files[0])}))}
                                className="hidden"
                            />
                        </label>
                        <p className="mt-2 text-sm text-gray-500">Upload square image that is at least 50x50px.</p>
                    </div>
                )
            } else if (item.type === 'multi-select') {
                let values;
                if (getProp(selects, "filters." + [item.name], false)) {
                    values = selects.filters[item.name](fieldsCpy);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
                }

                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <MultiSelect
                                className="form-control"
                                values={values}
                                onChange={handleInputChange}
                                {...item}
                            />
                        </div>
                    </div>
                );
            }  else if (item.type === 'url') {
                field = (
                <div className={formGroupClass}>
                    <label htmlFor={item.key}
                           className="block text-sm font-medium text-gray-700">
                        {translate("field." + item.name)}{req ? "*" : ""}
                    </label>

                    <div className="mt-1 rounded-md shadow-sm flex">
                        {!!item?.metadata?.urlPrefix && (
                            <span
                                className="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                                {item.metadata.urlPrefix}
                            </span>
                        )}

                        <FieldText
                            id={item.key}
                            className={"form-control rounded-tl-none rounded-bl-none shadow-none"}
                            onChange={handleInputChange}
                            {...item}
                        />
                    </div>
                </div>
                );
            } else {
                field = (
                    <div
                        className={formGroupClass}>
                        <label htmlFor={"text-" + item.key}
                               className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldText
                                id={"text-" + item.key}
                                className={"form-control"}
                                onChange={handleInputChange}
                                translate={translate}
                                {...item}
                            />
                            {!!item?.metadata?.fieldNote && (
                                <p className="mt-2 text-sm text-gray-500">{item.metadata.fieldNote}</p>
                            )}
                        </div>
                    </div>
                );
            }

            memo.push(field);

            return memo;
        }, []);
}

export function drawTinyField(item, handleInputChange) {
    /*
    file_picker_types: 'image',
    file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');



        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {

                var id = 'blobid' + (new Date()).getTime();
                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), {title: file.name});
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }
     */

    return (
        <Editor
            apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
            value={item.value}
            disabled={item.disabled}
            init={{
                height: 500,
                content_style: "",
                plugins: [
                    'advlist autolink lists link media image charmap print preview anchor image',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                    'undo redo | image | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
            }}
            onEditorChange={(content, editor) => {
                handleInputChange(item.name, content);
            }}
        />
    )
}

export function fillFieldsFromData(fieldTemplates, data) {
    const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];

    if (!data) {
        return fieldTemplates;
    }

    return Object.values(fieldTemplates).reduce((memo, item) => {
        if (item.validate.filter(value => formats.includes(value)).length > 0) {
            const val = data[item.name];
            if (!!val && (val.length > 0) && val.includes(",")) {
                item.value = val;
            } else {
                if (item.validate[0] == 'integer' || item.validate[0] == 'integer_or_empty') {
                    item.value = integerWithCommas(val);
                } else {
                    item.value = numberWithCommas(val);
                }
            }
        } else if ((item.type === 'select-search') || (item.type === 'select-search-create')) {
            if (!!item?.props?.isMulti) {
                item.value = data[item.name];
            } else {
                item.value = !!data[item.name]
                    ? {
                        value: data[item.name],
                        label: (data[item.name.replace("ID", "")])
                    }
                    : null // empty value should be null
            }
        } else if (item.type === 'datetimezone') {
            item.value = moment.utc(data[item.name], 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get('user').Contact.Timezone).format("YYYY-MM-DD HH:mm:ss");
        } else if (item.type === 'textarea') {
            item.value = data[item.name] ? data[item.name] : ""; // return null was causing a bug. Null value was still displaying old value after cancel action
        } else {
            item.value = data[item.name];
        }
        memo[item.name] = item;
        return memo;
    }, {});
}

export function getUserLabel() {
    return getProp(LocalStorage.get('user'), 'Contact.FirstName') + ' ' + getProp(LocalStorage.get('user'), 'Contact.LastName');
}

export function getUserTimeFormat() {
    return LocalStorage.get('user').Contact.DateTimeFormat.split(" ")[1]
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function setDocumentTitle(pathname, translate) {
    if (pathname) {
        let pageTitle;
        const path = pathname.split("/");
        if (path[2] === 'info') {
            pageTitle = translate("page.title." + path[1] + ".info") + (path[3] ? " - " + path[3] : "");
        } else {
            pageTitle = translate("page.title." + path[1]);
        }

        if (document.title !== pageTitle) {
            document.title = pageTitle + " | GoLeir";
        }
    }
}

export const scrollErrorIntoView = (fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (value.errorMessage) {
            const firstErrorInput = document.querySelector('input[name=' + key + ']');

            if (firstErrorInput) {
                firstErrorInput.parentElement.scrollIntoView({block: "center", behavior: "smooth"});
                return true;
            }
        }
    }

    return false;
}

export function checkPasswordStrength(password) {

    // Regular Expressions.
    let regex = [];
    regex.push("[A-Z]"); // Uppercase Alphabet.
    regex.push("[a-z]"); // Lowercase Alphabet.
    regex.push("[0-9]"); // Digit.
    regex.push("[!@#$%^&*()\\-_=+{};:,<.>?~\"|']"); // Special Character.

    let passed = 0;

    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }

    // Validate for length of Password.
    if (passed > 2 && password.length >= 8) {
        passed++;
    } else {
        passed--;
    }

    // Display status.
    let color = "";
    let strength = "";
    switch (passed) {
        case 0:
        case 1:
            strength = "Very weak";
            color = "red";
            break;
        case 2:
            strength = "Weak";
            color = "darkorange";
            break;
        case 3:
            strength = "Average";
            color = "darkorange";
            break;
        case 4:
            strength = "Strong";
            color = "green";
            break;
        case 5:
            strength = "Very Strong";
            color = "darkgreen";
            break;
    }

    return {
        passed: passed,
        strength: strength,
        color: color
    }
}

export const isNotificationVisible = (ui) => {
    return Array.isArray(ui?.messages) && !!ui.messages.length
}

export const getTinyModalElement = () => {
    return document.querySelector(".tox-dialog");
}



export function focusOnElement(firstElementIndex = 0, elementSelector = ".js-modal-ref") {
    // add all the elements inside modal which you want to make focusable

    const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector(elementSelector); // select the modal by it's id


    if (modal) {
        const firstFocusableElement = modal?.querySelectorAll(focusableElements)[firstElementIndex]; // get first element to be focused inside modal
        const focusableContent = modal.querySelectorAll(focusableElements);

        const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        document.addEventListener('keydown', function (e) {
            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus(); // add focus for the last focusable element
                    e.preventDefault();
                }
            } else { // if tab key is pressed
                if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                    firstFocusableElement.focus(); // add focus for the first focusable element
                    e.preventDefault();
                }
            }
        });
        firstFocusableElement && firstFocusableElement.focus();
    }
}

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function getButtonType(btnType, i) {
    if (btnType) {
        switch (btnType) {
            case "primary":
                return "btn-primary";
            case "secondary":
                return "btn-outline";
            default:
                return "btn-text"
        }
    }

    if (i === 0) {
        return "btn-primary";
    } else if (i === 1) {
        return "btn-outline";
    } else {
        return "btn-text"
    }
}

export function cloneDeep(entity, cache = new WeakMap()) {
    const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'WeakMap', 'WeakSet'];
    const entityType = Object.prototype.toString.call(entity);
    if (!new RegExp(referenceTypes.join('|')).test(entityType)) return entity;
    if (cache.has(entity)) {
        return cache.get(entity);
    }
    const c = new entity.constructor();

    if (entity instanceof Map || entity instanceof WeakMap) {
        entity.forEach((value, key) => c.set(cloneDeep(key), cloneDeep(value)));
    }
    if (entity instanceof Set || entity instanceof WeakSet) {
        entity.forEach((value) => c.add(cloneDeep(value)));
    }
    cache.set(entity, c);
    return Object.assign(c, ...Object.keys(entity).map((prop) => ({[prop]: cloneDeep(entity[prop], cache)})));
}