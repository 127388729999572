import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {createResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    checkPerm, classNames,
    CREATE_PERM,
    DELETE_PERM,
    fillFieldsFromData,
    getProp,
    getUserTimeFormat,
    isNotificationVisible,
    toBackDateFromUTC,
    toBackDateTime,
    UPDATE_PERM
} from "../../util/util";
import {CalendarIcon, CheckIcon, PlusIcon, VideoCameraIcon} from '@heroicons/react/solid'
import CalendarNew from "../../components/calendar";
import RoleModal from "../client-single-page/roles";
import moment from "moment";
import {Field, FieldsManager} from "../../data/services/fields";
import ModalSaveResource from "../../components/modal/modal-save-resource-headlessui";
import AddToCalendar from 'react-add-to-calendar';
import Loader from "../../components/loader";
import SimpleTable from "../../components/simple-table";
import DatePicker from "react-datepicker";
import Button from "../../components/button";
import {EyeIcon, PencilIcon} from "@heroicons/react/outline";
import DialogDefault from "../../components/dialog-default";
import DailyEvents from "./daily-events";
import CalendarOutlineIcon from "@heroicons/react/outline/CalendarIcon";
import CreateEventForm from "./create-event-form";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import PopOver from "../../components/popover";

class HomeNewView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            sort: "DESC",
            sortBy: "Date",
            paginationPage: 1,
            query: "",

            currMonth: moment().month(),
            currYear: moment().year(),
            activeDay: moment().format("YYYY-MM-DD"),

            fieldsSearch: {
                StartDate: new Field("StartDate", '', [], false, 'datetime'),
                EndDate: new Field("EndDate", '', [], false, 'datetime'),
            },

            selectedItem: null,
            selectedEvents: null,
            eventsTitle: "",
            selectedDay: moment().startOf("day").format("YYYY-MM-DD"),
            viewModalOpen: false,
            createModalOpen: false,
            editModalOpen: false,

            createModalTaskOpen: false,
            editModalTaskOpen: false,
            ContactIDs: [],

            isCalendarView: true,
            remountFields: 0,
            roleID: 0,

            isEventsDialogOpen: false,
            isCreateDialogOpen: false,
        };

    }


    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: Resources.Dashboard
        }))
    };

    fetchCalendarData = (year, month) => {
        this.setState({
            currMonth: month,
            currYear: year,
        }, this.fetchData)
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (fields, name, value) => {
        // Remount fields, by updating element key, to update search-select dropdown
        if ("RoleID" === name) {
            this.setState({
                remountFields: this.state.remountFields + 1,
                roleID: value.value
            })
            fields = FieldsManager.updateField(fields, "CandidateID", "");
            fields = FieldsManager.updateField(fields, "ContactIDs", "");

            fields.CandidateID.disabled = false;
            fields.ContactIDs.disabled = false;
        }
        return FieldsManager.updateField(fields, name, value);
    }

    handleNextMonth = () => {
        // Zero based month counting, 11 is December
        this.setState({
            currMonth: (this.state.currMonth + 1 > 11) ? 0 : this.state.currMonth + 1,
            currYear: (this.state.currMonth + 1 > 11) ? (this.state.currYear + 1) : this.state.currYear
        }, this.fetchData)
    }

    handlePrevMonth = () => {
        this.setState({
            currMonth: (this.state.currMonth - 1 < 0) ? 11 : this.state.currMonth - 1,
            currYear: (this.state.currMonth - 1 < 0) ? (this.state.currYear - 1) : this.state.currYear
        }, this.fetchData)
    }

    handleCalendarEventTap = (it) => {
        const date = it.date;

        if (date.date !== this.state.activeDay) {
            this.setState({
                activeDay: date
            })
        }

        if (!!it.metadata.RoleCandidateInterviewID) {
            this.props.history.push("room/" + it.metadata.RoleCandidateInterviewID + "/" + it.metadata.AutoToken);
        } else if (!!it.metadata.Task) {
            this.handleToggleEditTaskModal(it.metadata.Task)
        } else if (!!it.metadata.Meeting) {
            this.handleToggleEditModal(it.metadata.Meeting);
        } else {
            this.handleToggleView(it.metadata);
        }
    }

    handleToggleView = (item = null) => {
        if (item && !!item.Type) {
            item.RoleID = item.ID;
        }

        if (this.state.viewModalOpen) {
            this.fetchData(); // always fetch on close
        }

        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen,
            isEventsDialogOpen: false
        })
    }

    handleToggleCreateModal = (date = "") => {
        if (!date || !!date.target || date.split(" ").length === 1) {
            date = moment(date ?? this.state.activeDay, "YYYY-MM-DD").startOf("day").add(12, "hours").format("YYYY-MM-DD HH:mm:ss");
        }

        this.setState({
            isCreateDialogOpen: !this.state.isCreateDialogOpen,
            selectedDay: date,
        })
    }

    handleToggleEditModal = (item = this.state.selectedItem) => {
        if (item && !!item.Type) {
            item.MeetingID = item.ID;
        }
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            isEventsDialogOpen: false
        })
    }

    handleToggleCreateTaskModal = () => {
        this.setState({
            createModalTaskOpen: !this.state.createModalTaskOpen
        })
    }

    handleToggleEditTaskModal = (item = this.state.selectedItem) => {
        if (item && !!item.Type) {
            item.TaskDate = item.Date;
            item.TaskTitle = item.Title;
            item.TaskID = item.ID;
        }

        this.setState({
            selectedItem: item,
            editModalTaskOpen: !this.state.editModalTaskOpen,
            isEventsDialogOpen: false,
        })
    }

    handleToggleCalendarView = () => {
        this.setState({
            isCalendarView: !this.state.isCalendarView
        }, this.fetchData)
    }

    handleListDateSearch = (name, value) => {
        this.setState({fieldsSearch: FieldsManager.updateField(this.state.fieldsSearch, name, value)}, this.fetchData);
    }

    handleListDateSearchClear = () => {
        let fieldsSearch = this.state.fieldsSearch;
        Object.values(fieldsSearch).map(key => {
            key.value = '';
        });

        this.setState({
            fieldsSearch: fieldsSearch
        }, this.fetchData);
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            open: 1,
            month: this.state.currMonth,
            year: this.state.currYear,
            isCalendar: this.state.isCalendarView ? 1 : 0,

            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            StartDate: this.state.isCalendarView ? moment(this.state.currMonth, "M").subtract(1, "month").format("YYYY-MM-DD") : this.state.fieldsSearch.StartDate.value,
            EndDate: this.state.isCalendarView ? moment(this.state.currMonth, "M").add(1, "month").endOf("month").format("YYYY-MM-DD") : this.state.fieldsSearch.EndDate.value,
        };
    };

    getFields = (item = null) => {
        if (item) {
            item.Candidate = item.Name
        }

        const fieldTemplates = {
            RoleID: new Field("RoleID", item ? item.RoleName : "", ['empty'], false, 'select-search', {}, {isClearable: false}),
            CandidateID: new Field("CandidateID", item ? item.Name : "", ['empty'], !item?.RoleID, 'select-search'),
            ContactIDs: new Field("ContactIDs", "", [], !item?.RoleID, 'select-search', {}, {isMulti: true}),
            StartDate: new Field("StartDate", '', ['empty'], false, 'datetimezone'),
            EndDate: new Field("EndDate", '', ['empty'], false, 'datetimezone', {}, {
                minDate: this.getStartDateUTC(this.state.selectedDay),
                minTime: moment(this.state.selectedDay, "YYYY-MM-DD HH:mm:ss").add(15, "minutes").format("YYYY-MM-DD HH:mm:ss")
            }),

            Description: new Field("Description", '', [], false, 'textarea')
        };

        let itemClone = null;
        if (item) {
            itemClone = JSON.parse(JSON.stringify(item))
            itemClone.ContactIDs = itemClone?.ContactIDs?.map((it) => {
                return {
                    value: it.ClientContactID,
                    label: it.FirstName + " " + it.LastName
                }
            });
        }


        return fillFieldsFromData(fieldTemplates, itemClone);
    };

    getTaskFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            TaskTitle: new Field("TaskTitle", '', ['empty'], false, 'text'),
            TaskTypeID: new Field("TaskTypeID", 1, ['empty'], false, 'hidden'),
            TaskStatusID: new Field("TaskStatusID", '', ['empty'], false, 'select'),
            RoleID: new Field("RoleID", '', [''], false, 'select-search'),
            CandidateID: new Field("CandidateID", '', [], false, 'select-search'),
            TaskDate: new Field("TaskDate", '', [], false, 'datetimezone'),

            Description: new Field("Description", '', [], false, 'textarea')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleTabChange = (name) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.name === name;
                return it;
            })
        })
    };

    getRoleParams = () => {
        const role = !!this.state.roleID ? this.state.roleID : this.state.selectedItem?.RoleID;

        if (!!role) {
            return {RoleID: role};
        }

        return {};
    }

    getEventColor = (time, duration, item) => {
        const type = this.getEventType(item);

        const hasStarted = moment().isAfter(time);
        const hasEnded = moment().isAfter(time.add(duration, "minutes"));
        const isInProgress = hasStarted && !hasEnded;
        const isNextDayOrAfter = moment().isSameOrAfter(time, "day");
        const isSameDay = moment().isSame(time, "day");
        //const isPending = !hasStarted && !hasEnded;

        switch (type) {
            case "task":
            case "interview":
                if (item.TaskStatusID === 2) {
                    return "green"
                } else if (isNextDayOrAfter) {
                    return "red";
                } else if (hasStarted) {
                    return "cyan";
                } else {
                    return "gray";

                }
            case "meeting":
            case "video":
                if (isInProgress) {
                    return "cyan"
                } else if (hasEnded) {
                    return "green"
                } else {
                    return "gray"
                }
            case "milestone":
                if (isSameDay) {
                    return "cyan"
                } else if (hasEnded) {
                    return "green"
                } else {
                    return "gray"
                }
            default:
                return "cyan"
        }
    }

    getEventColorClass = (color) => {
        switch (color) {
            case "green":
                return {
                    bg50: "bg-green-50",
                    hoverBg100: "hover:bg-green-100",
                    text500: "text-green-500",
                    text700: "text-green-700",
                    groupHoverText700: "group-hover:text-green-700",
                    bg400: "bg-green-400"
                };
            case "red":
                return {
                    bg50: "bg-red-50",
                    hoverBg100: "hover:bg-red-100",
                    text500: "text-red-500",
                    text700: "text-red-700",
                    groupHoverText700: "group-hover:text-red-700",
                    bg400: "bg-red-400"
                };
            case "indigo":
                return {
                    bg50: "bg-indigo-50",
                    hoverBg100: "hover:bg-indigo-100",
                    text500: "text-indigo-500",
                    text700: "text-indigo-700",
                    groupHoverText700: "group-hover:text-indigo-700",
                    bg400: "bg-indigo-400"
                };
            case "cyan":
                return {
                    bg50: "bg-cyan-50",
                    hoverBg100: "hover:bg-cyan-100",
                    text500: "text-cyan-500",
                    text700: "text-cyan-700",
                    groupHoverText700: "group-hover:text-cyan-700",
                    bg400: "bg-cyan-400"
                };
            case "purple":
                return {
                    bg50: "bg-purple-50",
                    hoverBg100: "hover:bg-purple-100",
                    text500: "text-purple-500",
                    text700: "text-purple-700",
                    groupHoverText700: "group-hover:text-purple-700",
                    bg400: "bg-purple-400"
                };
            case "blue":
                return {
                    bg50: "bg-blue-50",
                    hoverBg100: "hover:bg-blue-100",
                    text500: "text-blue-500",
                    text700: "text-blue-700",
                    groupHoverText700: "group-hover:text-blue-700",
                    bg400: "bg-blue-400"
                };
            case "yellow":
                return {
                    bg50: "bg-yellow-50",
                    hoverBg100: "hover:bg-yellow-100",
                    text500: "text-yellow-600",
                    text700: "text-yellow-700",
                    groupHoverText700: "group-hover:text-yellow-900",
                    bg400: "bg-yellow-400"
                };
            case "gray":
                return {
                    bg50: "bg-gray-50",
                    hoverBg100: "hover:bg-gray-100",
                    text500: "text-gray-500",
                    text700: "text-gray-700",
                    groupHoverText700: "group-hover:text-gray-700",
                    bg400: "bg-gray-400"
                };
            default:
                return {
                    bg50: "bg-primary-50",
                    hoverBg100: "hover:bg-primary-100",
                    text500: "text-primary-500",
                    text700: "text-primary-700",
                    groupHoverText700: "group-hover:text-primary-700",
                    bg400: "bg-primary-400"
                };
        }
    }

    getEventType = (it) => {
        if (it?.TaskTypeID === 1) {
            return "task";
        } else if (it?.TaskTypeID === 2) {
            return "interview";
        } else if (it?.RoleCandidateInterviewID) {
            return "video"
        }

        return "milestone";
    }

    getEventIcon = (it) => {
        if (it.RoleCandidateInterviewID) {
            return VideoCameraIcon;
        } else if (it.MeetingID) {
            return CalendarIcon;
        } else if (it.TaskID) {
            return CheckIcon;
        }
        return null;
    }

    getEventLabel = (it) => {
        if (it.RoleCandidateInterviewID) {
            return "Interview room";
        } else if (it.MeetingID) {
            return "Edit meeting";
        } else if (it.TaskID) {
            return "Edit task";
        }
        return "Edit";
    }

    handleDayClick = (day) => {
        this.setState({
            isEventsDialogOpen: !this.state.isEventsDialogOpen,
            selectedEvents: day
        })
    }

    handleMoreClick = (day) => {
        this.setState({
            isEventsDialogOpen: !this.state.isEventsDialogOpen,
            selectedEvents: day.events,
            activeDay: day.date
        })
    }

    handleSquashedEventsClick = (events) => {
        const startTime = moment(events.squashedTimeStart, "HH:mm").format(getUserTimeFormat());
        const endTime = moment(events.squashedTimeStart, "HH:mm").add(events.squashedDuration, "minutes").format(getUserTimeFormat())

        this.setState({
            isEventsDialogOpen: !this.state.isEventsDialogOpen,
            selectedEvents: events.eventSquashedItems,
            eventsTitle: "Overlapping events (" + startTime + " - " + endTime + ")",
            activeDay: events.date
        })
    }

    setActiveDay = (day) => {
        this.setState({
            activeDay: day
        })
    }

    getStartDateUTC = (date) => {
        if (date.split(" ").length === 1) {
            return toBackDateFromUTC(moment(date, "YYYY-MM-DD HH:mm:ss").startOf('day').add(12, "hours").format("YYYY-MM-DD HH:mm:ss"));
        }

        return toBackDateFromUTC(date);
    }

    getEndDateUTC = (date) => {
        if (date.split(" ").length === 1) {
            return toBackDateFromUTC(moment(date, "YYYY-MM-DD HH:mm:ss").startOf('day').add(13, "hours").format("YYYY-MM-DD HH:mm:ss"));
        }
        return toBackDateFromUTC(moment(date, "YYYY-MM-DD HH:mm:ss").add(1, "hours").format("YYYY-MM-DD HH:mm:ss"));
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const events = getProp(this.props, "resource.data.dates", [])
            .reduce((memo, it) => {

                const date = moment.utc(it.Date, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone).format("YYYY-MM-DD");
                const time = moment.utc(it.Date, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone)

                let duration = null;
                if (it.EndDate) {
                    const endTime = moment.utc(it.EndDate, 'YYYY-MM-DD HH:mm:ss').tz(LocalStorage.get("user")?.Contact?.Timezone)
                    duration = endTime.diff(time, 'minutes')
                }

                if (!memo[date]) {
                    memo[date] = [];
                }

                it.Duration = !!duration && duration > 0 ? duration : 15;

                memo[date].push({
                    name: !!it.TaskID ? it.TaskTitle : it.Name,
                    icon: this.getEventIcon(it),
                    date: date,
                    time: time.format("HH:mm"),
                    timeEnd: time.clone().add(it.Duration, "minutes").format("HH:mm"),
                    Duration: it.Duration,
                    displayTime: time.format("h:mm a"),
                    durationDisplay: time.format("h:mm") + " - " + time.add(it.Duration, "minutes").format("h:mma"),
                    color: this.getEventColorClass(this.getEventColor(time, it.Duration, it)),
                    metadata: {
                        RoleCandidateInterviewID: it.RoleCandidateInterviewID ?? false,
                        Meeting: it.MeetingID ? it : false,
                        Task: it.TaskID ? it : false,
                        RoleID: it.RoleID,
                        AutoToken: it.AutoToken ?? false,
                        fromCalendar: true,
                        Notes: it.Description,
                        EventBtnLabel: this.getEventLabel(it)
                    }
                })
                return memo;
            }, []);

        const metadata = {
            CandidateID: {
                api: 'api/' + Resources.Candidates,
                query: this.getRoleParams(),
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName
                })
            },
            ContactIDs: {
                api: 'api/' + Resources.ClientsContacts,
                query: this.getRoleParams(),
                searchMap: (item) => ({
                    value: item.ClientContactID,
                    label: item.FirstName + " " + item.LastName
                })
            },
        };

        const metadataTask = {
            RoleID: {
                api: 'api/' + Resources.Roles,
                query: {},
                searchMap: (item) => ({
                    value: item.RoleID,
                    label: item.RoleName + " / " + item.ClientName,
                    ClientID: item.ClientID
                })
            },
            CandidateID: {
                api: 'api/' + Resources.Candidates,
                query: {},
                searchMap: (item) => ({
                    value: item.CandidateID,
                    label: item.FirstName + " " + item.LastName
                })
            }
        }

        const data = getProp(this.props, "resource.data.dates", []);
        const count = getProp(this.props, "resource.data.count", []);

        const legend = [
            {
                Desc: "Milestone",
            },
            {
                Desc: "Task/Interview",
                Icon: CheckIcon,
            },
            {
                Desc: "Video interview",
                Icon: VideoCameraIcon,
            },
            {
                Desc: "Meeting",
                Icon: CalendarIcon,
            }
        ].map(it => (
            <div
                className="pt-1 flex items-center justify-between px-4">
                <dt className="flex items-center text-sm text-gray-600">
                    {!!it.Icon && (
                        <it.Icon className={"w-5 h-5 mr-3 text-gray-400"}/>
                    )}

                    {!it.Icon && (
                        <div className="w-5 h-5 flex items-center justify-center mr-3">
                            <div className={"w-2 h-2 bg-gray-400 rounded-full"} />
                        </div>
                    )}
                    <span>{it.Desc}</span>
                </dt>
            </div>
        ));

        const legendColor = [
            {
                Desc: "Not started/Pending",
                Color: "bg-gray-400",
            },
            {
                Desc: "In progress",
                Color: "bg-primary-600",
            },
            {
                Desc: "Done/Completed",
                Color: "bg-green-600",
            },
            {
                Desc: "Overdue",
                Color: "bg-red-600",
            }
        ].map(it => (
            <div
                className="pt-1 flex items-center justify-between px-4">
                <dt className="flex items-center text-sm text-gray-600">
                    <div className={"w-5 h-5 mr-3 rounded-md " + it.Color}/>
                    <span>{it.Desc}</span>
                </dt>
            </div>
        ));

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative z-0">
                    <div className="pt-6 px-4 sm:px-6 lg:px-8">
                        {!!this.props.resource.isLoading && (
                            <div className="absolute inset-0 flex justify-center items-center text-center w-full z-10">
                                <Loader/>
                            </div>
                        )}


                        {!this.state.isCalendarView && (
                            <div className="flex mb-3">
                                <div className="flex-1 text-left">
                                    <div className="inline-block">
                                        <span className="text-sm mx-2">Start Date</span>
                                        <DatePicker
                                            selected={moment(this.state.fieldsSearch.StartDate.value).isValid() ? moment(this.state.fieldsSearch.StartDate.value).toDate() : null}
                                            onChange={(date) => {
                                                this.handleListDateSearch("StartDate", !!date ? toBackDateTime(date) : "");
                                            }}
                                            minDate={null}
                                            maxDate={moment(this.state.fieldsSearch.EndDate.value).subtract(1, 'days').toDate()}
                                            disabled={this.props.disabled}
                                            showTimeSelect={false}
                                            dateFormat={this.props.dateFormat ?? "MM/dd/yyyy"}
                                            timeCaption="time"
                                            popperPlacement={"bottom-start"}
                                            className={"datepickerIcon h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700 react-datepicker-ignore-onclickoutside"}
                                        />
                                    </div>

                                    <div className="inline-block mx-2">
                                        <span className="text-sm mr-1">End Date</span>
                                        <DatePicker
                                            selected={moment(this.state.fieldsSearch.EndDate.value).isValid() ? moment(this.state.fieldsSearch.EndDate.value).toDate() : null}
                                            onChange={(date) => {
                                                this.handleListDateSearch("EndDate", !!date ? toBackDateTime(date) : "");
                                            }}
                                            minDate={moment(this.state.fieldsSearch.StartDate.value).add(1, 'days').toDate()}
                                            maxDate={null}
                                            disabled={this.props.disabled}
                                            showTimeSelect={false}
                                            dateFormat={this.props.dateFormat ?? "MM/dd/yyyy"}
                                            timeCaption="time"
                                            popperPlacement={"bottom-start"}
                                            className={"datepickerIcon h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700 react-datepicker-ignore-onclickoutside"}
                                        />
                                    </div>

                                    <Button
                                        type="button"
                                        disabled={(!this.state.fieldsSearch.StartDate.value && !this.state.fieldsSearch.EndDate.value)}
                                        onClick={this.handleListDateSearchClear}
                                        className="btn btn-primary focus:ring-offset-inverse flex-initial"
                                    >
                                        Clear date filters
                                    </Button>

                                </div>

                                <button
                                    type="button"
                                    onClick={this.handleToggleCalendarView}
                                    className="btn btn-outline focus:ring-offset-inverse flex-initial"
                                >
                                    <CalendarOutlineIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {this.state.isCalendarView ? translate("btn.list_view") : translate("btn.calendar_view")}
                                </button>
                            </div>
                        )}


                        {/*Calendar*/}
                        <div className="sm:mb-6 lg:mb-8">
                            {!this.state.isCalendarView && (
                                <div>
                                    <SimpleTable
                                        data={data}
                                        count={count}

                                        fields={{
                                            Role: new Field("RoleID", ['empty'], false, 'text'),
                                            Candidate: new Field("CandidateID", ['empty'], false, 'text'),
                                            Date: new Field("Date", '', ['empty'], false, 'datetime'),
                                            Type: new Field("Type", '', ['empty'], false, 'text'),
                                            Status: new Field("Status", '', ['empty'], false, 'text'),
                                            Description: new Field("Description", '', [], false, 'textarea')
                                        }}
                                        translate={this.props.translate}
                                        isLoading={this.props.resource.isLoading}

                                        limit={this.state.limit}
                                        offset={this.state.offset}
                                        paginationPage={this.state.paginationPage}
                                        onOffsetChange={this.handleUpdateOffset}

                                        sort={this.state.sort}
                                        sortBy={this.state.sortBy}
                                        onSortChange={this.handleUpdateSort}
                                        onCustomAction={(it) => {
                                            return (
                                                <>
                                                    {it.Type == "Task" && (
                                                        <button
                                                            onClick={() => this.handleToggleEditTaskModal(it)}
                                                            type="button"
                                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                        >
                                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white"
                                                                        aria-hidden="true"/>
                                                            Edit
                                                        </button>
                                                    )}
                                                    {it.Type == "Meeting" && (
                                                        <button
                                                            onClick={() => this.handleToggleEditModal(it)}
                                                            type="button"
                                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                        >
                                                            <PencilIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white"
                                                                        aria-hidden="true"/>
                                                            Edit
                                                        </button>
                                                    )}

                                                    {it.Type == "Milestone" && (
                                                        <button
                                                            onClick={() => this.handleToggleView(it)}
                                                            type="button"
                                                            title="View Role"
                                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                        >
                                                            <EyeIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white"
                                                                     aria-hidden="true"/>
                                                            View
                                                        </button>
                                                    )}

                                                    {it.Type == "Interview" && (
                                                        <button
                                                            onClick={() => this.props.history.push("room/" + it.ID + "/" + it.AutoToken)}
                                                            type="button"
                                                            title="Enter Interview"
                                                            className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                        >
                                                            <VideoCameraIcon
                                                                className="-ml-1 mr-2 h-5 w-5 h-5 text-white"
                                                                aria-hidden="true"/>
                                                            Enter
                                                        </button>
                                                    )}
                                                </>
                                            )
                                        }}
                                    />
                                </div>
                            )}

                            {!!this.state.isCalendarView && (
                                <React.Fragment>
                                    <CalendarNew
                                        onFetchData={this.fetchCalendarData}
                                        events={events}
                                        translate={this.props.translate}
                                        activeDay={this.state.activeDay}
                                        setActiveDay={this.setActiveDay}
                                        onDayClick={this.handleToggleCreateModal}
                                        onEventClick={this.handleCalendarEventTap}
                                        onSquashedEventsClick={this.handleSquashedEventsClick}
                                        onMoreClick={this.handleMoreClick}
                                        hasCreatePerm={checkPerm(Resources.Dashboard, CREATE_PERM)}
                                        hasDeletePerm={checkPerm(Resources.Dashboard, DELETE_PERM)}
                                        hasUpdatePerm={checkPerm(Resources.Dashboard, UPDATE_PERM)}
                                        calendarHeight={"max-h-[calc(100vh-16rem)]"}
                                        actions={[ // siplify to create only and remove actions
                                            {
                                                icon: PlusIcon,
                                                visible: checkPerm(Resources.Dashboard, CREATE_PERM),
                                                label: "Create",
                                                className: "btn btn-primary",
                                                onClick: this.handleToggleCreateModal
                                            }
                                        ]}
                                        customViewMenuItems={[{
                                            id: "list-view",
                                            onMenuItemClick: this.handleToggleCalendarView,
                                            name: translate("btn.list_view")
                                        }]}
                                        infoIcon={(
                                            <PopOver
                                                btnClass={classNames("btn btn-text p-0 ml-2")}
                                                BtnIcon={InformationCircleIcon}
                                                btnIconClass={classNames("h-5 w-5 relative text-primary-500")}
                                                widthClass={"max-w-[20rem]"}
                                                positionClass="translate-x-0 left-2"
                                            >
                                                <div className="bg-inverse rounded-md pt-3">
                                                    <div className="space-y-1 pb-3">
                                                        <p className="pb-1 px-4 text-sm text-gray-600 font-bold">Icons</p>

                                                        {legend}
                                                    </div>

                                                    <div className="border-t border-gray-200 space-y-1 py-3">
                                                        <p className="pb-1 px-4 text-sm text-gray-600 font-bold">Colors</p>

                                                        {legendColor}
                                                    </div>
                                                </div>
                                            </PopOver>
                                        )}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </main>

                <ModalSaveResource
                    title={"Create Task"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalTaskOpen}
                    onClose={this.handleToggleCreateTaskModal}
                    fields={this.getTaskFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Tasks,
                                piggyResource: Resources.Dashboard,
                                piggyQuery: this.getQuery()
                            }));
                            this.handleToggleCreateTaskModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataTask}
                />

                <ModalSaveResource
                    title={"Edit " + this.getEventType(this.state.selectedItem)}
                    widthClass="max-w-lg"
                    visible={this.state.editModalTaskOpen}
                    onClose={this.handleToggleEditTaskModal}
                    fields={this.getTaskFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign({TaskID: this.state.selectedItem.TaskID}, params),
                                resource: Resources.Tasks,
                                piggyResource: Resources.Dashboard
                            }));
                            this.handleToggleEditTaskModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataTask}
                />

                <ModalSaveResource
                    bodyKey={this.state.remountFields}
                    handleInputChange={this.handleInputChange}
                    title={"Edit Meeting"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign({MeetingID: this.state.selectedItem.MeetingID}, params),
                                resource: Resources.Meeting,
                                piggyResource: Resources.Dashboard
                            }));
                            this.handleToggleEditModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    onCustomActions={() => {
                        let event = {
                            title: this.state.selectedItem?.Description,
                            description: this.state.selectedItem?.RoleName,
                            location: '',
                            startTime: this.state.selectedItem?.StartDate,
                            endTime: this.state.selectedItem?.EndDate
                        };

                        return (
                            <>
                                <AddToCalendar event={event}/>

                                <button
                                    type="button"
                                    className="mr-3 btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={() => {
                                        this.props.history.push("meeting-room/" + this.state.selectedItem.MeetingID + "/" + this.state.selectedItem.AutoToken);
                                    }}
                                >
                                    Go To Meeting
                                </button>
                            </>
                        )
                    }}
                />

                {this.state.viewModalOpen && (
                    <RoleModal
                        {...this.props}
                        id={getProp(this.state.selectedItem, "RoleID", 0)}
                        visible={!!this.state.viewModalOpen}
                        onClose={this.handleToggleView}
                        disableCloseOnOutsideClick={isNotificationVisible(this.props.ui)}
                        selectedTab={getProp(this.state.selectedItem, "fromCalendar", false) ? Resources.RoleDates : null}
                    />
                )}


                <DialogDefault
                    title={!!this.state.eventsTitle ? this.state.eventsTitle : "Events for " + moment(this.state.activeDay).format("MM/DD/YYYY")}
                    widthClass="max-w-2xl"
                    disableOverflow
                    visible={this.state.isEventsDialogOpen}
                    onClose={() => this.setState({isEventsDialogOpen: false, eventsTitle: ""})}
                    translate={translate}
                >
                    <div>
                        {checkPerm(Resources.Dashboard, CREATE_PERM) && (!!this.state.selectedEvents && !!this.state.selectedEvents.length) && (
                            <div className="text-right space-x-4 mt-6 pb-6 border-b border-gray-200">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    className="btn btn-primary"
                                >
                                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                                    Create
                                </button>
                            </div>
                        )}

                        {!!this.state.selectedEvents && !!this.state.selectedEvents.length && (
                            <div className="max-h-[calc(100vh-15rem)] overflow-y-auto">
                                <DailyEvents
                                    selectedEvents={this.state.selectedEvents}
                                    onEventClick={(event) => this.handleCalendarEventTap(event, this.state.activeDay)}
                                    hasUpdatePerm={checkPerm(Resources.Dashboard, UPDATE_PERM)}
                                    translate={translate}
                                />
                            </div>
                        )}

                        {!!this.state.selectedEvents && !this.state.selectedEvents.length && (
                            <div className="text-center mt-8">
                                <h3 className="mt-2 text-sm font-bold text-gray-900">No events for this day</h3>
                                {checkPerm(Resources.Dashboard, CREATE_PERM) && (
                                    <React.Fragment>
                                        <p className="mt-1 text-sm text-gray-500">Get started by creating a new task or
                                            meeting.</p>

                                        <div className="mt-6 space-x-6">
                                            <button
                                                type="button"
                                                onClick={this.handleToggleCreateModal}
                                                className="btn btn-primary"
                                            >
                                                <PlusIcon className="-ml-1 mr-2 h-5 w-5"
                                                          aria-hidden="true"/>
                                                Create
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </div>

                    <DialogDefault
                        title="Crate"
                        widthClass="max-w-lg"
                        bgClass={"bg-gray-50"}
                        visible={this.state.isCreateDialogOpen && this.state.isEventsDialogOpen}
                        onClose={() => this.setState({isCreateDialogOpen: false})}
                        hideFooter={true}
                        translate={translate}
                    >
                        <CreateEventForm
                            onClose={() => this.setState({isCreateDialogOpen: false})}
                            getTaskFields={this.getTaskFields({
                                TaskTypeID: 1,
                                TaskDate: this.getStartDateUTC(this.state.selectedDay)
                            })}
                            getMeetingFields={this.getFields({
                                StartDate: this.getStartDateUTC(this.state.selectedDay),
                                EndDate: this.getEndDateUTC(this.state.selectedDay)
                            })}
                            metadataTask={metadataTask}
                            translate={translate}
                            onSubmit={(params, resource) => {
                                if (params) {
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get("user"),
                                        query: this.getQuery(),
                                        params: params,
                                        resource: resource,
                                        piggyResource: Resources.Dashboard,
                                        piggyQuery: this.getQuery()
                                    }));

                                    this.setState({isCreateDialogOpen: false, isEventsDialogOpen: false});
                                }
                            }}
                        />
                    </DialogDefault>
                </DialogDefault>

                <DialogDefault
                    title="Crate"
                    widthClass="max-w-lg"
                    bgClass={"bg-gray-50"}
                    visible={this.state.isCreateDialogOpen && !this.state.isEventsDialogOpen}
                    onClose={() => this.setState({isCreateDialogOpen: false})}
                    hideFooter={true}
                    translate={translate}
                >
                    <CreateEventForm
                        onClose={() => this.setState({isCreateDialogOpen: false})}
                        getTaskFields={this.getTaskFields({
                            TaskTypeID: 1,
                            TaskDate: this.getStartDateUTC(this.state.selectedDay)
                        })}
                        getMeetingFields={this.getFields({
                            StartDate: this.getStartDateUTC(this.state.selectedDay),
                            EndDate: this.getEndDateUTC(this.state.selectedDay)
                        })}
                        metadataTask={metadataTask}
                        translate={translate}
                        onSubmit={(params, resource) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: resource,
                                    piggyResource: Resources.Dashboard,
                                    piggyQuery: this.getQuery()
                                }));

                                this.setState({isCreateDialogOpen: false});
                            }
                        }}
                    />
                </DialogDefault>
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(HomeNewView);
