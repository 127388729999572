import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useEffect, useState} from "react";
import {Field, FieldsManager} from "../../data/services/fields";
import {getResourceDetails} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import FieldText from "../../components/field-text";
import {drawTinyField, fieldsToHtml} from "../../util/util";

export function SendEmailDialog({translate, dispatch, sendEmailDialog, selectedItemsMap, sendNotificationEmails, handleToggleSendEmail, resource}) {

    /** State
     ================================================================= */
    const [fields, setFields] = useState({
        EmailTemplateID: new Field("EmailTemplateID", '', [], false, 'select-search')
    });
    const [Subject, setSubject] = useState("");
    const [Content, setContent] = useState("");

    /** Data Events
     ================================================================= */
    const fetchEmailTemplate = () => {
        dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                RoleID: 0,
                CandidateID: 0,
                EmailTemplateID: fields.EmailTemplateID.value?.value
            },
            resource: Resources.EmailTemplatesParse
        }))
    };

    /** UI Events
     ================================================================= */
    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value));
    };
    useEffect(() => {
        fetchEmailTemplate();
    }, [fields])

    useEffect(() => {
        setSubject(resource.detailsData?.subject);
        setContent(resource.detailsData?.content);
    }, [resource.detailsData]);

    const selectSearchFields = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {
        EmailTemplateID: {
            api: 'api/' + Resources.EmailTemplates,
            query: {},
            searchMap: (item) => ({
                value: item.EmailTemplateID,
                label: item.EmailTemplateName
            })
        },
        RoleID: {
            api: 'api/' + Resources.Roles,
            query: {},
            searchMap: (item) => ({
                value: item.RoleID,
                label: item.RoleName
            })
        }
    });

    return (
        <Transition show={sendEmailDialog} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-20 overflow-y-auto"
                static
                open={sendEmailDialog}
                onClose={handleToggleSendEmail}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                    &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                            <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                <p>You are about to send email to following addresses:</p>
                                <p className={"text-lg leading-6 font-medium text-secondary-900 break-words"}>{Object.values(selectedItemsMap).join(",")}</p>
                                <p>Are you sure?</p>

                                {selectSearchFields}

                                <p className="mb-1 text-sm text-secondary-500 mr-2 pt-1">* Selected template fields will be replaced with the real values when sending email.</p>

                                <p>Subject</p>
                                <FieldText
                                    className={"w-full"}
                                    value={Subject}
                                    onChange={(name, value) => {
                                        setSubject(value)
                                    }}
                                />

                                <p>Content</p>
                                {drawTinyField({
                                    name: "Content",
                                    value: Content
                                }, (name, value) => {
                                    setContent(value);
                                })}
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => sendNotificationEmails(Subject, Content)}
                                        >
                                            Send
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={handleToggleSendEmail}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}