import React, {Component, createRef} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {CloudIcon, CogIcon, CollectionIcon, MailIcon, PhoneIcon} from "@heroicons/react/solid";
import Resources from "../../data/services/resources";
import {Field} from "../../data/services/fields";
import ResourceListTab from "../../components/resource-list-tab";
import AccountTab from "./accountTab";
import ProfileTab from "./profileTab";
import EmailTab from "./emailTab";
import {checkPerm, READ_PERM} from "../../util/util";
import SmsTab from "./smsTab";
import RecruiterTab from "./recruiterTab";
import {Editor} from "@tinymce/tinymce-react";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class SettingsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subNavigation: checkPerm(Resources.Clients, READ_PERM) ? [
                {
                    name: 'Recruiter',
                    description: 'Edit public profile information',
                    href: '#',
                    icon: CollectionIcon,
                    resource: Resources.Company
                },
                {
                    name: 'Account',
                    description: 'Edit account settings, like name or timezone',
                    href: '#',
                    icon: CloudIcon,
                    resource: Resources.Company
                },
                {
                    name: 'Email',
                    description: 'Edit email sending settings',
                    href: '#',
                    icon: MailIcon,
                    resource: Resources.CompanyEmail
                },
                {
                    name: 'SMS',
                    description: 'Set texting settings',
                    href: '#',
                    icon: PhoneIcon,
                    resource: Resources.CompanyPhoneNumberList
                },
                {
                    name: 'Profile',
                    description: 'Edit basic profile settings, like name or timezone',
                    href: '#',
                    icon: CogIcon
                },
                {
                    name: "Email Templates",
                    description: 'Create email templates for quick send later on.',
                    href: '#',
                    icon: MailIcon
                }
            ] : [
                {
                    name: 'Profile',
                    description: 'Edit basic profile settings, like name or timezone',
                    href: '#',
                    icon: CogIcon
                }
            ],
            selectedTab: checkPerm(Resources.Clients, READ_PERM) ? 'Recruiter' : 'Profile',
        };

        this.editorRef = createRef();
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <LayoutDashboard footerBorder={true} {...this.props}>
                <main className="flex flex-col md:flex-row custom-min-h-page-2">
                    {/* Secondary sidebar */}
                    <nav
                        aria-label="Sections"
                        className="flex-shrink-0 md:w-96 bg-white border-r border-primary-gray-200 xl:flex xl:flex-col page-content-height"
                    >
                        <div className="flex-shrink-0 h-16 px-6 border-b border-primary-gray-200 flex items-center">
                            <p className="text-lg font-medium text-primary-gray-900">Settings</p>
                        </div>
                        <div className="flex-1 min-h-0 overflow-y-auto">
                            {this.state.subNavigation.filter((item) => (!!item.resource ? checkPerm(item.resource, READ_PERM) : true)).map((item, i) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        (this.state.selectedTab === item.name) ? 'bg-primary-50 bg-opacity-50' : 'hover:bg-primary-50 hover:bg-opacity-50',
                                        'flex p-6 border-b border-gray-200'
                                    )}
                                    aria-current={(this.state.selectedTab === item.name) ? 'page' : undefined}
                                    onClick={() => {
                                        this.setState({
                                            selectedTab: item.name
                                        })
                                    }}
                                >
                                    <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400"
                                               aria-hidden="true"/>
                                    <div className="ml-3 text-sm">
                                        <p className="font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-gray-500">{item.description}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </nav>

                    <div className="flex-1 xl:overflow-y-auto">
                        <div className="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                            {(this.state.selectedTab === ('Account')) && checkPerm(Resources.Company, READ_PERM) && (
                                <AccountTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {(this.state.selectedTab === ('Email')) && checkPerm(Resources.CompanyEmail, READ_PERM) && (
                                <EmailTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {(this.state.selectedTab === ('SMS')) && checkPerm(Resources.CompanyPhoneNumberList, READ_PERM) && (
                                <SmsTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                    secondResource={this.props.secondResource}
                                />
                            )}


                            {(this.state.selectedTab === ('Profile')) && checkPerm(Resources.User, READ_PERM) && (
                                <ProfileTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {(this.state.selectedTab === 'Questions') && checkPerm(Resources.ShortSurveyQuestions, READ_PERM) && (
                                <ResourceListTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sortBy={"ShortSurveyQuestionID"}
                                    primaryKey={"ShortSurveyQuestionID"}
                                    resourceName={Resources.ShortSurveyQuestions}
                                    fields={{
                                        ShortSurveyQuestion: new Field("ShortSurveyQuestion", '', ['empty'], false, 'text')
                                    }}
                                />
                            )}

                            {(this.state.selectedTab === 'Email Templates') && checkPerm(Resources.EmailTemplates, READ_PERM) && (
                                <ResourceListTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}

                                    sortBy={"EmailTemplateID"}
                                    primaryKey={"EmailTemplateID"}
                                    resourceName={Resources.EmailTemplates}
                                    modalClassName={"max-w-4xl"}
                                    fields={{
                                        EmailTemplateName: new Field("EmailTemplateName", '', ['empty'], false, 'text'),
                                        Subject: new Field("Subject", '', ['empty'], false, 'text'),
                                        Content: new Field("Content", '', ['empty'], false, 'custom', {
                                            render: (item) => {
                                                return (
                                                    <td className={"border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 bg-inverse h-12"}>
                                                        {item.Content}
                                                    </td>
                                                )
                                            }
                                        })
                                    }}
                                    metadata={{
                                        Content: (fields, handleInputChange) => {
                                            const item = fields[2];

                                            const keywords = [
                                                {field: "RoleName", desc: "Role Name", type: 1},
                                                {field: "Description", desc: "Role Description", type: 1},

                                                {field: "Requirements", desc: "Role Requirements", type: 1},
                                                {field: "Responsibilities", desc: "Role Responsibilities", type: 1},
                                                {field: "SalaryRange", desc: "Role Salary Range", type: 1},
                                                {field: "SalaryBonus", desc: "Role Salary Bonus", type: 1},
                                                {field: "Vacation", desc: "Role Vacation", type: 1},

                                                {field: "PostDate", desc: "Role Post Date", type: 1},
                                                {field: "SeoUrl", desc: "Role Public Url", type: 1},

                                                {field: "FirstName", desc: "Candidate First Name", type: 2},
                                                {field: "LastName", desc: "Candidate Last Name", type: 2},
                                                {field: "Phone", desc: "Candidate Phone", type: 2},
                                                {field: "UserFirstName", desc: "My Name", type: 3},
                                                {field: "UserLastName", desc: "My Surname", type: 3},
                                                {field: "UserEmail", desc: "My Email", type: 3},
                                                {field: "UserPhone", desc: "My Phone", type: 3}
                                            ].map((item) => {
                                                let color = "bg-purple-100 text-purple-800";
                                                switch (item.type) {
                                                    case 2:
                                                        color = "bg-indigo-100 text-indigo-800";
                                                        break;
                                                    case 3:
                                                        color = "bg-green-100 text-green-800";
                                                        break;
                                                    default:
                                                        color = "bg-purple-100 text-purple-800";
                                                        break;
                                                }
                                                return (
                                                    <span
                                                        onClick={() => {
                                                            this.editorRef.current.execCommand('mceInsertContent', true, "{{$" + item.field + "}}");
                                                        }}
                                                        className={"cursor-pointer inline-flex m-1 items-center px-3 py-0.5 rounded-full text-sm font-medium " + color}>
                                                    {item.desc}
                                                  </span>
                                                )
                                            })
                                            return (
                                                <>
                                                    <label htmlFor={item.key}
                                                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                                        {translate("field.Content")}{"*"}
                                                    </label>
                                                    <div>{keywords}</div>
                                                    <Editor
                                                        apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
                                                        value={item.value}
                                                        onInit={(evt, editor) => this.editorRef.current = editor}
                                                        init={{
                                                            height: 500,
                                                            content_style: "",
                                                            plugins: [
                                                                'advlist autolink lists link media image charmap print preview anchor image',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                'undo redo | image | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | help',
                                                        }}
                                                        onEditorChange={(content, editor) => {
                                                            handleInputChange(item.name, content);
                                                        }}
                                                    />
                                                    {item.errorMessage && (
                                                        <div className={"text-red-700 text-sm italic mt-1"}>
                                                            <span>Please fill out this field*</span>
                                                        </div>
                                                    )}

                                                </>
                                            )
                                        }
                                    }}
                                />
                            )}

                            {(this.state.selectedTab === 'Recruiter') && checkPerm(Resources.RecruiterProfile, READ_PERM) && (
                                <RecruiterTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(SettingsPage);