import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {classNames, DEFAULT_CRUD_STATE, filterColors, getLookup, getProp} from "../../util/util";
import {PlusIcon} from '@heroicons/react/solid'
import {Field, FieldsManager} from "../../data/services/fields";
import ResourceTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import ArchivedSwitch from "../../components/archived-switch";
import PageHeader from "../../components/layout-dashboard/page-header";
import PopOver from "../../components/popover";
import ColorSwatchIcon from "@heroicons/react/outline/ColorSwatchIcon";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import SimpleTable from "../../components/simple-table";

class ClientsPage extends Component {

    constructor(props) {
        super(props);

        const query = new URLSearchParams(props.location.search);
        const createDialog = query.get('createDialog');

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "ClientName",

            fields: this.getFields(),

            columnFilters: {},
            selectedItems: {},
            colorFilter: [],

            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: !!createDialog,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Clients
        }));
    };

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: this.getResourceName(),
            piggyResource: Resources.Clients
        }));
    }

    submitRowColor = (ColorID) => {
        if (!Object.keys(this.state.selectedItems).length) return;

        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedItems),
                ColorID: ColorID
            },
            notificationMessage: `Row color updated`,
            resource: Resources.ClientsColor,
            query: this.getQuery(),
            piggyResource: Resources.Clients,
        }));

        this.setState({selectedItems: {}})
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleUpdateColumnFilter = (parameters) => {

        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters,
            limit: this.state.limit,
            offset: 0,
            paginationPage: 1
        }, () => this.fetchData())
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    /**
     * @param {{ClientID:string}} item
     */
    handleEdit = (item) => {
        this.props.history.push("/client/" + item.ClientID)
    }

    handleToggleViewModal = () => {
        this.setState({
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({
            searchFields: FieldsManager.updateField(this.state.searchFields, name, value),
            paginationPage: 1,
            offset: 0
        }, this.fetchData);
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ClientID";
    }

    getResourceName = () => {
        return Resources.Client;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters),
            colorIDs: JSON.stringify(this.state.colorFilter),
        }
    }

    getFields = () => ({
        ClientName: new Field("ClientName", '', ['empty'], false, "text"),
        OrganizationTypeID: new Field("OrganizationTypeID", '', [''], false, "select", {filterOptions: getLookup("OrganizationType", "OrganizationTypeID", "OrganizationType")}),

        AddressName: new Field("AddressName", '', [''], false, "text"),
        AddressName2: new Field("AddressName2", '', [], false, "text"),
        CityName: new Field("CityName", '', [''], false, "text"),
        StateID: new Field("StateID", '', [''], false, "select", {filterOptions: getLookup("State", "StateID", "State")}),
        PostalCode: new Field("PostalCode", '', [''], false, "text"),
        CountryID: new Field("CountryID", '', [''], false, "select", {filterOptions: getLookup("Country", "CountryID", "Country")}),
        AreaCode: new Field("AreaCode", '', [''], false, "text"),
        PhoneNumber: new Field("PhoneNumber", '', [''], false, "text"),
        PhoneExtension: new Field("PhoneExtension", '', [''], false, "text")
    });

    handleItemSelection = (it) => {
        let items = this.state.selectedItems;

        if (items[it[this.getPrimaryKey()]]) {
            delete items[it[this.getPrimaryKey()]];
        } else {
            items[it[this.getPrimaryKey()]] = it;
        }

        this.setState({selectedItems: items})
    }

    handleSelectAllOnPage = (data, selected) => {
        let selectedItems = {}

        if (!selected) {
            selectedItems = data.reduce((memo, it) => {
                memo[it[this.getPrimaryKey()]] = it;
                return memo
            }, {});
        }

        this.setState({selectedItems});
    }

    updateColorFilters = (i) => {
        let colorFilter = this.state.colorFilter;

        const index = colorFilter.indexOf(i);
        if (index > -1) {
            colorFilter.splice(index, 1);
        } else {
            colorFilter.push(i)
        }

        this.setState({
            colorFilter,
            offset: 0,
            paginationPage: 1,
        }, () => this.fetchData())
    }

    clearColorFilter = () => {
        this.setState({
            colorFilter: []
        }, () => this.fetchData())
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;
        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const isItemSelected = Object.keys(this.state.selectedItems).length;

        const colorPicker = filterColors.map((it, i) => (
            <button
                onClick={() => this.submitRowColor(i)}
                disabled={!isItemSelected}
                className={classNames(
                    "w-6 h-6 rounded-full ring-offset-inverse ring-primary-500 focus:ring-2 focus:ring-offset-2",
                    it === "transparent" ? "bg-transparent border border-primary" : it,
                    isItemSelected ? "" : "opacity-25 cursor-default"
                )}
            />)
        )

        const colorFilter = filterColors.map((it, i) => (
            <button
                onClick={() => this.updateColorFilters(i)}
                className={classNames(
                    "w-6 h-6 rounded-full ring-offset-inverse ring-primary-500 focus:ring-2 focus:ring-offset-2 flex items-center justify-center",
                    it === "transparent" ? "bg-transparent border border-primary" : it,
                )}
            >
                {this.state.colorFilter.includes(i)
                    ? <CheckIcon className={classNames("w-5 h-5", !!i ? "text-white" : "text-primary-600")}/>
                    : null}
            </button>)
        )

        return (
            <LayoutDashboard {...this.props}>
                <main className="flex-1 relative z-0 overflow-y-auto custom-min-h-page-2">
                    <PageHeader
                        title={translate("page.title.clients")}
                    >
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </PageHeader>

                    <div className="pt-8 pb-16 sm:px-6 lg:px-8 px-4">
                        <div className="flex flex-wrap">
                            <div className="flex flex-wrap sm:space-x-6 md:mr-3 md:w-auto w-full">
                                <FieldSearch
                                    onChange={this.handleQueryChange}
                                    name={"query"}
                                    value={this.state.query}
                                    placeholder="Search"
                                    className="w-full"
                                    classNameContainer={"mb-3 w-64"}
                                />

                                <div className="relative ml-auto sm:ml-0">
                                    {!!this.state.colorFilter.length && (
                                        <span className="flex h-3 w-3 -top-1 -right-1 z-10 absolute">
                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75" />
                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-primary-500" />
                                            </span>
                                    )}

                                    <PopOver
                                        btnClass={classNames("btn btn-outline")}
                                        BtnIcon={ColorSwatchIcon}
                                        btnIconClass={classNames("h-5 w-5 relative")}
                                        btnLabelClass={"btn btn-outline"}
                                        chevronIcon={true}
                                        widthClass={"max-w-[14rem]"}
                                        positionClass="translate-x-0 right-0"
                                    >
                                        <div className="bg-inverse rounded-md pt-4 space-y-3">
                                            <p className="px-4 text-gray-600">Filter by color:</p>

                                            <div className="grid gap-4 grid-cols-5 px-4">
                                                {colorFilter}
                                            </div>

                                            <div className="flex border-t border-secondary-200 py-2 px-4">
                                                <button
                                                    onClick={() => this.clearColorFilter()}
                                                    className="btn btn-outline ml-auto"
                                                    disabled={!this.state.colorFilter.length}
                                                >
                                                    Clear all
                                                </button>
                                            </div>
                                        </div>
                                    </PopOver>
                                </div>

                                <div className="w-full sm:w-auto mb-3">
                                    <button
                                        onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query || this.state.colorFilter.length)
                                            ? this.setState({
                                                columnFilters: {},
                                                query: "",
                                                colorFilter: []
                                            }, () => this.fetchData())
                                            : null
                                        }
                                        className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query || this.state.colorFilter.length ? "btn-primary" : " btn-disabled")}
                                    >
                                        Clear all filters
                                    </button>
                                </div>

                                <div className="md:flex items-center space-x-3 mb-3 hidden">
                                    {colorPicker}
                                </div>
                            </div>

                            <ArchivedSwitch
                                classNameContainer="h-9 md:ml-auto mb-2"
                                translate={translate}
                                value={this.state.archived}
                                onChange={(val) => this.setState({archived: val}, this.fetchData)}
                            />
                        </div>

                        <ResourceTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}
                            onUpdateColumnFilter={this.handleUpdateColumnFilter}
                            columnFilters={this.state.columnFilters}

                            bulkSelectKey={this.getPrimaryKey()}
                            bulkSelectedItems={Object.keys(this.state.selectedItems)}
                            onBulkSelect={this.handleItemSelection}
                            onSelectAllOnPage={this.handleSelectAllOnPage}
                            onSubmitRowColor={this.submitRowColor}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={null}
                            onEdit={this.handleEdit}
                            onDelete={this.handleToggleConfirmModal}
                            onRestore={this.handleRestore}

                            addResourceTableClass={"page-table-height"}
                            addTableClass={"page-table-height"}
                        />
                    </div>
                </main>

                <ModalSaveResource
                    title={"Create Client"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Client,
                                piggyResource: Resources.Clients
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: Resources.Clients
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </LayoutDashboard>
        );
    }
}

export default connect(state => state)(ClientsPage);