import React, {useRef} from 'react'
import Modal from "./index";
import LoaderSmall from "../loader-small";
import XIcon from "@heroicons/react/outline/XIcon";
import DialogDefaultFooter from './modal-footer'
import {classNames} from "../../util/util";

export default function ModalDefault({
                                         show,
                                         initialFocusRef,
                                         widthClass,
                                         hideOverlay,
                                         onClose,
                                         onBackdropClick,
                                         isLoading,
                                         onButtonClick,
                                         buttonDisabled,
                                         buttonLabel,
                                         onSecondaryButtonClick,
                                         secondaryButtonLabel,
                                         title,
                                         customButtonsHTML,
                                         secondaryButtonDisabled,
                                         closeButtonLabel,
                                         hideXbtn,
                                         children,
                                         limitHeight
                                     }) {
    const closeButtonRef = useRef(null);

    return (
        <Modal
            show={show}
            hideOverlay={hideOverlay}
            onClose={onClose}
            widthClass={widthClass}
            onBackdropClick={onBackdropClick}
            initialFocusRef={initialFocusRef ?? closeButtonRef}
        >
            <div className="relative">
                <header
                    className="p-4 flex justify-between items-center border-secondary border-b text-default">
                    <h2 className="text-lg text-current truncate">{title}</h2>

                    {!hideXbtn && (
                        <button className="focus:ring-2 focus:ring-offset-2 focus:ring-primary rounded-full"
                                aria-label="Close"
                                onClick={() => {
                                    onClose();
                                }}>
                            <XIcon className="w-5 h-5"/>
                        </button>
                    )}
                </header>

                <div className={classNames(limitHeight ? "h-dialog-body" : "", isLoading ? "invisible" : "")}>
                    {children}
                </div>

                {isLoading && (
                    <div className="inset-center">
                        <LoaderSmall/>
                    </div>
                )}

                <DialogDefaultFooter
                    buttonLabel={buttonLabel}
                    buttonDisabled={buttonDisabled}
                    onButtonClick={onButtonClick}

                    closeButtonLabel={closeButtonLabel}
                    onClose={onClose}

                    secondaryButtonLabel={secondaryButtonLabel}
                    secondaryButtonDisabled={secondaryButtonDisabled}
                    onSecondaryButtonClick={onSecondaryButtonClick}
                >
                    {!!customButtonsHTML && (
                        customButtonsHTML
                    )}
                </DialogDefaultFooter>
            </div>
        </Modal>
    )
}
