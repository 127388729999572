import React, {Component} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toBackDateTime} from "../../util/util";
import moment from "moment";

export default class FieldDate extends Component {
    render() {
        const filterPassedTime = (time) => {
            if (!this.props.minTime) {
                return true;
            }

            const minTime = new Date(moment(this.props.minTime, "YYYY-MM-DD HH:mm:ss").valueOf());
            const selectedDate = new Date(time);

            return minTime.getTime() <= selectedDate.getTime();
        };

        return (
            <React.Fragment>
                <DatePicker
                    selected={moment(this.props.value).isValid() ? moment(this.props.value).toDate() : null}
                    onChange={(date) => {
                        this.props.onChange(this.props.name, !!date ? toBackDateTime(date) : "");
                    }}
                    minDate={this.props.minDate ? moment(this.props.minDate).toDate() : null}
                    maxDate={this.props.maxDate ? moment(this.props.maxDate).toDate() : null}
                    disabled={this.props.disabled}
                    showTimeSelect={this.props.showTimeSelect}
                    timeFormat={this.props.timeFormat ?? "h:mm aa"}
                    dateFormat={this.props.dateFormat ?? "MM/dd/yyyy h:mm aa"}
                    timeIntervals={15}
                    timeCaption="time"
                    popperPlacement={this.props.placement ? this.props.placement : "bottom-start"}
                    className={"datepickerIcon " + this.props.className + (this.props.errorMessage ? " is-invalid" : "")}
                    filterTime={filterPassedTime}
                />
                {this.props.errorMessage && (
                    <div className={"text-red-700 italic mt-1 text-sm"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </div>
                )}
            </React.Fragment>
        )
    }
}

