import React, {useEffect} from "react";
import {logout} from "../../../data/actions/user";
import LocalStorage from "../../../util/localStorage";

export default function IdleLogoutDialog(props) {
    useEffect(() => {
        window.addEventListener("unload", function (e) {
            props.dispatch(logout({}));
        });

        LocalStorage.set("idleTimer", true)

        return () => {
            props.dispatch(logout({}));
        }
    }, []);

    return (
        <p>You have been logged out automatically because you were inactive for 30 minutes.</p>
    )
}