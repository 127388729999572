import React, {useCallback, useEffect, useRef, useState} from "react";
import {focusOnElement, classNames} from "../../util/util";
import Loader from "../loader";
import XIcon from "@heroicons/react/outline/XIcon";

const DialogCustomInner = (props) => {
    const [activeElement] = useState(document.activeElement);
    const dialogRef = useRef(null);

    // Close on ESC key
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    // Scroll trap, focus on last active element after dialog close action
    useEffect(() => {
        focusOnElement();

        return () => {
            if (!!activeElement) activeElement.focus();
        };
    }, []);

    // Scroll lock
    useEffect(() => {
        let overflow = document.documentElement.style.overflow;
        let paddingRight = document.documentElement.style.paddingRight;

        let pageHeader = document.querySelector(".navbar.fixed-top");

        let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

        document.documentElement.style.overflow = 'hidden';
        document.documentElement.style.paddingRight = `${scrollbarWidth}px`;

        if (!!pageHeader) pageHeader.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
            document.documentElement.style.overflow = overflow;
            document.documentElement.style.paddingRight = paddingRight;
            if (!!pageHeader) pageHeader.style.paddingRight = paddingRight;
        };
    }, []);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            const siblingDialog = dialogRef?.current.nextSibling;

            // Close headlessui first
            if (!!document.getElementById("headlessui-portal-root")) return null;

            if (!siblingDialog) {
                props.closeSelf();
            }
        }
    }, []);

    const modalClass = classNames("bg-gray-50 mx-auto inline-block text-left transition-all transform bg-inverse shadow-xl rounded-2xl overflow-hidden", props.widthClass ?? "max-w-lg", props.topPosition ? "" : "align-middle")

    return (
        <React.Fragment>
            <div
                ref={dialogRef}
                className={classNames("js-modal-ref fixed inset-0 z-40 transition-opacity", (props.isHideAnimationActive ? "custom-dialog-fade" : ""), (!!props.backdropClass ? props.backdropClass : "bg-black bg-opacity-30"))}
                onClick={(e) => props.closeSelf(e.target, dialogRef?.current.nextSibling)}
            >
                <div className="min-h-screen px-4 text-center mx-4">
                    <div className="fixed z-50 inset-0 overflow-y-auto js-modal-backdrop">
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                                &#8203;
                                </span>
                        <div className={classNames(modalClass, !!props.className ? props.className : "p-6 my-8", "custom-dialog-show", props.isHideAnimationActive ? "custom-dialog-hide" : "")}>
                            <div
                                className="">
                                    {props.title && (
                                      <div className="bg-white border-b-2 border-primary-600 -m-6 px-6 py-4 flex items-center relative">
                                          <h2 className="text-xl font-bold leading-6 text-secondary-900">{props.title}</h2>
                                      </div>
                                    )}

                                    {!props.hideCloseBtn && (
                                        <div className="block absolute top-0 right-0 pt-4 pr-4">
                                            <button type="button"
                                                    onClick={() => props.closeSelf()}
                                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                    tabIndex="0"><span className="sr-only">Close</span>
                                                <XIcon className="w-5 h-5"/>
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {props.isLoading && (
                                    <div className="p-5 text-center">
                                        <Loader />
                                    </div>
                                )}

                                <div
                                    className={"modal-body" + (props.addClassBody ? " " + props.addClassBody : "") + (!!props.isLoading ? "" : "")}>
                                    {props.children}
                                </div>

                                {!props.hideFooter && (
                                    <div className="text-right sm:pt-5 mt-5">
                                        <div className="bg-white -mx-6 -mb-6 px-6 py-4 border-t-2 border-primary-600">
                                            {/* TODO: Cleanup. Use customButtonsHTML or onCustomActions and delete the other */}
                                            {!!props.customButtonsHTML && (
                                                props.customButtonsHTML
                                            )}

                                            {!!props.onCustomActions && props.onCustomActions()}

                                            {!!props.secondaryButtonLabel && (
                                                <button
                                                    className={`btn btn-secondary mr-auto focus:btn ${props.secondaryButtonDisabled ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        if (!props.secondaryButtonDisabled) {
                                                            props.onSecondaryButtonClick();
                                                        }
                                                    }}
                                                >
                                                    {props.secondaryButtonLabel}
                                                </button>
                                            )}

                                            {!!props.closeButtonLabel && (
                                                <button className="btn btn-outline ml-3 focus:ring-offset-inverse hover:cursor-pointer" onClick={() => {
                                                    props.closeSelf();
                                                }}>{props.closeButtonLabel}</button>
                                            )}

                                            {!!props.primaryButtonLabel && (
                                                <button
                                                    className={`btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer ${props.primaryButtonDisabled ? "disabled" : ""}`}
                                                    onClick={() => {
                                                        if (!props.primaryButtonDisabled) {
                                                            props.onPrimaryButtonClick();
                                                        }
                                                    }}
                                                >
                                                    {props.primaryButtonLabel}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default DialogCustomInner;