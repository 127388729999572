import React, {Component, Fragment} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import SimpleTable from "../../../components/simple-table";
import {checkPerm, DELETE_PERM, fillFieldsFromData, getProp, READ_PERM, UPDATE_PERM} from "../../../util/util";
import {Field} from "../../../data/services/fields";
import ModalSaveResource from "../../../components/modal/modal-save-resource";
import {
    AnnotationIcon,
    ClipboardListIcon,
    DocumentDownloadIcon, MailIcon, PaperClipIcon,
    PencilIcon,
    PlusIcon,
    QuestionMarkCircleIcon
} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import FieldTextarea from "../../../components/field-textarea";
import Loader from "../../../components/loader";
import FieldText from "../../../components/field-text";
import {createResource, downloadFile} from "../../../data/actions/resource";
import Button from "../../../components/button";
import ModalViewDocument from "../../../components/modal/modal-view-document";
import Env from "../../../util/env";
import moment from "moment";
import LikertScales from "../../../components/survey-builder/question-types/likert-scales";
import QuestionMatrix from "../../../components/survey-builder/question-types/question-matrix";
import RatingScales from "../../../components/survey-builder/question-types/rating-scales";
import QuestionDropdown from "../../../components/survey-builder/question-types/dropdown";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import QuestionRanking from "../../../components/survey-builder/question-types/question-ranking";
import {Dialog, Transition} from "@headlessui/react";

export default class CandidatesTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "FirstName",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,

            selectedForInterview: null,
            questionsFields: {},
            surveyFields: {},
            feedbackFields: {},
            questionItems: {},

            viewModalOpen: false,
            viewStatusReportModalOpen: false,

            packageModalOpen: false,
            sendPackageEmailModalOpen: false,
            sendPackageEmail: ""
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading && !!this.props.dialogResource?.data?.questions) {
            this.setState({
                questionsFields: getProp(this.props, "dialogResource.data.questions", []).reduce((memo, it) => {
                    memo[it.RoleQuestionsID] = it.Answer;
                    return memo;
                }, {})
            })
        }

        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading && !!this.props.dialogResource?.data?.survey) {
            let questionItems = getProp(this.props, "dialogResource.data.survey", {}).reduce((memo, it, i) => {
                const orderNumber = it.RoleSurveyID;

                if (it.Items.length) {
                    memo[orderNumber] = it.Items

                    if (it.Answer && it.ShortSurveyTypeID == 5) {
                        const items = it.Items.reduce((memo, it) => {memo[it.RoleSurveyItemID] = it; return memo}, {});

                        const userAnswers = it.Answer.split(",");

                        // Ignore if questions are added or removed
                        if (userAnswers.length === it.Items.length) {
                            memo[orderNumber] = userAnswers.map(it => {
                                let itemID = it.split('-')[0]
                                return items[itemID]
                            });
                        }
                    }

                }

                return memo
            }, {});

            this.setState({
                surveyFields: getProp(this.props, "dialogResource.data.survey", []).reduce((memo, it) => {

                    memo[it.RoleSurveyID] = it.Answer;

                    // Ranking questions
                    if (it.ShortSurveyTypeID == 5) {
                        const items = it.Items.reduce((memo, it) => {memo[it.RoleSurveyItemID] = it; return memo}, {});

                        memo[it.RoleSurveyID] = it.Answer.split(",").map(it => {
                            let itemID = it.split('-')[0]
                            return items[itemID]
                        });
                    }

                    // Matrix
                    if (it.ShortSurveyTypeID == 3) {
                        memo[it.RoleSurveyID] = it.Answer.split(",").reduce((memo, it, i) => {
                            let index = i+1;
                            let value = it.split('-')[1]
                            switch (value) {
                                case "1":
                                    memo[index] = "very_satisfied"
                                    break;
                                case "2":
                                    memo[index] = "satisfied"
                                    break;
                                case "3":
                                    memo[index] = "neither"
                                    break;
                                case "4":
                                    memo[index] = "dissatisfied";
                                    break;
                                case "5":
                                    memo[index] = "very_dissatisfied";
                                    break;
                                default:
                                    return  memo[i] = "";
                            }
                            return memo
                        }, {})
                    }

                    return memo;
                }, {}),
                questionItems: questionItems
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    fetchQuestionsData = (item) => {
        this.setState({
            questionsFields: {},
            surveyFields: {},
            selectedForInterview: item
        }, () => {
            this.props.dispatch(getDialogResource({
                user: LocalStorage.get("user"),
                query: {
                    id: this.getId(),
                    CandidateID: item.CandidateID
                },
                resource: Resources.RoleQuestionsInterview
            }))
        });
    };

    fetchSurveyData = (item) => {
        this.setState({
            questionsFields: {},
            surveyFields: {},
            selectedForInterview: item
        }, () => {
            this.props.dispatch(getDialogResource({
                user: LocalStorage.get("user"),
                query: {
                    id: this.getId(),
                    CandidateID: item.CandidateID
                },
                resource: Resources.RoleSurveyInterview
            }))
        });
    };

    fetchFeedbackData = (item) => {
        this.setState({
            questionsFields: {},
            surveyFields: {},
            selectedForInterview: item
        }, () => {
            this.props.dispatch(getDialogResource({
                user: LocalStorage.get("user"),
                query: {
                    id: this.getId(),
                    CandidateID: item.CandidateID
                },
                resource: Resources.RoleSurveyInterview
            }))
        });
    };

    submitQuestionsAnswers = () => {
        const CandidateID = this.state.selectedForInterview.CandidateID;
        const questionsFields = this.state.questionsFields;
        this.setState({
            selectedForInterview: null,
            questionsFields: {},
            surveyFields: {}
        }, () => {
            this.props.dispatch(createDialogResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: {
                    id: this.getId(),
                    Answers: Object.keys(questionsFields).map((key) => {
                        return {
                            RoleQuestionsID: key,
                            Description: questionsFields[key]
                        }
                    }),
                    CandidateID: CandidateID
                },
                resource: Resources.RoleQuestionsInterview,
                piggyResource: this.getResourceName()
            }));
        })
    };

    submitSurveyAnswers = () => {
        const CandidateID = this.state.selectedForInterview.CandidateID;
        const surveyFields = this.state.surveyFields;
        this.setState({
            selectedForInterview: null,
            questionsFields: {},
            surveyFields: {}
        }, () => {
            const allQuestionTypes = Object.values(getProp(this.props, "dialogResource.data.survey", {})).reduce((memo, it) => { memo[it.RoleSurveyID] = it.ShortSurveyTypeID; return memo}, {});

            this.props.dispatch(createDialogResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: {
                    id: this.getId(),
                    Answers: Object.keys(surveyFields).filter(key => !(allQuestionTypes[key] == 4 && !surveyFields[key])).map((key) => {
                        let answer = surveyFields[key];

                        if (allQuestionTypes[key] == 3) {
                            answer = this.formatMatrixQuestionsForSubmit(surveyFields[key], key);
                        }

                        // rankingQuestions
                        if (allQuestionTypes[key] == 5 && !!answer.length) {

                            // Get default (randomized) order if user didn't made any ranking changes
                            if (answer[0] == undefined) {
                                this.state.questionItems[key].map(it => it.RoleSurveyItemID).join(",");
                            } else {
                                answer = answer.map(it => it.RoleSurveyItemID).join(",")
                            }
                        }

                        return {
                            RoleSurveyID: key,
                            CandidateAnswer: answer
                        }
                    }),
                    CandidateID: CandidateID
                },
                resource: Resources.RoleSurveyInterview,
                piggyResource: this.getResourceName()
            }));
        })
    };

    sendPackageEmail = (item, email) => {
        const candidate = getProp(this.props, "candidate", {});
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {
                id: item.CandidateID,
                RoleID: item.RoleID,
                Email: email
            },
            notificationMessage: "Email for candidate " + candidate.FirstName + " " + candidate.LastName + " sent to " + email,
            resource: Resources.CandidatePackage
        }));
        this.handleToggleSendPackageEmail();
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQuestionAnswerChange = (question, answer) => {
        let questionsFields = this.state.questionsFields;
        questionsFields[question] = answer;
        this.setState({
            questionsFields: questionsFields
        })
    }

    handleSurveyAnswerChange = (survey, answer) => {
        let surveyFields = this.state.surveyFields;
        surveyFields[survey] = answer;
        this.setState({
            surveyFields: surveyFields
        })
    }

    handleToggleViewModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleToggleViewStatusReportModal = () => {
        this.setState({
            viewStatusReportModalOpen: !this.state.viewStatusReportModalOpen
        })
    }

    handleToggleEditModal = (item) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "RoleCandidateID";
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.RoleCandidates;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            CandidateID: new Field("CandidateID", item ? item.FirstName + " " + item.LastName : "", ['empty'], false, 'select-search'),
            Note: new Field("Note", '', ['empty'], false, 'textarea'),
            RoleCandidateStatusID: new Field("RoleCandidateStatusID", '', [], false, 'select'),
            CandidateProfileID: new Field("CandidateProfileID", 0, ['empty'], false, 'select-search'),
            MatchPercentage: new Field("MatchPercentage", "", [], false, 'gauge'),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    downloadReport = (item) => {
        this.props.dispatch(downloadFile({
            user: LocalStorage.get("user"),
            query: {
                id: this.getId(),
                RoleID: item.RoleID,
                CandidateID: item.CandidateID,
                name: item.FirstName + " " + item.LastName + ".pdf"
            },
            resource: Resources.RoleCandidateFeedbackReport
        }))
    }

    handleDragStart = (i) => {
        this.setState({itemIndexDragged : i})
    }

    handleDragEnter = (i, questionNum, RoleSurveyID) => {
        let questionItems = this.state.questionItems;
        let rowOrder = JSON.parse(JSON.stringify(questionItems[questionNum]));

        [rowOrder[this.state.itemIndexDragged], rowOrder[i]] = [rowOrder[i], rowOrder[this.state.itemIndexDragged]];

        questionItems[questionNum] = rowOrder;

        this.setState({
            questionItems: questionItems,
            itemIndexDragged: i,
        }, () => {
            this.handleSurveyAnswerChange(RoleSurveyID, rowOrder)
        })
    }

    handleDragEnd = () => {

    }

    handleDragLeave = () => {

    }

    handleDrop = () => {
        this.setState({itemIndexDragged : -1})
    }

    handleToggleSendPackageEmail = (item = null) => {
        this.setState({
            selectedItem: item,
            sendPackageEmailModalOpen: !this.state.sendPackageEmailModalOpen
        })
    }

    handleDownloadPackage = (item) => {
        this.setState({
            packageModalOpen: !this.state.packageModalOpen,
            selectedItem: item
        })
    }

    formatMatrixQuestionsForSubmit = (itemValue, questionID) => {
        let formatedValue = [];
        for (const [key, value] of Object.entries(itemValue)) {
            const RoleSurveyItemID = this.state.questionItems[questionID][key - 1].RoleSurveyItemID
            switch (value) {
                case "very_satisfied":
                    formatedValue.push(RoleSurveyItemID + "-1");
                    break;
                case "satisfied":
                    formatedValue.push(RoleSurveyItemID + "-2");
                    break;
                case "neither":
                    formatedValue.push(RoleSurveyItemID + "-3");
                    break;
                case "dissatisfied":
                    formatedValue.push(RoleSurveyItemID + "-4");
                    break;
                case "very_dissatisfied":
                    formatedValue.push(RoleSurveyItemID + "-5");
                    break;
                default:
                    formatedValue.push(RoleSurveyItemID + "-0")
            }
        }
        return formatedValue.join(",");
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;
        const questionMatrixID = 3;
        const ratingScalesID = 1;
        const likertScalesID = 2;
        const dropDownID = 4;
        const rankingQuestionsID = 5;
        const textQuestionID = 6;
        const numberQuestionID = 7;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const hasSurvey = getProp(this.props, "dialogResource.data.hasSurvey", false);
        const hasQuestions = getProp(this.props, "dialogResource.data.hasQuestions", false);

        const questions = getProp(this.props, "dialogResource.data.questions", []);

        const questionsFields = questions.map((it) => {
            return (
                <div
                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:py-5">
                    <label htmlFor={it.RoleQuestionsID}
                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                        {it.Description}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <FieldTextarea
                            className="form-control h-28"
                            onChange={(name, value) => {
                                this.handleQuestionAnswerChange(it.RoleQuestionsID, value)
                            }}
                            name={it.RoleQuestionsID}
                            addClass={"form-control"}
                            value={this.state.questionsFields[it.RoleQuestionsID] ?? ""}
                        />
                    </div>
                </div>
            )
        });

        const survey = getProp(this.props, "dialogResource.data.survey", []);

        const surveyFields = survey.map((it, i) => {
            let dropdownValues = {};
            if (it.ShortSurveyTypeID == dropDownID) {
                dropdownValues = it.Items.reduce((memo, it, i) => {
                    memo[it.RoleSurveyItemID] = it.RoleSurveyItemName;
                    return memo
                }, {});
            }

            let field = "";
            let disc = "";
            switch (it.ShortSurveyTypeID) {
                case 1:
                case ratingScalesID:
                    field = (
                        <RatingScales
                            onClick={(name, value) => {
                                this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                            }}
                            value={this.state.surveyFields[it.RoleSurveyID] ?? ""}
                            topRating={10}
                        />
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Enter text as an answer.</p>);
                    break;
                case 2:
                case likertScalesID:
                    field = (
                        <LikertScales
                            name={"question-" + it.RoleSurveyID}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                            }}
                            value={this.state.surveyFields[it.RoleSurveyID]}
                        />
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Enter number as an answer.</p>);
                    break;
                case 3:
                case questionMatrixID:
                    {this.state.questionItems[it.RoleSurveyID] && (
                        field = (
                            <QuestionMatrix
                                index={i}
                                name={"question-" + it.RoleSurveyID}
                                matrixQuestions={this.state.questionItems[it.RoleSurveyID]}
                                translate={translate}
                                onChange={(name, value) => {
                                    this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                                }}
                                value={this.state.surveyFields[it.RoleSurveyID]}
                            />
                        )
                    )}
                    break;
                case 4:
                case dropDownID:
                    field = (
                        <div className={"w-3"}>
                            <QuestionDropdown
                                value={this.state.surveyFields[it.RoleSurveyID]}
                                onChange={(name, value) => {
                                    this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                                }}
                                name={"question-" + it.RoleSurveyID}
                                values={dropdownValues}
                            />
                        </div>
                    );
                    disc = (<p className="mb-1 text-sm text-secondary-500">* Mark checkbox for yes answer.</p>);
                    break;
                case 5:
                case rankingQuestionsID:
                    {!!this.state.questionItems[it.RoleSurveyID]?.length && (
                        field = (
                            <QuestionRanking
                                translate={translate}
                                questionID={it.RoleSurveyID}
                                rankingOptions={this.state.questionItems[it.RoleSurveyID]}
                                itemIndexDragged={this.state.itemIndexDragged}
                                onDragStart={this.handleDragStart}
                                onDragEnter={(i, questionNum) => this.handleDragEnter(i, questionNum, it.RoleSurveyID)}
                                onDragEnd={this.handleDragStart}
                                onDragLeave={this.handleDragLeave}
                                onDrop={this.handleDragLeave}
                            />
                        )
                    )}
                    break;
                case 6:
                case textQuestionID:
                    field = (
                        <FieldTextarea
                            className="form-control w-full"
                            name={"question-" + it.RoleSurveyID}
                            value={this.state.surveyFields[it.RoleSurveyID]}
                            placeholder={this.props.translate("placeholder.enter_answer")}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                            }}
                        />
                    )
                    break;
                case 7:
                case numberQuestionID:
                    field = (
                        <FieldText
                            className="form-control w-48"
                            name={"question-" + it.RoleSurveyID}
                            value={this.state.surveyFields[it.RoleSurveyID]}
                            placeholder={this.props.translate("placeholder.enter_number")}
                            onChange={(name, value) => {
                                this.handleSurveyAnswerChange(it.RoleSurveyID, value)
                            }}
                        />
                    )
                    break;
                default:
                    field = <div/>
            }
            return (
                <>
                    <div key={it.ShortSurveyQuestion} className="flex flex-col justify-center p-4 border-t ">
                        <div className={"flex justify-between items-center px-4 pb-4 mb-4 border-b border-dashed"}>
                            <div>
                                <p className="m-0">
                                    <span>{i + 1}. </span>
                                    <span className="text-lg text-secondary-800">{it.ShortSurveyQuestion}</span>
                                </p>
                            </div>

                            <div>

                            </div>
                        </div>

                        {field}

                        {!!it.Instructions && (
                            <div className="rounded-md bg-secondary-50 p-4 mt-6">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon
                                            className="h-5 w-5 text-secondary-400"
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-secondary-700">{it.Instructions}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )
        });

        return (
            <div className="">
                {(!!questions && (questions.length > 0)) && (
                    <>
                        {!isLoading && (
                            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                                <div className="ml-4 mt-2 flex-shrink-0">

                                    <button type="button"
                                            className="btn btn-outline focus:ring-offset-inverse"
                                            onClick={this.fetchData}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                        onClick={this.submitQuestionsAnswers}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="mx-auto px-4 sm:p-6 lg:p-8">
                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}
                            <div className="flex flex-col mt-2">
                                <h2>
                                    {this.state.selectedForInterview?.FirstName} {this.state.selectedForInterview?.LastName}
                                </h2>

                                <div className="align-middle min-w-full overflow-x-auto">
                                    {questionsFields}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {(!!survey && (survey.length > 0)) && (
                    <>
                        {!isLoading && (
                            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                                <div className="ml-4 mt-2 flex-shrink-0">

                                    <button type="button"
                                            className="btn btn-outline focus:ring-offset-inverse"
                                            onClick={this.fetchData}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                        onClick={this.submitSurveyAnswers}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className="mx-auto px-4 sm:p-6 lg:p-8">
                            {isLoading && (
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            )}
                            <div className="flex flex-col mt-2">
                                <section className="mb-8">
                                    <div className="bg-white shadow sm:rounded-md">
                                        <div
                                            className="px-4 py-1 sm:px-6 bg-secondary-100 flex justify-between items-center">
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                                <span
                                                    className="text-secondary-600">Survey questions for</span> {this.state.selectedForInterview?.FirstName} {this.state.selectedForInterview?.LastName}
                                            </h2>
                                        </div>

                                        {surveyFields}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </>
                )}

                {(!survey || (survey.length === 0)) && (!questions || (questions.length === 0)) && (
                    <>
                        <div className="mt-6 flex flex-wrap text-gray-700 text-sm">
                            <div className="items-center sm:flex sm:h-6 my-2">
                                <p className="mb-2 sm:mb-0 uppercase mr-4">{translate("text.match_percentage")}:</p>

                                <div className="mb-2 sm:mb-0 flex mx-3">
                                    <span className="w-5 h-5 block rounded-md mr-1 border-2 border-black"
                                          style={{background: "#0bb24e"}}></span>
                                    <span>High</span>
                                </div>

                                <div className="mb-2 sm:mb-0 flex mx-3">
                                    <span className="w-5 h-5 block rounded-md mr-1 border-2 border-black"
                                          style={{background: "#fccc26"}}></span>
                                    <span>Medium</span>
                                </div>

                                <div className="mb-2 sm:mb-0 flex mx-3">
                                    <span className="w-5 h-5 block rounded-md mr-1 border-2 border-black"
                                          style={{background: "#ea2d2d"}}></span>
                                    <span>Low</span>
                                </div>

                                <div className="mb-2 sm:mb-0 flex mx-3">
                                    <span className="w-5 h-5 block rounded-md mr-1 border-2 border-black"
                                          style={{background: "#374151"}}></span>
                                    <span>Unsatisfactory</span>
                                </div>
                            </div>
                            <div className="flex flex-shrink-0 ml-auto mb-3 flex-col-reverse sm:flex-col">
                                {checkPerm(Resources.RoleCandidateFeedbackReport, READ_PERM) && (
                                    <Button
                                        type="button"
                                        onClick={() => this.handleToggleViewStatusReportModal()}
                                        className={"cursor-pointer mr-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"}
                                    >
                                        <DocumentDownloadIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                              aria-hidden="true"/>
                                        Status Report
                                    </Button>
                                )}

                                {checkPerm(this.getResourceName, UPDATE_PERM) && (
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.create")}
                                    </button>
                                )}
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onCustomAction={(it) => {
                                return (
                                    <>
                                        {checkPerm(this.getResourceName(), UPDATE_PERM) && (
                                            <button
                                                type="button"
                                                title={translate("btn.edit")}
                                                onClick={(e) => {
                                                    this.handleToggleEditModal(it)
                                                }}
                                                className="mr-2 my-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                            >
                                                <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                            aria-hidden="true"/>
                                                <span className="hidden md:inline">{translate("btn.edit")}</span>
                                            </button>
                                        )}

                                        {checkPerm(Resources.RoleQuestionsInterview, READ_PERM) && (
                                            <>
                                                {hasQuestions && (
                                                    <button
                                                        type="button"
                                                        title={translate("btn.questions")}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            e.nativeEvent.stopImmediatePropagation();
                                                            this.fetchQuestionsData(it);
                                                        }}
                                                        className="mr-2 my-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                    >
                                                        <QuestionMarkCircleIcon
                                                            className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                            aria-hidden="true"/>

                                                        <span className="hidden md:inline">{translate("btn.questions")}</span>
                                                    </button>
                                                )}
                                                {!hasQuestions && (
                                                    <Button
                                                        type="button"
                                                        title={translate("btn.questions")}
                                                        disabled={true}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        className="mr-2 my-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                    >
                                                        <QuestionMarkCircleIcon
                                                            className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                            aria-hidden="true"/>

                                                        <span className="hidden md:inline">{translate("btn.questions")}</span>
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        {checkPerm(Resources.RoleSurveyInterview, READ_PERM) && (
                                            <Button
                                                type="button"
                                                title={translate("btn.survey")}
                                                onClick={(e) => {
                                                    if (hasSurvey) {
                                                        this.fetchSurveyData(it)
                                                    }
                                                }}
                                                disabled={!hasSurvey}
                                                className={(hasSurvey ? "cursor-pointer" : "cursor-disabled") + " mr-2 my-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"}
                                            >
                                                <ClipboardListIcon
                                                    className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>

                                                <span className="hidden md:inline">{translate("btn.survey")}</span>
                                            </Button>
                                        )}

                                        {checkPerm(Resources.RoleCandidatesStatusReport, READ_PERM) && (
                                            <Button
                                                type="button"
                                                title={translate("btn.feedback_report")}
                                                onClick={() => this.handleToggleViewModal(it)}
                                                disabled={!it.feedbackReport}
                                                className={(it.feedbackReport ? "cursor-pointer" : "cursor-disabled") + " mr-2 inline-flex items-center px-4 my-2 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"}
                                            >
                                                <AnnotationIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                                aria-hidden="true"/>

                                                <span
                                                    className="hidden md:inline">{translate("btn.feedback_report")}</span>
                                            </Button>
                                        )}

                                        {(checkPerm(Resources.CandidatePackage, READ_PERM)) && (
                                            <>
                                                <button
                                                    onClick={() => this.handleToggleSendPackageEmail(it)}
                                                    type="button"
                                                    className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                >
                                                    <MailIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                                    Send Package
                                                </button>

                                                <button
                                                    onClick={() => this.handleDownloadPackage(it)}
                                                    type="button"
                                                    className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                >
                                                    <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                                    Download Package
                                                </button>
                                            </>
                                        )}
                                    </>
                                )
                            }}

                            //onDelete={checkPerm(this.getResourceName(), DELETE_PERM) && (this.handleToggleConfirmModal)}

                        />
                    </>
                )}

                <ModalSaveResource
                    title={"Add candidate"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields(null, ['MatchPercentage'])}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        CandidateID: {
                            api: 'api/' + Resources.Candidates,
                            query: {},
                            searchMap: (item) => ({
                                value: item.CandidateID,
                                label: item.FirstName + " " + item.LastName
                            })
                        }
                    }}
                />

                <ModalSaveResource
                    title={"Edit candidate"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem, ['MatchPercentage', 'CandidateID'])}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{
                        CandidateProfileID: {
                            api: 'api/' + Resources.RoleVersionCreate,
                            query: {
                                id: this.state.selectedItem?.CandidateID,
                            },
                            searchMap: (item) => ({
                                value: item.CandidateProfileID,
                                label: item.ProfileName,
                            })
                        }
                    }}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <ModalViewDocument
                    title={"View report"}
                    className="max-w-2xl"
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                    translate={this.props.translate}

                    fileType={"pdf"}
                    filePath={
                        Env.getApiUrl('api/' + Resources.RoleCandidateFeedbackReport, {
                            id: this.getId(),
                            RoleID: this.state.selectedItem?.RoleID,
                            CandidateID: this.state.selectedItem?.CandidateID,
                            name: this.state.selectedItem?.FirstName + " " + this.state.selectedItem?.LastName + ".pdf",
                            token: LocalStorage.get('user').access_token
                        })

                    }
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get('user'),
                            query: {
                                id: this.getId(),
                                RoleID: this.state.selectedItem?.RoleID,
                                CandidateID: this.state.selectedItem?.CandidateID,
                                name: this.state.selectedItem?.FirstName + " " + this.state.selectedItem?.LastName + ".pdf"
                            },
                            resource: Resources.RoleCandidateFeedbackReport
                        }))
                    }}
                />

                <ModalViewDocument
                    title={"View report"}
                    className="max-w-2xl"
                    visible={this.state.viewStatusReportModalOpen}
                    onClose={this.handleToggleViewStatusReportModal}
                    translate={this.props.translate}

                    fileType={"pdf"}
                    filePath={
                        Env.getApiUrl('api/' + Resources.RoleCandidatesStatusReport, {
                            id: this.getId(),
                            RoleID: this.props.role.RoleID,
                            name: "Status Report - " + moment().format("MM-DD-YYYY hh:mm") + ".pdf",
                            token: LocalStorage.get('user').access_token
                        })

                    }
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get('user'),
                            query: {
                                id: this.getId(),
                                RoleID: this.props.role.RoleID,
                                name: "Status Report - " + moment().format("MM-DD-YYYY hh:mm") + ".pdf"
                            },
                            resource: Resources.RoleCandidatesStatusReport
                        }))
                    }}
                />

                <ModalViewDocument
                    title={"View Package Document"}
                    className="max-w-2xl"
                    visible={this.state.packageModalOpen}
                    onClose={this.handleDownloadPackage}
                    translate={this.props.translate}
                    fileType={"pdf"}
                    token={LocalStorage.get('user').access_token}
                    filePath={Env.getApiUrl('api/' + Resources.CandidatePackage, {
                        id: this.state.selectedItem?.CandidateID,
                        RoleID: this.state.selectedItem?.RoleID,
                        CandidateID: this.state.selectedItem?.CandidateID,
                        name: this.state.selectedItem?.FirstName + " " + this.state.selectedItem?.LastName + ".pdf"
                    })}
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get("user"),
                            query: {
                                id: this.state.selectedItem?.CandidateID,
                                RoleID: this.state.selectedItem?.RoleID,
                                CandidateID: this.state.selectedItem?.CandidateID,
                                name: this.state.selectedItem?.FirstName + " " + this.state.selectedItem?.LastName + ".pdf"
                            },
                            resource: Resources.CandidatePackage
                        }))
                    }}
                />

                <Transition show={this.state.sendPackageEmailModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.sendPackageEmailModalOpen}
                        onClose={this.handleToggleSendPackageEmail}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                    &#8203;
                    </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        <p>Enter email to send package to:</p>

                                        <p className={"mt-1 mb-1 text-sm text-secondary-500"}>* Email will contain
                                            information
                                            about {this.state.selectedItem?.FirstName} {this.state.selectedItem?.LastName}</p>
                                        <FieldText
                                            onChange={(name, value) => {
                                                this.setState({
                                                    sendPackageEmail: value
                                                })
                                            }}
                                            value={this.state.sendPackageEmail}
                                        />
                                    </div>

                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                <button
                                                    type="button"
                                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                    onClick={() => this.sendPackageEmail(this.state.selectedItem, this.state.sendPackageEmail)}
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={this.handleToggleSendPackageEmail}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        )
    }
}
