import React, {Component} from 'react'
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import SimpleTable from "../../../components/simple-table";
import {
    checkPerm,
    CREATE_PERM,
    DELETE_PERM,
    fillFieldsFromData,
    getProp,
    READ_PERM,
    UPDATE_PERM
} from "../../../util/util";
import {Field} from "../../../data/services/fields";
import ModalSaveResource from "../../../components/modal/modal-save-resource";
import {AnnotationIcon, PaperClipIcon, PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import Modal from "../../../components/modal/index";
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import {getSecondResource} from "../../../data/actions/secondResource";
import axios from "axios";
import Env from "../../../util/env";
import {processResponse} from "../../../data/services/api-util";
import LoaderSmall from "../../../components/loader-small";

export default class CandidateMeetingTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "Date",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            notesModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        axios.get(
            Env.getApiUrl("api/" + this.props.resourceName, {id :this.props.role.RoleID}),
            {
                headers: {
                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                }
            }
        )
            .then((response) => {
                const result = processResponse(response);
                if (result && result.status === 0) {
                    const list = result.data.contacts.reduce((memo, it) => {
                        memo[it.ClientContactID] = it.ClientContactName;
                        return memo;
                    }, {});
                    this.setState({
                        ContactIDs: list
                    })
                }
            })
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleResend = (item) => {
        let params = {
            [this.getPrimaryKey()]: item[this.getPrimaryKey()],
            resend: 1
        };
        this.props.dispatch(updateDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: params,
            resource: this.getResourceName(),
            piggyResource: this.getResourceName()
        }));
    };

    handleViewNotes = (item) => {
        this.setState({
            notesModalOpen: true
        }, () => {
            this.props.dispatch(getSecondResource({
                user: LocalStorage.get("user"),
                query: {
                    id: item.MeetingID
                },
                resource: Resources.MeetingNotes
            }))
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleView = (item) => {
        this.props.history.push("/meeting-room/" + item[this.getPrimaryKey()] + "/" + item.AutoToken);
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "MeetingID";
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.RoleCandidateMeeting;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null) => {
        if (item) {
            item = Object.assign({}, item);
        }
        const fieldTemplates = {
            CandidateID: new Field("CandidateID", item ? (item.FirstName + ' ' + item.LastName) : "", ['empty'], false, 'select'),
            ContactIDs: new Field("ContactIDs", "", [], false, 'multi-select'),
            StartDate: new Field("StartDate", '', ['empty'], false, 'datetime'),
            EndDate: new Field("EndDate", '', ['empty'], false, 'datetime'),
            Description: new Field("Description", '', ['empty'], false, 'textarea')
        };

        if (item) {
            item.ContactIDs = item?.ContactIDs?.map((it) => {
                return {
                    value: it.ClientContactID,
                    label: it.FirstName + " " + it.LastName
                }
            });
        }



        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const candidatesList = Object.values(getProp(this.props, "dialogResource.data.candidates", {})).reduce((memo, it) => {memo[it.CandidateID] = it.Candidate; return memo;}, {});

        const metadata = {
            CandidateID: candidatesList,
            ContactIDs: this.state.ContactIDs
        };

        const notes = getProp(this.props, "secondResource.data.list", []).map((it) => {
            return <div>{it.Note}</div>
        });

        const notesCount = getProp(this.props, "secondResource.data.count", 0);

        const isLoadingSecond = getProp(this.props, "secondResource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <div className="flex-shrink-0 ml-auto mb-3">
                        {checkPerm(this.getResourceName(), CREATE_PERM) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={this.handleView}
                    onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) ? this.handleToggleEditModel : null}
                    onDelete={checkPerm(this.getResourceName(), DELETE_PERM) ? this.handleToggleConfirmModal : null}
                    onCustomAction={(it) => {
                        return (
                            <>
                                {checkPerm(this.getResourceName(), UPDATE_PERM)  && (
                                    <button
                                        onClick={() => this.handleResend(it)}
                                        type="button"
                                        className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                    >
                                        <PaperClipIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                        Resend Invitation
                                    </button>
                                )}

                                {checkPerm(Resources.MeetingNotes, READ_PERM) && (
                                <button
                                    onClick={() => this.handleViewNotes(it)}
                                    type="button"
                                    className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                    <AnnotationIcon className="-ml-1 mr-2 h-5 w-5 h-5 text-white" aria-hidden="true"/>
                                    View Personal Notes
                                </button>
                                )}
                            </>
                        )
                    }}
                />

                <ModalSaveResource
                    title={"Edit Meeting"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params.RoleID = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={"Create Meeting"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params.RoleID = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <Modal
                    visible={this.state.notesModalOpen}
                    onClose={() => this.setState({notesModalOpen: false})}
                >
                    <p className="text-lg text-secondary-500">Meeting Notes</p>
                    {!isLoadingSecond && notes}

                    {!isLoadingSecond && (notesCount === 0) && (
                        <p>No personal notes for this meeting</p>
                    )}

                    {isLoadingSecond && (
                        <div className="mt-3 flex rounded-btn bg-secondary-100 mb-6">
                            <LoaderSmall text="Loading"/>
                        </div>
                    )}
                </Modal>
            </div>
        )
    }
}
