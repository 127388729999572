import React, {Component} from 'react'
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {checkPerm, drawTinyField, fillFieldsFromData, getProp, UPDATE_PERM} from "../../../util/util";
import {Field, FieldsManager} from "../../../data/services/fields";
import Loader from "../../../components/loader";
import Button from "../../../components/button";
import FieldCheckbox from "../../../components/field-checkbox";

export default class DescriptionTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "dialogResource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            this.props.dispatch(updateDialogResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        RoleDescriptionID: getProp(this.props, "dialogResource.data.RoleDescriptionID", 0)
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName()
            }));
        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "dialogResource.data", {});

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.RoleDescription;
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            DisplayClient: new Field("DisplayClient", '', [], false, 'checkbox'),
            Description: new Field("Description", '', [], false, 'tiny'),
            Requirements: new Field("Requirements", '', [], false, 'tiny'),
            Responsibilities: new Field("Responsibilities", '', [], false, 'tiny')

        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <label htmlFor={"DisplayClient"}
                                   className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                {translate("field.DisplayClient")}
                            </label>
                            <div className="">
                                <FieldCheckbox onChange={this.handleInputChange} {...this.state.fields.DisplayClient} />
                            </div>
                        </div>

                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <label htmlFor={"Description"}
                                   className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                {translate("field.Description")}
                            </label>
                            <div className={"form-control w-full max-w-full"}>
                                {drawTinyField(this.state.fields.Description, this.handleInputChange, translate)}
                            </div>
                        </div>

                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <label htmlFor={"Requirements"}
                                   className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                {translate("field.Requirements")}
                            </label>
                            <div className={"form-control w-full max-w-full"}>
                                {drawTinyField(this.state.fields.Requirements, this.handleInputChange, translate)}
                            </div>
                        </div>

                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <label htmlFor={"Responsibilities"}
                                   className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                {translate("field.Responsibilities")}
                            </label>

                            <div className={"form-control w-full max-w-full"}>
                                {drawTinyField(this.state.fields.Responsibilities, this.handleInputChange, translate)}
                            </div>
                        </div>
                    </div>
                </form>

                {!isLoading && (
                    <div className="pt-5">
                        <div className="flex justify-end mb-5">
                            {checkPerm(this.getResourceName(), UPDATE_PERM) && (
                                <>
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>


                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.handleSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}
