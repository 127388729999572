import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {drawTinyField, fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Loader from "../../components/loader";
import Button from "../../components/button";

export default class OverviewTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data." + this.getResourceName(), {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            this.props.dispatch(updateResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        ClientOverviewID: getProp(this.props, "resource.data." + this.getResourceName() + ".ClientOverviewID", 0),
                        id: this.getId()
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: this.getResourceName(),
                piggyResource: this.getResourceName()
            }));
        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data." + this.getResourceName(), {});

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getResourceName = () => {
        return this.props.resourceName ?? Resources.ClientOverview;
    }

    getQuery = () => {
        return {
            id: this.getId()
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            OrganizationalOverview: new Field("OrganizationalOverview", '', [], false, 'tiny'),
            AwardsAndAccolades: new Field("AwardsAndAccolades", '', [], false, 'tiny')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <div className="">
                {isLoading && (
                    <div className="h-80">
                        <div className={"inset-center"}>
                            <Loader/>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <React.Fragment>

                        <form className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                                <div className="mb-2 mt-2 sm:mt-0 sm:col-span-2">
                                    <label htmlFor={"OrganizationalOverview"}
                                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                        {translate("field.OrganizationalOverview")} {!!this.state.fields.OrganizationalOverview.disabled ? "(Editor disabled)" : null}
                                    </label>
                                    <div className={"form-control w-full max-w-full"}>
                                        {drawTinyField(this.state.fields.OrganizationalOverview, this.handleInputChange, translate)}
                                    </div>
                                </div>

                                <div className="mb-2 mt-2 sm:mt-0 sm:col-span-2">
                                    <label htmlFor={"AwardsAndAccolades"}
                                           className="block text-sm font-medium text-secondary-700 sm:mt-px sm:pt-2">
                                        {translate("field.AwardsAndAccolades")} {!!this.state.fields.AwardsAndAccolades.disabled ? "(Editor disabled)" : null}
                                    </label>
                                    <div className={"form-control w-full max-w-full"}>
                                        {drawTinyField(this.state.fields.AwardsAndAccolades, this.handleInputChange, translate)}
                                    </div>
                                </div>

                            </div>
                        </form>


                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.onSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                            </div>
                        </div>

                    </React.Fragment>
                )}
            </div>
        )
    }
}
