import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import SimpleTable from "../../components/simple-table";
import {fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {Field} from "../../data/services/fields";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";

export default class LocationsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "LocationName",

            columnFilters: {},
            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "ClientLocationID";
    }

    getResourceName = () => {
        return Resources.ClientLocations;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            columnFilters: JSON.stringify(this.state.columnFilters)
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LocationName: new Field("LocationName", '', ['empty'], false, "text"),
            LocationTypeID: new Field("LocationTypeID", '', [''], false, 'select', {filterOptions: getLookup("LocationType", "LocationTypeID", "LocationType")}),

            AddressName: new Field("AddressName", '', ['empty'], false, "text"),
            AddressName2: new Field("AddressName2", '', [], false, "text"),
            CityName: new Field("CityName", '', ['empty'], false, "text"),
            StateID: new Field("StateID", '', [''], false, "select", {filterOptions: getLookup("State", "StateID", "State")}, {menuPlacement: "top"}),
            PostalCode: new Field("PostalCode", '', [''], false, "text"),
            CountryID: new Field("CountryID", '', [''], false, "select", {filterOptions: getLookup("Country", "CountryID", "Country")}),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateColumnFilter = (parameters) => {
        let columnFilters = this.state.columnFilters;

        if (!parameters.options.length) {
            delete columnFilters[parameters.name]
        } else {
            columnFilters[parameters.name] = parameters.options
        }

        this.setState({
            columnFilters
        }, () => this.fetchData())
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mr-6 mb-3"}
                    />

                    <div className="mr-6 w-full sm:w-auto mb-3">
                        <button
                            onClick={() => (!!Object.keys(this.state.columnFilters).length || !!this.state.query)
                                ? this.setState({
                                    columnFilters: {},
                                    query: ""
                                }, () => this.fetchData())
                                : null
                            }
                            className={"btn " + (!!Object.keys(this.state.columnFilters).length || !!this.state.query ? "btn-primary" : " btn-disabled")}
                        >
                            Clear all filters
                        </button>
                    </div>

                    <div className="flex-shrink-0 ml-auto mb-3">
                        <button
                            type="button"
                            onClick={this.handleToggleCreateModal}
                            className="btn btn-primary focus:ring-offset-inverse"
                        >
                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                            {translate("btn.create")}
                        </button>
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}
                    onUpdateColumnFilter={this.handleUpdateColumnFilter}
                    columnFilters={this.state.columnFilters}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onEdit={this.handleToggleEditModel}
                    onDelete={this.handleToggleConfirmModal}
                />

                <ModalSaveResource
                    title={"Edit Location"}
                    widthClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel(null)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalSaveResource
                    title={"Create Location"}
                    widthClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
