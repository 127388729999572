import React, {useState} from 'react'
import {InformationCircleIcon} from "@heroicons/react/solid";
import IconSort from "../icon/sort";
import {fieldsToCells, classNames} from "../../util/util";
import FiltersMenu from "./filters-menu";
import FieldCheckbox from "../field-checkbox";
import BulkSelectMenu from "./bulk-select-menu";


const Table = (props) => {
    const {
        haveActions,
        isLoading,
        data,
        fields,
        onEdit,
        onSortChange,
        onRowClick,
        sortBy,
        sort,
        onUpdateColumnFilter,
        translate,
        getActions,
        bulkSelectKey,
        onBulkSelect,
        itemIsSelected,
        selectedOptions,
        columnFilters,
        bulkSelectedItems,
        onSelectAllOnPage,
        checkIfAllOnPageSelected,
        getCellClasses
    } = props;

    const [bulkSelectDropdown, setBulkSelectDropdown] = useState(null);

    const getFilter = (it) => {
        let options = fields[it]?.metadata?.filterOptions;

        if (options) {
            return Object.entries(options).reduce((memo, it) => {
                memo.push({
                    value: it[0],
                    name: it[1]
                });
                return memo;
            }, []);
        }
    }

    const columns = Object.keys(fields)
        .filter(
            it => !fields[it]?.metadata?.hideTable
        )
        .map((it, i) => {
            const headerFilter = getFilter(it);

            return (
                <th
                    className="bg-black text-left text-xs font-medium text-white uppercase tracking-wider"
                >
                    <div className="flex items-center">
                        {!headerFilter && (
                            <div
                                className="px-4 py-2 flex items-center cursor-pointer hover:bg-secondary-800 rounded-md"
                                onClick={!it?.metadata?.omitSort && onSortChange ? () => onSortChange(it) : null}>
                                <div className="mr-3 whitespace-nowrap">
                                    {translate("table." + it)}
                                </div>

                                {!!onSortChange && !it?.metadata?.omitSort && (
                                    <IconSort
                                        className="min-w-icon ml-auto"
                                        active={sortBy === it}
                                        sort={sort}
                                    />
                                )}
                            </div>
                        )}

                        {!!headerFilter && (
                            <React.Fragment>
                                <div className="relative">
                                    <FiltersMenu
                                        options={headerFilter ?? headerFilter}
                                        selectedOptions={selectedOptions}
                                        columnFilters={columnFilters}
                                        column={it}
                                        onUpdateColumnFilter={onUpdateColumnFilter}
                                        header={translate("table." + it)}
                                        translate={translate}
                                    />
                                </div>

                                <div className="cursor-pointer hover:bg-secondary-800 p-1 rounded-md"
                                     onClick={onSortChange ? () => onSortChange(it) : null}>
                                    <IconSort
                                        className="min-w-icon ml-auto"
                                        active={sortBy === it}
                                        sort={sort}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </th>
            )
        });



    const row = data.map((it, i) => {
        const cellClasses = getCellClasses(it.ColorID);
        const fieldRows = fieldsToCells(fields, it, cellClasses);
        return (
            (
                <tr
                    onClick={() => {
                        onRowClick && onRowClick(it)
                    }}
                    key={i}
                    className={classNames(
                        "group",
                        (i % 2 === 0) ? (!it.ArchivedDate ? 'bg-inverse' : "bg-secondary-100") : (!it.ArchivedDate ? 'bg-secondary-50' : 'bg-secondary-100'))
                    }
                >
                    {bulkSelectKey && (
                        <td className={classNames(cellClasses, "text-center")}>
                            <FieldCheckbox
                                onChange={() => onBulkSelect(it)}
                                onFocus={false}
                                value={itemIsSelected(it)}
                            />
                        </td>
                    )}

                    {fieldRows}

                    {haveActions && (
                        getActions(it, cellClasses)
                    )}
                </tr>
            )
        )
    });

    const generateRandomSkeletonWidth = () => {
        let classes = ['w-3/12', 'w-4/12', 'w-5/12', 'w-6/12', 'w-7/12', 'w-8/12', 'w-9/12']
        return classes[Math.floor(Math.random() * classes.length)];
    }

    const getSkeletonRows = (columns, n = 10) => {
        const skeletonRows = [];

        for (let i = 1; i <= n; i++) {
            const dataCells = columns.map(col => (
                <td
                    className={"animate-pulse border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold w-32 h-12"}
                >
                    <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                </td>
            ));

            skeletonRows.push(
                <tr>
                    {dataCells}
                    {haveActions && (
                        <td
                            className={"animate-pulse border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold w-32 h-12"}
                        >
                            <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                        </td>
                    )}
                </tr>
            )
        }

        return skeletonRows;
    }

    return (
        <div className={""}>
            <table className={"min-w-full divide-y divide-secondary-200 "}>
                <thead>
                <tr>
                    {bulkSelectKey && (
                        <th className="px-6 py-3 bg-black text-left text-xs font-medium text-white uppercase tracking-wider">
                            {(checkIfAllOnPageSelected(data, bulkSelectedItems) || !bulkSelectedItems.length) && (
                                <FieldCheckbox
                                    onChange={() => onSelectAllOnPage(data, bulkSelectedItems.length)}
                                    value={bulkSelectedItems.length}
                                />
                            )}

                            {!(checkIfAllOnPageSelected(data, bulkSelectedItems) || !bulkSelectedItems.length) && (
                                <React.Fragment>
                                    <FieldCheckbox
                                        onChange={() => setBulkSelectDropdown(!bulkSelectDropdown)}
                                        value={bulkSelectedItems.length}
                                    />

                                    <BulkSelectMenu
                                        onSelectAllOnPage={onSelectAllOnPage}
                                        data={data}
                                        bulkSelectedItems={bulkSelectedItems}
                                    />
                                </React.Fragment>
                            )}
                        </th>
                    )}

                    {columns}

                    {haveActions && (
                        <th className="px-6 py-3 bg-black text-left text-xs font-medium text-white uppercase tracking-wider">
                            Actions
                        </th>
                    )}
                </tr>
                </thead>
                <tbody className="bg-inverse divide-y divide-secondary-200">

                {!isLoading && row}

                {isLoading && getSkeletonRows(columns)}

                {!isLoading && (row.length === 0) && (
                    <tr>
                        <td className={"items-center"} colSpan={Object.keys(fields).length + (onEdit ? 1 : 0)}>
                            <div className="rounded-md bg-blue-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon className="h-5 w-5 text-secondary-400"
                                                               aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3 flex-1 md:flex md:justify-between">
                                        <p className="text-sm text-black-700">No data</p>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default Table;