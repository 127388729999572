import React, {Component} from 'react';
import {getProp} from "../../../util/util";

export default class QuestionRanking extends Component {

    render() {
        const options = getProp(this.props, "rankingOptions", []).map((it, i) => {
            return (
                <div
                    className={"flex relative select-none p-1 rounded mt-2" + (this.props.itemIndexDragged === i ? " bg-primary-50" : "")}
                    onDragEnter={() => this.props.onDragEnter(i, this.props.questionID)}
                    onDragLeave={this.props.onDragLeave}
                    onDrop={this.props.onDrop}
                >
                    <label className="px-2 w-12 flex justify-between items-center">
                        <div className="mr-2 cursor-move select-none" draggable onDragStart={() => this.props.onDragStart(i)}>
                            <div className="w-3 grid grid-cols-2 gap-1">
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                                <div className="w-1 h-1 mr-1 bg-primary-500 rounded-full"></div>
                            </div>
                        </div>

                        {i + 1}.
                    </label>

                    <p>{it.RoleSurveyItemName}</p>
                </div>
            )
        })
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        )
    }
}
