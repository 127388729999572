import React, {useEffect, useRef} from "react";
import {classNames} from "../../../util/util"
import moment from "moment";

export default function CalendarWeekView(props) {
    const {days, onDayClick, weekFormat, selectedDay, setActiveDay, renderEventButton, sortDayEvents, getCurrentWeek, calendarHeight} = props;

    const getDayOfWeekClass = (dayOrder) => {
        switch (dayOrder) {
            case 0:
                return "sm:col-start-1";
            case 1:
                return "sm:col-start-2";
            case 2:
                return "sm:col-start-3";
            case 3:
                return "sm:col-start-4";
            case 4:
                return "sm:col-start-5";
            case 5:
                return "sm:col-start-6";
            case 6:
                return "sm:col-start-7";
            default:
                return "sm:col-start-1";
        }
    }

    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)

    useEffect(() => {
        // Set the container scroll position based on the current time.
        const currentMinute = new Date().getHours() * 60
        container.current.scrollTop =
            ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
                currentMinute) /
            1440
    }, [])

    const handleDayClick = (e, day = null) => {
        const activeDay = day?.date ?? selectedDay.date;

        const rect = e.target.getBoundingClientRect();
        const y = e.clientY - rect.top;  //y position within the element.
        const topMargin = 30;
        const sectionHeight = 56; // 30min section
        const halfHour = 30;
        const minutes = Math.floor((y - topMargin) / sectionHeight) * halfHour;
        const date = moment(activeDay, "YYYY-MM-DD").utc().startOf('day').add(1, 'days').add(minutes, 'minutes').format('YYYY-MM-DD HH:mm');
        onDayClick(date)
    }

    let currentWeek = getCurrentWeek(days);

    return (
        <div ref={container} className={classNames(calendarHeight, "flex flex-auto flex-col overflow-auto bg-white")}>
            <div style={{width: '165%'}} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
                <div
                    ref={containerNav}
                    className="sticky top-0 z-10 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
                >
                    <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                        {currentWeek.map((day, i) => (
                            <button
                                type="button"
                                onClick={() => setActiveDay(day.date)}
                                disabled={!day.isCurrentMonth}
                                className={classNames("flex flex-col items-center pt-2 pb-3", !day.isCurrentMonth ? "bg-gray-100" : "")}
                            >
                                {weekFormat[i].abbr}

                                <span className={classNames(
                                    "mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900",
                                    day.isToday && day.isSelected ? "rounded-full bg-primary-600 font-semibold text-white" : "",
                                    day.isToday && !day.isSelected ? "text-primary-600 font-semibold" : "",
                                    !day.isToday && day.isSelected ? "rounded-full bg-gray-600 font-semibold text-white" : "",
                                )}>{day.day}</span>
                            </button>
                        ))}
                    </div>

                    <div
                        className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                        <div className="col-end-1 w-14"/>

                        {currentWeek.map((day, i) => (
                            <div className="flex items-center justify-center py-3">
                                        <span className={day.isToday && day.isSelected ? "flex items-baseline" : ""}>
                                            {weekFormat[i].abbr + weekFormat[i].abbrExt} <span className={classNames(
                                            "items-center justify-center font-semibold text-gray-900",
                                            day.isToday && day.isSelected ? "ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 font-semibold text-white" : ""
                                        )}>{day.day}</span>
                                        </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-auto">
                    <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100"/>
                    <div className="grid flex-auto grid-cols-1 grid-rows-1">
                        {/* Horizontal lines */}
                        <div
                            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                            style={{gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))'}}
                        >
                            <div ref={containerOffset} className="row-end-1 h-7"></div>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    12AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    1AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    2AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    3AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    4AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    5AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    6AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    7AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    8AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    9AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    10AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    11AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    12PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    1PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    2PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    3PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    4PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    5PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    6PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    7PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    8PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    9PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    10PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    11PM
                                </div>
                            </div>
                            <div/>
                        </div>

                        {/* Vertical lines */}
                        <div
                            className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                            <div className="col-start-1 row-span-full"/>
                            <div className="col-start-2 row-span-full"/>
                            <div className="col-start-3 row-span-full"/>
                            <div className="col-start-4 row-span-full"/>
                            <div className="col-start-5 row-span-full"/>
                            <div className="col-start-6 row-span-full"/>
                            <div className="col-start-7 row-span-full"/>
                            <div className="col-start-8 row-span-full w-8"/>
                        </div>

                        {/* WEEK Events */}
                        <ol
                            className="hidden col-start-1 col-end-2 row-start-1 sm:grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                            style={{gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto'}}
                        >

                            {currentWeek.map((day, dayIndex) => {
                                    if (!day.isCurrentMonth) {
                                        return (
                                            <li className={classNames("relative mt-px flex", getDayOfWeekClass(dayIndex))}
                                                style={{gridRow: '1 / span 288'}}>
                                                <span
                                                    className={classNames("group absolute inset-0 flex flex-col overflow-y-auto p-2 text-xs leading-5 bg-gray-100 opacity-50")}
                                                />
                                            </li>
                                        )
                                    }

                                const dayEvents = day.events.map((event, eventIndex) => {
                                        const eventStartPosition = (moment(event.date + " " + event.time, "YYYY-MM-DD HH:mm").diff(moment(event.date, 'YYYY-MM-DD'), 'minutes') / 5) + 2;
                                        const eventDuration = (event?.squashedDuration ?? event?.Duration) / 5;

                                        return (
                                            <li
                                                key={dayIndex + eventIndex}
                                                className={classNames(event.hidden ? "hidden" : "", "relative mt-px flex hover:z-20", getDayOfWeekClass(dayIndex))}
                                                style={{gridRow: eventStartPosition + ' / span ' + eventDuration}}>
                                                {renderEventButton(event)}
                                            </li>
                                        )
                                    })

                                return (
                                    <React.Fragment>
                                        <li
                                            className={classNames("relative mt-px flex cursor-pointer", getDayOfWeekClass(dayIndex))}
                                            onClick={(e) => handleDayClick(e, day)}
                                            style={{gridRow: '1 / span 288'}}
                                        />

                                        {dayEvents}
                                    </React.Fragment>
                                )}
                            )}
                        </ol>

                        {/* Day Events (mobile view)*/}
                        <ol
                            className="relative grid col-start-1 col-end-2 row-start-1 sm:hidden grid-cols-1 sm:grid-cols-7 sm:pr-8"
                            style={{gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto'}}
                        >
                            <li
                                className="absolute inset-0 cursor-pointer"
                                onClick={handleDayClick}
                            />

                            {!!selectedDay && sortDayEvents(selectedDay).events.map((event, i) => {
                                const eventStartPosition = (moment(event.date + " " + event.time, "YYYY-MM-DD HH:mm").diff(moment(event.date, 'YYYY-MM-DD'), 'minutes') / 5) + 2;
                                const eventDuration = (event?.squashedDuration ?? event?.Duration) / 5;
                                return (
                                    <li className={classNames(event.hidden ? "hidden" : "", "relative mt-px flex", getDayOfWeekClass(i))}
                                        style={{gridRow: eventStartPosition + ' / span ' + eventDuration}}>
                                        {renderEventButton(event)}
                                    </li>
                                )
                            })}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}
