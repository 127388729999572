import React, {useEffect, useState} from "react";
import {classNames, fieldsToHtml, scrollErrorIntoView} from "../../../util/util";
import {FieldsManager} from "../../../data/services/fields";
import Resources from "../../../data/services/resources";
import moment from "moment";

export default function CreateEventForm(props) {
    const {getTaskFields, getMeetingFields, metadataTask, translate, addFormClass, onSubmit, onClose} = props;

    const [tabs, setTabs] = useState([
        {name: 'Task', current: true},
        {name: 'Interview', current: false},
        {name: 'Meeting', current: false},
    ])

    const [taskFields, setTaskFields] = useState(getTaskFields);
    const [meetingFields, setMeetingFields] = useState(getMeetingFields);

    const [roleQuery, setRoleQuery] = useState({RoleID: 0});
    const [remount, setRemount] = useState(0);

    const handleTabChange = (name) => {
        setTabs(tabs.map((it) => {
            it.current = it.name === name;
            return it;
        }));

        if ("Task" === name) {
            setTaskFields(FieldsManager.updateField(taskFields, "TaskTypeID", 1))
        }

        if ("Interview" === name) {
            setTaskFields(FieldsManager.updateField(taskFields, "TaskTypeID", 2))
        }
    };

    const selectedTab = tabs.find(it => it.current);

    const handleTaskInputChange = (name, value) => {
        setTaskFields(FieldsManager.updateField(taskFields, name, value));
    }

    const handleMeetingInputChange = (name, value) => {
        let meetingFieldsUpdate = meetingFields;

        // Remount fields, by updating element key, to update search-select dropdown
        if ("RoleID" === name) {
            meetingFieldsUpdate = FieldsManager.updateField(meetingFieldsUpdate, "CandidateID", "");
            meetingFieldsUpdate = FieldsManager.updateField(meetingFieldsUpdate, "ContactIDs", "");

            meetingFieldsUpdate.CandidateID.disabled = false;
            meetingFieldsUpdate.ContactIDs.disabled = false;

            setRoleQuery({RoleID: value.value});
            setRemount(remount + 1);
        }

        if ("StartDate" === name) {
            const startDate = moment(meetingFieldsUpdate.StartDate.value, "YYYY-MM-DD HH:mm:ss");
            const endDate = moment(meetingFieldsUpdate.EndDate.value, "YYYY-MM-DD HH:mm:ss");

            const duration = moment.duration(endDate.diff(startDate)).asMinutes();

            const newDate = moment(value, "YYYY-MM-DD HH:mm:ss");
            const newEndDate = newDate.clone().add(duration, "minutes").format("YYYY-MM-DD HH:mm:ss");
            const newEndMinTime = newDate.clone().add(15, "minutes").format("YYYY-MM-DD HH:mm:ss");
            meetingFieldsUpdate.EndDate.value = newEndDate;
            meetingFieldsUpdate.EndDate.props.minDate = newEndDate;
            meetingFieldsUpdate.EndDate.props.minTime = newEndMinTime;
        }

        setMeetingFields(FieldsManager.updateField(meetingFieldsUpdate, name, value));
    }

    const getValidFields = () => {
        let fields = (selectedTab.name === "Meeting") ?  meetingFields : taskFields;

        let validatedFields = FieldsManager.validateFields(fields);

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            if (selectedTab.name === "Meeting") {
                setMeetingFields(validatedFields)
            } else {
                setTaskFields(validatedFields)
            }

            scrollErrorIntoView(validatedFields);
        }
    }

    const getResource = () => {
        return (selectedTab.name === "Meeting") ?  Resources.Meeting : Resources.Tasks;
    }

    const metadataMeeting = {
        CandidateID: {
            api: 'api/' + Resources.Candidates,
            query: roleQuery,
            searchMap: (item) => ({
                value: item.CandidateID,
                label: item.FirstName + " " + item.LastName
            })
        },
        RoleID: {
            api: 'api/' + Resources.Roles,
            query: {},
            searchMap: (item) => ({
                value: item.RoleID,
                label: item.RoleName
            })
        },
        ContactIDs: {
            api: 'api/' + Resources.ClientsContacts,
            query: roleQuery,
            searchMap: (item) => ({
                value: item.ClientContactID,
                label: item.FirstName + " " + item.LastName
            })
        },
    };

    return (
        <div className="pt-4">
            <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() => handleTabChange(tab.name)}
                        className={classNames(
                            tab.current ? 'bg-primary-100 text-primary-900' : 'text-gray-500 hover:text-gray-700',
                            'px-3 py-2 text-sm rounded-md font-semibold'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                    >
                        {tab.name}
                    </button>
                ))}
            </nav>

            <div className="space-y-8 divide-y divide-secondary-200">
                <div
                    key={remount}
                    className={classNames(addFormClass, "mt-6 sm:mt-5 space-y-6 sm:space-y-5")}>
                    {(selectedTab.name === "Task" || selectedTab.name === "Interview") && (
                        fieldsToHtml(Object.values(Object.assign({}, taskFields)), translate, handleTaskInputChange, metadataTask)
                    )}

                    {(selectedTab.name === "Meeting") && (
                        fieldsToHtml(Object.values(Object.assign({}, meetingFields)), translate, handleMeetingInputChange, metadataMeeting)
                    )}
                </div>

                <div className="text-right mt-5">
                    <div className="bg-white -mx-6 -mb-6 px-6 py-4 border-t-2 border-primary-600">
                        <div className="flex justify-end">
                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={(e) => onClose()}
                            >
                                {translate("btn.close")}
                            </button>

                            <button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={() => onSubmit(getValidFields(), getResource())}
                            >
                                {translate("btn.save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}