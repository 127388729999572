import React, {Component} from 'react'
import {BriefcaseIcon, CalendarIcon} from '@heroicons/react/solid'
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {getProp, toFrontDate} from "../../util/util";
import {getResource} from "../../data/actions/resource";
import PublicLayout from "../../components/layout";
import {Link} from "react-router-dom";
import {InformationCircleIcon, LocationMarkerIcon} from "@heroicons/react/outline";
import {Helmet} from "react-helmet/es/Helmet";
import Button from "../../components/button";

class RolePublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editModalOpen: false,
            msgModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                url: this.props.match.params && this.props.match.params.url
            },
            resource: this.getResourceName()
        }));
    };

    /** UI Events
     ================================================================= */


    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.RoleShared;
    }

    /** Render
     ================================================================= */
    render() {
        const {resource} = this.props;

        const role = getProp(this.props, "resource.data", {});

        return (
            <PublicLayout {...this.props}>
                <Helmet>
                    <meta name="description" content={role.RoleName}/>

                    <meta property="og:title" content="Home"/>
                    <meta property="og:url" content={window.location}/>
                    <meta property="og:description" content={role.RoleName}/>

                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:url" content={window.location}/>
                    <meta name="twitter:description" content={role.RoleName}/>
                </Helmet>

                {/* Page heading */}
                <header className="bg-secondary-100 border-b-2 border-primary">
                    <div
                        className="mx-auto p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between">
                        {(!resource.isLoading) && (
                            <div className="flex-1 min-w-0">
                                <h1 className="mt-2 text-2xl font-bold leading-7 text-secondary-900 sm:text-3xl sm:truncate">
                                    {role.RoleName}
                                </h1>
                                {(!resource.isLoading || role.RoleName) && (
                                    <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                        {!!role.Client && (
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <InformationCircleIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {role.Client.ClientName}
                                            </div>
                                        )}
                                        {!!role.LocationName && (
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <LocationMarkerIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {role.LocationName}
                                            </div>
                                        )}
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                           aria-hidden="true"/>
                                            {role.RoleTitle}
                                        </div>
                                        <div className="mt-2 flex items-center text-sm text-secondary-500">
                                            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                          aria-hidden="true"/>
                                            {toFrontDate(role.PostDate)}
                                        </div>


                                    </div>
                                )}

                                {role.RoleStatusID === 3 && (
                                    <div className="p-2 block sm:flex-wrap sm:mt-0 sm:space-x-8">
                                        <span className="text-sm">Role is closed. New applications are disabled.</span>
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="mt-5 flex xl:mt-0 xl:ml-4">
                            <Link to={"/roles/" + role.Token}
                                  className="mt-3 mr-2 w-full inline-flex justify-center rounded-md px-4 py-2 font-medium btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer sm:mt-0 sm:w-auto sm:text-sm">
                                View All Available Roles
                            </Link>

                            {role.RoleStatusID !== 3 && (
                                <Link to={"/register-candidate/" + role.Token + "/" + role.RoleID}
                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm">
                                    Apply For The Role
                                </Link>
                            )}

                            {role.RoleStatusID === 3 && (
                                <Button
                                    disabled={true}
                                    title={"New applications are disabled"}
                                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm">
                                    Apply For The Role
                                </Button>
                            )}
                        </div>
                    </div>



                </header>

                <main className="pt-8 pb-16 sm:px-6 lg:px-8 px-4 relative bg-inverse custom-min-h-tab">
                    <div className="">
                        {/* Content */}
                        <div className="mt-6">

                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Role Information</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">General requirements, and
                                    description.</p>
                            </div>

                            {!!role.AdvDesc && (
                                <div className="mt-5 border-t border-gray-200">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Description</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow"><span
                                                    dangerouslySetInnerHTML={{__html: role.AdvDesc}}/></span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            )}
                            {!!role.Requirements && (
                                <div className="mt-5 border-t border-gray-200">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Requirements</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow"><span
                                                    dangerouslySetInnerHTML={{__html: role.Requirements}}/></span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            )}
                            {!!role.Responsibilities && (
                                <div className="mt-5 border-t border-gray-200">
                                    <dl className="divide-y divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Responsibilities</dt>
                                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow"><span
                                                    dangerouslySetInnerHTML={{__html: role.Responsibilities}}/></span>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-6">
                        {(!resource.isLoading) && (
                            <div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Client Information</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Overview, Awards and Accolades</p>
                            </div>
                        )}

                        {!!role.OrganizationalOverview && (
                            <div className="mt-5 border-t border-gray-200">
                                <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-sm font-medium text-gray-500">Organizational Overview</dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow"><span
                                                    dangerouslySetInnerHTML={{__html: role.OrganizationalOverview}}/></span>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        )}

                        {!!role.AwardsAndAccolades && (
                            <div className="mt-5 border-t border-gray-200">
                                <dl className="divide-y divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                        <dt className="text-sm font-medium text-gray-500">Awards And Accolades</dt>
                                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <span className="flex-grow"><span
                                                    dangerouslySetInnerHTML={{__html: role.AwardsAndAccolades}}/></span>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        )}
                    </div>

                </main>
            </PublicLayout>
        )
    }
}

export default connect(state => state)(RolePublicPage);