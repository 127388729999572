import {ClockIcon} from "@heroicons/react/solid";
import DocumentIcon from "@heroicons/react/solid/DocumentIcon";
import PencilIcon from "@heroicons/react/solid/PencilIcon";
import {classNames} from "../../../util/util"

export default function DailyEvents(props) {
    const {selectedEvents, onEventClick, translate, hasUpdatePerm} = props;
    return (
        <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {!!selectedEvents && selectedEvents.map((event) => {
                const EventIcon = event?.icon ? event.icon : PencilIcon;
                const EventBtnLabel = event?.metadata?.EventBtnLabel ?? translate("btn.edit");
                return (
                    <li key={event.id}
                        className="group relative flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                        <div className="flex-auto">
                            <div className="flex-shrink-0 flex items-center">
                                {!!event.icon && (
                                    <event.icon className={classNames(event.color["text500"], "w-5 h-5 mr-1")}/>
                                )}

                                {!event.icon && (
                                    <div className="w-5 h-5 mr-1 flex-shrink-0 flex items-center justify-center">
                                        <span className={classNames("h-1.5 w-1.5 rounded-full", event.color['bg400'])}/>
                                    </div>
                                )}

                                <p className="font-semibold text-gray-900">{event.name}</p>
                            </div>

                            <time dateTime={event.datetime}
                                  className="mt-2 flex items-center text-gray-700">
                                <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                {event.time}
                            </time>

                            {!!event.metadata?.Notes && (
                                <div className="hidden lg:block mt-2 ">
                                    <DocumentIcon className="mr-2 h-5 w-5 text-gray-400 float-left" aria-hidden="true"/>
                                    {event.metadata.Notes}
                                </div>
                            )}
                        </div>

                        {hasUpdatePerm && (
                            <button
                                onClick={() => onEventClick(event)}
                                className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus:opacity-100"
                            >
                                <span className="hidden sm:inline">{EventBtnLabel}</span>

                                <EventIcon className="w-5 h-5 sm:hidden"/><span className="sr-only">{event.name}</span>
                            </button>
                        )}
                    </li>
                )})
            }
        </ol>
    )
}